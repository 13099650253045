// @import dependencies
import React, { CSSProperties } from 'react'
// @end dependencies

// @import components
import ContactFormModule from 'components/modules/ContactFormModule/ContactFormModule';
import LandingLayout from '../LandingLayout/LandingLayout';
import { AiFillQuestionCircle, AiFillYoutube } from 'react-icons/ai';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
import contanctImg from 'assets/images/landing/contact.png'
// @end assets

// @import styles
import './ContactLandingPage.scss'
import { RiFacebookCircleFill } from 'react-icons/ri';
import { IoLogoWhatsapp } from 'react-icons/io';
import TextComponent from 'components/commons/Text/TextComponent';
// @end styles

interface IContactLandingPageProps {
    className?: string
    style?: CSSProperties
    id?: string
    
}

const ContactLandingPage: React.FC<IContactLandingPageProps> = (props) => {
    return(
        <LandingLayout
            hideFooter
        >
            <div
                className={`contact_landing_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
                id={props.id}
            >
                <div className="container-form-image">
                    <AiFillQuestionCircle
                        className='icon-question'
                    />
                    <ContactFormModule
                        title='Cuéntanos, hablemos'
                        subtitle='ENVÍA TUS DUDAS, SUGERENCIAS Y COMENTARIOS'
                        classNameInput='input-contact-landing'
                        className='contact_landing--form'
                        style={{
                            margin: '0px auto'
                        }}
                    />
                    <div
                        className='container-image-contact'
                    >
                        <img 
                            src={contanctImg} 
                            alt="" 
                        />
                        <div className="container-icons-social">
                            <RiFacebookCircleFill/>
                            <AiFillYoutube/>
                            <IoLogoWhatsapp/>
                        </div>
                        <TextComponent
                            style={{
                                textAlign: 'center',
                                color: 'var(--text2)'
                            }}
                        >
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum quis sapiente, animi blanditiis facilis nobis voluptates nostrum incidunt labore provident?
                        </TextComponent>
                    </div>
                </div>
            </div>
        </LandingLayout>
    );
}

export default ContactLandingPage;