import * as ToolTypes from 'types/tool/tool.types'

export type ITypeStateHardware = 'busy' | 'available' 

export interface IHardware{
    id?: string
    tools?: ToolTypes.ITool[]
    name?: string
    widgets?: any[]
    state?: ITypeStateHardware
    working?: boolean
}

export interface IParamsHardwareCreate{
    hardware: IHardwareInput
}

export interface IParamsHardwareUpdate{
    hardware: IUpdateHardwareInput
    id: string
}

export interface IParamsHardwareDelete{
    id: string
}

export interface IParamsHardwareList{

}

export interface IParamsHardwareGetById{
    id: string
}

interface IHardwareInput{
    tools?: string[]
    widgets?: string[]
    state?: ITypeStateHardware
    working?: boolean
    name: string
}

interface IUpdateHardwareInput{
    tools?: string[]
    widgets?: string[]
    state?: ITypeStateHardware
    working?: boolean
    name?: string
}

// !Aún no se trae los widgets porque no se ha definido si se los va a utilizar
export const fullHardwareQuery = `
    id
    name
    tools{
        ${ToolTypes.fullToolQuery}
    }
    state
    working
`