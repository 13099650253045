import AppLayout from 'components/layouts/AppLayout/AppLayout';
import React, { CSSProperties, useState, useEffect } from 'react'
import { MdLanguage, MdLocationOn, MdSecurity } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { IStoreApp } from 'redux/reducers/index';
import TitlePageComponent from 'components/commons/TitlePage/TitlePageComponent';
import TextComponent from 'components/commons/Text/TextComponent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import FieldEditorComponent from 'components/modules/FieldEditorComponent/FieldEditorComponent';
import moment from 'moment';
import UserService from 'services/user/userService';
import * as AuthActions from 'redux/reducers/auth/auth.actions'
import ChangeLanguagePopUp from 'components/modules/ChangeLanguagePopUp/ChangeLanguagePopUp';
import { useTranslation } from 'react-i18next';
import SubtitleComponent from 'components/commons/Subtitle/SubtitleComponent';
import RecommendedLaboratoryCard from 'components/modules/RecommendedLaboratoryCard/RecommendedLaboratoryCard';
import { useResizeDetector } from 'react-resize-detector';
import ImgProfileComponent from 'components/modules/ImgProfileComponent/ImgProfileComponent';
import ModalChangePassword from 'components/modules/ModalChangePassword/ModalChangePassword';

import './ProfilePage.scss'

import headerImg1 from 'assets/images/profile/headerImg1.png'
import { Link } from 'react-router-dom';

interface IProfilePageProps {
    className?: string
    style?: CSSProperties
    history?: any
}

const ProfilePage: React.FC<IProfilePageProps> = (props) => {

    const [openLanguage, updateOpenLanguage] = useState<boolean>(false)
    const [openPassword, updateOpenPassword] = useState<boolean>(false)
    const { width, ref } = useResizeDetector()
    const user = useSelector((store: IStoreApp) => store.auth.user)
    const dispatch = useDispatch()
    const {t} = useTranslation()

    // @INFO Servicios
    const userService = new UserService()

    /**
     * @INFO Guardar la información del usuario
     * @param data 
     */
    const handleSaveField = async (data: any) => {
        const response = await userService.updateUser(data)
        if(response?.data?.updateUser){
            const newUser = {...response.data.updateUser}
            dispatch(AuthActions.updateUserAction(newUser))
        }
    }

    /**
     * @INFO Cerrar modal de cambiar contraseña y guardar cambios
     * @param data 
     */
    const handleCloseChangePassword = async (data?: any) => {   
        updateOpenPassword(false)
        if(data){
            dispatch(AuthActions.changePasswordAction({
                ...data
            }))
        }
    }

    /**
     * @INFO Subir la imagen de perfil
     * @param file 
     * @returns 
     */
    const handleSaveAvatar = async (file: File) => {
        if(!file) return
        await handleSaveField({
            avatar:  file
        })
    }

    return(
        <AppLayout
            history={props.history}
            headerTitle='Laboratorios virtuales'
        >
            <div
                className={`profile_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
                ref={ref}
            >
                <div className="container-card-fields-profile">
                    <div className="container-header-profile">
                        <img src={headerImg1} className='img-header' alt="" />
                        <ImgProfileComponent
                            src={user?.avatar ? user?.avatar : ''}
                            className='img-profile-header'
                            onLoadFile={handleSaveAvatar}
                        />
                        <div className="container-actions-header">
                            <button
                                className='btn btn-plan-selector'
                            >
                                Plan premium
                            </button>
                            <ChangeLanguagePopUp/>
                        </div>
                    </div>
                    <div className="container-name-user">
                        {width && width > 960 ?
                            <>
                                <div>
                                    <TitlePageComponent>
                                        {`${user?.firstName} ${user?.lastName}`}
                                    </TitlePageComponent>
                                </div>
                                <div className="location-text-component">
                                    <MdLocationOn/>
                                    <TextComponent>
                                        {user?.city} {user?.province} - {user?.country}
                                    </TextComponent>
                                </div>
                            </>
                        : null}
                        <Link
                            to='/my-labs'
                        >
                            <ButtonComponent
                                variant='accent'
                                className='btn-my-laboratories'
                            >
                                {t('profile.my_labs')}
                            </ButtonComponent>
                        </Link>
                    </div>
                    <div className="container-text-movil-name">
                        <SubtitleComponent>
                            {`${user?.firstName} ${user?.lastName}`}
                        </SubtitleComponent>
                        <div className="location-text-component">
                            <MdLocationOn/>
                            <TextComponent>
                                {user?.city} {user?.province} - {user?.country}
                            </TextComponent>
                        </div>
                    </div>
                    <div className="container-fields-list-profile">
                        <FieldEditorComponent
                            defaultValue={user?.firstName}
                            keyApi='firstName'
                            label={t('register.fields.firstName')}
                            // fontSize='1.5rem'
                            className='field-editor-profile'
                            onSave={handleSaveField}
                        />
                        <FieldEditorComponent
                            defaultValue={user?.lastName}
                            keyApi='lastName'
                            label={t('register.fields.lastName')}
                            // fontSize='1.5rem'
                            className='field-editor-profile'
                            onSave={handleSaveField}
                        />
                        <FieldEditorComponent
                            defaultValue={user?.email}
                            keyApi='email'
                            label={t('register.fields.email')}
                            // fontSize='1.5rem'
                            className='field-editor-profile'
                            onSave={handleSaveField}
                        />
                        <FieldEditorComponent
                            defaultValue={moment(user?.birthday).format('YYYY-MM-DD')}
                            keyApi='birthday'
                            label={t('register.fields.birthday')}
                            // fontSize='1.5rem'
                            className='field-editor-profile'
                            onSave={handleSaveField}
                            type='date'
                            isDate
                        />
                        <FieldEditorComponent
                            defaultValue={user?.phoneNumber}
                            keyApi='phoneNumber'
                            label={t('register.fields.phoneNumber')}
                            // fontSize='1.5rem'
                            className='field-editor-profile'
                            onSave={handleSaveField}
                            type='number'
                        />
                        <ButtonComponent
                            onClick={() => updateOpenPassword(true)}
                            variant='primary'
                            style={{width: 'fit-content'}}
                        >
                            <ButtonComponent.Icon>
                                <MdSecurity/>
                            </ButtonComponent.Icon>
                            {t('profile.change_password.name')}
                        </ButtonComponent>
                    </div>
                </div>
                <div className="container-recommended-cards">
                    <SubtitleComponent
                        style={{fontWeight: 600}}
                    >
                        {t('profile.recommended')}
                    </SubtitleComponent>
                    <div className="container-cards-categories">
                        <RecommendedLaboratoryCard/>
                        <RecommendedLaboratoryCard/>
                        <RecommendedLaboratoryCard/>
                        <RecommendedLaboratoryCard/>
                    </div>
                </div>
                <ModalChangePassword 
                    open={openPassword} 
                    onClose={handleCloseChangePassword}
                />
            </div>
        </AppLayout>
    );
}

export default ProfilePage;