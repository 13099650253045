// @import dependencies
import React, { CSSProperties, ReactNode } from 'react';
import TextComponent from '../Text/TextComponent';
// @end dependencies

// @import components
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './HeaderImageCard.scss'
// @end styles

interface IHeaderImageCardProps {
    className?: string
    classNameChildren?: string
    style?: CSSProperties
    id?: string
    title?: string
    image: string
    children: ReactNode
}

const HeaderImageCard: React.FC<IHeaderImageCardProps> = (props) => {

    return(
        <div
            className={`header_image_card-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <div className="header_image_card--image" style={{ background: `url(${props.image})` }}>
                {props.title ? (
                    <TextComponent
                        type='h2'
                        style={{ color: 'white' }}
                    >
                        {props.title}
                    </TextComponent>
                ) : null}
            </div>
            <div className={`header_image_card--container-children ${props.classNameChildren ? props.classNameChildren : ''}`}>
                {props.children}
            </div>
        </div>
    );
}

export default HeaderImageCard;