import { FC } from 'react'

import WindowsImg from 'assets/images/landing/customLandings/SmartFactory/windows.png'
import MacOSImg from 'assets/images/landing/customLandings/SmartFactory/mac.png'

import './SmartFactoryPlatforms.scss'

interface SmartFactoryPlatformsProps {

}

interface Platform{
    name: string
    img: string
}

const PLATFORMS = [
    {
        name: 'Windows',
        img: WindowsImg,
    },
    {
        name: 'Mac OS',
        img: MacOSImg,
    },
]

const SmartFactoryPlatforms: FC<SmartFactoryPlatformsProps> = () => {
    return (
        <div className='smart_factory_platforms--layout'>
            <h1>
                Encuéntranos en distintas plataformas
            </h1>
            <div className="smart_factory_platforms--list">
                {PLATFORMS.map((platform, idx) => (
                    <div key={idx} className="smart_factory_platforms--card">
                        <img src={platform.img} alt="" />
                        <p>{platform.name}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SmartFactoryPlatforms
