// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import MultiSelectComponent, { IOptionSelect } from 'components/commons/MultiSelectComponent/MultiSelectComponent';
// @end components

// @import types
import * as OccupationTypes from 'types/occupation/occupation.types';
// @end types

// @import services
import OccupationService from 'services/occupation/occupationService';
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './OccupationSelectModule.scss'
// @end styles

interface IOccupationSelectModuleProps {
    className?: string
    style?: CSSProperties
    id?: string
    label?: string
    defaultValue?: string[]
    isMultiple?: boolean
    onChange?: (id?: string[], toolCategories?: OccupationTypes.Occupation[]) => any
    onChangeOne?: (id?: string, toolCategory?: OccupationTypes.Occupation) => any
}

const OccupationSelectModule: React.FC<IOccupationSelectModuleProps> = (props) => {

    const [occupations, updateOccupations] = useState<OccupationTypes.Occupation[]>([])
    const [resetSelect, updateResetSelect] = useState<boolean>(false)

    // @INFO Servicios
    const occupationService = new OccupationService();

    useEffect(() => {
        getOccupations()
    },[])

    useEffect(() => {
        updateResetSelect(true)
        setTimeout(() => {
          updateResetSelect(false)
        },1)
    }, [occupations])

    /**
     * @INFO Obtener las ocupaciones
     */
    const getOccupations = async () => {
        const auxOccupations = await occupationService.list();
        updateOccupations(auxOccupations);
    }

    /**
     * @INFO Cambia el selector 
     * @param _options 
     */
    const handleChangeSelect = (_options: IOptionSelect[]) => {
        const _tools = occupations?.filter((_t) => _options.find((_o) => _o.value === _t.id))
        const _tool = _tools?.length ? _tools[0] : undefined
        if(props.onChangeOne){
            props.onChangeOne(_tool?.id, _tool)
        }
        if(props.onChange){
            props.onChange(_tools?.map((_t) => _t.id) as string[], _tools)
        }
    }

    /**
     * @INFO Obtener las opciones por defecto
     * @returns 
     */
    const getDefaultOptions = () => {
        const _options = occupations?.reduce((accum: IOptionSelect[], item) => {
            if(props.defaultValue?.find((_d) => _d === item.id)){
                accum.push({
                    label: item.name ? item.name : '',
                    value: item.id ? item.id : ''
                })
            }
            return accum
        },[])
        return _options
    }

    return(
        <div
            className={`occupation_select_module-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            {!resetSelect ?
                <MultiSelectComponent
                    options={occupations.map((_cat) => { return {label: _cat.name ? _cat.name : '', value: _cat.id ? _cat.id : ''} })}
                    disableMulti={!props.isMultiple}
                    label={props.label}
                    defaultValue={getDefaultOptions()}
                    onChange={(data) => handleChangeSelect(data)}
                />
            :null}
        </div>
    );
}

export default OccupationSelectModule;