// @import types
import { Layout } from 'react-grid-layout';
import * as LaboratoryTypes from 'types/laboratories/laboratories.types';
// @end types

type TypesStateReservation = 'pending' | 'inProgress' | 'finished' | 'missed';
export interface IAvailableHour{
    date: Date
    available: boolean
}

export interface IAvailableHoursParams{
    lab: string
    date: Date
}

export interface INewReserveParams{
    lab: string
    date: Date
}

export interface UpdateReserveParams{
    id: string
    reservation: {
        savedData?: any
        date?: Date
        state?: TypesStateReservation
        layout?: any
    }
}

export interface RemoveReserveParams{
    id: string
}

export interface PublishReserveParams{
    id: string
}

export interface ValidateReserveParams{
    id: string
}

export interface ListReserveParams{
    
}

export interface IReservation{
    id: string
    user: string
    lab: LaboratoryTypes.ILaboratory
    hardware: string
    date: Date
    state: TypesStateReservation
    savedData: any
    layout: Layout[]
    published: boolean
    publicSettings?: PublicSettingsReservation;
    parent: string;
    name?: string;
    slug?: string;
}

export interface PublicSettingsReservation{
    header?: {
        enabled?: boolean;
        title?: string;
        backgroundColor?: string;
    }
    chart?: {
        enableReports?: boolean;
        enableFilterData?: boolean;
        enableSidebar?: boolean;
    }
    source?: {
        enableVoltage?: boolean;
        enableCurrent?: boolean;
    }
    button?: {
        enable?: boolean;
    }
    pushButton?: {
        enable?: boolean;
        selectIcons?: boolean;
    }
}

export const fullAvailableHourQuery = `
    date
    available
`;

export const fullReservationQuery = `
    id
    user
    lab{
        ${LaboratoryTypes.fullLaboratoryQuery}
    }
    hardware
    date
    state
    savedData
    layout
    published
    publicSettings
    parent
    name
    slug
`;