import React, { CSSProperties, useEffect, useState, cloneElement, Children} from 'react'
import ProgressBarComponent from '../ProgressBar/ProgressBarComponent'

import './Stepper.scss'

interface IStepperComponentProps {
    className?: string
    style?: CSSProperties
    children?: any
    index?: number
    showProgress?: boolean
    disableSteps?: boolean
    onChange?: (index: number) => any
}

interface IStepperComponentSubComponents{
    StepperContent: React.FC<IStepperContentProps>
    IconsContent: React.FC<IIconsContentProps>
    Icon: React.FC<IIconProps>
    Content: React.FC<IContentProps>
}

const StepperComponent: React.FC<IStepperComponentProps> & IStepperComponentSubComponents = (props) => {

    const [steps, updateSteps] = useState<any[]>([])

    useEffect(() => {
        if(props.children?.length){
            const newSteps = props.children.reduce((accum: any[], item: any) => {
                accum.push(item)
                return accum
            },[])
            updateSteps([...newSteps])
        }
    }, [props.children])

    const getHeader = () => {
        const extraProps: IIconsContentProps = {
            index: props.index,
            showProgress: props.showProgress,
            onChange: props.onChange
        }
        return cloneElement(props.children[0], extraProps)
    }

    const getBody = () => {
        const extraProps: IStepperContentProps = {
            index: props.index
        }
        return cloneElement(props.children[1], extraProps)
    }

    return(
        <div
            className={`stepper_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            {steps.length === 2 ?
                <div className="container-icons-stepper">
                    {getHeader()}
                    {getBody()}
                </div>
            :null}
        </div>
    );
}

// ==================================================================
// Contenedor de pasos para el stepper
// ==================================================================
interface IStepperContentProps {
    className?: string
    style?: CSSProperties
    children?: any
    index?: number
}

const StepperContent: React.FC<IStepperContentProps> = (props) => {
    return(
        <div
            className={`stepper_content_stepper-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            {props.children?.length ?
                Children.map(props.children, (itemChild, idxChild) => {
                    if(idxChild === props.index){
                        return itemChild
                    }else{
                        return null
                    }
                })
            :null}
        </div>
    );
}

// ==================================================================
// Contenedor de iconos para el stepper
// ==================================================================
interface IIconsContentProps {
    className?: string
    style?: CSSProperties
    children?: any
    index?: number
    showProgress?: boolean
    onChange?: (index: number) => any
}

const IconsContent: React.FC<IIconsContentProps> = (props) => {

    const getChildrenLength = () => {
        if(props.children?.length){
            return props.children?.length
        }else{
            return 0
        }
    }

    const handleChange = (index: number) => {
        if(props.onChange){
            props.onChange(index)
        }
    }

    const getProgress = () => {
        if(props.index !== undefined){
            const length = getChildrenLength()
            const index = props.index ? props.index+1 : 1
            if(length){
                const progress = Math.round((index/length)*100)
                if(progress <= 100 && progress >= 0){
                    return progress
                }else if(progress > 100){
                    return 100
                }else{
                    return 0
                }
            }else{
                return 0
            }
        }else{
            return 0
        }
    }

    const getIcon = (item: any, idx: number) => {
        const currentIdx = props.index ? props.index : 0
        const extraProps: IIconProps = {
            onClick: () => handleChange(idx)
        }
        if(currentIdx >= idx){
            extraProps.active = true
        }else{
            extraProps.active = false
        }
        return cloneElement(item, extraProps)
    }

    return(
        <div
            className={`icons_content_stepper-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <div className="content-icons">
                {props.children?.length ?
                    Children.map(props.children ,(itemIcon: any, idxIcon: number) => (
                        getIcon(itemIcon, idxIcon)
                    ))
                :null}
            </div>
            {props.showProgress ? 
                <ProgressBarComponent 
                    progress={getProgress()} 
                    height='6px'
                /> 
            :null}
        </div>
    );
}

// ==================================================================
// Icono para el stepper
// ==================================================================
interface IIconProps {
    className?: string
    style?: CSSProperties
    children?: any
    active?: boolean
    onClick?: () => any
}

const Icon: React.FC<IIconProps> = (props) => {
    return(
        <div
            className={`icon_stepper-layout ${props.active ? 'active': ''} ${props.className ? props.className : ''}`}
            style={props.style}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    );
}

// ==================================================================
// Contenido para el stepper
// ==================================================================
interface IContentProps {
    className?: string
    style?: CSSProperties
    children?: any
}

const Content: React.FC<IContentProps> = (props) => {
    return(
        <div
            className={`content_stepper-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            {props.children}
        </div>
    );
}

StepperComponent.StepperContent = StepperContent
StepperComponent.IconsContent = IconsContent
StepperComponent.Icon = Icon
StepperComponent.Content = Content

export default StepperComponent;