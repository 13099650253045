import React, { CSSProperties, useState, useEffect } from 'react'

// @import services
import BannerService from 'services/banner/bannerService';
import ObjectService from 'services/object/objectService';
import ScreenNotification from 'services/screenNotification/screenNotification';
// @end services

// @import hooks
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useValidator from 'hooks/useFormValidator/useFormValidator';
import useMoment from 'hooks/useMoment/useMoment';
// @end hooks

// @import components
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import SubtitleComponent from 'components/commons/Subtitle/SubtitleComponent';
import InputComponent from 'components/commons/Input/InputComponent';
import InputFileComponent from 'components/commons/InputFileComponent/InputFileComponent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
// @end components

// @import types
import { IStoreApp } from 'redux/reducers';
import * as BannerTypes from 'types/banners/banners.types'
// @end types

import './CreateBannerPage.scss'
import BreadcrumbComponent from 'components/commons/BreadcrumbComponent/breadcrumbComponent';
import SelectComponent from 'components/commons/SelectComponent/SelectComponent';
import BannerLocationSelect from '../../../modules/BannerLocationSelect/BannerLocationSelect';

interface ICreateBannerPageProps {
    className?: string
    style?: CSSProperties
    history?: any
}

const CreateBannerPage: React.FC<ICreateBannerPageProps> = (props) => {

    const {t} = useTranslation()
    const theme = useSelector((store: IStoreApp) => store.app.theme)
    const { moment } = useMoment()

    const [banner, updateBanner] = useState<BannerTypes.IBanner>({})
    const [loading, updateLoading] = useState<boolean>(false)
    const form = useValidator([
        { name: 'title', required: true, messageError: t('obligatory_field') },
        { name: 'description', required: true, messageError: t('obligatory_field') },
        { name: 'imageFile', required: true, messageError: t('obligatory_field'), loadKey: 'image' },
        { name: 'action.web.name', required: true, messageError: t('obligatory_field') },
        { name: 'action.web.route', required: true, messageError: t('obligatory_field') },
        { name: 'action.movil.name', required: true, messageError: t('obligatory_field') },
        { name: 'action.movil.route', required: true, messageError: t('obligatory_field') },
        { name: 'startDate', required: true, messageError: t('obligatory_field') },
        { name: 'finishDate', required: true, messageError: t('obligatory_field') },
        { name: 'locations', required: true, messageError: t('obligatory_field') },
    ])

    const params = useParams<{idBanner?: string}>()

    // @Servicios
    const objectService = new ObjectService()
    const screenNotification = new ScreenNotification()
    const bannerService = new BannerService()

    useEffect(() => {
        getInitialData()
    }, [])

    useEffect(() => {
        setValuesForm()
    },[banner])

    /**
     * @INFO Obtener la data inicial para el componente
     */
    const getInitialData = async () => {
        getBanner()
    }

    /**
     * @INFO Establecer los valores del laboratorio en el formulario
     */
    const setValuesForm = () => {
        if(banner){
            form.setValues({
                ...banner,
                startDate: moment(banner.startDate).format('YYYY-MM-DD'),
                finishDate: moment(banner.finishDate).format('YYYY-MM-DD'),
            })
        }
    }

    /**
     * @INFO Obtener el banner
     */
    const getBanner = async () => {
        if(!params.idBanner) {
            const auxBanner: BannerTypes.IBanner = {
                title: 'Sin titulo',
                description: 'Sin descripción',
            }
            updateBanner({...auxBanner})
        } else{
            const newBanner = await bannerService.getById({ id: params.idBanner })
            if(newBanner){
                updateBanner(newBanner)
            }
        }
    }

    /**
     * @INFO Click en crear o actualizar el banner
     */
    const handleClickCreateOrUpdate = async () => {
        updateLoading(true)
        // @INFO Obtener el objeto completo del formulario
        const values = form.getValues()
        // @INFO Establecer las propiedades que no son del tipo correcto como indefinidas
        const paramsCreateOrUpdate = objectService.filterPropertyByType({...values}, [
            {name: 'imageFile', type: 'object'},
        ])
        // @INFO Enviar la petición
        let response: any = undefined
        if(params.idBanner){
            response = await bannerService.update({
                banner: paramsCreateOrUpdate,
                id: params.idBanner
            })
            if(response){
                screenNotification.showSuccessTimer()
            }
        }else{
            response = await bannerService.create({
                banner: paramsCreateOrUpdate
            })
            if(response){
                await screenNotification.showSuccessTimer()
                if(props.history){
                    props.history.push(`/edit-banner/${response.id}`)
                }
            }
        }
        updateLoading(false)
    }

    return(
        <AppLayout
            history={props.history}
            headerTitle='Catalogo'
        >
            <div
                className={`create_banner_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
            >
                <BreadcrumbComponent
                    style={{
                        marginBottom: '20px'
                    }}
                >
                    <BreadcrumbComponent.Item
                        title='Catalogador'
                        path='/cataloger'
                    />
                    <BreadcrumbComponent.Item
                        title='Lista'
                        path='/cataloger-list/banner'
                    />
                    <BreadcrumbComponent.Item
                        title={banner?.title ? banner.title : 'Nuevo banner'}
                        current
                    />
                </BreadcrumbComponent>
                <SubtitleComponent
                    style={{ marginBottom: '15px' }}
                >
                    {params.idBanner ? 'Editar banner' : 'Crear banner'}
                </SubtitleComponent>
                <div className="container-form">
                    <InputComponent
                        name='title'
                        label='Titulo'
                        value={form.values['title']}
                        onChange={form.handleChange}
                        invalid={form.errors['title'] ? true : false}
                        invalidText={form.errors['title']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <InputComponent
                        name='description'
                        label='Descripción'
                        value={form.values['description']}
                        onChange={form.handleChange}
                        invalid={form.errors['description'] ? true : false}
                        invalidText={form.errors['description']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <BannerLocationSelect
                        label='Ubicación'
                        defaultValue={banner?.locations?.length ? banner?.locations : undefined}
                        isMultiple
                        onChange={(locations) => form.setValue('locations', locations)}
                    />
                    <InputComponent
                        name='startDate'
                        label='Inicio'
                        type='date'
                        value={form.values['startDate']}
                        onChange={form.handleChange}
                        invalid={form.errors['startDate'] ? true : false}
                        invalidText={form.errors['startDate']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <InputComponent
                        name='finishDate'
                        label='Fin'
                        type='date'
                        value={form.values['finishDate']}
                        onChange={form.handleChange}
                        invalid={form.errors['finishDate'] ? true : false}
                        invalidText={form.errors['finishDate']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <InputComponent
                        name='action.web.name'
                        label='Nombre Web'
                        value={form.values['action.web.name']}
                        onChange={form.handleChange}
                        invalid={form.errors['action.web.name'] ? true : false}
                        invalidText={form.errors['action.web.name']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <InputComponent
                        name='action.web.route'
                        label='Url Web'
                        value={form.values['action.web.route']}
                        onChange={form.handleChange}
                        invalid={form.errors['action.web.route'] ? true : false}
                        invalidText={form.errors['action.web.route']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <InputComponent
                        name='action.movil.name'
                        label='Nombre movil'
                        value={form.values['action.movil.name']}
                        onChange={form.handleChange}
                        invalid={form.errors['action.movil.name'] ? true : false}
                        invalidText={form.errors['action.movil.name']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <InputComponent
                        name='action.movil.route'
                        label='Url movil'
                        value={form.values['action.movil.route']}
                        onChange={form.handleChange}
                        invalid={form.errors['action.movil.route'] ? true : false}
                        invalidText={form.errors['action.movil.route']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <InputFileComponent
                        as='image'
                        name='imageFile'
                        label='Imagen'
                        value={form.values['imageFile']}
                        onChange={form.handleChange}
                        invalid={form.errors['imageFile'] ? true : false}
                        invalidText={form.errors['imageFile']}
                    />
                    <div
                        style={{ gridColumn: '1/3', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                    >
                        <ButtonComponent
                            onClick={handleClickCreateOrUpdate}
                            variant='primary'
                            disabled={form.isInvalid()}
                            loading={loading}
                        >
                            {params.idBanner ? 'Guardar' : 'Crear'}
                        </ButtonComponent>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

export default CreateBannerPage;