// @import dependencies
import ImageComponent from 'components/commons/ImageComponent/ImageComponent';
import React, { CSSProperties } from 'react';
// @end dependencies

// @import components
// @end components

// @import types
import * as ToolTypes from 'types/tool/tool.types';
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ImageModuleDashboard.scss'
// @end styles

interface IImageModuleDashboardProps {
    tool: ToolTypes.ITool
    className?: string
    style?: CSSProperties
    id?: string
}

const ImageModuleDashboard: React.FC<IImageModuleDashboardProps> = (props) => {
    return(
        <div
            className={`image_module_dashboard-layout ${props.className ? props.className : ''}`}
            style={{
                ...props.style
            }}
            id={props.id}
        >
            <ImageComponent
                src={props.tool?.config?.url ? props.tool?.config?.url : ''}
                alt={props.tool?.name}
                className='image_module_dashboard--image'
            />
        </div>
    );
}

export default ImageModuleDashboard;
