import { FC } from 'react'

import FooterImage from 'assets/images/landing/customLandings/SmartFactory/footer.png'
import VlesimIcon from 'assets/images/Vlesim_white.png'

import './SmartFactoryFooter.scss'

interface SmartFactoryFooterProps{

}

const SmartFactoryFooter: FC<SmartFactoryFooterProps> = () => {
    return(
        <div className="smart_factory_footer--layout">
            <img src={VlesimIcon} alt="Vlesim-icon" />
            <img src={FooterImage} alt="footer-smart-factory" />
        </div>
    )
}

export default SmartFactoryFooter