import React, {useState, useEffect} from 'react';
import { UseChangeStateInput, UseChangeStateOutput } from './useChangeState.types';

export function useChangeState<T>(params: UseChangeStateInput<T>): UseChangeStateOutput<T>{

    const [previous, updatePrevious] = useState<T | undefined>(params.variable);
    const [current, updateCurrent] = useState<T | undefined>(undefined);

    useEffect(() => {
        updatePrevious(current ? {...current} : undefined);
        updateCurrent(params.variable);
    }, [params.variable]);

    return {
        previous,
        current
    }
}