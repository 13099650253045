// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import hooks
import { useParams } from 'react-router';
import useValidator from 'hooks/useFormValidator/useFormValidator';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// @end hooks

// @import types
import { IStoreApp } from 'redux/reducers/index';
import * as LaboratoryTypes from 'types/laboratories/laboratories.types'
import * as LaboratoryCategoryTypes from 'types/laboratoryCategory/laboratoryCategory.types'
import * as SubareaTypes from 'types/subarea/subarea.types'
import { IFieldFormValidator } from 'hooks/useFormValidator/useFormValidator.types';
// @end types

// @import components
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import InputComponent from 'components/commons/Input/InputComponent';
import { Form } from 'react-bootstrap';
import ModalEditorJs from './components/ModalEditorJs/ModalEditorJs';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import { MdModeEdit } from 'react-icons/md';
import SubtitleComponent from 'components/commons/Subtitle/SubtitleComponent';
import InputFileComponent from 'components/commons/InputFileComponent/InputFileComponent';
import RenderOptionsCategory from 'components/modules/RenderOptionsCategory/RenderOptionsCategory';
import LabelComponent from 'components/commons/LabelComponent/LabelComponent';
import BreadcrumbComponent from 'components/commons/BreadcrumbComponent/breadcrumbComponent';
import CheckBoxComponent from 'components/commons/CheckBoxComponent/CheckBoxComponent';
import UsersSelectModule from 'components/modules/UsersSelectModule/UsersSelectModule';
// @end components

// @import services
import LaboratoryService from 'services/laboratory/laboratoryService';
import LaboratoryCategoryService from 'services/laboratoryCategory/laboratoryCategoryService';
import ObjectService from 'services/object/objectService';
import ScreenNotification from 'services/screenNotification/screenNotification';
import SubareaService from 'services/subarea/subareaService';
// @end services

// @import styles
import './CreateLaboratoryPage.scss'
import ToolSelectModule from 'components/modules/ToolSelectModule/ToolSelectModule';
import TextComponent from 'components/commons/Text/TextComponent';
import CopyTextComponent from 'components/commons/CopyTextComponent/CopyTextComponent';
import LaboratoryDashboardForm from './components/LaboratoryDashboardForm/LaboratoryDashboardForm';
// @end styles

interface ICreateLaboratoryPageProps {
    className?: string
    style?: CSSProperties
    history?: any
}

const CreateLaboratoryPage: React.FC<ICreateLaboratoryPageProps> = (props) => {

    const {t} = useTranslation()
    const theme = useSelector((store: IStoreApp) => store.app.theme)

    const [categories, updateCategories] = useState<LaboratoryCategoryTypes.ILaboratoryCategory[]>([])
    const [currentCategory, updateCurrentCategory] = useState<LaboratoryCategoryTypes.ILaboratoryCategory | undefined>(undefined)
    const [subareas, updateSubareas] = useState<SubareaTypes.ISubarea[]>([])
    const [laboratory, updateLaboratory] = useState<LaboratoryTypes.ILaboratory>({})
    const [firstLoad, updateFirstLoad] = useState<boolean>(false)
    const [openObjectives, updateOpenObjectives] = useState<boolean>(false)
    const [openProcedure, updateOpenProcedure] = useState<boolean>(false)
    const [loading, updateLoading] = useState<boolean>(false)
    const [triggerAddFields, updateTriggerAddFields] = useState<any>({})
    const [triggerRemoveFields, updateTriggerRemoveFields] = useState<any>({})
    const form = useValidator([
        { name: 'name', required: true, messageError: t('obligatory_field') }, 
        { name: 'description', required: true, messageError: t('obligatory_field') }, 
        { name: 'subarea', required: true, messageError: t('obligatory_field') }, 
        { name: 'category', required: true, messageError: t('obligatory_field') }, 
        { name: 'thumbnailFile', required: true, messageError: t('obligatory_field'), loadKey: 'thumbnail' }, 
        { name: 'generalSchemeFile', required: true, messageError: t('obligatory_field'), loadKey: 'generalScheme' },
        { name: 'price', required: true, messageError: t('obligatory_field') },
        { name: 'objectives' }, 
        { name: 'procedure' },
        { name: 'private' },
        { name: 'users' },
        { name: 'config.widgets' },
        { name: 'config.overwriteLayout' },
    ])

    const params = useParams<{idLab?: string}>()

    // @Servicios
    const laboratoryService = new LaboratoryService()
    const laboratoryCategoryService = new LaboratoryCategoryService()
    const objectService = new ObjectService()
    const screenNotification = new ScreenNotification()
    const subareaService = new SubareaService()

    useEffect(() => {
        getInitialData()
    }, [])

    useEffect(() => {
        if(categories?.length && !firstLoad){
            updateFirstLoad(true)
            getLaboratory()
        }
    },[categories])

    useEffect(() => {
        setValuesForm()
    },[laboratory])

    useEffect(() => {
        if(form.values['category']){
            const _category = categories.find((item) => item.id === form.values['category'])
            // configFields(_category)
            updateCurrentCategory(_category)
            updateTriggerAddFields({})
        }
    },[form.values['category']])

    useEffect(() => {
        configFieldsAdd({...currentCategory})
    }, [triggerAddFields])
    
    useEffect(() => {
        configFieldsRemove({...currentCategory})
    }, [triggerRemoveFields])

    /**
     * @INFO Configurar los campos del formulario
     */
    const configFieldsAdd = (_category?: LaboratoryCategoryTypes.ILaboratoryCategory) => {
        const addFields: IFieldFormValidator[] = []
        // @INFO Duración
        if(_category?.config?.hasDuration){
            addFields.push(
                { name: 'config.duration', required: true, messageError: t('obligatory_field'), value: laboratory?.config?.duration?.toString() }
            )
        }

        // @INFO Realidad virtual
        if(_category?.config?.hasVirtualReality){
                addFields.push({ name: 'config.virtualReality.video.platform', required: true, messageError: t('obligatory_field'), value: laboratory?.config?.virtualReality?.video?.platform })
                addFields.push({ name: 'config.virtualReality.video.id', required: true, messageError: t('obligatory_field'), value: laboratory?.config?.virtualReality?.video?.id })
                addFields.push({ name: 'config.virtualReality.carousel', required: true, messageError: t('obligatory_field'), value: [] })
                addFields.push({ name: 'config.virtualReality.resources.webFile', required: true, messageError: t('obligatory_field'), value: laboratory?.config?.virtualReality?.resources?.web, loadKey: 'config.virtualReality.resources.web' })
                addFields.push({ name: 'config.virtualReality.resources.windowsFile', messageError: t('obligatory_field'), value: laboratory?.config?.virtualReality?.resources?.windows, loadKey: 'config.virtualReality.resources.windows' })
                addFields.push({ name: 'config.virtualReality.resources.androidFile', messageError: t('obligatory_field'), value: laboratory?.config?.virtualReality?.resources?.android, loadKey: 'config.virtualReality.resources.android' })
                addFields.push({ name: 'config.virtualReality.resources.iosFile', messageError: t('obligatory_field'), value: laboratory?.config?.virtualReality?.resources?.ios, loadKey: 'config.virtualReality.resources.ios' })
        }

        if(_category?.config?.hasHardwareDuration){
            addFields.push(
                { name: 'config.hardwareDuration', required: true, messageError: t('obligatory_field'), value: laboratory?.config?.hardwareDuration?.toString() }
            )
        }

        if(_category?.config?.hasHardware){
            addFields.push(
                { name: 'config.hardware', required: true, messageError: t('obligatory_field'), value: laboratory?.config?.hardware?.map((h) => h.id) }
            )
        }

        if(_category?.config?.hasMultipleDashboards){
            addFields.push(
                { name: 'dashboards', required: true, messageError: t('obligatory_field'), value: laboratory?.dashboards }
            )
        }

        // @INFO Agregar campos
        form.addFields(addFields)
        updateTriggerRemoveFields({})
    }
    
    /**
     * @INFO Configurar los campos del formulario
     */
    const configFieldsRemove = (_category?: LaboratoryCategoryTypes.ILaboratoryCategory) => {
        const removeFields: string[] = []

        // @INFO Duración
        if(!_category?.config?.hasDuration){
            removeFields.push('config.duration')
        }

        // @INFO Realidad virtual
        if(!_category?.config?.hasVirtualReality){
            removeFields.push('config.virtualReality.video.platform')
            removeFields.push('config.virtualReality.video.id')
            removeFields.push('config.virtualReality.carousel')
            removeFields.push('config.virtualReality.resources.webFile')
            removeFields.push('config.virtualReality.resources.windowsFile')
            removeFields.push('config.virtualReality.resources.androidFile')
            removeFields.push('config.virtualReality.resources.iosFile')
        }

        if(!_category?.config?.hasHardwareDuration){
            removeFields.push('config.hardwareDuration')
        }

        if(!_category?.config?.hasHardware){
            removeFields.push('config.hardware')
        }

        if(!_category?.config?.hasMultipleDashboards){
            removeFields.push('dashboards')
        }

        // @INFO Eliminar campos campos
        form.removeFields(removeFields)
    }

    /**
     * @INFO Establecer los valores del laboratorio en el formulario
     */
    const setValuesForm = () => {
        if(laboratory){
            form.setValues({
                ...laboratory,
                category: laboratory?.category?.id,
                subarea: laboratory?.subarea?.id
            })
        }
    }

    /**
     * @INFO Obtener la data inicial para el componente
     */
    const getInitialData = async () => {
        await getLaboratoryCategories()
        await getSubareas()
    }

    /**
     * @INOF Obtener la lista de categorias
     */
    const getLaboratoryCategories = async () => {
        const _categories = await laboratoryCategoryService.list()
        updateCategories(_categories)
    }

    /**
     * @INOF Obtener la lista de subareas
     */
     const getSubareas = async () => {
        const _subareas = await subareaService.list()
        updateSubareas(_subareas)
    }

    /**
     * @INFO Obtener el laboratorio
     */
    const getLaboratory = async () => {
        if(!params.idLab) {
            const auxLab = {
                category: categories?.length ? categories[0] : undefined,
                name: 'Sin titulo',
                description: 'Sin descripción'
            }
            updateLaboratory({...auxLab})
        } else{
            const newLab = await laboratoryService.getById({ id: params.idLab })
            if(newLab){
                updateLaboratory(newLab)
            }
        }
    }

    /**
     * @INFO Click en crear o actualizar el laboratorio
     */
    const handleClickCreateOrUpdate = async () => {
        updateLoading(true)
        // @INFO Obtener el objeto completo del formulario
        const values = form.getValues()
        // @INFO Establecer las propiedades que no son del tipo correcto como indefinidas
        const paramsCreateOrUpdate = objectService.filterPropertyByType({...values}, [
            {name: 'thumbnailFile', type: 'object'},
            {name: 'generalSchemeFile', type: 'object'},
            {name: 'config.virtualReality.resources.webFile', type: 'object'},
            {name: 'config.virtualReality.resources.windowsFile', type: 'object'},
            {name: 'config.virtualReality.resources.androidFile', type: 'object'},
            {name: 'config.virtualReality.resources.iosFile', type: 'object'},
        ])
        if(paramsCreateOrUpdate.price){
            paramsCreateOrUpdate.price = Number(paramsCreateOrUpdate.price)
        }
        // @INFO Enviar la petición
        let response: any = undefined
        if(params.idLab){
            response = await laboratoryService.update(paramsCreateOrUpdate, params.idLab)
            if(response){
                screenNotification.showSuccessTimer()
            }
        }else{
            response = await laboratoryService.create(paramsCreateOrUpdate)
            if(response){
                await screenNotification.showSuccessTimer()
                if(props.history){
                    props.history.push(`/edit-laboratory/${response.id}`)
                }
            }
        }
        updateLoading(false)
    }

    return(
        <AppLayout
            history={props.history}
            headerTitle='Catalogo'
        >
            <div
                className={`create_laboratory_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
            >
                <BreadcrumbComponent
                    style={{
                        marginBottom: '20px'
                    }}
                >
                    <BreadcrumbComponent.Item
                        title='Catalogador'
                        path='/cataloger'
                    />
                    <BreadcrumbComponent.Item
                        title='Lista'
                        path='/cataloger-list/laboratory'
                    />
                    <BreadcrumbComponent.Item
                        title={laboratory?.name ? laboratory.name : 'Nuevo laboratorio'}
                        current
                    />
                </BreadcrumbComponent>
                <SubtitleComponent
                    style={{ marginBottom: '15px' }}
                >
                    {params.idLab ? 'Editar laboratorio' : 'Crear laboratorio'}
                </SubtitleComponent>
                {laboratory?.id ?
                    <div
                        style={{
                            marginBottom: '20px'
                        }}
                    >
                        <TextComponent
                            type='label'
                        >
                            ID: 
                        </TextComponent>
                        <CopyTextComponent
                            text={laboratory.id}
                            style={{
                                width: '250px'
                            }}
                        />
                    </div> 
                :null}
                <div className="container-form">
                    <InputComponent
                        name='name'
                        label='Nombre'
                        value={form.values['name']}
                        onChange={form.handleChange}
                        invalid={form.errors['name'] ? true : false}
                        invalidText={form.errors['name']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <InputComponent
                        name='description'
                        label='Descripción'
                        value={form.values['description']}
                        onChange={form.handleChange}
                        invalid={form.errors['description'] ? true : false}
                        invalidText={form.errors['description']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <InputComponent
                        name='price'
                        label='Precio en dolares (USD)'
                        type='number'
                        value={form.values['price']}
                        onChange={form.handleChange}
                        invalid={form.errors['price'] ? true : false}
                        invalidText={form.errors['price']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <CheckBoxComponent
                        name='config.overwriteLayout'
                        checked={form.values['config.overwriteLayout']}
                        label='Sobrescribir layout'
                        onChange={form.handleChange}
                    />
                    <CheckBoxComponent
                        name='private'
                        checked={form.values['private']}
                        label='Privado'
                        onChange={form.handleChange}
                    />
                    {form.values['private'] && laboratory ?
                        <UsersSelectModule
                            label='Usuarios'
                            isMultiple
                            onChange={(_ids) => form.setValue('users', _ids)}
                            defaultValue={laboratory?.users?.map((u) => u?.id as string)}
                            initialUsers={laboratory?.users}
                        />
                    : null}
                    {laboratory ?
                        <ToolSelectModule
                            isMultiple
                            label='Widgets'
                            defaultValue={laboratory?.config?.widgets?.length ? laboratory.config.widgets.map((_t) => _t.id as string) : undefined}
                            onChange={(_ids) => form.setValue('config.widgets', _ids)}
                        />
                    :null}
                    <div
                        className='container-images-form-general'
                    >
                        <InputFileComponent
                            as='image'
                            name='thumbnailFile'
                            label='Miniatura'
                            value={form.values['thumbnailFile']}
                            onChange={form.handleChange}
                            invalid={form.errors['thumbnailFile'] ? true : false}
                            invalidText={form.errors['thumbnailFile']}
                        />
                        <InputFileComponent
                            as='image'
                            name='generalSchemeFile'
                            label='Esquema'
                            value={form.values['generalSchemeFile']}
                            onChange={form.handleChange}
                            invalid={form.errors['generalSchemeFile'] ? true : false}
                            invalidText={form.errors['generalSchemeFile']}
                        />
                    </div>
                    <Form.Group 
                        controlId="exampleForm.ControlSelect1"
                        // style={{ gridColumn: '1/3' }}
                    >
                        <LabelComponent>
                            Subarea
                        </LabelComponent>
                        <Form.Control 
                            as="select"
                            name='subarea'
                            onChange={form.handleChange}
                            value={form.values['subarea']}
                        >
                            <option selected>-- Subarea --</option>
                            {subareas?.map((item) => (
                                <option
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </option>
                            ))}
                        </Form.Control>
                        {form.errors['subarea'] ? 
                            <LabelComponent style={{ color: '#dc3545', fontWeight: 400 }} >
                                {form.errors['subarea']}
                            </LabelComponent>
                        : null}
                    </Form.Group>
                    <Form.Group 
                        controlId="exampleForm.ControlSelect1"
                        style={{ gridColumn: '1/3' }}
                    >
                        <LabelComponent>
                            Categoría
                        </LabelComponent>
                        <Form.Control 
                            as="select"
                            name='category'
                            onChange={form.handleChange}
                            value={form.values['category']}
                        >
                            <option selected>-- Categoría --</option>
                            {categories?.map((item) => (
                                <option
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </option>
                            ))}
                        </Form.Control>
                        {form.errors['category'] ? 
                            <LabelComponent style={{ color: '#dc3545', fontWeight: 400 }} >
                                {form.errors['category']}
                            </LabelComponent>
                        : null}
                    </Form.Group>
                    <div
                        style={{ gridColumn: '1/3' }}
                    >
                        {categories.find((item) => item.id === form.values['category']) ?
                            <RenderOptionsCategory
                                category={categories.find((item) => item.id === form.values['category']) as LaboratoryCategoryTypes.ILaboratoryCategory}
                                form={form}
                                laboratory={laboratory}
                            />
                        :null}
                    </div>
                    <ButtonComponent
                        onClick={() => updateOpenObjectives(true)}
                        variant='primary2'
                        style={{ color: 'white' }}
                    >
                        <ButtonComponent.Icon>
                            <MdModeEdit color='white' />
                        </ButtonComponent.Icon>
                        Objetivos
                    </ButtonComponent>
                    <ButtonComponent
                        onClick={() => updateOpenProcedure(true)}
                        variant='primary2'
                        style={{ color: 'white' }}
                    >
                        <ButtonComponent.Icon>
                            <MdModeEdit color='white' />
                        </ButtonComponent.Icon>
                        Procedimiento
                    </ButtonComponent>
                    <div
                        style={{ gridColumn: '1/3', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                    >
                        <ButtonComponent
                            onClick={handleClickCreateOrUpdate}
                            variant='primary'
                            disabled={form.isInvalid()}
                            loading={loading}
                        >
                            {params.idLab ? 'Guardar' : 'Crear'}
                        </ButtonComponent>
                    </div>
                </div>
            </div>
            <ModalEditorJs
                open={openObjectives}
                onClose={() => updateOpenObjectives(false)}
                title='Objetivos'
                name={'objectives'}
                value={form.values['objectives']}
                onChangeForm={form.handleChange}
            />
            <ModalEditorJs
                open={openProcedure}
                onClose={() => updateOpenProcedure(false)}
                title='Procedimiento'
                name={'procedure'}
                value={form.values['procedure']}
                onChangeForm={form.handleChange}
            />
        </AppLayout>
    );
}

export default CreateLaboratoryPage;