// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import MultiSelectComponent from 'components/commons/MultiSelectComponent/MultiSelectComponent';
// @end components

// @import types
import { IOptionSelect } from 'components/commons/MultiSelectComponent/MultiSelectComponent';
import * as HardwareTypes from 'types/hardware/hardware.types';
// @end types

// @import services
import HardwareService from 'services/hardware/hardwareService';
import ObjectService from 'services/object/objectService';
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './HardwareSelectModule.scss'
// @end styles

interface IHardwareSelectModuleProps {
    className?: string
    style?: CSSProperties
    id?: string
    label?: string
    defaultValue?: string[]
    isMultiple?: boolean
    onChange?: (id?: string[], toolCategories?: HardwareTypes.IHardware[]) => any
    onChangeOne?: (id?: string, toolCategory?: HardwareTypes.IHardware) => any
}

const HardwareSelectModule: React.FC<IHardwareSelectModuleProps> = (props) => {

    const [hardwares, updateHardwares] = useState<HardwareTypes.IHardware[]>([])
    const [resetSelect, updateResetSelect] = useState<boolean>(false)

    // @INFO Servicios
    const hardwareService = new HardwareService()
    const objectService = new ObjectService();

    useEffect(() => {
        getHardwares()
    },[])

    useEffect(() => {
        updateResetSelect(true)
        setTimeout(() => {
          updateResetSelect(false)
        },1)
    }, [hardwares])

    /**
     * @INFO Obtener las herramientas
     */
    const getHardwares = async () => {
        const _tools = await hardwareService.list()
        updateHardwares(_tools)
    }

    /**
     * @INFO Cambia el selector 
     * @param _options 
     */
    const handleChangeSelect = (_options: IOptionSelect[]) => {
        const _tools = hardwares?.filter((_t) => _options.find((_o) => _o.value === _t.id))
        const _tool = _tools?.length ? _tools[0] : undefined
        const newTools = _tools.reduce((accumTools: HardwareTypes.IHardware[], tool) => {
            accumTools.push(objectService.filterPropertyByType(_tool, [ { name: '__typename', type: 'number'} ]))
            return accumTools;
        }, []);
        if(props.onChangeOne){
            props.onChangeOne(_tool?.id, newTools[0])
        }
        if(props.onChange){
            props.onChange(_tools?.map((_t) => _t.id) as string[], newTools)
        }
    }

    /**
     * @INFO Obtener las opciones por defecto
     * @returns 
     */
    const getDefaultOptions = () => {
        const _options = hardwares?.reduce((accum: IOptionSelect[], item) => {
            if(props.defaultValue?.find((_d) => _d === item.id)){
                accum.push({
                    label: item.name ? item.name : '',
                    value: item.id ? item.id : ''
                })
            }
            return accum
        },[])
        return _options
    }

    return(
        <div
            className={`hardware_select_module-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            {!resetSelect ?
                <MultiSelectComponent
                    options={hardwares.map((_cat) => { return {label: _cat.name ? _cat.name : '', value: _cat.id ? _cat.id : ''} })}
                    disableMulti={!props.isMultiple}
                    label={props.label}
                    defaultValue={getDefaultOptions()}
                    onChange={(data) => handleChangeSelect(data)}
                />
            :null}
        </div>
    );
}

export default HardwareSelectModule;