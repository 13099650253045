import React from 'react';
import styled from 'styled-components'
import shortId from 'shortid';
import parse from 'html-react-parser';

export interface TableEditorJsProps {
  data: {content: (string[])[]}
}

const Container = styled.table`
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  margin-bottom: 15px;
  &>tbody{
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    &>tr{
      display: table-row;
      vertical-align: inherit;
      border-color: inherit;
      .tc-table__cell{
        border: 1px solid #dbdbe2;
        padding: 0;
        vertical-align: top;
        .tc-table__area{
          padding: 10px;
          height: 100;
          .tc-table__inp{
            outline: none;
            flex-grow: 100;
            min-height: 1.5em;
            height: 100%;
            overflow: hidden;
            color: var(--text);
          }
        }
      }
    }
  }
`;

const TableEditorJs: React.SFC<TableEditorJsProps> = (props) => {
  return (
    <Container>
      <tbody>
        {props.data.content.map(itemRow => (
          <tr key={shortId.generate()} >
            {itemRow.map(itemCol => (
              <td key={shortId.generate()} className='tc-table__cell' >
                <div className="tc-table__area">
                  <div className="tc-table__inp">
                    {parse(itemCol)}
                  </div>
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Container>
  );
}

export default TableEditorJs;
