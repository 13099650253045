import React, { CSSProperties, cloneElement, Children } from 'react'
import { Link } from 'react-router-dom'

import './breadcrumbComponent.scss'

interface IBreadcrumbComponentProps {
  className?: string
  style?: CSSProperties
  history?: any
  children?: any
}

interface IBreadcrumbComponentComponentsProps{
  Item: React.FC<IItemProps>
}

const BreadcrumbComponent: React.FC<IBreadcrumbComponentProps> & IBreadcrumbComponentComponentsProps = (props) => {

  const getItem = (item: any) => {
    if(!item) return null
    const extraProps = {
      history: props.history
    }
    return cloneElement(item, extraProps)
  }

  return(
    <div
      className={`breadcrumb_component-layout ${props.className ? props.className : ''}`}
      style={props.style}
    >
      {
        Children.map(props.children, (item, idx) => (
          <>
            {idx > 0 ?
              <p>|</p>
            : null}
            {getItem(item)}
          </>
        ))
      }
    </div>
  );
}

interface IItemProps {
  className?: string
  style?: CSSProperties
  current?: boolean
  path?: string
  title: string
}

const Item: React.FC<IItemProps> = (props) => {

  return(
    props.path ?
      <Link
        to={props.path}
        style={{
          textDecoration: 'none'
        }}
      >
        <p
          className={`item_breadcrumb_component-layout ${!props.current ? 'hover-text' : ''} ${props.className ? props.className : ''}`}
          style={props.style}
        >
          {props.title}
        </p>
      </Link>
    :
    <p
      className={`item_breadcrumb_component-layout ${!props.current ? 'hover-text' : ''} ${props.className ? props.className : ''}`}
      style={props.style}
    >
      {props.title}
    </p>
  );
}

BreadcrumbComponent.Item = Item

export default BreadcrumbComponent;
