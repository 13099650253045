// @import dependencies
import React, { CSSProperties, useEffect } from 'react'
// @end dependencies

// @import components
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import NotificationItem from 'components/commons/NotificationItem/NotificationItem';
import TextComponent from 'components/commons/Text/TextComponent';
import { AiOutlineFolderOpen, AiOutlineSetting } from 'react-icons/ai';
// @end components

// @import types
import { IStoreApp } from 'redux/reducers/index';
// @end types

// @import services
// @end services

// @import hooks
import { useSelector } from 'react-redux';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './NotificationsList.scss'
// @end styles

interface INotificationsListProps {
    className?: string
    style?: CSSProperties
    id?: string
    
}

const NotificationsList: React.FC<INotificationsListProps> = (props) => {
    const notifications = useSelector((store: IStoreApp) => store.notifications.notifications);

    
    
    return(
        <div
            className={`notifications_list-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <div className="header-card-list-notifications">
                <TextComponent
                    type='text'
                    style={{fontWeight: 600}}
                >
                    Notificaciones
                </TextComponent>
                {/* <AiOutlineSetting
                    className='icon-setting-notifications'
                /> */}
            </div>
            <div className="container-list-notifications">
                {notifications.length ?
                    notifications.map((n) => (
                        <NotificationItem
                            key={n.id}
                            title={n.title}
                            description={n.description}
                            date={n.date}
                            type={n.type}
                            data={n.data}
                            read={n.read}
                            id={n.id}
                        />
                    ))
                : null}
            </div>
            {/* <div className="container-actions-notifications">
                <ButtonComponent
                    variant='accent'
                    style={{color: 'white', textTransform: 'capitalize'}}
                    iconColor='white'
                >
                    <ButtonComponent.Icon>
                        <AiOutlineFolderOpen/>
                    </ButtonComponent.Icon>
                    Ver Todas
                </ButtonComponent>
            </div> */}
        </div>
    );
}

export default NotificationsList;