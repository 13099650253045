import React, { CSSProperties } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Placement } from 'react-bootstrap/esm/Overlay';
import shortid from 'shortid';

export interface TooltipComponentProps {
    text: string
    children?: any
    placement?: Placement
    style?: CSSProperties
    className?: string
}
 
const TooltipComponent: React.SFC<TooltipComponentProps> = (props) => {
    return (  
        <OverlayTrigger
            overlay={
                <Tooltip id={shortid.generate()} >
                    {props.text}
                </Tooltip>
            }
            placement={props.placement}
        >
            <div
                style={props.style}
                className={props.className}
            >
                {props.children}
            </div>
        </OverlayTrigger>
    );
}
 
export default TooltipComponent;