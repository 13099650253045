import { IFieldFormValidator, IValidatorFieldFormValidator } from "./useFormValidator.types"

class UseFormValidatorValidators{
    constructor(){

    }

    /**
     * @INFO Validar un campo
     * @param value 
     */
    public isValid = (fields: IFieldFormValidator[], value?: string, validator?: IValidatorFieldFormValidator): boolean => {
        if(!validator) return true
        if(!value) return true
        switch(validator.type){
            case 'email':
                return this.emailValidator(value)
            case 'length':
                return this.lengthValidator(value, validator.min, validator.max)
            case 'regexp':
                return this.regexpValidator(value, validator.regexp)
            case 'equal':
                return this.equalValidator(fields, value, validator?.key)
            case 'different':
                return this.differentValidator(fields, value, validator?.key)
            case 'number':
                return true
            case 'range':
                return this.rangeValidator(value, validator.min, validator.max)
            default:
                return true
        }
    }

    /**
     * @INFO Validar email
     */
    private emailValidator = (value: string): boolean => {
        const regExp = new RegExp(/.+\@\w+(\.\w+)+/g)
        return regExp.test(value)
    }

    /**
     * @INFO Validar longitud de un string
     * @param value 
     * @param min 
     * @param max 
     */
    private lengthValidator = (value: string, min?: number, max?: number): boolean => {
        if(min === undefined && max === undefined){
            return true
        }else{
            let response = true
            if(min !== undefined && value?.length < min){
                response = false
            }
            if(response && max !== undefined && value?.length > max){
                response = false
            }
            return response
        }
    }

    /**
     * @INFO Validar expresión regular
     * @param value 
     * @param regexp 
     */
    private regexpValidator = (value: string, regexp?: RegExp) => {
        if(!regexp) return true
        return regexp.test(value)
    }

    /**
     * @INFO Validar igualdad entre campos
     * @param fields 
     * @param value 
     */
    private equalValidator = (fields: IFieldFormValidator[], value: string, to?: string) => {
        if(!to || !fields?.length || !value) return true
        const fieldToCompare = fields.find((item) => item.name === to)
        if(!fieldToCompare) return true
        if(fieldToCompare.value === value){
            return true
        }else{
            return false
        }
    }

    /**
     * @INFO Validar diferencia entre campos
     * @param fields 
     * @param value 
     */
     private differentValidator = (fields: IFieldFormValidator[], value: string, to?: string) => {
        if(!to || !fields?.length || !value) return true
        const fieldToCompare = fields.find((item) => item.name === to)
        if(!fieldToCompare) return true
        if(fieldToCompare.value !== value){
            return true
        }else{
            return false
        }
    }

    /**
     * @INFO Validar entre un rango de numeros
     * @param value 
     * @param min 
     * @param max 
     */
     private rangeValidator = (value: string, min?: number, max?: number): boolean => {
        if(min === undefined && max === undefined){
            return true
        }else{
            let response = true
            if(min !== undefined && Number(value) < min){
                response = false
            }
            if(response && max !== undefined && Number(value) > max){
                response = false
            }
            return response
        }
    }
}

export default UseFormValidatorValidators