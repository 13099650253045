// @import dependencies
import React, {useState, useEffect} from 'react';
// @end dependencies

// @import types
import * as UseDashboardTypes from './useDashboard.types';
import * as ReservationTypes from 'types/reservations/reservations.types';
import { IStoreApp } from 'redux/reducers/index';
import { TypeDashboardType } from 'types/app/app.actions.types';
// @end types

// @import services
import ReservationsService from 'services/reservations/reservationsService';
import AppService from 'services/app/appService';
// @end services

// @import actions
import * as DashboardActions from 'redux/reducers/dashboard/dashboard.actions';
// @end actions

// @import hooks
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RouterService from 'services/router/routerService';
import { useQuery } from 'hooks/useQuery/useQuery';
// @end hooks

export const useDashboard = (params?: UseDashboardTypes.UseDashboardInput): UseDashboardTypes.UseDashboardOutput => {

    const [toolId, updateToolId] = useState<string | undefined>(params?.toolId);
    const [dashboardType, updateDashboardType] = useState<TypeDashboardType>('normal');
    const [inicialized, updateInicialized] = useState<boolean>(false);
    const reservations = useSelector((store: IStoreApp) => store.dashboard.reservations)
    const reservation = useSelector((store: IStoreApp) => store.dashboard.reservation)
    const dispatch = useDispatch();
    const { objectURL } = useQuery()

    // Services
    const reservationsService = new ReservationsService();
    const appService = new AppService();

    useEffect(() => {
        setDashboardType();
    }, []);

    useEffect(() => {
        if (
            reservations.length &&
            objectURL?.reservation &&
            reservation?.name !== objectURL.reservation
        ) {
            const newReservation = reservations.find((r) => r.name === objectURL.reservation)
            if (newReservation) {
                dispatch(DashboardActions.setReservationAction(newReservation))
            }
        }
    }, [reservations.length])

    const setDashboardType = () => {
        const config = appService.getSystemConfig();
        if (config?.dashboard_type) {
            updateDashboardType(config.dashboard_type);
        }
        updateInicialized(true);
    }

    const getSavedDataById = (_toolId?: string) => {
        const id = _toolId ? _toolId : toolId;
        if (reservation?.savedData && id) {
            return reservation.savedData[id];
        }
        return undefined;
    }

    const updateSavedDataTool = async (newData: any, _toolId?: string) => {
        if (!reservation) return;
        const id = _toolId ? _toolId : toolId;
        if (!id) return;
        const response = await reservationsService.updateReserve({
            reservation: {
                savedData: {
                    ...reservation?.savedData,
                    [id]: newData
                }
            },
            id: reservation.id
        });
        if (response) {
            dispatch(DashboardActions.setReservationAction(response));
        }
    }

    const updateReservation = async (params: Partial<ReservationTypes.IReservation>) => {
        if (params && reservation) {
            const response = await reservationsService.updateReserve({
                reservation: params,
                id: reservation.id
            });
            if (response) {
                dispatch(DashboardActions.setReservationAction(response));
            }
        }
    }

    return {
        reservation,
        reservations,
        savedData: reservation?.savedData,
        savedDataTool: getSavedDataById(),
        dashboardType,
        inicialized,
        updateSavedDataTool,
        updateReservation
    }
}