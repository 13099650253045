// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import RatingComponent from 'components/commons/RatingComponent/RatingComponent';
import InputComponent from 'components/commons/Input/InputComponent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import TextComponent from 'components/commons/Text/TextComponent';
import AppLayout from 'components/layouts/AppLayout/AppLayout';
// @end components

// @import types
import { IStoreApp } from 'redux/reducers/index';
import * as LaboratoriesTypes from 'types/laboratories/laboratories.types';
// @end types

// @import services
import RatingService from 'services/rating/ratingService';
import LaboratoryService from 'services/laboratory/laboratoryService';
import ScreenNotification from 'services/screenNotification/screenNotification';
// @end services

// @import hooks
import { useParams, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
// @end hooks

// @import actions
// @end actions

// @import utils
import { HOME_ROUTE } from 'config/globals';
// @end utils

// @import assets
import finishLabIcon from 'assets/images/dashboard/finish_lab_icon.png';
// @end assets

// @import styles
import './FinishLaboratoryPage.scss'
// @end styles

interface IFinishLaboratoryPageProps {
    className?: string
    style?: CSSProperties
    id?: string
    history?: any
}

const FinishLaboratoryPage: React.FC<IFinishLaboratoryPageProps> = (props) => {

    const params = useParams<{idLab: string}>();
    const [comment, updateComment] = useState<string>('');
    const [loading, updateLoading] = useState<boolean>(false);
    const [rating, updateRating] = useState<number>(0);
    const [lab, updateLab] = useState<LaboratoriesTypes.ILaboratory | undefined>(undefined);
    const user = useSelector((store: IStoreApp) => store.auth.user);
    const history = useHistory();

    // Services
    const ratingService = new RatingService();
    const laboratoryService = new LaboratoryService();
    const screenNotification = new ScreenNotification();

    useEffect(() => {
        getLab();
    }, [])

    const getLab = async () => {
        const response = await laboratoryService.getById(({id: params.idLab}));
        if (response) {
            updateLab(response);
        } else {
            history.push(`${HOME_ROUTE}`);
        }
    }

    const handleCreateRating = async () => {
        if (comment.trim().length) {
            updateLoading(true);
            const response = await ratingService.create({
                rating: {
                    comment,
                    lab: params.idLab,
                    rating
                }
            });
            if (response) {
                await screenNotification.showSuccessTimer();
            }
            updateLoading(false);
            history.push(`${HOME_ROUTE}`);
        }
    }

    const handleClickSkip = () => {
        history.push(`${HOME_ROUTE}`);
    }

    return(
        <AppLayout
            history={props.history}
            headerTitle='Retroalimentación'
        >
            <div
                className={`finish_laboratory_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
                id={props.id}
            >
                <div className="content-page-feedback">
                    <TextComponent
                        type='h3'
                    >
                        ¡Has finalizado el laboratorio!
                    </TextComponent>
                    <img 
                        src={finishLabIcon}
                        alt="" 
                        className='icon-finish-lab'
                    />
                    <RatingComponent
                        rating={rating}
                        onChange={(e) => updateRating(e)}
                        size='3rem'
                    />
                    <InputComponent
                        type='textarea'
                        placeholder='Escribe tu opinión...'
                        className='input-area-finish'
                        onChange={(e) => updateComment(e.target.value)}
                        value={comment}
                    />
                    <ButtonComponent
                        variant='accent'
                        style={{padding: '0.6rem 3rem'}}
                        onClick={handleCreateRating}
                        disabled={!comment.trim().length}
                        loading={loading}
                    >
                        Enviar Opinión
                    </ButtonComponent>
                    <ButtonComponent
                        variant='primary'
                        style={{padding: '0.6rem 3rem'}}
                        onClick={handleClickSkip}
                    >
                        Saltar
                    </ButtonComponent>
                </div>
            </div>
        </AppLayout>
    );
}

export default FinishLaboratoryPage;