import { Payment } from 'types/license/license.types';
import { FC } from 'react'
import './PaymentMethod.scss'
import TextComponent from '../Text/TextComponent';
import CheckBoxComponent from '../CheckBoxComponent/CheckBoxComponent';

interface PaymentMethodProps {
    payments: Payment[]
    selected?: Payment
    onChange?: (payment: Payment) => void
}

const PaymentMethod: FC<PaymentMethodProps> = (props) => {

    const newMethod: Payment = {
        id: 'new',
        card_number: 'Nueva tarjeta',
    }

    const handleClick = (payment: Payment) => {
        if (props.onChange) {
            props.onChange(payment)
        }
    }

    return (
        <div className="payment_method-layout">
            <TextComponent type='label'>
                Selecciona el método de pago
            </TextComponent>
            {props.payments.map((payment) => (
                <PaymentMethodItem
                    payment={payment}
                    checked={!!props.selected?.card_number && props.selected?.card_number === payment.card_number}
                    onClick={() => handleClick(payment)}
                />
            ))}
            <PaymentMethodItem
                payment={newMethod}
                checked={props.selected?.card_number === newMethod.card_number}
                onClick={() => handleClick(newMethod)}
            />
        </div>
    )
}

interface PaymentMethodItemProps {
    payment: Payment
    checked: boolean
    onClick: () => void
}

const PaymentMethodItem: FC<PaymentMethodItemProps> = ({ payment, checked, onClick }) => {
    return (
        <div className="payment_method_item-layout">
            <CheckBoxComponent
                onChange={onClick}
                label={payment.card_number}
                checked={checked}
            />
        </div>
    )
}

export default PaymentMethod
