import React, { CSSProperties, useState } from 'react'
import { IoMdCloseCircle } from 'react-icons/io'

import './ImageComponent.scss'

export interface ImageComponentProps {
    className?: string
    style?: CSSProperties
    src: string
    alt?: string
    withZoom?: boolean
}
 
const ImageComponent: React.SFC<ImageComponentProps> = (props) => {

    const [open, updateOpen] = useState(false)

    const handleUpdateOpen = () => {
        if(!props.withZoom) return
        updateOpen(!open)
    }

    return (
        <>
            <img 
                className={`image_component-layout ${props.withZoom ? 'cursor-pointer' : ''} ${props.className ? props.className : ''}`}
                style={props.style}
                alt={props.alt}
                src={props.src}
                onClick={handleUpdateOpen}
            />
            <div 
                className={`container-img-component-zoom ${open ? 'make-zoom' : ''}`}
            >
                <div className="container-img-body-zoom">
                    <div 
                        className="icon-close-img"
                        onClick={handleUpdateOpen}
                    >
                        <IoMdCloseCircle/>
                    </div>
                    <img 
                        alt={props.alt}
                        src={props.src}
                    />
                </div>
            </div>
        </>
    );
}
 
export default ImageComponent;