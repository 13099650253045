// @import dependencies
import React, { CSSProperties } from 'react'
import { BsGrid } from 'react-icons/bs';
import { VscListSelection } from 'react-icons/vsc';
// @end dependencies

// @import components
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ListGridControl.scss'
// @end styles

export type TypeListGridControl = 'list' | 'grid';

interface IListGridControlProps {
    value: TypeListGridControl
    className?: string
    style?: CSSProperties
    id?: string
    onChange?: (value: TypeListGridControl) => void
}

const ListGridControl: React.FC<IListGridControlProps> = (props) => {

    const handleClickIcon = (type: TypeListGridControl) => {
        if(props.onChange){
            props.onChange(type);
        }
    }

    return(
        <div
            className={`list_grid_control-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <div 
                className={`grid-icon ${props.value === 'list' ? 'active' : ''}`}
                onClick={() => handleClickIcon('list')}
                style={{marginLeft: 'auto'}}
            >
                <VscListSelection/>
            </div>
            <div 
                className={`grid-icon ${props.value === 'grid' ? 'active' : ''}`}
                onClick={() => handleClickIcon('grid')}
            >
                <BsGrid/>
            </div>
        </div>
    );
}

export default ListGridControl;