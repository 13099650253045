// @import dependencies
import React, { CSSProperties, useEffect, useState, ReactElement } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { IoIosArrowForward } from 'react-icons/io'
// @end dependencies

// @import components
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './PanelComponent.scss'
// @end styles

interface IPanelComponentProps {
    className?: string
    style?: CSSProperties
    id?: string
    // @INFO Para el componente
    panel: ReactElement
    children: ReactElement
    panelWidth?: string
    panelOrientation?: IPanelOrientation
    collapse?: boolean
    menuIcon?: ReactElement
    classNameContent?: string
    classNamePanel?: string
    styleContent?: CSSProperties
    stylePanel?: CSSProperties
    open?: boolean
    height?: string
    defaultOpen?: boolean
    onClickOpen?: (_state?: boolean) => {}
}

type IPanelOrientation = 'left' | 'right'

const PanelComponent: React.FC<IPanelComponentProps> = (props) => {

    const [open, updateOpen] = useState<boolean>(props.defaultOpen !== undefined ? props.defaultOpen : true);

    const panelWidth: string = props.panelWidth ? props.panelWidth : '200px'
    const panelOrientation: IPanelOrientation = props.panelOrientation ? props.panelOrientation : 'left'
    const collapse: boolean = props.collapse !== undefined ? props.collapse : false

    useEffect(() => {
        if(props.open !== undefined){
            updateOpen(props.open)
        }
    },[props.open])

    /**
     * @INFO Click al menu del panel
     */
    const handleClickIconPanel = () => {
        if(props.open === undefined){
            updateOpen(!open)
        }
        if(props.onClickOpen){
            props.onClickOpen(!open)
        }
    }

    return(
        <div
            className={`panel_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            {props.collapse && !open ?
                <div 
                    className={`float-open-button-panel ${ props.panelOrientation ? props.panelOrientation : 'left'}`}
                    onClick={handleClickIconPanel}
                >
                    <IoIosArrowForward/>
                </div>
            :null}
            <div 
                className={`container-panel_component ${panelOrientation} ${props.classNamePanel ? props.classNamePanel : ''}`}
                style={{
                    ...props.stylePanel,
                    width: open ? panelWidth : '0px',
                    height: props.height,
                    padding: open ? '40px 16px 16px 16px' : '0px',
                }}
            >
                {collapse && open ? 
                    <div 
                        className="icon-menu-panel"
                        onClick={handleClickIconPanel}
                    >
                        <AiOutlineCloseCircle/>
                    </div>
                :null}
                {props.panel && open ? 
                    props.panel 
                :null}
            </div>
            <div 
                className={`container-content_component ${props.classNameContent ? props.classNameContent : ''}`}
                style={{
                    ...props.styleContent,
                    height: props.height
                }}
            >
                {props.children}
            </div>
        </div>
    );
}

export default PanelComponent;