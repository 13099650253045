import store from 'redux/store'
import * as SubareaTypes from 'types/subarea/subarea.types'
import graphQLUtil from "utils/graphQL/graphQL.util"
import { gql } from "@apollo/client"

class SubareaService{

    private storeObject = store

    constructor(){

    }

    /**
     * @INFO Traer la lista de subareas
     * @returns 
     */
    public list = async ():Promise<Array<SubareaTypes.ISubarea>> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query subareas($token: String!){
                        subareas(token: $token){
                            ${SubareaTypes.fullSubareaQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token
                }
            }
        })
        if(response?.data?.subareas?.length){
            return response.data.subareas
        }else{
            return []
        }
    }
}

export default SubareaService