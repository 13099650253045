// @import dependencies
import React from 'react'
// @end dependencies

// @import components
import AppLayout from 'components/layouts/AppLayout/AppLayout';
// @end components

// @import services
// @end services

// @import hooks
// @end hooks

// @import hooks
// @end hooks

// @import styles
import './CodeAuthenticationPage.scss';
// @end styles

// @import assets
import contanctImg from 'assets/images/landing/contact.png'
import CodeAuthenticationModule from 'components/modules/CodeAuthenticationModule/CodeAuthenticationModule';
import TextComponent from 'components/commons/Text/TextComponent';
// @end assets

export interface CodeAuthenticationPageProps {
    history?: any
}
 
const CodeAuthenticationPage: React.SFC<CodeAuthenticationPageProps> = (props) => { 
    
    return ( 
        <AppLayout
            headerTitle='Code authentication'
        >
            <div className="code_authentication_page">
                <div className="code_authentication--container">
                    <div className='code_authentication_page--container-form'>
                        <div className="code_authentication_page--form">
                            <TextComponent
                                type='label'
                                className='code_authentication_page--form-title'
                            >
                                Iniciar Sesión
                            </TextComponent>
                            <TextComponent type='h3' >
                                Escribe el código de 8 digitos para iniciar sesión.
                            </TextComponent>
                            <CodeAuthenticationModule
                                className='code_authentication_page--form-input'
                            />
                        </div>
                    </div>
                    <img 
                        src={contanctImg} 
                        alt="" 
                    />
                </div>
            </div>
        </AppLayout>
    );
}
 
export default CodeAuthenticationPage;