// @import dependencies
import React, { CSSProperties, Fragment, useState, useEffect } from 'react'
import shortId from 'shortid';
// @end dependencies

// @import components
import PanelComponent from 'components/commons/PanelComponent/PanelComponent';
import TextComponent from 'components/commons/Text/TextComponent';
import JitsiComponent from 'components/commons/JitsiComponent/JitsiComponent';
// @end components

// @import types
import * as JitsiTypes from 'types/jitsi/jitsi.types';
import * as ToolTypes from 'types/tool/tool.types';
import { IStoreApp } from 'redux/reducers/index';
// @end types

// @import services
import SocketService from 'services/socket/socketService';
// @end services

// @import hooks
import { useSelector } from 'react-redux';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './CameraModuleDashboard.scss'
import ButtonComponent from '../../../../commons/Button/ButtonComponent';
// @end styles

interface ICameraModuleDashboardProps {
    tool: ToolTypes.ITool
    className?: string
    style?: CSSProperties
    id?: string
    
}

const CameraModuleDashboard: React.FC<ICameraModuleDashboardProps> = (props) => {

    const [meetData, updateMeetData] = useState<JitsiTypes.INewJitsiMeet | undefined>(undefined)
    const [id, updateId] = useState<string>(shortId.generate())
    const [devices, updateDevices] = useState<JitsiTypes.IAvailableDeviceJitsi | undefined>(undefined)
    const [currentCamera, updateCurrentCamera] = useState<JitsiTypes.IJitsiDevice | undefined>(undefined)
    const user = useSelector((store: IStoreApp) => store.auth.user)

    // @INFO Servicios
    const socketService = new SocketService()

    useEffect(() => {
        // getRoomMeet()
        startMeeting();
        socketService.infoMeetingListen(props.tool.id ? props.tool.id : '', handleInfoMeeting);
        return () => {
            socketService.infoMeetingRemoveListen(props.tool.id ? props.tool.id : '', handleInfoMeeting);
            socketService.finishMeetingEmit(props.tool.id ? props.tool.id : '');
        }
    },[])

    // Si no hay una camara seleccionada y hay disponibles selecciono la primera
    useEffect(() => {
        if (!currentCamera && devices?.videoInput?.length){
            updateCurrentCamera(devices.videoInput[0]);
        }
    }, [devices]);

    useEffect(() => {
        if (currentCamera){
            socketService.changeCameraMeetingEmit(props.tool?.id ? props.tool.id : '', currentCamera);
        }
    }, [currentCamera]);

    const startMeeting = () => {
        socketService.startMeetingEmit(props.tool.id ? props.tool.id : '');
    }

    const handleInfoMeeting = (info: ToolTypes.CameraInfoMeeting) => {
        if (!info) return;
        updateMeetData({ roomName: info.roomName });
        updateDevices(info.devices);
    }

    /**
     * @INFO Obtener el listado de dispositivos disponibles
     * @param _devices 
     */
    const handleGetDevices = (_devices: JitsiTypes.IAvailableDeviceJitsi) => {
        updateDevices(_devices)
    }

    /**
     * @INFO Click en cambiar de camara
     * @param _camera 
     */
    const handleClickCamera = (_camera: JitsiTypes.IJitsiDevice) => {
        updateCurrentCamera(_camera)
    }

    return(
        <div
            className={`camera_module_dashboard-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >   
            <PanelComponent
                panelOrientation='right'
                styleContent={{ padding: '0px' }}
                collapse
                defaultOpen={false}
                classNameContent='content-panel-camera'
                panel={
                    <div
                        className='list-cameras-container'
                    >
                        {devices?.videoInput?.map((_d) => (
                            <ButtonComponent
                                key={_d.deviceId}
                                className='button-select-camera'
                                onClick={() => handleClickCamera(_d)}
                                variant={currentCamera?.deviceId === _d.deviceId ? 'primary2' : undefined }
                            >
                                {_d.label.split('(')[0]}
                            </ButtonComponent>
                        ))}
                    </div>
                }
            >
                <Fragment>
                    <div className="header-camera-container">
                        <TextComponent
                            type='text'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            {currentCamera?.label}
                        </TextComponent>
                    </div>
                    <div className="container-iframe-camera">
                        {meetData ?
                            <JitsiComponent
                                roomName={meetData.roomName}
                                // roomName='4sCxnqswV9feCnAoOR4Qy'
                                width='100%'
                                height='100%'
                                name={`${user?.firstName} ${user?.lastName}`}
                                id={id}
                                toolbarButtons={props.tool?.config?.jitsi?.toolbarButtons?.length ? props.tool.config.jitsi.toolbarButtons : ['fullscreen']}
                                videoInput={currentCamera}
                                onGetAvailableDevices={handleGetDevices}
                                style={{ height: '100%' }}
                                styleContainer={{ height: '100%' }}
                            />
                        :null}
                    </div>
                    <div className="footer-camera-container">

                    </div>
                </Fragment>
            </PanelComponent>
        </div>
    );
}

export default CameraModuleDashboard;