import * as AreaTypes from 'types/area/area.types'

export interface ISubarea{
    id?: string
    name?: string
    area?: AreaTypes.IArea
}

export const fullSubareaQuery = `
    id
    name
    area{
        ${AreaTypes.fullAreaQuery}
    }
`