import React, { CSSProperties } from 'react'

import './RecommendedLaboratoryCard.scss'

interface IRecommendedLaboratoryCardProps {
    className?: string
    style?: CSSProperties
    
}

const RecommendedLaboratoryCard: React.FC<IRecommendedLaboratoryCardProps> = (props) => {
    return(
        <div
            className={`recommended_laboratory_card-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >

        </div>
    );
}

export default RecommendedLaboratoryCard;