// @import dependencies
import store from 'redux/store';
import { gql } from '@apollo/client';
// @end dependencies

// @import types
import * as AuthTypes from 'types/auth/auth.types'
// @end types

// @import utils
import socketUtil from 'utils/socket/socket.util';
import graphQLUtil from 'utils/graphQL/graphQL.util';
// @end utils

class AuthService{

    private storeObject = store

    constructor(){

    }

    /**
     * @INFO Hacer login en la aplicación
     * @param _params 
     */
    public login = async (_params: AuthTypes.ILoginCredentials) => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query login($username: String!, $password: String!){
                        login(username: $username, password: $password){
                            token
                            user{
                                ${AuthTypes.FullUserGQL}
                            }
                        }
                    }
                `,
                variables: {
                    username: _params.email,
                    password: _params.password
                }
            }
        },{
            alertOnFailed: true
        })
        if(response?.data?.login?.token){
            socketUtil.connectIO(response.data.login.token);
        }
        return response
    }

    /**
     * @INFO Hacer login por token
     * @param _params 
     * @returns 
     */
    public loginByToken = async (_params: AuthTypes.ILoginByToken) => {
        if(!_params.token) return {}
        const response = await graphQLUtil.send({
            watch: {
                query: gql`
                    query loginToken($token: String!){
                        loginToken(token: $token){
                            token
                            user{
                                ${AuthTypes.FullUserGQL}
                            }
                        }
                    }
                `,
                variables:{
                    token: _params.token
                }
            },
            method: 'watch'
        })
        if(response?.data?.loginToken?.token){
            socketUtil.connectIO(response.data.loginToken.token);
        }
        return response
    }

    /**
     * @INFO Hacer login por codigo
     * @param _params 
     * @returns 
     */
     public loginByCode = async (_params: AuthTypes.VerifyAuthenticationCodeParams): Promise<boolean> => {
        if(!_params.token) return false
        const response = await graphQLUtil.send({
            watch: {
                query: gql`
                    query verifyAuthenticationCode($token: String!, $code: String!){
                        verifyAuthenticationCode(token: $token, code: $code)
                    }
                `,
                variables:{
                    token: _params.token,
                    code: _params.code,
                }
            },
            method: 'watch'
        })
        if(response?.data?.verifyAuthenticationCode){
            return true
        } else {
            return false
        }
    }

    /**
     * @INFO Crear una nueva cuenta
     * @param _params 
     * @returns 
     */
    public createAccount = async (_params: any) => {
        if(!_params) return {}
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation newUser($user: UserInput!){
                        newUser(user: $user){
                            token,
                            user{
                                ${AuthTypes.FullUserGQL}
                            }
                        }
                    }
                `,
                variables: {
                    user: {..._params}
                }
            }
        },
        {
            alertOnFailed: true
        })
        return response
    }


    /**
     * @INFO Cambiar contraseña
     * @param _params 
     * @returns 
     */
    public changePassword = async (_params: AuthTypes.IParamsChangePassword) => {
        if(!_params) return {}
        const token = this.storeObject.getState().auth?.token
        if(!token) return {}
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation changePassword($token: String!, $currentPassword: String!, $newPassword: String!){
                        changePassword(token: $token, currentPassword: $currentPassword, newPassword: $newPassword)
                    }
                `,
                variables:{
                    token,
                    ..._params
                }
            }
        },
        {
            alertOnFailed: true
        })
        return response
    }

    /**
     * @INFO Guardar token
     * @param token 
     */
    public setToken = (token: string) => {
        localStorage.setItem('Token', token)
    }

    /**
     * @INFO Obtener token
     */
    public getToken = () => {
        let token: string | undefined = this.storeObject.getState().auth.token
        if(!token){
            // @ts-ignore
            token = localStorage.getItem('Token')
        }
        return token
    }

    /**
     * @INFO Eliminar el token del local storage
     */
    public deleteToken = () => {
        localStorage.removeItem("Token")
    }

    /**
     * @INFO Verificar si hay un usuario con sesión activa en la app
     */
    public isLogin = () => {
        const isLogin = this.storeObject.getState().auth.isLogged
        if(isLogin){
            return true
        }else {
            return false
        }
    }

    /**
     * @INFO Revisar si la data del usuario ya ha cargado
     */
    public hasData = () => {
        const hasData = this.storeObject.getState().auth.hasData
        if(hasData){
            return true
        }else{
            return false
        }
    }
}

export default AuthService