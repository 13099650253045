import store from 'redux/store'
import graphQLUtil from "utils/graphQL/graphQL.util"
import { gql } from "@apollo/client"

import * as PlanTypes from 'types/plan/plan.types';

class PlanService{

    private storeObject = store

    constructor(){

    }

    /**
     * @INFO Traer la lista de planes
     * @param _params 
     */
    public list = async (_params?: PlanTypes.ParamsPlansList): Promise<PlanTypes.Plan[]> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query plans{
                        plans{
                            ${PlanTypes.fullPlanQuery}
                        }
                    }
                `,
            }
        })
        if(response?.data?.plans?.length){
            return response.data.plans
        }else{
            return []
        }
    }
    
    /**
     * @INFO Traer un plan por id
     * @param _params 
     */
    public getById = async (_params: PlanTypes.ParamsPlanGetById): Promise<PlanTypes.Plan | undefined> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query plan($token: String!, $id: ID!){
                        plan(token: $token, id: $id){
                            ${PlanTypes.fullPlanQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        })
        if(response?.data?.plan){
            return response.data.plan
        }else{
            return undefined
        }
    }

    /**
     * @INFO Crear un plan
     * @param _params 
     * @returns 
     */
    public create = async (_params: PlanTypes.ParamsPlanCreate): Promise<PlanTypes.Plan | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation newPlan($token: String!, $plan: PlanInput!){
                        newPlan(token: $token, plan: $plan){
                            ${PlanTypes.fullPlanQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    plan: {..._params.plan}
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.newPlan){
            return response.data.newPlan
        }else{
            return undefined
        }
    }

    /**
     * @INFO Actualizar un plan
     * @param _params 
     * @returns 
     */
    public update = async (_params: PlanTypes.ParamsPlanUpdate)
    : Promise<PlanTypes.Plan | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation updatePlan($token: String!, $id: ID!, $plan: UpdatePlanInput!){
                        updatePlan(token: $token, id: $id, plan: $plan){
                            ${PlanTypes.fullPlanQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    plan: {..._params.plan},
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.updatePlan){
            return response.data.updatePlan
        }else{
            return undefined
        }
    }

    /**
     * @INFO Eliminar un plan
     * @param _params 
     * @returns 
     */
    public delete = async (_params: PlanTypes.ParamsPlanDelete): Promise<PlanTypes.Plan | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation removePlan($token: String!, $id: ID!){
                        removePlan(token: $token, id: $id){
                            ${PlanTypes.fullPlanQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.removePlan){
            return response.data.removePlan
        }else{
            return undefined
        }
    }
}

export default PlanService