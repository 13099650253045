import store from "redux/store"


interface ISubcriptor{
  root: string
  functions: ((data?: any)=>void) []
  lastData: any
}

class StoreService {
  static myInstance: StoreService | null = null
  private storeObject = store
  private subscriptors: ISubcriptor[] = []

  static getInstance(){
    if(StoreService.myInstance === null){
      StoreService.myInstance = new StoreService()
    }
    return this.myInstance
  }

  constructor(){
    this.storeObject.subscribe(this.handleChangeStore)
  }

  // @INFO Enviar datos a los suscriptores
  private handleChangeStore = () => {
    if(StoreService.myInstance === null) return
    try {
      this.subscriptors.forEach(subs => {
        const data = this.getDataRoot(subs.root)
        if(JSON.stringify(subs.lastData) === JSON.stringify(data)) return
        subs.lastData = data
        subs.functions.forEach(fn => {
          if(data === undefined){
            fn(data)
          }else{
            fn(JSON.parse(JSON.stringify(data)))
          }
        })
      })
    } catch (error) {}
  }

  // @INFO agregar suscriptor
  public subscribe(root: string, fn: (data?:any)=>void, firstSend: boolean = true, since?: string){
    // Enviar dato al que se suscribe
    const data = this.getDataRoot(root)
    if(firstSend){
      if(data === undefined){
        fn(data)
      }else{
        fn(JSON.parse(JSON.stringify(data)))
      }
    }
    // Agregar el nuevo suscriptor al array
    const index = this.subscriptors.findIndex(subs => subs.root === root)
    if(index >= 0){
      this.subscriptors[index].functions.push(fn)
    }else{
      this.subscriptors.push({root, functions: [fn], lastData: data})
    }
    // console.log(`Subscribe: ${since} `, this.subscriptors)
  }

  // @INFO remover suscriptor
  public unsubscribe(root: string, fn: (data?:any)=>void){
      const index = this.subscriptors.findIndex(subs => subs.root === root)
      if(index < 0) return
      const idxFunc = this.subscriptors[index].functions.findIndex(func => func === fn)
      if(idxFunc < 0) return
      this.subscriptors[index].functions.splice(idxFunc, 1)
      // Si ya no hay funciones elimino el root
      if(this.subscriptors[index].functions.length === 0){
        this.subscriptors.splice(index, 1)
      }
      // console.log('Unsubscribe: ',this.subscriptors)
  }

  // @INFO Encontrar la informacion segun la ruta
  private getDataRoot(root: string){
    const roots = root.split('.')
    const value = roots.reduce((acum: any, item: string) => {
        if(acum === undefined || acum === null) return undefined
        acum = acum[item]
        return acum
    }, {...this.storeObject.getState()})
    return value
  }

}

const storeService = StoreService.getInstance()

export default storeService
