import React from 'react';
import styled from 'styled-components'
import parse from 'html-react-parser';

export interface QuoteEditorJsProps {
  data: {alignment: string, caption: string, text: string}
}

const Container = styled.div`
  color: var(--text);
  .container-text{
    border: 1px solid rgba(201, 201, 204, 0.48);
    border-radius: 4px;
    padding: 10px;
    width: auto;
    height: auto;
    font-size: 1rem;
    margin-bottom: 10px;
  }
`;

const QuoteEditorJs: React.SFC<QuoteEditorJsProps> = (props) => {
  return (
    <Container
      style={{textAlign: props.data.alignment as any}}
    >
      <div className="container-text">
        {parse(props.data.text)}
      </div>
      <div className="container-text">
        {parse(props.data.caption)}
      </div>
    </Container>
  );
}

export default QuoteEditorJs;
