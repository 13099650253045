// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
import shortId from 'shortid';
// @end dependencies

// @import components
import LabelComponent from 'components/commons/LabelComponent/LabelComponent';
import InputComponent from 'components/commons/Input/InputComponent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import { MdDelete } from 'react-icons/md';
// @end components

// @import types
import * as ToolTypes from 'types/tool/tool.types'
// @end types

// @import services
// @end services

// @import hooks
import useValidator from 'hooks/useFormValidator/useFormValidator';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './VariablesFormModule.scss'
import RangeInputComponent from 'components/commons/RangeInputComponent/RangeInputComponent';
import ObjectService from 'services/object/objectService';
// @end styles

interface IVariablesFormModuleProps {
    className?: string
    style?: CSSProperties
    id?: string
    label?: string
    defaultValue?: ToolTypes.IVariableTool[]
    onChange?: (_variables: ToolTypes.IVariableTool[]) => any
}

interface IVariableComponent extends ToolTypes.IVariableTool{
    id: string
}

const VariablesFormModule: React.FC<IVariablesFormModuleProps> = (props) => {

    const [variables, updateVariables] = useState<IVariableComponent[]>([])
    
    const objectService = new ObjectService()

    useEffect(() => {
        handleSendOnChange()
    },[variables])

    useEffect(() => {
        if(props.defaultValue?.length){
            const _variables = props.defaultValue.map((_v) => {
                const newVar: IVariableComponent = {
                    name: _v.name,
                    unit: _v.unit,
                    variableKey: _v.variableKey,
                    limits: _v.limits?.min && _v.limits?.max ? objectService.transformPropertyFromRoot(_v.limits, [{ root: 'min', transform: 'string' }, { root: 'max', transform: 'string' }]) : undefined,
                    id: shortId.generate()
                }
                return newVar
            })
            updateVariables([..._variables])
        }
    }, [])

    const handleClickNew = () => {
        const newVar: IVariableComponent = {
            name: '',
            unit: '',
            variableKey: '',
            id: shortId.generate(),
        }
        updateVariables([...variables, newVar])
    }

    const handleClickDelete = (_id: string) => {
        const newVars = variables.filter((_v) => _v.id !== _id)
        updateVariables([...newVars])
    }

    const handleSendOnChange = () => {
        if(props.onChange){
            if(variables?.length){
                props.onChange(variables.map((_v) => { 
                    const newVar: ToolTypes.IVariableTool = {
                        name: _v.name,
                        unit: _v.unit,
                        variableKey: _v.variableKey,
                        limits: _v.limits?.min && _v.limits?.max ? objectService.transformPropertyFromRoot(_v.limits, [{ root: 'min', transform: 'string' }, { root: 'max', transform: 'string' }]) : undefined
                    }
                    return newVar
                }))
            }else{
                props.onChange([])
            }
        }
    }

    const handleChangeVariable = (_variable: IVariableComponent) => {
        const idx = variables.findIndex((_v) => _v.id === _variable.id)
        if(idx >= 0){
            const newVariables = [...variables]
            newVariables[idx] = {..._variable}
            updateVariables([...newVariables])
        }
    }

    return(
        <div
            className={`variables_form_module-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            {props.label ?
                <LabelComponent>
                    {props.label}
                </LabelComponent>
            :null}
            {variables?.map((_v) => (
                <ItemVariable
                    key={_v.id}
                    variable={_v}
                    onClickDelete={() => handleClickDelete(_v.id)}
                    onChange={handleChangeVariable}
                />
            ))}
            <ButtonComponent
                onClick={handleClickNew}
                variant='primary'
            >
                Nueva variable
            </ButtonComponent>
        </div>
    );
}

// ============================================================
// @INFO Item de variable
// ============================================================
interface IItemVariableProps {
    variable: IVariableComponent
    className?: string
    style?: CSSProperties
    id?: string
    onClickDelete?: () => any
    onChange?: (_variable: IVariableComponent) => any
}

const ItemVariable: React.FC<IItemVariableProps> = (props) => {

    const form = useValidator([
        { name: 'name', value: props.variable.name },
        { name: 'unit', value: props.variable.unit },
        { name: 'variableKey', value: props.variable.variableKey },
        { name: 'limits', value: props.variable.limits }
    ])

    useEffect(() => {
        if(props.onChange){
            props.onChange({
                id: props.variable.id,
                ...form.getValues()
            })
        }
    }, [form.values])

    return(
        <div
            className={`item_variable-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <InputComponent
                name='name'
                value={form.values['name']}
                onChange={form.handleChange}
                label='Nombre'
                placeholder='Nombre'
            />
            <InputComponent
                name='unit'
                value={form.values['unit']}
                onChange={form.handleChange}
                label='Unidad'
                placeholder='Unidad'
            />
            <InputComponent
                name='variableKey'
                value={form.values['variableKey']}
                onChange={form.handleChange}
                label='Key de variable'
                placeholder='Key de variable'
            />
            <RangeInputComponent
                label='Limites'
                value={form.values['limits']}
                onChange={(_range) => form.setValue('limits', _range)}
                required={false}
                className='range-input-variables'
            />
            <MdDelete
                onClick={props.onClickDelete}
            />
        </div>
    );
}


export default VariablesFormModule;