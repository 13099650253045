import React, { CSSProperties, useState, useEffect } from 'react'
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import HomeCarousel from 'components/commons/HomeCarousel/HomeCarousel';
import PreviewLaboratoryCard from 'components/modules/PreviewLaboratoryCard/PreviewLaboratoryCard';
import { BsGrid } from 'react-icons/bs';
import { VscListSelection } from 'react-icons/vsc';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import FilterLaboratories from 'components/modules/FilterLaboratories/FilterLaboratories';
import { useResizeDetector } from 'react-resize-detector';
import PopUpComponent from 'components/commons/PopUp/PopUpComponent';
import TextComponent from 'components/commons/Text/TextComponent';
import LaboratoryService from 'services/laboratory/laboratoryService';
import { useTranslation } from 'react-i18next';
import useValidator from 'hooks/useFormValidator/useFormValidator';

// @import types
import * as LaboratoryTypes from 'types/laboratories/laboratories.types'
// @end types

import './LaboratoriesPage.scss'
import FavoriteService from 'services/favorite/favoriteService';
import ScreenNotification from 'services/screenNotification/screenNotification';
import { BannerLocation } from 'types/banners/banners.types';

interface ILaboratoriesPageProps {
    className?: string
    style?: CSSProperties
    history?: any
}

const LaboratoriesPage: React.FC<ILaboratoriesPageProps> = (props) => {

    const [list, updateList] = useState<boolean>(false)
    const [openFilter, updateOpenFilter] = useState<boolean>(false)
    const [laboratories, updateLaboratories] = useState<LaboratoryTypes.ILaboratory[]>([])
    const detectorContainer = useResizeDetector()
    const {t} = useTranslation()

    const formFilter = useValidator([
        { name: 'category', value: [] },
        { name: 'subarea', value: [] },
        { name: 'minPrice', value: undefined },
        { name: 'maxPrice', value: undefined },
    ])

    // @Servicios
    const laboratoryService = new LaboratoryService()
    const favoriteService = new FavoriteService()
    const screenNotification = new ScreenNotification()

    useEffect(() => {
        getLaboratories()
    }, [])

    const getLaboratories = async (_query?: LaboratoryTypes.IFilterLabsQuery) => {
        const labs = await laboratoryService.list({ limit: 20, page: 1, filter: _query })
        updateLaboratories(labs)
    }

    const handleClickGetLab = (idLab?: string) => {
        if(props.history && idLab){
            props.history.push(`/laboratory-preview/${idLab}`)
        }
    }

    /**
     * @INFO Filtrar laboratorios
     * @param _query 
     */
    const handleClickFilterLabs = async (_query?: LaboratoryTypes.IFilterLabsQuery) => {
        // console.log('Filtrar: ', _query);
        if(_query){
            if(!_query.category?.length){
                delete _query.category
            }
            if(!_query.subarea?.length){
                delete _query.subarea
            }
            await getLaboratories(_query)
        }
    }

    /**
     * @INFO Agregar el laboratorio a favoritos
     * @param _labId 
     */
    const handleClickFavorite = async (_labId?: string) => {
        if(!_labId) return
        const response = await favoriteService.create({
            lab: _labId
        })
        if(response){
            screenNotification.showSuccessTimer({
                text: 'Laboratorio agregado a favoritos'
            })
        }
    }

    return(
        <AppLayout
            history={props.history}
            headerTitle='Catalogo'
            fetchMyLabs
        >
            <div
                className={`laboratories_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
                ref={detectorContainer.ref}
            >
                {detectorContainer?.width && detectorContainer?.width >= 960 ?
                    <div className="col-filters">
                        <FilterLaboratories
                            formValidator={formFilter}
                            onClickFilter={handleClickFilterLabs}
                        />
                    </div>
                :null}
                <div className="col-container">
                    <HomeCarousel
                        className='carousel-laboratories'
                        filter={{ locations: [BannerLocation.LABORATORIES_PAGE] }}
                    />
                    <div className="container-filter-grid">
                        <div className="free-premium-buttons">
                            <ButtonComponent
                                disabled
                                className={`${true ? 'disabled' : ''}`}
                            >
                                {t('laboratories.free')}
                            </ButtonComponent>
                            <ButtonComponent
                                variant='accent'
                                className={`${false ? 'disabled' : ''}`}
                            >
                                {t('laboratories.premium')}
                            </ButtonComponent>
                        </div>
                        {detectorContainer.width && detectorContainer.width <= 960 ?
                            <>  
                                <PopUpComponent
                                    content={
                                        <FilterLaboratories
                                            formValidator={formFilter}
                                            onClickFilter={handleClickFilterLabs}
                                        />
                                    }
                                    show={openFilter}
                                    onClickClose={() => updateOpenFilter(false)}
                                    placement='bottom-start'
                                >
                                    <TextComponent
                                        style={{color: 'var(--primary2)', marginLeft: 'auto'}}
                                        onClick={() => updateOpenFilter(!openFilter)}
                                    >
                                        {t('laboratories.filter')}
                                    </TextComponent>
                                </PopUpComponent>
                            </>
                        :
                            <>
                                <div 
                                    className={`grid-icon ${list ? 'active' : ''}`}
                                    onClick={() => updateList(true)}
                                    style={{marginLeft: 'auto'}}
                                >
                                    <VscListSelection/>
                                </div>
                                <div 
                                    className={`grid-icon ${!list ? 'active' : ''}`}
                                    onClick={() => updateList(false)}
                                >
                                    <BsGrid/>
                                </div>
                            </>
                        }
                    </div>
                    <div 
                        className={`container-list-cards-preview ${list ? 'list' : ''}`}
                    >
                        {laboratories.map((itemLab) => (
                            <PreviewLaboratoryCard
                                key={itemLab.id}
                                laboratory={itemLab}
                                list={detectorContainer.width && detectorContainer.width > 960 ? list : undefined}
                                free={!itemLab.price ? true : false}
                                addToMyLabs
                                hideShare={false}
                                onClickGet={() => handleClickGetLab(itemLab.id)}
                                onClickFavorite={() => handleClickFavorite(itemLab.id)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

export default LaboratoriesPage;