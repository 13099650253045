// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import TitlePageComponent from 'components/commons/TitlePage/TitlePageComponent';
import TextComponent from 'components/commons/Text/TextComponent';
import SubtitleComponent from 'components/commons/Subtitle/SubtitleComponent';
import CardCategoryHome from 'components/screens/HomePage/components/CardCategoryHome/CardCategoryHome';
import PlanCard from 'components/modules/PlanCard/PlanCard';
import HomeCarousel from 'components/commons/HomeCarousel/HomeCarousel';
import LandingLayout from '../LandingLayout/LandingLayout';
// @end components

// @import types
import { IStoreApp } from 'redux/reducers';
import * as PlanTypes from 'types/plan/plan.types'
// @end types

// @import services
import PlanService from 'services/plan/planService';
// @end services

// @import hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
import imageBasic from 'assets/images/home/basicas.svg'
import imageVR from 'assets/images/home/vr.svg'
import imageAI from 'assets/images/home/ai.svg'
import imageRobotic from 'assets/images/home/robotica.svg'
import personPc from 'assets/images/landing/person_pc.png'
// @end assets

// @import styles
import './HomeLandingPage.scss'
import { BannerLocation } from 'types/banners/banners.types';
// @end styles

interface IHomeLandingPageProps {
    className?: string
    style?: CSSProperties
    id?: string
    
}

const HomeLandingPage: React.FC<IHomeLandingPageProps> = (props) => {

    const [plans, setPlans] = useState<PlanTypes.Plan[]>([])
    const app = useSelector((store: IStoreApp) => store.app)
    const {t} = useTranslation()

    const categoriesTest = [
        { title: t('home.categories.basics.name'), description: t('home.categories.basics.description'), image: imageBasic, myProgress: 60, totalProgress: 20 },
        { title: t('home.categories.vr.name'), description: t('home.categories.vr.description'), image: imageVR, myProgress: 45, totalProgress: 13 },
        { title: t('home.categories.ai.name'), description: t('home.categories.ai.description'), image: imageAI, myProgress: 71, totalProgress: 24 },
        { title: t('home.categories.robotic.name'), description: t('home.categories.robotic.description'), image: imageRobotic, myProgress: 52, totalProgress: 41 },
    ]

    const planService = new PlanService()

    useEffect(() => {
        getPlans()
    }, [])

    const getPlans = async () => {
        const response = await planService.list()
        setPlans(response)
    }

    return(
        <LandingLayout>
            <div
                className={`home_landing_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
                id={props.id}
            >
                <HomeCarousel
                    // onlyAvailable
                    classNameItem='item-banner-home-landing'
                    className='banners-home-landing'
                    filter={{ locations: [BannerLocation.LANDING_VLESIM] }}
                />
                <div className="container-content-page">
                    <div className="container-benefits">
                        <div>
                            <img src={personPc} alt="" />
                        </div>
                        <div>
                            <TextComponent>
                                LA MEJOR PLATAFORMA VIRTUAL
                            </TextComponent>
                            <TitlePageComponent>
                                Beneficios
                            </TitlePageComponent>
                            <div className="item-benefit">
                                <div>
                                    <SubtitleComponent>
                                        1
                                    </SubtitleComponent>
                                </div>
                                <TextComponent>
                                    Descubra en la plataforma VLESIM soluciones innovadoras desarrolladas a partir de tecnologías convergentes de la Industria 4.0. 
                                </TextComponent>
                            </div>
                            <div className="item-benefit">
                                <div>
                                    <SubtitleComponent>
                                        2
                                    </SubtitleComponent>
                                </div>
                                <TextComponent>
                                    VLESIM integra laboratorios virtuales y de acceso remoto desde un equipo de cómputo o desde un dispositivo móvil.
                                </TextComponent>
                            </div>
                            <div className="item-benefit">
                                <div>
                                    <SubtitleComponent>
                                        3
                                    </SubtitleComponent>
                                </div>
                                <TextComponent>
                                    VLESIM ofrece entornos de laboratorios para los diferentes sectores productivos con tecnologías de la Industria 4.0 incluyendo realidad virtual y aumentada, internet de las cosas, computación en la nube, analítica de datos, inteligencia artificial y automatización y robótica.
                                </TextComponent>
                            </div>
                        </div>
                    </div>
                    <div className="container-categories">
                        {categoriesTest.map((item) => (
                            <CardCategoryHome
                                key={item.title}
                                title={item.title}
                                description={item.description}
                                image={item.image}
                                myProgress={item.myProgress}
                                totalProgress={item.totalProgress}
                                style={{
                                    background: 'linear-gradient(180deg, rgba(12, 156, 242, 0.6) 0%, rgba(0, 250, 236, 0.6) 100%)',
                                    backgroundColor: 'transparent'
                                }}
                                // onClick={() => handleClickcategory()}
                            />
                        ))}
                    </div>
                    <div className="container-characteristics">
                        <div>
                            <TextComponent
                                style={{
                                    textTransform: 'uppercase'
                                }}
                            >   
                                Previsualiza nuestra plataforma
                            </TextComponent>
                            <TitlePageComponent>
                                Características
                            </TitlePageComponent>
                            <TextComponent>   
                                Los entornos de laboratorios son la realización de la transformación digital aplicados a un proceso de formación o producción, ofreciendo los siguientes beneficios:
                            </TextComponent>
                            <ul>
                                <li>
                                    Apropiación de nuevas tecnologías
                                </li>
                                <li>
                                    Tomar decisiones en tiempo real
                                </li>
                                <li>
                                    Establecer una línea base de un proceso
                                </li>
                                <li>
                                    Optimizar los recursos reflejados en ahorros
                                </li>
                                <li>
                                    Mejorar la calidad de formación del personal
                                </li>
                                <li>
                                    Generar nuevos ambientes de aprendizaje
                                </li>
                                <li>
                                    Aumentar la productividad con flexibilidad y agilidad.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <img src={personPc} alt="" />
                        </div>
                    </div>
                    <div className="container-plans">
                        {plans?.map((plan) => (
                            <PlanCard
                                key={plan.id}
                                plan={plan}
                                showPrice
                                path='/login?redirectTo=/plans'
                                style={{
                                    backgroundColor: 'rgba(0,0,0, $alpha: 0.2)'
                                }}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </LandingLayout>
    );
}

export default HomeLandingPage;