// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import TextComponent from 'components/commons/Text/TextComponent';
import { Modal } from 'react-bootstrap';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import ReserveLaboratoryModule from '../ReserveLaboratoryModule/ReserveLaboratoryModule';
// @end components

// @import types
import * as ReservationTypes from 'types/reservations/reservations.types';
// @end types

// @import services
// @end services

// @import hooks
import useReservation from 'hooks/useReservation/useReservation';
import useMoment from 'hooks/useMoment/useMoment';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ModalEditReserve.scss'
// @end styles

interface IModalEditReserveProps {
    reservation?: ReservationTypes.IReservation
    open: boolean
    className?: string
    style?: CSSProperties
    id?: string
    onClose?: (data?: any) => any
}

const ModalEditReserve: React.FC<IModalEditReserveProps> = (props) => {

    const hookReservation = useReservation();
    const {moment} = useMoment();
    const [openEdit, updateOpenEdit] = useState<boolean>(false);
    
    useEffect(() => {
        updateOpenEdit(false);
    }, [props.open])

    /**
     * @INFO Cerrar el modal
     */
    const handleCloseModal = () => {
        if(props.onClose){
            props.onClose()
        }
    }

    /**
     * @INFO Eliminar la reserva
     */
    const handleClickDelete = async () => {
        if (!props.reservation) return
        const response = await hookReservation.deleteReservation(props.reservation);
        if (response) {
            handleCloseModal();
        }
    }

    const handleClickEdit = () => {
        updateOpenEdit(true);
    }

    const handleReservationUpdated = (newReservation: ReservationTypes.IReservation | undefined) => {
        if (newReservation && props.onClose) {
            props.onClose(newReservation);
        }
    }
    
    return(
        <Modal
            onHide={() => handleCloseModal()}
            show={props.open}
            centered
            backdrop="static"
            keyboard={false}
            size='lg'
        >
            <Modal.Header>
                <TextComponent
                    type='subtitle'
                >
                    Editar reserva
                </TextComponent>
            </Modal.Header>
            <Modal.Body>
                <div
                    className={`modal_edit_reserve-layout ${props.className ? props.className : ''}`}
                    style={props.style}
                    id={props.id}
                >
                    <TextComponent><strong>Laboratorio:</strong> {props.reservation?.lab?.name}</TextComponent>
                    <TextComponent><strong>Día:</strong> {moment(props.reservation?.date).format('YYYY / MMMM / DD')}</TextComponent>
                    <TextComponent><strong>Hora:</strong> {props.reservation ? hookReservation.getReservationDateFormated(props.reservation) : ''}</TextComponent>
                    {openEdit ? 
                        <ReserveLaboratoryModule
                            idLab={props.reservation?.lab?.id ? props.reservation?.lab?.id : ''}
                            duration={props.reservation?.lab?.config?.duration}
                            initialDate={props.reservation?.date}
                            updateReserve={props.reservation?.id}
                            onReserveSaved={handleReservationUpdated}
                        />
                    :null}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonComponent
                    onClick={() => handleCloseModal()}
                >
                    Cancelar
                </ButtonComponent>
                {!openEdit ?
                    <>
                        <ButtonComponent
                            variant='primary2'
                            onClick={handleClickEdit}
                        >
                            Editar
                        </ButtonComponent>
                        <ButtonComponent
                            variant='primary'
                            onClick={handleClickDelete}
                        >
                            Eliminar
                        </ButtonComponent>
                    </>
                :
                    null
                }
            </Modal.Footer>
        </Modal>
    );
}

export default ModalEditReserve;