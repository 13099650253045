import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector';

// @INFO Importar archivos
import enTranslation from 'resources/en/translation.json'
import esTranslation from 'resources/es/translation.json'

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
    resources: {
        en: {translation: enTranslation},
        es: {translation: esTranslation} 
    },
    fallbackLng: 'en',
    interpolation:{
        escapeValue: false
    },
    debug: false
})

export default i18n