// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import ButtonComponent from 'components/commons/Button/ButtonComponent'
import TextComponent from 'components/commons/Text/TextComponent'
import ModalNewNotebook from '../ModalNewNotebook/ModalNewNotebook'
// @end components

// @import types
import * as NotebookTypes from 'types/notebook/notebook.types'
import * as LaboratoryTypes from 'types/laboratories/laboratories.types'
import { IStoreApp } from 'redux/reducers/index';
// @end types

// @import services
import NotebookService from 'services/notebook/notebookService'
// @end services

// @import hooks
import { useSelector } from 'react-redux';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './NotebookDashboard.scss'
// @end styles

interface INotebookDashboardProps {
    laboratory: LaboratoryTypes.ILaboratory
    className?: string
    style?: CSSProperties
    id?: string
}

const NotebookDashboard: React.FC<INotebookDashboardProps> = (props) => {

    const [currentNotebook, updateCurrentNotebook] = useState<NotebookTypes.INotebook | undefined>(undefined)
    const [notebooks, updateNotebooks] = useState<NotebookTypes.INotebook[]>([])
    const [openModal, updateOpenModal] = useState<boolean>(false)

    const app = useSelector((store: IStoreApp) => store.app)

    // @INFO Servicios
    const notebookService = new NotebookService()

    useEffect(() => {
        if(props.laboratory){
            getNotebookList()
        }
    },[props.laboratory])

    useEffect(() => {
        if(notebooks?.length){
            if(!currentNotebook){
                updateCurrentNotebook(notebooks[0])
            }
        }
    },[notebooks])

    /**
     * @INFO Obtener los notebook del laboratorio
     */
    const getNotebookList = async () => {
        const _notebooks = await notebookService.list({
            lab: props.laboratory.id
        })
        if(_notebooks){
            updateNotebooks(_notebooks)
        }else{
            updateNotebooks([])
        }
    }

    /**
     * @INFO Click en nuevo laboratorio
     */
    const handleClickNewNotebook = () => {
        updateOpenModal(true)
    }

    /**
     * @INFO Se cierra el modal de nuevo notebook
     * @param _status 
     */
    const handleCloseModal = async (_status?: boolean) => {
        if(_status){
            await getNotebookList()
        }
        updateOpenModal(false)
    }

    /**
     * @INFO Click a un notebook
     * @param _notebook 
     */
    const handleClickNotebook = (_notebook: NotebookTypes.INotebook) => {
        updateCurrentNotebook(_notebook)
    }

    return(
        <div
            className={`notebook_dashboard-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <div className="container-list-notebooks">
                <ButtonComponent
                    variant='primary'
                    onClick={handleClickNewNotebook}
                >
                    Nuevo notebook
                </ButtonComponent>
                {notebooks?.length ?
                    notebooks.map((_notebook) => (
                        <ItemNotebook
                            key={_notebook.id}
                            title={_notebook?.name ? _notebook.name : ''}
                            active={_notebook.id === currentNotebook?.id}
                            onClick={() => handleClickNotebook(_notebook)}
                        />
                    ))
                :
                    <TextComponent>
                        Aún no tienes notebooks
                    </TextComponent>
                }
            </div>
            <div className="container-current-notebook">
                {currentNotebook ?
                    <iframe 
                        src={currentNotebook.url} 
                        frameBorder={0}
                        className={`frame-notebook ${app.sidebarState ? 'open-sidebar' : ''}`}
                        
                        // allow='https://vlesim.com/notebook/notebooks'
                    />  
                :null}
            </div>
            {props.laboratory?.id?
                <ModalNewNotebook
                    open={openModal}
                    labId={props.laboratory.id}
                    onClose={handleCloseModal}
                />
            :null}
        </div>
    );
}

// ==================================================
// @INFO Item de notebook
// ==================================================
interface IItemNotebookProps {
    title: string
    className?: string
    style?: CSSProperties
    id?: string
    active?: boolean
    onClick?: () => any
}

const ItemNotebook: React.FC<IItemNotebookProps> = (props) => {
    return(
        <div
            className={`item_notebook-layout ${props.active ? 'active' : ''} ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
            onClick={props.onClick}
        >
            <TextComponent>
                {props.title}
            </TextComponent>
        </div>
    );
}


export default NotebookDashboard;