import * as LocationsTypes from 'types/locations/locations.types'
import * as LocationsActionTypes from 'types/locations/locations.actions.types'

/**
 * @INFO Actualizar los países
 * @param countries 
 * @returns 
 */
export const updateCountriesAction = (countries: string[]) => {
    return async (dispatch: Function) => {
        dispatch(updateCountries(countries))
    }
}

const updateCountries = (countries: string[]) => ({
    type: LocationsActionTypes.UPDATE_COUNTRIES,
    payload: countries
})

/**
 * @INFO Actualizar las provincias
 * @param provinces 
 * @returns 
 */
export const updateProvincesAction = (provinces: string[]) => {
    return async (dispatch: Function) => {
        dispatch(updateProvinces(provinces))
    }
}

const updateProvinces = (provinces: string[]) => ({
    type: LocationsActionTypes.UPDATE_PROVINCES,
    payload: provinces
})

/**
 * @INFO Actualizar las ciudades
 * @param cities 
 * @returns 
 */
export const updateCitiesAction = (cities: string[]) => {
    return async (dispatch: Function) => {
        dispatch(updateCities(cities))
    }
}

const updateCities = (cities: string[]) => ({
    type: LocationsActionTypes.UPDATE_CITIES,
    payload: cities
})