// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import InputComponent from 'components/commons/Input/InputComponent';
import SubtitleComponent from 'components/commons/Subtitle/SubtitleComponent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import CheckBoxComponent from 'components/commons/CheckBoxComponent/CheckBoxComponent';
// @end components

// @import types
import * as HardwareTypes from 'types/hardware/hardware.types'
import { IStoreApp } from 'redux/reducers';
// @end types

// @import services
import HardwareService from 'services/hardware/hardwareService';
import ScreenNotification from 'services/screenNotification/screenNotification';
// @end services

// @import hooks
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useValidator from 'hooks/useFormValidator/useFormValidator';
import { useSelector } from 'react-redux';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './CreateHardwarePage.scss'
import ToolSelectModule from 'components/modules/ToolSelectModule/ToolSelectModule';
import BreadcrumbComponent from 'components/commons/BreadcrumbComponent/breadcrumbComponent';
// @end styles

interface ICreateHardwarePageProps {
    className?: string
    style?: CSSProperties
    id?: string
    history?: any
}

const CreateHardwarePage: React.FC<ICreateHardwarePageProps> = (props) => {

    const [loading, updateLoading] = useState<boolean>(false)
    const [hardware, updateHardware] = useState<HardwareTypes.IHardware | undefined>(undefined)
    const theme = useSelector((store: IStoreApp) => store.app.theme)

    const params = useParams<{idHardware?: string}>()
    const {t} = useTranslation()
    const form = useValidator([
        { name: 'name', required: true, messageError: t('obligatory_field') },
        { name: 'state', messageError: t('obligatory_field') },
        { name: 'working', messageError: t('obligatory_field') },
        { name: 'tools', required: true, messageError: t('obligatory_field') },
    ])

    // @INFO Servicios
    const screenNotification = new ScreenNotification()
    const hardwareService = new HardwareService()

    useEffect(() => {
        getInitialData()
    }, [])

    useEffect(() => {
        setValuesForm()
    },[hardware])

    /**
     * @INFO Obtener la data inicial para el componente
     */
     const getInitialData = async () => {
        getHardware()
    }

    /**
     * @INFO Establecer los valores del hardware
     */
    const setValuesForm = () => {
        if(hardware){
            form.setValues({
                ...hardware
            })
        }
    }

    /**
     * @INFO Obtener el hardware
     */
    const getHardware = async () => {
        if(!params.idHardware) {
            const auxHardware: HardwareTypes.IHardware = {
                name: 'Sin titulo',
                state: 'available',
                working: true
            }
            updateHardware({...auxHardware})
        } else{
            const newHardware = await hardwareService.getById({ id: params.idHardware })
            if(newHardware){
                updateHardware(newHardware)
            }
        }
    }


    /**
     * @INFO Click en crear o actualizar el hardware
     */
     const handleClickCreateOrUpdate = async () => {
        updateLoading(true)
        // @INFO Obtener el objeto completo del formulario
        const values = form.getValues()
        // @INFO Enviar la petición
        let response: any = undefined
        if(params.idHardware){
            response = await hardwareService.update({
                hardware: values,
                id: params.idHardware
            })
            if(response){
                screenNotification.showSuccessTimer()
            }
        }else{
            response = await hardwareService.create({
                hardware: values
            })
            if(response){
                await screenNotification.showSuccessTimer()
                if(props.history){
                    props.history.push(`/edit-hardware/${response.id}`)
                }
            }
        }
        updateLoading(false)
    }

    return(
        <AppLayout
            history={props.history}
            headerTitle='Catalogador hardware'
        >
            <div
                className={`create_hardware_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
                id={props.id}
            >
                <BreadcrumbComponent
                    style={{
                        marginBottom: '20px'
                    }}
                >
                    <BreadcrumbComponent.Item
                        title='Catalogador'
                        path='/cataloger'
                    />
                    <BreadcrumbComponent.Item
                        title='Lista'
                        path='/cataloger-list/hardware'
                    />
                    <BreadcrumbComponent.Item
                        title={hardware?.name ? hardware.name : 'Nuevo hardware'}
                        current
                    />
                </BreadcrumbComponent>
                <SubtitleComponent
                    style={{ marginBottom: '15px' }}
                >
                    {params.idHardware ? 'Editar hardware' : 'Crear hardware'}
                </SubtitleComponent>
                <div className="container-form">
                    <InputComponent
                        name='name'
                        label='Nombre'
                        placeholder='Nombre'
                        value={form.values['name']}
                        onChange={form.handleChange}
                        invalid={form.errors['name'] ? true : false}
                        invalidText={form.errors['name']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <CheckBoxComponent
                        name='state'
                        checked={form.values['state']}
                        label='Activo/Inactivo'
                        onChange={form.handleChange}
                    />
                    <CheckBoxComponent
                        name='working'
                        checked={form.values['working']}
                        label='Funcionando/Averiado'
                        onChange={form.handleChange}
                    />
                    <ToolSelectModule
                        isMultiple
                        label='Herramientas'
                        defaultValue={hardware?.tools?.length ? hardware.tools.map((_t) => _t.id as string) : undefined}
                        onChange={(_ids) => form.setValue('tools', _ids)}
                    />
                    <div
                        style={{ gridColumn: '1/3', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                    >
                        <ButtonComponent
                            onClick={handleClickCreateOrUpdate}
                            variant='primary'
                            disabled={form.isInvalid()}
                            loading={loading}
                        >
                            {params.idHardware ? 'Guardar' : 'Crear'}
                        </ButtonComponent>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

export default CreateHardwarePage;