// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import CalendarComponent from 'components/commons/CalendarComponent/CalendarComponent';
import SubtitleComponent from 'components/commons/Subtitle/SubtitleComponent';
import { FaRegCalendar } from 'react-icons/fa';
// @end components

// @import types
import * as ReservationTypes from "types/reservations/reservations.types"
import { DateClickArg } from '@fullcalendar/interaction';
// @end types

// @import services
import ReservationsService from 'services/reservations/reservationsService';
// @end services

// @import hooks
import useMoment from 'hooks/useMoment/useMoment';
import { useTranslation } from 'react-i18next';
import useReservation from 'hooks/useReservation/useReservation';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ReserveLaboratoryModule.scss'
// @end styles

interface IReserveLaboratoryModuleProps {
    idLab: string
    className?: string
    style?: CSSProperties
    id?: string
    duration?: number
    initialDate?: Date
    updateReserve?: string
    onReserveSaved?: (data?: ReservationTypes.IReservation | undefined) => void
}

const ReserveLaboratoryModule: React.FC<IReserveLaboratoryModuleProps> = (props) => {
    
    const [availableHours, updateAvailableHours] = useState<ReservationTypes.IAvailableHour[]>([]);
    const [currentDate, updateCurrentDate] = useState<Date>(props.initialDate ? props.initialDate : new Date());
    const [currentHour, updateCurrentHour] = useState<ReservationTypes.IAvailableHour | undefined>(undefined);
    const [loading, updateLoading] = useState<boolean>(false);
    const {t} = useTranslation();
    const {moment} = useMoment();
    const hookReservation = useReservation();

    // @INFO Servicios
    const reservationsService = new ReservationsService();

    useEffect(() => {
        // getInitialData()
    }, [])

    useEffect(() => {
        getInitialData()
    },[currentDate])

    /**
     * @INFO Obtener la data inicial del componente
     */
    const getInitialData = async () => {
        getAvailableHours()
    }

    /**
     * @INFO Obtener la lista de horas disponibles
     */
    const getAvailableHours = async () => {
        const response = await reservationsService.availableHours({
            date: currentDate,
            lab: props.idLab
        })
        updateAvailableHours(response)
    }

    /**
     * @INFO Reservar el laboratorio
     */
    const handleClickReserve = async () => {
        if(!currentHour) return
        updateLoading(true)
        let response: ReservationTypes.IReservation | undefined;
        if (props.updateReserve) {
            response = await hookReservation.updateReservation({
                id: props.updateReserve,
                reservation: {
                    date: currentHour.date
                }
            });
        } else {
            response = await hookReservation.createReservation({
                lab: props.idLab,
                date: currentHour.date
            });
        }
        updateLoading(false)
        if(response && props.onReserveSaved){
            props.onReserveSaved(response)
        }
    }

    /**
     * @INFO Click para seleccionar un dia
     * @param args 
     */
    const handleClickDay = (args: DateClickArg) => {
        updateCurrentDate(args.date)
    }
    
    return(
        <div
            className={`reserve_laboratory_module-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <div className="container-calendar-reserve">
                <CalendarComponent
                    events={[
                        { title: 'Evento 1', date: moment(currentDate).format('YYYY-MM-DD') }
                    ]}
                    style={{ height: '100%', width: '100%', boxShadow: 'none' }}
                    contentHeight={'400px'}
                    dateClick={(args) => handleClickDay(args)}
                    disableDayView
                />
            </div>
            <div className="container-available-hours">
                <SubtitleComponent>
                    Horas disponibles
                </SubtitleComponent>
                <div className="list-available-hours">
                    {availableHours?.map((item) => (
                        item.available ?
                            <ItemHour
                                key={(new Date(item.date)).getTime()}
                                text={`${moment(item.date).format('HH:mm')} - ${moment(item.date).add(props.duration ? props.duration : 0, 's').format('HH:mm')}`}
                                active={currentHour?.date === item.date ? true : false}
                                onClick={() => updateCurrentHour(item)}
                            />
                        :null
                    ))}
                </div>
                {props.updateReserve ?
                    <ButtonComponent
                        variant='primary'
                        disabled={currentHour ? false : true}
                        loading={loading}
                        onClick={() => handleClickReserve()}
                    >
                        Guardar
                    </ButtonComponent>
                :
                    <ButtonComponent
                        variant='accent'
                        style={{color: 'white'}}
                        disabled={currentHour ? false : true}
                        loading={loading}
                        onClick={() => handleClickReserve()}
                    >
                        <ButtonComponent.Icon>
                            <FaRegCalendar/>
                        </ButtonComponent.Icon>
                        {t('laboratory_preview.reserve')}
                    </ButtonComponent>
                }
            </div>
        </div>
    );
}

// ================================================
// Item hora
// ================================================
interface IItemHourProps {
    text: string
    active?: boolean
    className?: string
    style?: CSSProperties
    onClick?: () => any
}

const ItemHour: React.FC<IItemHourProps> = (props) => {
    return(
        <div
            className={`item_hour-layout ${props.active ? 'active' : ''} ${props.className ? props.className : ''}`}
            style={props.style}
            onClick={props.onClick}
        >
            <SubtitleComponent
                className='text-hour-option'
            >
                {props.text}
            </SubtitleComponent>
        </div>
    );
}

export default ReserveLaboratoryModule;