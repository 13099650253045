import React, { CSSProperties, ChangeEvent, useState, useEffect } from 'react'
import InputComponent from '../Input/InputComponent'

import './DurationComponent.scss'

interface IDurationComponentProps {
    className?: string
    style?: CSSProperties
    value?: string
    name?: string
    ref?: any
    disabled?: boolean
    required?: boolean
    valid?: boolean
    validText?: string
    invalid?: boolean
    invalidText?: string
    label?: string
    placeholder?: string
    background?: 'background-login' | 'background' | 'background2' 
    onChange?: (e: any) => any
    onEnter?: (value?: string)=>any
}

const DurationComponent: React.FC<IDurationComponentProps> = (props) => {

    const [text, updateText] = useState<string | undefined>(undefined)

    useEffect(() => {
        if(!text){
            updateText(getDurationFormated(props.value ? Number(props.value) : 0))
        }
    },[props.value])

    useEffect(() => {
        if(text){
            const _seconds = getSecondsFromDuration(text)
            if(props.onChange){
                const dataSend = {
                    target: {
                        name: props.name,
                        value: _seconds
                    }
                }
                props.onChange(dataSend)
            }
        }
    },[text])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const durString = e.target.value
        updateText(durString)
    }

    /**
     * @INFO Obtener duración a partir de segundos
     * @param seconds
     */
     const getDurationFormated = (seconds: number) => {
        const hours = Math.trunc(seconds / 3600)
        const minutes = Math.trunc((seconds - (hours*3600))/60)
        const seconds2 = Math.trunc(seconds - (hours*3600) - (minutes*60))
        let response: string = ''
        if(hours){
            response = `${response} ${hours}h`
        }
        if(minutes){
            response = `${response} ${minutes}m`
        }
        if(seconds2){
            response = `${response} ${seconds2}s`
        }
        return response
    }

    /**
     * @INFO Obtener segundos a partir de un texto
     * @param inputValue
     */
     const getSecondsFromDuration = (inputValue: string) => {
        const auxVector = inputValue.split(' ')
        let seconds: number = 0
        if(auxVector.length){
            auxVector.forEach(item => {
                if(item.includes('h')){
                    const hours = Number(item.replace('h', ''))
                    seconds += hours*3600
                }else if(item.includes('m')){
                    const minutes = Number(item.replace('m', ''))
                    seconds += minutes*60
                }else if(item.includes('s')){
                    seconds += Number(item.replace('s', ''))
                }
            })
        }
        return seconds
    }

    return(
        <div
            className={`duration_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <InputComponent
                type='text'
                value={text}
                name={props.name}
                ref={props.ref}
                disabled={props.disabled}
                required={props.required}
                valid={props.valid}
                validText={props.validText}
                invalid={props.invalid}
                invalidText={props.invalidText}
                label={props.label}
                placeholder={props.placeholder ? props.placeholder : '0h 0m 0s'}
                background={props.background}
                onChange={handleChange}
                onEnter={props.onEnter}
            />
        </div>
    );
}

export default DurationComponent;