import React, { CSSProperties } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'

import './PopUpComponent.scss'
import shortid from 'shortid';
import { IoMdClose } from 'react-icons/io';

type ITypePlacement = 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' | 'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start'

interface IPopUpComponentProps {
    show: boolean
    placement?: ITypePlacement
    className?: string
    style?: CSSProperties
    children?: any
    content?: any
    hideClose?: boolean
    onClickClose?: () => any
}

const PopUpComponent: React.FC<IPopUpComponentProps> = (props) => {
    return(
        <OverlayTrigger
            show={props.show}
            key={props.placement}
            placement={props.placement}
            overlay={
                <Popover
                    className={`pop_up-layout ${props.hideClose ? 'hice-close' : ''} ${props.className ? props.className : ''}`}
                    style={props.style}
                    id={shortid.generate()}
                >
                    {!props.hideClose ?
                        <div className="icon-close-popup">
                            <IoMdClose
                                onClick={props.onClickClose}
                            />
                        </div>
                    :null}
                    {props.content}
                </Popover>
            }
        >
            {props.children}
        </OverlayTrigger>
    );
}


export default PopUpComponent;