import { FC } from 'react'

import PersonPcImage from 'assets/images/landing/person_pc.png'

import './SmartFactoryOurClients.scss'

interface SmartFactoryOurClientsProps{
    className?: string
}

interface ClientCardProps{
    description: string
    name: string
    image: string
    email: string
}

const SmartFactoryOurClients: FC<SmartFactoryOurClientsProps> = ({ className }) => {
    return (
        <div className={`smart_factory_our_clients--layout ${className ? className : ''}`}>
            <h1>¿Que dicen nuestros clientes?</h1>
            <p>Creamos la posiblidad de crecer e interactuar sin riesgos y sin mayores costos por eso nuestros usuarios no lo agradecen.</p>
            <div className="smart_factory_our_clients--cards">
                <ClientCard
                    description='“I know in real-time where the money is spent,and
                    I don’t have to lend out the company’s credit card
                    anymore. What a relief!”'
                    image={PersonPcImage}
                    name='Denny Hilguston'
                    email='@denny.hill'
                />
                <ClientCard
                    description='“I know in real-time where the money is spent,and
                    I don’t have to lend out the company’s credit card
                    anymore. What a relief!”'
                    image={PersonPcImage}
                    name='Denny Hilguston'
                    email='@denny.hill'
                />
                <ClientCard
                    description='“I know in real-time where the money is spent,and
                    I don’t have to lend out the company’s credit card
                    anymore. What a relief!”'
                    image={PersonPcImage}
                    name='Denny Hilguston'
                    email='@denny.hill'
                />
            </div>
        </div>
    )
}

const ClientCard: FC<ClientCardProps> = ({ description, name, image, email }) => {
    return (
        <div className='client_card' >
            <p>{description}</p>
            <div className="client_card--info">
                <img className="client_card--info-image" alt='' src={image} />
                <div className="client_card--info-name">
                    <p>{name}</p>
                    <p>{email}</p>
                </div>
            </div>
        </div>
    )
}

export default SmartFactoryOurClients