// @import dependencies
import React, { CSSProperties, useState } from 'react'
// @end dependencies

// @import components
import InputComponent from 'components/commons/Input/InputComponent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import TitlePageComponent from 'components/commons/TitlePage/TitlePageComponent';
import SubtitleComponent from 'components/commons/Subtitle/SubtitleComponent';
// @end components

// @import types
// @end types

// @import services
import EmailService from 'services/email/emailService';
import ScreenNotification from 'services/screenNotification/screenNotification';
// @end services

// @import hooks
import useValidator from 'hooks/useFormValidator/useFormValidator';
import { useTranslation } from 'react-i18next';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ContactFormModule.scss'
// @end styles

interface IContactFormModuleProps {
    title?: string
    subtitle?: string
    className?: string
    classNameInput?: string
    style?: CSSProperties
    id?: string
    
}

const ContactFormModule: React.FC<IContactFormModuleProps> = (props) => {

    const {t} = useTranslation()
    const [loading, updateLoading] = useState<boolean>(false)

    // @INFO Servicios
    const emailService = new EmailService()
    const screenNotification = new ScreenNotification()

    const form = useValidator([
        {name: 'firstName', required: true, value: '', messageError: t('obligatory_field')},
        {name: 'lastName', required: true, value: '', messageError: t('obligatory_field')},
        {name: 'email', required: true, value: '', messageError: t('obligatory_field'), validators: [{type: 'email'}]},
        {name: 'phoneNumber', required: true, value: '', messageError: t('obligatory_field')},
        {name: 'comment', required: true, value: '', messageError: t('obligatory_field')},
    ])

    /**
     * @INFO enviar el email
     */
    const handleClickSendEmail = async () => {
        updateLoading(true)
        const dataSend = form.getValues()
        const response = await emailService.sendEmail({
            message: `
                Nombres: ${dataSend.firstName} ${dataSend.lastName}

                Telefono: ${dataSend.phoneNumber}
                
                Comentario:  ${dataSend.comment}
            `,
            subject: `
                Contacto: ${dataSend.firstName} ${dataSend.lastName}
            `,
            to: [dataSend.email]
        })
        if(response){
            await screenNotification.showSuccessTimer({
                text: 'Hemos recibido tu mensaje, pronto te contactaremos',
                title: 'Gracias por tu mensaje',
                timer: 2000
            })
        }
        updateLoading(false)
    }

    return(
        <div
            className={`contact_form_module-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            {props.subtitle ?
                <SubtitleComponent>
                    {props.subtitle}
                </SubtitleComponent>
            :null}
            {props.title ?
                <TitlePageComponent>
                    {props.title}
                </TitlePageComponent>
            :null}
            <div
                style={{
                    width: '100%',
                    backgroundColor: 'var(--primary2)',
                    height: '4px'
                }}
            />
            <div 
                className="container-general-fields"
            >
                <InputComponent
                    placeholder={t('register.fields.firstName')}
                    name='firstName'
                    invalid={form.errors['firstName'] ? true : false}
                    invalidText={form.errors['firstName']}
                    value={form.values['firstName']}
                    className={props.classNameInput}
                    onChange={form.handleChange}
                />
                <InputComponent
                    placeholder={t('register.fields.lastName')}
                    name='lastName'
                    invalid={form.errors['lastName'] ? true : false}
                    invalidText={form.errors['lastName']}
                    value={form.values['lastName']}
                    className={props.classNameInput}
                    onChange={form.handleChange}
                />
                <InputComponent
                    placeholder={t('register.fields.email')}
                    name='email'
                    invalid={form.errors['email'] ? true : false}
                    invalidText={form.errors['email']}
                    value={form.values['email']}
                    className={props.classNameInput}
                    onChange={form.handleChange}
                />
                <InputComponent
                    placeholder={t('register.fields.phoneNumber')}
                    name='phoneNumber'
                    invalid={form.errors['phoneNumber'] ? true : false}
                    invalidText={form.errors['phoneNumber']}
                    value={form.values['phoneNumber']}
                    className={props.classNameInput}
                    onChange={form.handleChange}
                />
            </div>
            <InputComponent
                type='textarea'
                placeholder={t('register.fields.comment')}
                name='comment'
                invalid={form.errors['comment'] ? true : false}
                invalidText={form.errors['comment']}
                value={form.values['comment']}
                className={props.classNameInput}
                onChange={form.handleChange}
            />
            <ButtonComponent
                variant='primary'
                disabled={form.isInvalid()}
                onClick={handleClickSendEmail}
                loading={loading}
                style={{
                    padding: '0.5rem 4rem'
                }}
            >
                Enviar
            </ButtonComponent>
        </div>
    );
}

export default ContactFormModule;