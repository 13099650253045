import { IPositionHelpSteps, ITypeTypesHelpSteps, IStepHelpSteps } from 'types/helpSteps/helpStepsTypes';
import React, { Component, CSSProperties } from 'react';
import { Styles, Step, CallBackProps } from 'react-joyride'

import { HelpStepsLayout } from './../index'



interface IHelpStepsComponentProps {
  steps: IStepHelpSteps[]
  styleJoyride?: Styles
  className?: string
  style?: CSSProperties
  continuous?: boolean
  showProgress?: boolean
  disableOverlayClose?: boolean
  showSkipButton?: boolean
  position?: IPositionHelpSteps    // Posición en la pantalla, debe estar el prop fixed=true
  type?: ITypeTypesHelpSteps  // Estilos por defecto para el componente por defecto 'floating'
  fixed?: boolean   // Si se establece en true se puede utilizar el prop 'position' con cualquier 'type'
  onUpdate?:(data?: CallBackProps)=> void
  onNext?:(data?: CallBackProps)=> void
  onReset?:(data?: CallBackProps)=> void
  onClose?:(data?: CallBackProps)=> void
  onPrev?:(data?: CallBackProps)=> void
}

interface IHelpStepsComponentState {}

class HelpStepsComponent extends Component<IHelpStepsComponentProps, IHelpStepsComponentState> {
    render() {
        return (
            <HelpStepsLayout
              {...this.props}
            />
        );
    }
}

export default HelpStepsComponent;
