// @import dependencies
import React, { CSSProperties, useState } from 'react';
// @end dependencies

// @import components
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { MdContentCopy } from 'react-icons/md';
import { Link } from 'react-router-dom';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './CopyTextComponent.scss'
// @end styles

interface ICopyTextComponentProps {
    text: string
    className?: string
    style?: CSSProperties
    id?: string
    linkToText?: boolean
}

const CopyTextComponent: React.FC<ICopyTextComponentProps> = (props) => {

    const [copied, updateCopied] = useState<boolean>(false);

    return(
        <div
            className={`copy_text_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            {props.linkToText ?
                <Link
                    to={{
                        pathname: props.text
                    }}
                    target='_blank'
                >
                    <code>
                        {props.text}
                    </code>
                </Link>
            :
                <code>
                    {props.text}
                </code>
            }
            <CopyToClipboard
                onCopy={() => updateCopied(true)}
                text={props.text}
            >
                <MdContentCopy
                    className={`${copied ? 'copied' : ''}`}
                />
            </CopyToClipboard>
        </div>
    );
}

export default CopyTextComponent;