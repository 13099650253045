import React from 'react';
import shortId from 'shortid';
import parse from 'html-react-parser';

export interface ListEditorJsProps {
  data: {items: string[], style: 'ordered' | 'unordered'}
}

const ListEditorJs: React.SFC<ListEditorJsProps> = (props) => {
  return (
    props.data.style === 'ordered' ?
      <ol style={{fontSize: '1.1rem', lineHeight: '1.6em', color: 'var(--text)' }} >
        {props.data?.items?.map(item => (
          <li key={shortId.generate()} >{parse(item)}</li>
        ))}
      </ol>
    :
      <ul style={{fontSize: '1.1rem', lineHeight: '1.6em', color: 'var(--text)' }} >
        {props.data?.items?.map(item => (
          <li key={shortId.generate()} >{parse(item)}</li>
        ))}
      </ul>
  );
}

export default ListEditorJs;
