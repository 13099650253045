// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import SubtitleComponent from 'components/commons/Subtitle/SubtitleComponent';
import InputComponent from 'components/commons/Input/InputComponent';
import CheckBoxComponent from 'components/commons/CheckBoxComponent/CheckBoxComponent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
// @end components

// @import types
import { IStoreApp } from 'redux/reducers';
import * as ToolCategoryTypes from 'types/toolCategory/toolCategory.types';
// @end types

// @import services
import ToolCategoryService from 'services/toolCategory/toolCategoryService';
import ScreenNotification from 'services/screenNotification/screenNotification';
// @end services

// @import hooks
import useValidator from 'hooks/useFormValidator/useFormValidator';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './CreateToolCategoryPage.scss'
import BreadcrumbComponent from 'components/commons/BreadcrumbComponent/breadcrumbComponent';
// @end styles

interface ICreateToolCategoryPageProps {
    className?: string
    style?: CSSProperties
    id?: string
    history?: any
}

const CreateToolCategoryPage: React.FC<ICreateToolCategoryPageProps> = (props) => {

    const [loading, updateLoading] = useState<boolean>(false)
    const [toolCategory, updateToolCategory] = useState<ToolCategoryTypes.IToolCategory | undefined>(undefined)
    
    const theme = useSelector((store: IStoreApp) => store.app.theme)
    const {t} = useTranslation()

    const form = useValidator([
        { name: 'name', required: true, messageError: t('obligatory_field') },
        { name: 'description', required: true, messageError: t('obligatory_field') },
        // @INFO Configuraciones de la categoría
        { name: 'config.hasEvent',value: false },
        { name: 'config.hasSamplingTime',value: false },
        { name: 'config.hasVariables',value: false },
        { name: 'config.hasVoltageRange',value: false },
        { name: 'config.hasCurrentRange',value: false },
        { name: 'config.hasVoltage',value: false },
        { name: 'config.hasCurrent',value: false },
        { name: 'config.hasStorage',value: false },

        { name: 'config.canDeployCode',value: false },
        
        { name: 'config.isLineChart',value: false },
        { name: 'config.isCamera',value: false },
        { name: 'config.isSource',value: false },
        { name: 'config.isButton',value: false },
        { name: 'config.isPushbutton',value: false },
        { name: 'config.isCodeEditor',value: false },
        { name: 'config.isOscilloscope',value: false },
        { name: 'config.isRoboticArm',value: false },
        { name: 'config.isBarChart',value: false },
        { name: 'config.isSlider',value: false },
        { name: 'config.isImage',value: false },
        { name: 'config.isTable',value: false },
    ])

    const params = useParams<{idToolCategory?: string}>()

    // @INFO Servicios
    const toolCategoryService = new ToolCategoryService()
    const screenNotification = new ScreenNotification()

    useEffect(() => {
        getInitialData()
    }, [])

    useEffect(() => {
        setValuesForm()
    },[toolCategory])

    /**
     * @INFO Obtener la data inicial para el componente
     */
     const getInitialData = async () => {
        getToolCategory()
    }

     /**
     * @INFO Establecer los valores del laboratorio en el formulario
     */
    const setValuesForm = () => {
        if(toolCategory){
            form.setValues({...toolCategory})
        }
    }

    /**
     * @INFO Obtener la categoría de herramienta
     */
    const getToolCategory = async () => {
        if(!params.idToolCategory) {
            const auxBanner: ToolCategoryTypes.IToolCategory = {
                name: 'Sin titulo',
                description: 'Sin descripción',
            }
            updateToolCategory({...auxBanner})
        } else{
            const newToolCategory = await toolCategoryService.getById({ id: params.idToolCategory })
            if(newToolCategory){
                updateToolCategory(newToolCategory)
            }
        }
    }

    /**
     * @INFO Click en crear o actualizar la categoría
     */
     const handleClickCreateOrUpdate = async () => {
        updateLoading(true)
        // @INFO Obtener el objeto completo del formulario
        const values = form.getValues()
        // @INFO Enviar la petición
        let response: any = undefined
        if(params.idToolCategory){
            response = await toolCategoryService.update({
                category: values,
                id: params.idToolCategory
            })
            if(response){
                screenNotification.showSuccessTimer()
            }
        }else{
            response = await toolCategoryService.create({
                category: values
            })
            if(response){
                await screenNotification.showSuccessTimer()
                if(props.history){
                    props.history.push(`/edit-tool-category/${response.id}`)
                }
            }
        }
        updateLoading(false)
    }

    return(
        <AppLayout
            history={props.history}
            headerTitle='Catalogo'
        >
            <div
                className={`create_tool_category_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
                id={props.id}
            >
                <BreadcrumbComponent
                    style={{
                        marginBottom: '20px'
                    }}
                >
                    <BreadcrumbComponent.Item
                        title='Catalogador'
                        path='/cataloger'
                    />
                    <BreadcrumbComponent.Item
                        title='Lista'
                        path='/cataloger-list/tool-category'
                    />
                    <BreadcrumbComponent.Item
                        title={toolCategory?.name ? toolCategory.name : 'Nueva categoría de herramienta'}
                        current
                    />
                </BreadcrumbComponent>
                <SubtitleComponent
                    style={{ marginBottom: '15px' }}
                >
                    {params.idToolCategory ? 'Editar categoría de herramienta' : 'Crear categoría de herramienta'}
                </SubtitleComponent>
                <div className="container-form">
                    <InputComponent
                        name='name'
                        label='Titulo'
                        value={form.values['name']}
                        onChange={form.handleChange}
                        invalid={form.errors['name'] ? true : false}
                        invalidText={form.errors['name']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <InputComponent
                        name='description'
                        label='Descripción'
                        value={form.values['description']}
                        onChange={form.handleChange}
                        invalid={form.errors['description'] ? true : false}
                        invalidText={form.errors['description']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <SubtitleComponent>
                        Configuración:
                    </SubtitleComponent>
                    <div className="container-checks-options">
                        <CheckBoxComponent
                            name='config.hasEvent'
                            checked={form.values['config.hasEvent']}
                            label='Evento por socket predeterminado'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.hasSamplingTime'
                            checked={form.values['config.hasSamplingTime']}
                            label='Tiempo de muestreo'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.hasVariables'
                            checked={form.values['config.hasVariables']}
                            label='Variables'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.hasVoltageRange'
                            checked={form.values['config.hasVoltageRange']}
                            label='Rango de voltaje'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.hasCurrentRange'
                            checked={form.values['config.hasCurrentRange']}
                            label='Rango de corriente'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.hasVoltage'
                            checked={form.values['config.hasVoltage']}
                            label='Voltaje'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.hasCurrent'
                            checked={form.values['config.hasCurrent']}
                            label='Corriente'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.hasStorage'
                            checked={form.values['config.hasStorage']}
                            label='Almacena datos'
                            onChange={form.handleChange}
                        />
                        
                        <CheckBoxComponent
                            name='config.canDeployCode'
                            checked={form.values['config.canDeployCode']}
                            label='Permite subir código'
                            onChange={form.handleChange}
                        />

                        <CheckBoxComponent
                            name='config.isLineChart'
                            checked={form.values['config.isLineChart']}
                            label='Grafico de linea'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.isSource'
                            checked={form.values['config.isSource']}
                            label='Es fuente DC'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.isCamera'
                            checked={form.values['config.isCamera']}
                            label='Es camara'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.isButton'
                            checked={form.values['config.isButton']}
                            label='Es boton'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.isPushbutton'
                            checked={form.values['config.isPushbutton']}
                            label='Es pulsador'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.isCodeEditor'
                            checked={form.values['config.isCodeEditor']}
                            label='Es editor de codigo'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.isOscilloscope'
                            checked={form.values['config.isOscilloscope']}
                            label='Es osciloscopio'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.isRoboticArm'
                            checked={form.values['config.isRoboticArm']}
                            label='Es brazo robotico'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.isBarChart'
                            checked={form.values['config.isBarChart']}
                            label='Es gráfica de barras'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.isSlider'
                            checked={form.values['config.isSlider']}
                            label='Es slider'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.isImage'
                            checked={form.values['config.isImage']}
                            label='Es imagen'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.isTable'
                            checked={form.values['config.isTable']}
                            label='Es tabla'
                            onChange={form.handleChange}
                        />
                    </div>
                    <div
                        style={{ gridColumn: '1/3', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                    >
                        <ButtonComponent
                            onClick={handleClickCreateOrUpdate}
                            variant='primary'
                            disabled={form.isInvalid()}
                            loading={loading}
                        >
                            {params.idToolCategory ? 'Guardar' : 'Crear'}
                        </ButtonComponent>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

export default CreateToolCategoryPage;