import { SystemConfig, TypeModulesSystem } from "types/app/app.actions.types";
import * as AppActions from 'redux/reducers/app/app.actions';
import systemConfigs from 'config/system';
import store from "redux/store";
import { custom_system_type } from "config/globals";
import axios from "axios";
import crypto from 'crypto';

class AppService{

    private storeObject = store;

    constructor(){

    }

    /**
     * @INFO Hacer pausa asíncrona
     * @param ms 
     * @returns 
     */
    public asyncPause = (ms: number) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(true), ms);
        });
    }

    /**
     * @INFO Verificar si un modulo esta disponible para mostrar según la configuración del sistema
     * @param module 
     */
    public validateCustomConfigModule = (module: TypeModulesSystem) => {
        const config = this.getSystemConfig();
        const systemType = this.storeObject.getState().app.systemType;
        if (systemType === 'custom') {
            if (config && config?.modules?.includes(module)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    /**
     * @INFO Obtener la configuración del sistema
     * @returns 
     */
    public getSystemConfig = () => {
        let systemConfig = this.storeObject.getState()?.app?.systemConfig;
        if (!systemConfig) {
            systemConfig = this.loadSystemConfig();
        }
        return systemConfig;
    }

    /**
     * @INFO Establecer la configuración de la aplicación
     */
    public loadSystemConfig = (): SystemConfig | undefined => {
        const host = window.location.hostname;
        const prefixHost = host.split('.vlesim.')[0];
        if (custom_system_type && prefixHost && (custom_system_type as any)[prefixHost] && (custom_system_type as any)[prefixHost].key) {
        const key = (custom_system_type as any)[prefixHost].key;
        const config = (systemConfigs as any)[key];
        this.storeObject.dispatch(AppActions.updateSystemConfigAction(config));
        this.storeObject.dispatch(AppActions.updateSystemTypeAction('custom'));
        return config;
        }
    }

    /**
     * @INFO Obtener la ip del cliente
     */
    public getIpClient = async (): Promise<string> => {
        const res = await axios.get('https://geolocation-db.com/json/')
        return res.data.IPv4
    }

    /**
     * @INFO Encriptar datos para enviar al servidor
     * @param data 
     */
    public encryptData = (data: string, publicKey: string): string => {
        const encryptedData = crypto.publicEncrypt(
            {
                key: publicKey,
                padding: crypto.constants.RSA_PKCS1_PADDING,
            },
            Buffer.from(data)
        );
        return encryptedData.toString('base64')
    }
}

export default AppService;