// @import dependencies
import React, { CSSProperties } from 'react'
import { Link } from 'react-router-dom';
// @end dependencies

// @import components
import TextComponent from 'components/commons/Text/TextComponent';
// @end components

// @import types
import * as ReservationTypes from 'types/reservations/reservations.types';
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './LabInProgress.scss'
import TimerComponent from '../TimerComponent/TimerComponent';
// @end styles

interface ILabInProgressProps {
    reservation: ReservationTypes.IReservation
    className?: string
    style?: CSSProperties
    id?: string
}

const LabInProgress: React.FC<ILabInProgressProps> = (props) => {
    return(
        <div
            className={`lab_in_progress-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <TimerComponent
                size='small'
                initialDate={props.reservation.date}
                limitTime={props.reservation.lab.config?.duration}
                withoutProgress
            />
            <Link
                to={`/dashboard-laboratory/${props.reservation.lab.id}`}
                style={{textDecoration: 'none'}}
            >
                <TextComponent
                    type='label'
                    style={{fontWeight: 'bold'}}
                >
                    En progreso
                </TextComponent>
                <TextComponent
                    type='label'
                >
                    {props.reservation.lab.name}
                </TextComponent>
            </Link>
        </div>
    );
}

export default LabInProgress;