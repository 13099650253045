import React from 'react';
import parse from 'html-react-parser';
// import ImgComponent from 'app_components/commons/img/containers/imgComponent';
import shortId from 'shortid';
import LinkToolEditorJs from './components/linkToolEditorJs';
import QuoteEditorJs from './components/quoteEditorJs';
import ListEditorJs from './components/listEditorJs';
import RawEditorJs from './components/rawEditorJs';
import ChecklistEditorJs from './components/checklistEditorJs';
import TableEditorJs from './components/tableEditorJs';


interface IBlockEditorJS{
  data: any,
  type: string
}

class EditorjsService {

  constructor() {
  }

  /**
   * @INFO Obtener el html en string a partir de un json del editor js
   * @param blocks
   * @returns
   */
  public jsonToHtml = (blocks: IBlockEditorJS[]) => {
    if(!blocks?.length) return ''
    const response = blocks.reduce((acum: string, item) => {
      acum += this.getHtmlBlock(item)
      return acum
    }, '')
    return response
  }

  /**
   * @INFO Obtener el contenido del editor en componentes de React
   * @param blocks
   * @returns
   */
  public jsonToComponent = (blocks: IBlockEditorJS[]) => {
    if(!blocks?.length) return null
    return (
      <div>
        {blocks.map((item) => (
          <div key={shortId.generate()} >
            {this.getComponentBlock(item)}
          </div>
        ))}
      </div>
    )
  }

  /**
   * ! INCOMPLETO
   * @INFO obtener el html STRING de un bloque del editor
   * * Importante: por cada plugin agregado al editor se debe crear un nuevo case para obtener el html de ese bloque
   * @param block
   */
  private getHtmlBlock = (block: IBlockEditorJS) => {
    let response = ''
    if(!block.type) return response
    switch(block.type){
      // Header (h1 ... hn)
      case 'header':
        response = `<h${block.data.level}>${block.data.text}</h${block.data.level}>`
        break;
      // Parágrafo (<p>)
      case 'paragraph':
        response = `<p>${block.data.text}</p>`
        break;
      // Imagen (<img>)
      case 'image':
        response = `<img style='${block.data.withBackground ? 'padding: 30px; background-color: var(--primary);' : ''} width: 100%; ${block.data.withBorder ? 'border: 2px solid var(--primary);' : ''}' src='${block.data.file.url}' alt='${block.data.caption}' />`
        break;
      // Link a web (<div> ... <a>)
      case 'linkTool':
        response = `<a href='${block.data.link}' target='_blank' >${block.data.link}</a>`
        break;
      // Cita (<div> ... <div>)
      case 'quote':
        response =  `<div style='margin: 15px 0px;' >` +
                      `<p style='text-align: ${block.data.alignment}; margin: 0px; padding: 0px;' >${block.data.text}</p>` +
                      `<p style='text-align: ${block.data.alignment}; font-size: 0.8rem; margin: 0px; padding: 0px;' >${block.data.caption}</p>` +
                    `</div>`
        break;
      // Lista ordenada o desordenada (<ol> | <ul>)
      case 'list':
        response = ''
        break;
      // Texto con formato código (<div>)
      case 'raw':
        response = ''
        break;
      // checklist (<input type='radio'>)
      case 'checklist':
        response = ''
        break;
      // Tabla sin header (<table>)
      case 'table':
        response = ''
        break;
      default:
        break;
    }
    return response
  }

  /**
   * @INFO Obtener el componente de REACT para un bloque del editor
   * * Importante: por cada plugin agregado al editor se debe crear un nuevo case para obtener el html de ese bloque
   * @param block
   */
  private getComponentBlock = (block: IBlockEditorJS) => {
    let response: any = null
    if(!block.type) return response
    switch(block.type){
      case 'header':
        response = parse(`<h${block.data.level} style='margin-bottom: ${40/block.data.level}px; color: var(--text);' >${block.data.text}</h${block.data.level}>`)
        break;
      case 'paragraph':
        response = parse(`<p style='color: var(--text);' >${block.data.text}</p>`)
        break;
      case 'image':
        response = <img
                    src={block.data?.file?.url}
                    alt={block.data?.caption}
                    style={{
                      width: '100%',
                      padding: block.data.withBackground ? '30px 50px' : undefined,
                      backgroundColor: block.data.withBackground ? 'var(--primary)' : undefined ,
                      border: block.data.withBorder ? '2px solid var(--primary)' : '',
                      marginBottom: '20px'
                    }}
                  />
        break;
      case 'linkTool':
        response = <LinkToolEditorJs data={block.data} />
        break;
      case 'quote':
        response =  <QuoteEditorJs data={block.data} />
        break;
      case 'list':
        response = <ListEditorJs data={block.data} />
        break;
      case 'raw':
        response = <RawEditorJs data={block.data} />
        break;
      case 'checklist':
        response = <ChecklistEditorJs data={block.data} />
        break;
      case 'table':
        response = <TableEditorJs data={block.data} />
        break;
      default:
        break;
    }
    return response
  }

}

export default EditorjsService
