import React, { CSSProperties, ChangeEvent, useState, useEffect } from 'react'
import InputComponent from '../Input/InputComponent'
import shortId from 'shortid';

import './InputFileComponent.scss'
import TextComponent from '../Text/TextComponent';
import { AiFillCamera } from 'react-icons/ai';

interface IInputFileComponentProps {
    as: 'file' | 'image'
    className?: string
    style?: CSSProperties
    value?: string
    name?: string
    ref?: any
    disabled?: boolean
    required?: boolean
    valid?: boolean
    validText?: string
    invalid?: boolean
    invalidText?: string
    label?: string
    onChange?: (e: ChangeEvent<HTMLInputElement>)=>any
    onEnter?: (value?: string)=>any
}

const InputFileComponent: React.FC<IInputFileComponentProps> = (props) => {

    const [file, updateFile] = useState<File | undefined>(undefined)
    const [urlFile, updateUrlFile] = useState<string | undefined>('')
    const [idFile, updateIdFile] = useState<string | undefined>(undefined)

    useEffect(() => {
        updateIdFile(shortId.generate())
    },[])

    useEffect(() => {
        if(!props.value) return
        if(typeof props.value === 'string'){
            updateUrlFile(props.value)
            updateFile(undefined)
        }
    },[props.value])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.files?.length){
            const file = e.target.files[0]
            const url = URL.createObjectURL(file)
            updateFile(file)
            updateUrlFile(url)
            if(props.onChange){
                props.onChange(e)
            }
        }
    }
    
    return(
        <div
            className={`input_file_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <InputComponent
                name={props.name}
                ref={props.ref}
                disabled={props.disabled}
                required={props.required}
                valid={props.valid}
                validText={props.validText}
                invalid={props.invalid}
                invalidText={props.invalidText}
                label={props.label}
                type='file'
                id={idFile}
                className={`${props.as === 'image' ? 'hide-input-file-component' : ''}`}
                onChange={handleChange}
                onEnter={props.onEnter}
                style={props.as === 'image' ? {display: 'none'} : undefined}
            >
            </InputComponent>
            {props.as === 'image' && idFile ?
                <label htmlFor={idFile}>
                    <div 
                        className="container-image-input-file_component"
                        style={{ backgroundImage: urlFile ? `url(${urlFile})` : undefined }}
                    >
                        {!urlFile ? 
                            <TextComponent
                                style={{ color: 'white', fontWeight: 600, fontSize: '1rem' }}
                            >
                                Sube un archivo
                            </TextComponent>
                        : null}
                        <div className="on-hover-input-image">
                            <AiFillCamera/>
                        </div>
                    </div>
                </label>
            :null}
        </div>
    );
}

export default InputFileComponent;