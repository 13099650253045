// @import dependencies
import React, { CSSProperties } from 'react'
// @end dependencies

// @import components
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './SimpleArrowComponent.scss'
// @end styles

interface ISimpleArrowComponentProps {
    type: 'next' | 'prev'
    className?: string
    style?: CSSProperties
    id?: string
    onClick?: () => void
}

const SimpleArrowComponent: React.FC<ISimpleArrowComponentProps> = (props) => {
    return(
        <div
            className={`simple_arrow_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
            onClick={props.onClick}
        >
            {props.type === 'next' ?
                <GrFormNext/>
            :
                <GrFormPrevious/>
            }
        </div>
    );
}

export default SimpleArrowComponent;