import { IUseChangeLangInput, IUseChangeLangOutput } from "./useChangeLang.types";
import { useSelector, useDispatch } from 'react-redux';
import { IStoreApp } from 'redux/reducers/index';

import UserService from "services/user/userService";

import * as AuthTypes from 'types/auth/auth.types'
import * as AuthActions from 'redux/reducers/auth/auth.actions'
import * as AppActions from 'redux/reducers/app/app.actions'

export const useChangeLang = (_params?: IUseChangeLangInput): IUseChangeLangOutput => {

    const lang = useSelector((store: IStoreApp) => store.app.language)
    const isLogged = useSelector((store: IStoreApp) => store.auth.isLogged)
    const dispatch = useDispatch()

    // @INFO Servicios
    const userService = new UserService()

    const setLanguage = async (lang: AuthTypes.IUser['lang']) => {
        if(isLogged){
            const response = await userService.updateUser({lang})
            if(response?.data?.updateUser){
                const newUser = {...response.data.updateUser}
                dispatch(AuthActions.updateUserAction(newUser))
            }
        }else{
            dispatch(AppActions.updateLanguageAction(lang))
        }
    }

    return {
        lang,
        setLanguage
    }
}