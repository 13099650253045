import React from 'react'
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import { useDispatch, useSelector } from 'react-redux'
import { AiFillQuestionCircle } from 'react-icons/ai';
import { IStoreApp } from 'redux/reducers/index';
import { useTranslation } from 'react-i18next';
import { MdInvertColors, MdPhoneInTalk } from 'react-icons/md';
import { IoMdMail } from 'react-icons/io';
import TooltipComponent from 'components/commons/TooltipComponent/TooltipComponent';
import { updateThemeAppAction } from 'redux/reducers/app/app.actions';
import LoginForm from 'components/modules/LoginForm/LoginForm';

import vlesimIcon from 'assets/images/logoVlesim1.png'
import imgLight from 'assets/icons/LoginIcons/img_light.svg'
import imgDark from 'assets/icons/LoginIcons/img_dark.svg'

import './LoginPage.scss'


export interface LoginPageProps {
    history?: any
}

const LoginPage: React.SFC<LoginPageProps> = (props) => {

    
    const {t} = useTranslation()
    const app = useSelector((store: IStoreApp) => store.app)
    
    const dispatch = useDispatch()

    const handleChangeTheme = () => {
        if(app.theme === 'light'){
            dispatch(updateThemeAppAction('dark'))
        }else if(app.theme === 'dark'){
            dispatch(updateThemeAppAction('light'))
        }
    }

    return (  
        <div
            className='login_page-layout'
        >
            <div 
                className="container-info"
                style={{backgroundColor: app.theme === 'dark' ? '#064D77' : undefined}}
            >
                <div 
                    className="btn-change-theme"
                    onClick={handleChangeTheme}
                >
                    <MdInvertColors/>
                </div>
                <img 
                    src={vlesimIcon} 
                    className='icon-vlesim' 
                />
                <img 
                    src={app.theme === 'light' ? imgLight : imgDark} 
                    className='icon-section' 
                    alt="" 
                />
                <div className="container-icons">
                    <TooltipComponent
                        text={t('login.questions')}
                    >
                        <div className="icon-menu">
                            <AiFillQuestionCircle/>
                        </div>
                    </TooltipComponent>
                    <TooltipComponent
                        text={t('login.phone')}
                    >
                        <div className="icon-menu">
                            <MdPhoneInTalk/>
                        </div>
                    </TooltipComponent>
                    <TooltipComponent
                        text={t('login.contact')}
                    >
                        <div className="icon-menu">
                            <IoMdMail/>
                        </div>
                    </TooltipComponent>
                </div>
                <div className="container-options">
                    <ButtonComponent
                        className='button-options'
                    >
                        {t('login.options')}
                    </ButtonComponent>
                </div>
            </div>
            {/* ===================================================== */}
            {/* ================ Formulario de login ================ */}
            {/* ===================================================== */}
            <LoginForm
                history={props.history}
            />
        </div>
    );
}
 
export default LoginPage;