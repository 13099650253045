import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { IQueryParamsURL } from "types/router/router.types"
import { IRouteApp } from 'types/routes/routes.types';
import AuthService from '../auth/authService';
import PermissionsService from '../permissions/permissionsService';
import LoadingPage from 'components/screens/LoadingPage/LoadingPage';
import NotFoundPage from 'components/screens/NotFoundPage/NotFoundPage';
import { HOME_ROUTE } from 'config/globals';
import shortId from 'shortid';
import { SystemConfig } from '../../types/app/app.actions.types';
import AppService from 'services/app/appService';

type ITypeRoutes = '/' | '/profile' | '/home'

class RouterService{

    private permissionsService = new PermissionsService()
    private authService = new AuthService()
    private appService = new AppService();

    constructor(){

    }

    /**
     * @INFO Renderizar una ruta
     * @param _route 
     */
    public renderRoute = (_route: IRouteApp) => {
      // @INFO Obtener la configuración del sistema
      let systemConfig = this.appService.getSystemConfig();
        // @INFO Reviso si tiene pathVariables
        let pathVariables = ''
        if(_route.pathVariables?.length){
          pathVariables = _route.pathVariables.reduce((accum: string, item) => {
            accum = `${accum}/:${item}`
            return accum
          }, pathVariables)
        }
        // @INFO Revisar si la ruta esta bloqueada desde el sistema
        const customBlock = this.validateCustomConfigBlockRoute(_route, systemConfig);
        if (customBlock) {
          return (
            this.redirectToDefault(_route, systemConfig)
          )
        }
        // @INFO Reviso si es privada o publica
        const customPublic = this.validateCustomConfigPublicRoute(_route, systemConfig);
        if(_route.private?.state && !customPublic){
            let accepted = true
            // @INFO Revisar si tiene condiciones para la ruta
            if(_route.private.conditions?.length){
                accepted = _route.private.conditions.reduce((accum: boolean, item) => {
                    if(item === 'auth'){
                        accum = this.authService.isLogin()
                    }
                    return accum
                }, accepted)
            }
            // @INFO Revisar si la ruta tiene permisos
            if(_route.private.permissions?.length){
                accepted = _route.private.permissions.reduce((accum: boolean, item) => {
                    if(accum){
                      accum = this.permissionsService.check(item)
                    }
                    return accum
                }, accepted)
            }
            // @INFO Reviso si tiene permiso para acceder, de lo contrario lo mando a la home o login
            if(accepted){
                return(
                    <Route
                        key={_route.path + pathVariables}
                        path={_route.path + pathVariables}
                        component={_route.component}
                        exact={_route.exact}
                    />
                )
            }else if(window.location.pathname.includes(_route.path)){
              if(this.authService.isLogin()){
                return(
                    <Redirect key={shortId.generate()} to={HOME_ROUTE} />
                )
              }else{
                return(
                  this.redirectToDefault(_route, systemConfig)
                )
              }
            }else{
              return <Route
                  key={_route.path + pathVariables}
                  path={_route.path + pathVariables}
                  component={NotFoundPage}
                  exact={_route.exact}
              />
            }
        }else{
            return (
                <Route
                    key={_route.path + pathVariables}
                    path={_route.path + pathVariables}
                    component={_route.component}
                    exact={_route.exact}
                />
            )
        }
    }

    /**
     * @INFO Verificar si la ruta es publica desde la configuración del sistema 
     * @param _route 
     * @returns 
     */
    private validateCustomConfigPublicRoute = (_route: IRouteApp, config?: SystemConfig) => {
      if (config?.public_routes?.includes(_route.path)) {
        return true;
      } else {
        return false;
      }
    }

    /**
     * @INFO Verificar si la ruta esta bloqueada desde la configuración del sistema 
     * @param _route 
     * @returns 
     */
    private validateCustomConfigBlockRoute = (_route: IRouteApp, config?: SystemConfig) => {
      if (config?.block_routes?.includes(_route.path)) {
        return true;
      } else {
        return false;
      }
    }

    /**
     * @INFO Redirigir a la ruta por defecto
     */
    private redirectToDefault = (_route: IRouteApp, config?: SystemConfig) => {
      if ( this.getIsActive(_route.path) ) {
        if ( config?.default_redirect ) {
          window.open(`${config.default_redirect}`, '_self')
        } else {
          window.open(`${window.location.origin}/`, '_self')
        }
      } else {
        return null
      }
    }

  /**
   * @INFO Saber si una ruta es actual o no
   * @param _route 
   * @returns 
   */
  private getIsActive = (_route: string) => {
    const currentPath = window.location.href
    if(_route === '/'){
        if(window.location.pathname === '/'){
            return true
        }else{
            return false
        }
    }else{
        if(currentPath.includes(_route)){
            return true
        }else{
            return false
        }
    }
  }

    /**
   * @INFO Obtener los parámetros de una ruta
   * @param route
   */
  public getParamsURL = () => {
    // @INFO Agarrar el ultimo "/"
    const _route = window.location.href
    const route = _route.split('/')[_route.split('/').length - 1]
    let newRoute = ''
    if(route.includes('=')){
      if(!route.includes('?')){
        newRoute = `?${route}`
      }else{
        newRoute = window.location.search
      }
    }else{
      newRoute = window.location.search
    }
    const query = new URLSearchParams(newRoute)
    return query
  }

  /**
   * @INFO Obtener el objeto con los parámetros en la url
   * @param _query
   * @returns
   */
  public getParamsURLObject = (_query?: URLSearchParams) => {
    const paramsURL = _query ? _query : this.getParamsURL()
    let result: IQueryParamsURL = {}
    // @ts-ignore
    for(let key of paramsURL.keys()){
      if(paramsURL.get(key)){
        result = {
          ...result,
          [key]: paramsURL.get(key)
        }
      }
    }
    return result
  }

  /**
   * @INFO Retorna el token del juego que viene en la url ?game_token=123
   * @param _query 
   * @returns string
   */
  public getGameToken = (_query?: URLSearchParams): string | undefined => {
    const paramsURL = _query ? _query : this.getParamsURL()
    const result = this.getParamsURLObject(paramsURL)?.game_token;
    return result
  }

  /**
   * @INFO Obtener la ruta actual
   */
  public getCurrentRoute = (): ITypeRoutes | undefined => {
    const currentPath = window.location.href
    const posibleRoutes: ITypeRoutes[] = ['/', '/profile']
    let response: ITypeRoutes | undefined = undefined
    posibleRoutes.forEach((item) => {
      if(response) return
      if(item === '/'){
          if(window.location.pathname === '/'){
              response = '/'
          }
      }else{
          if(currentPath.includes(item)){
              response = item
          }
      }
    })
    return response
  }

}

export default RouterService