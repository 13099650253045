import React, { CSSProperties, useEffect, useState } from 'react'
import CheckBoxComponent from 'components/commons/CheckBoxComponent/CheckBoxComponent';
import TextComponent from 'components/commons/Text/TextComponent';
import useValidator from 'hooks/useFormValidator/useFormValidator';
import InputRange, { Range } from 'react-input-range';
import FilterCheckArrayList from '../FilterCheckArrayList/FilterCheckArrayList';
import ButtonComponent from 'components/commons/Button/ButtonComponent';

// @import services
import LaboratoryCategoryService from 'services/laboratoryCategory/laboratoryCategoryService';
import SubareaService from 'services/subarea/subareaService';
// @end

// @import types
import * as SubareaTypes from 'types/subarea/subarea.types'
import * as LaboratoryCategoryTypes from 'types/laboratoryCategory/laboratoryCategory.types'
import { IUseFormValidator } from 'hooks/useFormValidator/useFormValidator.types';
// @end

import './FilterLaboratories.scss'
import 'react-input-range/lib/css/index.css'

interface IFilterLaboratoriesProps {
    className?: string
    style?: CSSProperties
    formValidator?: IUseFormValidator
    onClickFilter?: (query: any) => any
}

interface IOptionData{
    label: string
    value: string
}

const FilterLaboratories: React.FC<IFilterLaboratoriesProps> = (props) => {

    const [categories, updateCategories] = useState<IOptionData[]>([])
    const [subareas, updateSubareas] = useState<IOptionData[]>([])
    const [range, updateRange] = useState<Range>({min: 10, max: 30})

    const form = useValidator([
        { name: 'category', value: [] },
        { name: 'subarea', value: [] },
        { name: 'minPrice', value: undefined },
        { name: 'maxPrice', value: undefined },
    ])

    // @INFO Servicios
    const laboratoryCategoryService = new LaboratoryCategoryService()
    const subareaService = new SubareaService()

    useEffect(() => {
        getLaboratoryCategories()
        getSubareas()
    }, [])

    /**
     * @INOF Obtener la lista de categorias
     */
     const getLaboratoryCategories = async () => {
        const _categories = await laboratoryCategoryService.list()
        const _options = _categories.reduce((accum: IOptionData[], item) => {
            accum.push({
                label: item.name as string,
                value: item.id as string
            })
            return accum
        },[])
        updateCategories(_options)
    }
    
    /**
     * @INOF Obtener la lista de subareas
     */
     const getSubareas = async () => {
        const _subareas = await subareaService.list()
        const _options = _subareas.reduce((accum: IOptionData[], item) => {
            accum.push({
                label: item.name as string,
                value: item.id as string
            })
            return accum
        },[])
        updateSubareas(_options)
    }

    /**
     * @INFO Click en filtrar labs
     */
    const handleSearchLabs = () => {
        const querySearch = props.formValidator ? props.formValidator.getValues() : form.getValues()
        if(props.onClickFilter){
            props.onClickFilter(querySearch)
        }
    }

    /**
     * @INFO Cambia el selector de precio
     * @param data 
     */
    const handleChangeRange = (data: Range) => {
        // @INFO Guardar el estado
        updateRange(data)
        // @INFO Guardar en el formulario
        if(props.formValidator){
            props.formValidator.handleChange({
                target: {
                    name: 'minPrice',
                    value: data.min
                }
            })
            props.formValidator.handleChange({
                target: {
                    name: 'maxPrice',
                    value: data.max
                }
            })
        }else{
            form.handleChange({
                target: {
                    name: 'minPrice',
                    value: data.min
                }
            })
            form.handleChange({
                target: {
                    name: 'maxPrice',
                    value: data.max
                }
            })
        }
    }

    return(
        <div
            className={`filter_laboratories-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <TextComponent
                style={{fontWeight: 600}}
            >
                Categorías
            </TextComponent>
            <FilterCheckArrayList
                items={categories}
                type='check'
                onChangeForm={props.formValidator ? props.formValidator.handleChange : form.handleChange}
                // defaultValue={props.formValidator ? props.formValidator.values['category'] : form.values['category']}
                name='category'
            />
            <hr />
            <TextComponent
                style={{fontWeight: 600}}
            >
                Subareas
            </TextComponent>
            <FilterCheckArrayList
                items={subareas}
                type='check'
                onChangeForm={props.formValidator ? props.formValidator.handleChange : form.handleChange}
                // defaultValue={props.formValidator ? props.formValidator.values['subarea'] : form.values['subarea']}
                name='subarea'
            />
            <hr />
            {/* <div
                style={{ margin: '20px 0px' }}
            >
                <InputRange
                    onChange={(value) => handleChangeRange(value as Range)}
                    value={range}
                    minValue={0}
                    maxValue={100}
                />
            </div> */}
            {/* <TextComponent
                style={{fontWeight: 600}}
            >
                Categorías
            </TextComponent>
            <CheckBoxComponent
                checked
                label="Sistemas fotovoltaicos"
            />
            <CheckBoxComponent
                checked
                label="Electronica de potencia"
            />
            <hr /> */}
            <ButtonComponent
                variant='primary'
                disabled={props.formValidator ? props.formValidator.isInvalid() : form.isInvalid()}
                onClick={handleSearchLabs}
            >
                Filtrar
            </ButtonComponent>
        </div>
    );
}

export default FilterLaboratories;