import store from 'redux/store'
import graphQLUtil from "utils/graphQL/graphQL.util"
import { gql } from "@apollo/client"

import * as NotificationTypes from 'types/notification/notification.types';

class NotificationService{
    private storeObject = store

    constructor(){

    }

    /**
     * @INFO Traer la lista de notificaciones
     * @param _params 
     */
    public list = async (_params?: NotificationTypes.ParamsNotificationsList): Promise<NotificationTypes.ResponseNotificationsList | undefined> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query notifications($token: String!){
                        notifications(token: $token){
                            ${NotificationTypes.fullNotificationListQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token
                }
            }
        })
        if(response?.data?.notifications){
            return response.data.notifications
        }else{
            return undefined
        }
    }

    /**
     * @INFO Actualizar una notificación
     * @param _params 
     * @returns 
     */
    public update = async (_params: NotificationTypes.ParamsNotificationUpdate)
     : Promise<NotificationTypes.Notification | undefined> => {
         if(!_params) return
         const response = await graphQLUtil.send({
             method: 'mutate',
             data: {
                 mutation: gql`
                     mutation updateNotification($token: String!, $id: ID!, $notification: UpdateNotificationInput!){
                         updateNotification(token: $token, id: $id, notification: $notification){
                             ${NotificationTypes.fullNotificationQuery}
                         }
                     }
                 `,
                 variables: {
                     token: this.storeObject.getState().auth?.token,
                     notification: {..._params.notification},
                     id: _params.id
                 }
             }
         },
         {
             alertOnFailed: true
         })
         if(response?.data?.updateNotification){
             return response.data.updateNotification
         }else{
             return undefined
         }
     }
}

export default NotificationService;