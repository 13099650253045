import * as LaboratoryCategoryTypes from 'types/laboratoryCategory/laboratoryCategory.types'
import * as SubareaTypes from 'types/subarea/subarea.types'
import * as HardwareTypes from 'types/hardware/hardware.types'
import * as AuthTypes from 'types/auth/auth.types';
import * as UserTypes from 'types/user/user.types';
import * as ToolTypes from 'types/tool/tool.types';
import { IReservation } from 'types/reservations/reservations.types';
import { Layout } from 'react-grid-layout';

export type ITypesPlatformVideo = 'YouTube'
export interface IParamsLaboratoriesList{
    limit?: number
    page?: number 
    filter?: IFilterLabsQuery
}

export interface IFilterLabsQuery{
    category?: string[]
    subarea?: string[]
    tags?: string[]
    minPrice?: number
    maxPrice?: number
    search?: string
}

export interface IParamsLaboratoriesGetById{
    id: string
}

export interface IParamsCreateOrUpdateLaboratory{
    
}

export interface ParamsAddToMyLabs{
    lab: string;
}

export interface ParamsMyLabs{

}

export interface ParamsMyActiveLabs{
    
}

export interface ParamsValidAccessLab{
    lab: string
}

export interface ParamsSearchLabs{
    search: string;
}

export interface ILaboratoryDashboard{
    name: string
    defaultLayout?: Layout[] | string
    master: boolean
}

export interface ILaboratory{
    id?: string
    name?: string
    description?: string
    thumbnail?: string
    generalScheme?: string
    objectives?: any
    procedure?: any
    category?: LaboratoryCategoryTypes.ILaboratoryCategory
    price?: number
    subarea?: SubareaTypes.ISubarea
    private?: boolean
    users?: AuthTypes.IUser[]
    averageRating?: number
    numberRatings?: number
    dashboards?: ILaboratoryDashboard[]
    config?: {
        overwriteLayout?: boolean
        hardware?: HardwareTypes.IHardware[]
        widgets?: ToolTypes.ITool[]
        duration?: number
        hardwareDuration?: number
        virtualReality?: {
            video?: {
                id?: string
                platform?: ITypesPlatformVideo
            }
            carousel?: string[]
            resources?: {
                web?: string
                windows?: string
                android?: string
                ios?: string
            }
        }
    }
}

export interface IValidateLaboratoryResponse { 
    allow: boolean;
    reservation: IReservation;
    reservations: IReservation[];
}

export const fullLaboratoryQuery = `
    id
    name
    description
    thumbnail
    generalScheme
    objectives
    procedure
    averageRating
    numberRatings
    dashboards {
        name
        master
        defaultLayout
    }
    category{
        ${LaboratoryCategoryTypes.fullLaboratoryCategoryQuery}
    }
    price
    subarea{
        ${SubareaTypes.fullSubareaQuery}
    }
    private
    users{
        ${UserTypes.searchUsersQuery}
    }
    config{
        overwriteLayout
        widgets{
            ${ToolTypes.fullToolQuery}
        }
        hardware{
            ${HardwareTypes.fullHardwareQuery}
        }
        duration
        hardwareDuration
        virtualReality{
            video{
                id
                platform
            }
            carousel
            resources{
                web
                windows
            }
        }
    }
`