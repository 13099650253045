import graphQLUtil from "utils/graphQL/graphQL.util"
import { gql } from "@apollo/client"
import * as EmailTypes from 'types/email/email.types' 

class EmailService{
    constructor(){

    }

    /**
     * @INFO Enviar un email
     * @param _params 
     */
    public sendEmail = async (_params: EmailTypes.IParamsEmailSend): Promise<string | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation sendEmail($options: EmailOptions!){
                        sendEmail(options: $options)
                    }
                `,
                variables: {
                    options: _params
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.sendEmail){
            return response.data.sendEmail
        }else{
            return undefined
        }
    }
}

export default EmailService