// @import types
import * as LaboratoriesTypes from 'types/laboratories/laboratories.types';
import * as FavoriteTypes from 'types/favorite/favorite.types';
import * as LaboratoryCategoryTypes from 'types/laboratoryCategory/laboratoryCategory.types';
import * as ReservationTypes from 'types/reservations/reservations.types';
// @end types

export const GET_MY_LABS = '(laboratories) GET_MY_LABS';
export const GET_MY_ACTIVE_LABS = '(laboratories) GET_MY_ACTIVE_LABS';
export const GET_LABORATORY_CATEGORIES = '(laboratories) GET_LABORATORY_CATEGORIES';
export const GET_LABORATORY_FAVORITES = '(laboratories) GET_LABORATORY_FAVORITES';
export const GET_RESERVATIONS = '(laboratories) GET_RESERVATIONS';
export const ADD_RESERVATION = '(laboratories) ADD_RESERVATION';
export const DELETE_RESERVATION = '(laboratories) DELETE_RESERVATION';
export const UPDATE_RESERVATION = '(laboratories) UPDATE_RESERVATION';
export const SET_CURRENT_RESERVATION = '(laboratories) SET_CURRENT_RESERVATION';

export interface LaboratoriesState{
    myLabs: LaboratoriesTypes.ILaboratory[];
    myActiveLabs: LaboratoriesTypes.ILaboratory[];
    favorites: FavoriteTypes.IFavorite[];
    categories: LaboratoryCategoryTypes.ILaboratoryCategory[];
    reservations: ReservationTypes.IReservation[];
    currentReservation?: ReservationTypes.IReservation
}

interface GetMyLabsAction{
    type: typeof GET_MY_LABS;
    payload: LaboratoriesTypes.ILaboratory[];
}

interface GetMyActiveLabsAction{
    type: typeof GET_MY_ACTIVE_LABS;
    payload: LaboratoriesTypes.ILaboratory[];
}

interface GetLaboratoryCategoriesAction{
    type: typeof GET_LABORATORY_CATEGORIES;
    payload: LaboratoryCategoryTypes.ILaboratoryCategory[];
}

interface GetLaboratoryFavoritesAction{
    type: typeof GET_LABORATORY_FAVORITES;
    payload: FavoriteTypes.IFavorite[];
}

interface GetReservationsAction{
    type: typeof GET_RESERVATIONS;
    payload: ReservationTypes.IReservation[];
}

interface AddReservationsAction{
    type: typeof ADD_RESERVATION;
    payload: ReservationTypes.IReservation;
}

interface DeleteReservationsAction{
    type: typeof DELETE_RESERVATION;
    payload: ReservationTypes.IReservation;
}

interface UpdateReservationsAction{
    type: typeof UPDATE_RESERVATION;
    payload: ReservationTypes.IReservation;
}

interface UpdateCurrentReservationsAction{
    type: typeof SET_CURRENT_RESERVATION;
    payload: ReservationTypes.IReservation | undefined;
}

export type LaboratoriesActionTypes = GetMyLabsAction | GetMyActiveLabsAction | 
                                      GetLaboratoryCategoriesAction | GetLaboratoryFavoritesAction | GetReservationsAction |
                                      AddReservationsAction | DeleteReservationsAction | UpdateReservationsAction | UpdateCurrentReservationsAction;
