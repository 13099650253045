import React from 'react'

import './LoadingPage.scss'

import logoVlesim from 'assets/gifs/Vlesim_logo.gif' 
import vlesimIcon from 'assets/images/logoVlesim1.png'

export interface LoadingPageProps {
    history?: any
    path?: string
}
 
const LoadingPage: React.SFC<LoadingPageProps> = (props) => {

    return (
        <div className="loading_page-layout">
            <img src={logoVlesim} alt="" />
            <img src={vlesimIcon} alt="" />
        </div>
    );
}
 
export default LoadingPage;