import ButtonComponent from 'components/commons/Button/ButtonComponent'
import { FC } from 'react'
import { BiStats } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { IReservation } from 'types/reservations/reservations.types'
import * as DashboardActions from 'redux/reducers/dashboard/dashboard.actions';
import { IStoreApp } from 'redux/reducers/index';

import './RenderDashboardItems.scss'
import SavingIndicator from 'components/commons/SavingIndicator/SavingIndicator';
import TextComponent from 'components/commons/Text/TextComponent';

interface IRenderDashboardItemsProps {
    reservations: IReservation[]
}

const RenderDashboardItems: FC<IRenderDashboardItemsProps> = ({ reservations }) => {
    
    const history = useHistory()
    const dispatch = useDispatch()
    const currentReservation = useSelector((store: IStoreApp) => store.dashboard.reservation)
    const loading = useSelector((store: IStoreApp) => store.dashboard.loading)

    if (!reservations?.length || reservations.length < 2) return null

    const handleClickReservation = (reservation: IReservation) => {
        if (history) {
            history.replace({ search: `reservation=${reservation.name}` })
            dispatch(DashboardActions.setReservationAction(reservation))
        }
    }

    return (
        <div className="render_dashboard_items--layout">
            <div className="render_dashboard_items--header">
                <TextComponent type='subtitle'>
                    Dashboards
                </TextComponent>
                <SavingIndicator                                
                    state={loading ? 'saving' : 'saved'}
                    withText
                />
            </div>
            <div className="render_dashboard_items--items">
                {reservations?.map((reservation) => (
                    <ButtonComponent
                        onClick={() => handleClickReservation(reservation)}
                        variant='primary'
                        key={reservation.id}
                        disabled={currentReservation?.id === reservation.id || loading}
                    >
                        <ButtonComponent.Icon>
                            <BiStats/>
                        </ButtonComponent.Icon>
                        {reservation.name}
                    </ButtonComponent>
                ))}
            </div>
        </div>
    )
}

export default RenderDashboardItems
