import * as AuthTypes from 'types/auth/auth.types'
import * as AuthActionTypes from 'types/auth/auth.actions.types'
import AuthService from 'services/auth/authService';
import Swal from 'sweetalert2';

/**
 * @INFO Hacer Login de la app
 * @returns 
 */
export const loginAction = (credentials: AuthTypes.ILoginCredentials) => {
    return async (dispatch: Function) => {
        const authService = new AuthService()
        // @INFO Hacer llamado al API para loguearme
        dispatch(login())
        const response = await authService.login(credentials)
        // @INFO Revisar si fue exitoso o no
        if(response?.data?.login){
            // @INFO Guardar token
            authService.setToken(response?.data?.login?.token)
            // @INFO Hacer el dispatch de login
            dispatch(loginSuccess(response?.data?.login?.user, response?.data?.login?.token))
        }else{
            dispatch(loginError())
        }
    }
}

/**
 * @INFO Login por token de autenticación
 * @param credentials 
 * @returns 
 */
export const loginByTokenAction = (credentials: AuthTypes.ILoginByToken) => {
    return async (dispatch: Function) => {
        const authService = new AuthService()
        // @INFO Hacer llamado al API para loguearme
        dispatch(loginByToken())
        const response: any = await authService.loginByToken(credentials)
        // @INFO Revisar si fue exitoso o no
        if(response?.data?.loginToken){
            // @INFO Guardar token
            authService.setToken(response?.data?.loginToken?.token)
            // @INFO Hacer el dispatch de login
            dispatch(loginSuccess(response?.data?.loginToken?.user, response?.data?.loginToken?.token))
        }else{
            dispatch(loginError())
            dispatch(logoutAction())
        }
    }
}

/**
 * @INFO Crear una nueva cuenta
 * @param params 
 * @returns 
 */
export const createAccountAction = (params: any) => {
    return async (dispatch: Function) => {
        const authService = new AuthService()
        // @INFO Hacer llamado al API para crear la cuenta
        dispatch(createAccount())
        const response: any = await authService.createAccount(params)
        // @INFO Revisar si fue exitoso o no
        if(response?.data?.newUser){
            // @INFO Guardar token
            authService.setToken(response?.data?.newUser?.token)
            // @INFO Hacer el dispatch de login
            dispatch(loginSuccess(response?.data?.newUser?.user, response?.data?.newUser?.token))
        }else{
            dispatch(loginError())
            dispatch(logoutAction())
        }
    }
}

const login = () => ({
    type: AuthActionTypes.LOGIN
})

const loginByToken = () => ({
    type: AuthActionTypes.LOGIN_TOKEN
})

const createAccount = () => ({
    type: AuthActionTypes.CREATE_ACCOUNT
})

const loginSuccess = (user: AuthTypes.IUser, token: string) => ({
    type: AuthActionTypes.LOGIN_SUCCESS,
    payload: {user, token}
})

const loginError = () => ({
    type: AuthActionTypes.LOGIN_ERROR
})

/**
 * @INFO Hacer logout de la app
 * @returns 
 */
export const logoutAction = () => {
    return (dispatch: Function) => {
        const authService = new AuthService()
        authService.deleteToken()
        dispatch(logout())
        // @INFO Revisar si es necesario
        // window.location.reload()
    }
}

const logout = () => ({
    type: AuthActionTypes.LOGOUT
})

/**
 * @INFO Actualizar usuario
 * @param user 
 * @returns 
 */
export const updateUserAction = (user: any) => {
    return (dispatch: Function) => {
        dispatch(updateUser(user))
    }
}

const updateUser = (user: any) => ({
    type: AuthActionTypes.UPDATE_USER,
    payload: user
})

/**
 * @INFO Cambiar contraseña
 * @param _params 
 */
export const changePasswordAction = (_params: AuthTypes.IParamsChangePassword) => {
    return async (dispatch: Function) => {
        const authService = new AuthService()
        const response = await authService.changePassword(_params)
        // @INFO Si es exitoso hago logout de la app
        if(response?.data?.changePassword){
            dispatch(changePassword())
            await Swal.fire({
                text: 'Contraseña cambiada con éxito, deber hacer login con tu nueva contraseña',
                icon: 'success',
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: false
            })
            dispatch(logoutAction())
        }
    }
}

const changePassword = () => ({
    type: AuthActionTypes.CHANGE_PASSWORD
})