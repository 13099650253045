import React, { CSSProperties } from 'react'

import DropDownComponent from 'components/commons/DropDownComponent/DropDownComponent'

import { useChangeLang } from 'hooks/useChangeLang/useChangeLang';
import { useTranslation } from 'react-i18next';

import * as AuthTypes from 'types/auth/auth.types'

import './ChangeLanguagePopUp.scss'

interface IChangeLanguagePopUpProps {
    className?: string
    style?: CSSProperties
    onSelectLanguage?: (lang: string) => any
}

interface ILanguageButton{
    label: string
    value: AuthTypes.IUser['lang']
}

const ChangeLanguagePopUp: React.FC<IChangeLanguagePopUpProps> = (props) => {

    const {t} = useTranslation()
    const {lang, setLanguage} = useChangeLang()

    const languageButtons:ILanguageButton[] = [
        {label: 'Español', value: 'es'},
        {label: 'English', value: 'en'},
    ]

    const handleClickChangeLang = (lang: AuthTypes.IUser['lang']) => {
        if(props.onSelectLanguage){
            props.onSelectLanguage(lang)
        }
        setLanguage(lang)
    }

    return(
        <div
            className={`change_language_pop_up-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <DropDownComponent
                title={t(`idioms.${lang}`)}
            >
                {languageButtons.map((item) => (
                    <DropDownComponent.Item
                        key={item.value}
                        onClick={() => handleClickChangeLang(item.value)}
                    >
                        {t(`idioms.${item.value}`)}
                    </DropDownComponent.Item>
                ))}
            </DropDownComponent>
        </div>
    );
}

export default ChangeLanguagePopUp;