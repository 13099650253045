// @import dependencies
import React, { CSSProperties, useState } from 'react'
// @end dependencies

// @import components
import { MdInvertColors } from 'react-icons/md';
import { Link } from 'react-router-dom';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import ChangeLanguagePopUp from 'components/modules/ChangeLanguagePopUp/ChangeLanguagePopUp';
import TextComponent from 'components/commons/Text/TextComponent';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiMenu } from 'react-icons/bi';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
import { useChangeTheme } from 'hooks/useChangeTheme/useChangeTheme';
import { useTranslation } from 'react-i18next';
import { useChangeLang } from 'hooks/useChangeLang/useChangeLang';
import useWindowWidth from 'hooks/useWindowWidth/useWindowWidth';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
import vlesimIcon from 'assets/images/logoVlesim1.png'
// @end assets

// @import styles
import './HeaderLandingPage.scss'
// @end styles

interface IHeaderLandingPageProps {
    className?: string
    style?: CSSProperties
    id?: string
}

const HeaderLandingPage: React.FC<IHeaderLandingPageProps> = (props) => {

    const [open, updateOpen] = useState<boolean>(false);
    const themeControl = useChangeTheme()
    const {t} = useTranslation()
    const { lang } = useChangeLang()
    const { windowWidth } = useWindowWidth();

    return(
        <div
            className={`header_landing_page-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <img src={vlesimIcon} alt="" />
            {windowWidth <= 960 && !open ?
                <div
                    className='container-menu-movil'
                    onClick={() => updateOpen(true)}
                >
                    <BiMenu/>
                </div>
            :null}
            {/* {windowWidth > 960 || (open && windowWidth<=960) ? */}
            <div className={`container-actions-header ${open ? 'open' : ''}`}>
                {windowWidth <= 960 ? 
                <div 
                    className="close-icon-menu-movil"
                    onClick={() => updateOpen(false)}
                >
                    <AiOutlineCloseCircle/>
                </div>
                :null}
                <Link
                    to='/'
                >
                    <TextComponent
                        style={{
                            marginRight: '15px',
                            color: 'white'
                        }}
                    >
                        Inicio
                    </TextComponent>
                </Link>
                <Link
                    to='/contact'
                >
                    <TextComponent
                        style={{
                            marginRight: '15px',
                            color: 'white'
                        }}
                    >
                        Contacto
                    </TextComponent>
                </Link>
                <Link
                    to='/terms-and-conditions'
                >
                    <TextComponent
                        style={{
                            marginRight: '15px',
                            color: 'white'
                        }}
                    >
                        Terminos y condiciones
                    </TextComponent>
                </Link>
                <Link
                    to='/login'
                >
                    <ButtonComponent
                        variant='primary2'
                        style={{
                            color: 'white'
                        }}
                    >
                        {t('login.fields.enter')}
                    </ButtonComponent>
                </Link>
                <Link
                    to='/register'
                >
                    <ButtonComponent
                        variant='primary2'
                        style={{
                            color: 'white'
                        }}
                    >
                        {t('register.register')}
                    </ButtonComponent>
                </Link>
                <ChangeLanguagePopUp/>
                <div 
                    className="btn-change-theme"
                    onClick={() => themeControl.toggle()}
                >
                    <MdInvertColors/>
                </div>
            </div>
            {/* :null} */}
        </div>
    );
}

export default HeaderLandingPage;