import { ITypeThemeApp } from "types/app/app.actions.types";
import * as appTypes from 'types/app/app.actions.types'
import * as AuthTypes from 'types/auth/auth.types'

/**
 * @INFO actualizar el tema de la aplicación
 * @param type 
 * @returns 
 */
export const updateThemeAppAction = (type: ITypeThemeApp) => (
    (dispatch: Function) => {
        dispatch(updateThemeApp(type))
    }
)

const updateThemeApp = (type: ITypeThemeApp) => ({
    type: appTypes.UPDATE_THEME,
    payload: type
})

/**
 * Actualizar el estado del sidebar (true -> abierto)
 * @param state 
 * @returns 
 */
export const updateSidebarStateAction = (state: boolean) => {
    return (dispatch: Function) => {
        dispatch(updateSidebarState(state))
    }
}

const updateSidebarState = (state: boolean) => ({
    type: appTypes.UPDATE_SIDEBAR_STATE,
    payload: state
})

/**
 * @INFO Actualizar el lenguaje de la app
 * @param lang 
 * @returns 
 */
export const updateLanguageAction = (lang: AuthTypes.IUser['lang']) => {
    return (dispatch: Function) => {
        dispatch(updateLanguage(lang))
    }
}

const updateLanguage = (lang: AuthTypes.IUser['lang']) => ({
    type: appTypes.UPDATE_LANGUAGE,
    payload: lang
})

/**
 * @INFO Actualizar la configuración de la aplicación
 * @param config 
 * @returns 
 */
export const updateSystemConfigAction = (config: appTypes.SystemConfig) => {
    return (dispatch: Function) => {
        dispatch(updateSystemConfig(config))
    }
}

const updateSystemConfig = (config: appTypes.SystemConfig) => ({
    type: appTypes.UPDATE_SYSTEM_CONFIG,
    payload: config
})

/**
 * @INFO Actualizar el tipo de sistema
 * @param config 
 * @returns 
 */
export const updateSystemTypeAction = (type: appTypes.TypeSystemType) => {
    return (dispatch: Function) => {
        dispatch(updateSystemType(type))
    }
}

const updateSystemType = (type: appTypes.TypeSystemType) => ({
    type: appTypes.UPDATE_SYSTEM_TYPE,
    payload: type
})
