// @import dependencies
import React, { CSSProperties, useEffect, useState } from 'react'
// @end dependencies

// @import components
import Select from 'react-select';
import LabelComponent from 'components/commons/LabelComponent/LabelComponent';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './MultiSelectComponent.scss'
// @end styles

export interface IOptionSelect{
    value: string
    label: string
  }

interface IMultiSelectComponentProps {
    className?: string
    style?: CSSProperties
    id?: string
    label?: string
    placeholder?: string
    defaultValue?: IOptionSelect[]
    options?: IOptionSelect[]
    separateByComma?: boolean
    disabled?: boolean
    disableAutoOption?: boolean
    disableMulti?: boolean
    formatOptionLabel?: (option: IOptionSelect, labelMeta: any) => React.ReactNode;
    onChange: (data: IOptionSelect[]) => any
    onInputChange?: (value: string) => any
}

const MultiSelectComponent: React.FC<IMultiSelectComponentProps> = (props) => {

    const [selectedOptions, updateSelectedOptions] = useState<IOptionSelect[]>([])
    const [inputValue, updateInputValue] = useState('')

    useEffect(() => {
        if(props.defaultValue?.length){
            updateSelectedOptions([...props.defaultValue])
            sendOnChangeEvent([...props.defaultValue])
        }
    }, [])

    const getOptionsSelect = () => {
        let response: IOptionSelect[] = []
        if(!props.disableAutoOption){
          if( inputValue.length > 0){
            const idxAux = response.findIndex(item => item.label === inputValue || item.value === inputValue)
            if(!(idxAux >= 0)){
                response = [{
                    value: inputValue,
                    label: `${inputValue}`
                }, ...response]
            }
          }
        }
        if(props.options?.length){
          response = [...response, ...props.options]
        }
        return response
    }

    const onChangeInput = (value: string) => {
        if(props.separateByComma){
            if(value && value.includes(',')){
                const vectorDocs = value.split(',')
                const vectorResult = vectorDocs.reduce((acum: IOptionSelect[], item) => {
                    const auxItem = item.replace(' ', '')
                    if(auxItem.length){
                    acum.push({label: auxItem, value: auxItem})
                    }
                    return acum
                }, [])
                updateSelectedOptions([...selectedOptions, ...vectorResult])
                sendOnChangeEvent([...selectedOptions, ...vectorResult])
                updateInputValue('')
            }else{
                updateInputValue(value)
            }
        }else{
            updateInputValue(value)
        }
        if(props.onInputChange){
            props.onInputChange(value)
        }
    }

    const handleChangeSelect = (value: IOptionSelect[] | IOptionSelect) => {
        let response:IOptionSelect[] = []
        if(value){
            let aux:IOptionSelect[]  = []
            if(Array.isArray(value)){
                aux = [...value]
            }else{
                aux = [value]
            }
            response = [...aux]
            updateSelectedOptions([...aux])
            sendOnChangeEvent([...aux])
        }else{
            updateSelectedOptions([])
            sendOnChangeEvent([])
        }
    }

    const sendOnChangeEvent = (data: IOptionSelect[]) => {
        if(props.onChange){
            props.onChange(data)
        }
    }

    return(
        <div
            className={`multi_select_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            {props.label ?
                <LabelComponent>
                    {props.label}
                </LabelComponent>
            :null}
            <Select
                isMulti={!props.disableMulti}
                options={getOptionsSelect()}
                onInputChange={(value) => onChangeInput(value)}
                inputValue={inputValue}
                onChange={(value: any) => handleChangeSelect(value)}
                value={selectedOptions}
                placeholder={props.placeholder}
                isDisabled={props.disabled}
                formatOptionLabel={props.formatOptionLabel}
                required={false}
            />
        </div>
    );
}

export default MultiSelectComponent;