import React, { Component, CSSProperties } from 'react';

import { SwitchLayout } from './../index'

interface ISwitchComponentProps {
  checked: boolean
  style?: CSSProperties
  className?: string
  disabled?: boolean
  size?: 'small' | 'large'
  label?: string
  onClick: ()=>void
}

interface ISwitchComponentState {}

class SwitchComponent extends Component<ISwitchComponentProps, ISwitchComponentState> {
    render() {
        return (
            <SwitchLayout
              {...this.props}
            />
        );
    }
}

export default SwitchComponent;
