import React, { CSSProperties } from 'react'

import './LabelComponent.scss'

interface ILabelComponentProps {
    className?: string
    style?: CSSProperties
    children?: any
}

const LabelComponent: React.FC<ILabelComponentProps> = (props) => {
    return(
        <p
            className={`label_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            {props.children}
        </p>
    );
}

export default LabelComponent;