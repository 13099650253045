// @import types
import * as LaboratoriesActionTypes from 'types/laboratories/laboratories.actions.types';
// @end types

const initialState: LaboratoriesActionTypes.LaboratoriesState = {
    myLabs: [],
    myActiveLabs: [],
    categories: [],
    favorites: [],
    reservations: [],
    currentReservation: undefined
};

const laboratoriesReducer =  (
    state: LaboratoriesActionTypes.LaboratoriesState = initialState,
    action: LaboratoriesActionTypes.LaboratoriesActionTypes
): LaboratoriesActionTypes.LaboratoriesState => {
    switch(action.type){
        case LaboratoriesActionTypes.GET_MY_LABS:
            return {
                ...state,
                myLabs: action.payload
            }
        case LaboratoriesActionTypes.GET_MY_ACTIVE_LABS:
            return {
                ...state,
                myActiveLabs: action.payload
            }
        case LaboratoriesActionTypes.GET_LABORATORY_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            }
        case LaboratoriesActionTypes.GET_LABORATORY_FAVORITES:
            return {
                ...state,
                favorites: action.payload
            }
        case LaboratoriesActionTypes.GET_RESERVATIONS:
            return {
                ...state,
                reservations: action.payload
            }
        case LaboratoriesActionTypes.DELETE_RESERVATION:
            return {
                ...state,
                reservations: [...state.reservations.filter((r) => r.id !== action.payload.id)]
            }
        case LaboratoriesActionTypes.ADD_RESERVATION:
        case LaboratoriesActionTypes.UPDATE_RESERVATION:
            return {
                ...state,
                reservations: [...state.reservations.filter((r) => r.id !== action.payload.id), action.payload]
            }
        case LaboratoriesActionTypes.SET_CURRENT_RESERVATION:
            return {
                ...state,
                currentReservation: action.payload
            }
        default: 
            return {
                ...state
            }
    }
};

export default laboratoriesReducer;