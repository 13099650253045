import * as AuthTypes from 'types/auth/auth.types';

export interface Rating{
    id?: string;
    user?: AuthTypes.IUser;
    lab?: string;
    rating?: number;
    comment?: string;
}

export interface ParamsRatingCreate{
    rating: Rating
}

export interface ParamsRatingUpdate{
    rating: Partial<Rating>
    id: string
}

export interface ParamsRatingDelete{
    id: string
}

export interface ParamsRatingList{

}

export interface ParamsRatingGetById{
    id: string
}


export const fullRatingQuery = `
    id
    user{
        ${AuthTypes.FullUserGQL}
    }
    lab
    rating
    comment
`