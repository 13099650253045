// @import dependencies
import React, { CSSProperties } from 'react'
// @end dependencies

// @import components
import TitlePageComponent from 'components/commons/TitlePage/TitlePageComponent';
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import FavoritesModule from 'components/modules/FavoritesModule/FavoritesModule';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './FavoritesPage.scss'
// @end styles

interface IFavoritesPageProps {
    className?: string
    style?: CSSProperties
    id?: string
    history?: any
}

const FavoritesPage: React.FC<IFavoritesPageProps> = (props) => {
    return(
        <AppLayout
            history={props.history}
            headerTitle='Laboratorios virtuales'
        >
            <div
                className={`favorites_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
                id={props.id}
            >
                <TitlePageComponent>
                    Mis Favoritos
                </TitlePageComponent>
                <FavoritesModule
                    className='favorites-module-in-page'
                />
            </div>
        </AppLayout>
    );
}

export default FavoritesPage;