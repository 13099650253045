import store from 'redux/store';
import NotificationService from 'services/notification/notification.service';
import * as NotificationTypes from 'types/notification/notification.types';

export const getNotificationsAction = () => (
    async (dispatch: Function) => {
        const notificationService = new NotificationService();
        const response = await notificationService.list();
        if (response) {
            dispatch(getNotifications(response.notifications));
            dispatch(setUnreadNotifications(response.unreadCount));
        } else {
            dispatch(getNotifications([]));
            dispatch(setUnreadNotifications(0));
        }
    }
)

const getNotifications = (notifications: NotificationTypes.Notification[]): NotificationTypes.NotificationActionTypes => ({
    type: NotificationTypes.GET_NOTIFICATIONS,
    payload: notifications
})

export const setUnreadNotificationsAction = () => {
    return (dispatch: Function) => {
        const notifications = [...store.getState().notifications.notifications]
        let unreadCont = 0;
        notifications?.forEach((n) => {
            if (!n.read) {
                unreadCont += 1;
            }
        })
        dispatch(setUnreadNotifications(unreadCont))
    }
}

const setUnreadNotifications = (unread: number): NotificationTypes.NotificationActionTypes => ({
    type: NotificationTypes.SET_UNREAD_NOTIFICATIONS,
    payload: unread
})

export const updateNotificationAction = (notification: NotificationTypes.Notification) => {
    return (dispatch: Function) => {
        const newNotifications = [...store.getState().notifications.notifications]
        const idx = newNotifications.findIndex((n) => n.id === notification.id)
        if (idx >= 0) {
            newNotifications[idx] = notification
        }
        dispatch(updateNotification(newNotifications))
    }
}

const updateNotification = (notifications: NotificationTypes.Notification[]): NotificationTypes.NotificationActionTypes => ({
    type: '[Notifications] UPDATE_NOTIFICATION',
    payload: notifications
})