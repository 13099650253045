import ButtonComponent from 'components/commons/Button/ButtonComponent'
import CheckBoxComponent from 'components/commons/CheckBoxComponent/CheckBoxComponent'
import InputComponent from 'components/commons/Input/InputComponent'
import useValidator from 'hooks/useFormValidator/useFormValidator'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'
import { ILaboratoryDashboard } from 'types/laboratories/laboratories.types'

import './LaboratoryDashboardForm.scss'
import LabelComponent from 'components/commons/LabelComponent/LabelComponent';

interface LaboratoryDashboardFormProps{
    defaultValue?: ILaboratoryDashboard[]
    label: string
    onChange: (dashboards: ILaboratoryDashboard[]) => void
}

interface DashboardItemProps{
    name: string
    defaultLayout: string
    master: boolean
    onChange: (dashboard: ILaboratoryDashboard) => void
    onClickDelete: () => void
}

const LaboratoryDashboardForm: FC<LaboratoryDashboardFormProps> = ({ defaultValue, label, onChange }) => {
    const [value, setValue] = useState<ILaboratoryDashboard[]>([])

    useEffect(() => {
        if (defaultValue) {
            setValue(
                defaultValue.map(
                    ({ name, master, defaultLayout }) => ({ name, master, defaultLayout: defaultLayout ? JSON.stringify(defaultLayout) : undefined }),
                ),
            )
        }
    }, [])

    useEffect(() => {
        onChange([...value.map((item) => ({ ...item, defaultLayout: item.defaultLayout?.length ? JSON.parse(item.defaultLayout as string) : undefined }))])
    }, [value])

    const handleChange = (newDashboard: ILaboratoryDashboard, changeIdx: number) => {
        let newDashboards = [...value]
        if (newDashboard.master) {
            newDashboards = newDashboards.map((dashboard) => ({ ...dashboard, master: false }))
        }
        setValue(newDashboards.map((dashboard, idx) => idx === changeIdx ? newDashboard : dashboard ))
    }

    const handleAddDashboard = () => {
        setValue([...value, { name: 'New dashboard', master: false, defaultLayout: "" }])
    }

    const handleRemoveDashboard = (idx: number) => {
        const newValue = [...value]
        newValue.splice(idx, 1)
        setValue([...newValue])
    }

    return (
        <div className="laboratory_dashboard_form--layout">
            <LabelComponent style={{ marginRight: 'auto' }} >
                {label}
            </LabelComponent>
            {value?.map((dashboard, idx) => (
                <DashboardItem
                    name={dashboard.name}
                    master={dashboard.master}
                    defaultLayout={dashboard.defaultLayout as string}
                    key={idx}
                    onChange={(newDashboard) => handleChange(newDashboard, idx)}
                    onClickDelete={() => handleRemoveDashboard(idx)}
                />
            ))}
            <ButtonComponent variant='primary' onClick={handleAddDashboard}>
                <ButtonComponent.Icon>
                    <MdAdd/>
                </ButtonComponent.Icon>
                Agregar
            </ButtonComponent>
        </div>
    )
}

const DashboardItem: FC<DashboardItemProps> = ({ defaultLayout, name, master, onChange, onClickDelete }) => {

    const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        onChange({
            name: e.target.value,
            master,
            defaultLayout,
        })
    }

    const handleChangeDefaultLayout = (e: ChangeEvent<HTMLInputElement>) => {
        onChange({
            name,
            master,
            defaultLayout: e.target.value, 
        })
    }

    const handleChangeMaster = (e: ChangeEvent<HTMLInputElement>) => {
        onChange({
            name,
            master: e.target.checked,
            defaultLayout,
        })
    }

    return(
        <div className="dashboard_item--layout">
            <InputComponent
                name='name'
                value={name}
                onChange={handleChangeName}
                label='Nombre'
                placeholder='Nombre'
                background='background-input'
            />
            <InputComponent
                type='textarea'
                name='defaultLayout'
                value={defaultLayout}
                onChange={handleChangeDefaultLayout}
                label='Default layout'
                placeholder='Default layout'
                background='background-input'
            />
            <CheckBoxComponent
                name='master'
                checked={master}
                label='Maestro'
                onChange={handleChangeMaster}
            />
            <MdDelete
                onClick={onClickDelete}
            />
        </div>
    )
    
}

export default LaboratoryDashboardForm
