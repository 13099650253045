import axiosApi from "config/axios.config"
import { AxiosRequestConfig } from 'axios'
import Swal from 'sweetalert2'

export interface IHttpUtil extends AxiosRequestConfig{
    method: AxiosRequestConfig['method']
    url: string
    headers?: Object
}

export interface IHttpUtilOptions{
    alertOnFailed?: boolean
}

class HttpUtil{
    constructor(){

    }

    /**
     * @INFO Hacer llamada al API
     * @param _params 
     */
    public send = async (_params: IHttpUtil, _options?: IHttpUtilOptions) => {
        let response: any = {}
        try{
            response = await axiosApi.request(_params)
        }catch(e: any){
            if(e.response){
                response = e.response
            }else{
                response = {}
            }
        }
        if(response.status !== 200){
            // @INFO Mostrar mensaje de error
            if(_options?.alertOnFailed){
                let errorMessage = ""
                if(response.data?.errors?.length){
                    response.data.errors.forEach((item: any) => {
                        errorMessage += `${item.message} - `
                    })
                }else if(response.data?.error?.message){
                    errorMessage = response.data.error.message
                }
                Swal.fire({
                    text: errorMessage,
                    icon: 'error',
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false
                })
            }
        }
        /**
         * ! Falta cuando el token es invalido mostrar Alert y hacer logout
         */
        console.log('Response: ', response);
        return response
    }
}

const HTTPUtil = new HttpUtil()

export default HTTPUtil