import React, { CSSProperties, useEffect, useState, MouseEvent } from 'react'

// @import components
import SubtitleComponent from 'components/commons/Subtitle/SubtitleComponent';
import TextComponent from 'components/commons/Text/TextComponent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import { HiOutlineShoppingBag } from 'react-icons/hi'
import { BsHeart } from 'react-icons/bs';
import FreeTextComponent from 'components/commons/FreeTextComponent/FreeTextComponent';
import { MdFavorite } from 'react-icons/md';
import { MdInput } from 'react-icons/md';
import ShareModule from '../ShareModule/ShareModule';
import RatingComponent from 'components/commons/RatingComponent/RatingComponent';
// @end components

// @import services
import TimeService from 'services/time/timeService';
// @end services

// @import types
import * as LaboratoryTypes from 'types/laboratories/laboratories.types'
// @end types

// @import hooks
import { useHistory } from 'react-router';
import { useLaboratory } from 'hooks/useLaboratory/useLaboratory';
import { useTranslation } from 'react-i18next';
// @end hooks

import './PreviewLaboratoryCard.scss'

interface IPreviewLaboratoryCardProps {
    laboratory: LaboratoryTypes.ILaboratory
    className?: string
    style?: CSSProperties
    list?: boolean
    deleteFavorite?: boolean
    free?: boolean
    addToMyLabs?: boolean   // Solo si el laboratorio es gratuito
    hideFavorite?: boolean
    hideRating?: boolean
    hideShare?: boolean
    onClickGet?: () => any
    onClickFavorite?: () => any
    onClickDeleteFavorite?: () => any
}

const PreviewLaboratoryCard: React.FC<IPreviewLaboratoryCardProps> = (props) => {

    const {t} = useTranslation()
    const hookLabs = useLaboratory({ idLaboratoryVerify: props.laboratory.id });
    const history = useHistory()

    // @INFO Servicios
    const timeService = new TimeService();

    const handleClickGet = () => {
        if (props.addToMyLabs && props.free){
            hookLabs.addToMyLabs(props.laboratory.id as string);
        }
        if (props.onClickGet){
            props.onClickGet();
        }
    }

    /**
     * @INFO Click en iniciar un laboratorio
     */
    const handleClickStart = () => {
        if(history){
            history.push(`/dashboard-laboratory/${props.laboratory?.id}`)
        }
    }


    const getTextGetButton = () => {
        if (!hookLabs.labStatus?.exist){
            return `$${props.laboratory.price} ${t('laboratories.card.get')}`;
        }else if (hookLabs.labStatus.inactive){
            return `$${props.laboratory.price} Volver a obtener`;
        }else if (hookLabs.labStatus.active){
            return `Ingresar`;
        }
    }

    const handleClickFavorite = (e: MouseEvent<SVGElement, globalThis.MouseEvent>) => {
        e.stopPropagation();
        if (props.onClickFavorite) {
            props.onClickFavorite();
        }
    }

    const handleClickDeleteFavorite = (e: MouseEvent<SVGElement, globalThis.MouseEvent>) => {
        e.stopPropagation();
        if (props.onClickDeleteFavorite) {
            props.onClickDeleteFavorite();
        }
    }

    return(
        <div
            className={`preview_laboratory_card-layout ${props.list ? 'list': ''} ${props.className ? props.className : ''}`}
            style={props.style}
            onClick={handleClickGet}
        >
            {props.free ?<FreeTextComponent/> : null}
            <img src={props.laboratory.thumbnail} alt="" />
            <div>
                <SubtitleComponent
                    className='color-text2 title-card'
                >
                    {props.laboratory.name}
                </SubtitleComponent>
                <div>
                    {props.laboratory.category?.name ?
                        <TextComponent
                            className='color-text2 text-card'
                        >
                            {t('laboratories.card.category')}: {props.laboratory.category?.name}
                        </TextComponent>
                    :null}
                    {props.laboratory.config?.duration ?
                        <TextComponent
                            className='color-text2 text-card'
                        >
                            {t('laboratories.card.duration')}: {timeService.secondsFormatHMS(props.laboratory.config?.duration || 0, 'HHh MMm')}
                        </TextComponent>
                    : null}
                    {props.laboratory.subarea?.name ?
                        <TextComponent
                            className='color-text2 text-card'
                        >
                            {t('laboratories.card.area')}: {props.laboratory.subarea?.name} - {props.laboratory.subarea?.area?.name}
                        </TextComponent>
                    :null}
                </div>
            </div>
            <div>
                {!props.list ?
                    <ButtonComponent
                        variant='primary'
                        className='btn-get-lab'
                        onClick={handleClickStart}
                    >
                        <ButtonComponent.Icon>
                            {!hookLabs.labStatus.exist || hookLabs.labStatus.inactive ?
                                <HiOutlineShoppingBag
                                    style={{color: 'var(--accent)'}}
                                />
                            :
                                <MdInput
                                    style={{color: 'var(--accent)'}}
                                />
                            }
                        </ButtonComponent.Icon>
                        {getTextGetButton()}
                    </ButtonComponent>
                :null}
                <div>
                    <div
                        style={{marginLeft: 'auto'}}
                    >
                        {!props.hideRating ?
                            <RatingComponent
                                readonly
                                rating={props.laboratory?.averageRating}
                            />
                        :null}
                    </div>
                    <div>
                        {!props.hideFavorite ?
                            props.deleteFavorite || hookLabs.labStatus.favorite ?
                                <MdFavorite onClick={handleClickDeleteFavorite} />
                            :
                                <BsHeart onClick={handleClickFavorite} />
                        :null}
                        {!props.hideShare ?
                            <ShareModule
                                url={`/laboratory-preview/${props.laboratory?.id}`}
                                relativePath
                            />
                        :null}
                    </div>
                </div>
                {props.list ?
                    <ButtonComponent
                        variant='primary'
                        className='btn-get-lab'
                        onClick={handleClickStart}
                    >
                        <ButtonComponent.Icon>
                            <HiOutlineShoppingBag
                                style={{color: 'var(--accent)'}}
                            />
                        </ButtonComponent.Icon>
                        {getTextGetButton()}
                    </ButtonComponent>
                :null}
            </div>
        </div>
    );
}

export default PreviewLaboratoryCard;