import React, { CSSProperties } from 'react'
import TitlePageComponent from 'components/commons/TitlePage/TitlePageComponent'

import './CategoryCatalogerCard.scss'
import TextComponent from 'components/commons/Text/TextComponent'
import ButtonComponent from 'components/commons/Button/ButtonComponent'



interface ICategoryCatalogerCardProps {
    image: any
    title: string
    description: string
    explication: string
    buttonText: string
    className?: string
    style?: CSSProperties
    onClick?: () => any
}

const CategoryCatalogerCard: React.FC<ICategoryCatalogerCardProps> = (props) => {
    return(
        <div
            className={`category_cataloger_card-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <div>
                <img src={props.image} alt="" />
                <TitlePageComponent
                    style={{ color: 'white' }}
                >
                    {props.title}
                </TitlePageComponent>
                <TextComponent
                    style={{color: 'white'}}
                >
                    {props.description}
                </TextComponent>
            </div>
            <div>
                <TextComponent
                    style={{color: 'white'}}
                >
                    {props.explication}
                </TextComponent>
                <ButtonComponent
                    style={{ backgroundColor: 'white', color: 'var(--primary2)' }}
                    onClick={props.onClick}
                >
                    {props.buttonText}
                </ButtonComponent>
            </div>

        </div>
    );
}

export default CategoryCatalogerCard;