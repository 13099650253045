import React, { CSSProperties } from 'react'
import './SubtitleComponent.scss'

export interface SubtitleComponentProps {
    children?: any
    className?: string
    style?: CSSProperties
}
 
const SubtitleComponent: React.SFC<SubtitleComponentProps> = (props) => {
    return (  
        <p
            className={`subtitle_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            {props.children}
        </p>
    );
}
 
export default SubtitleComponent;