// @import reducers
import { combineReducers } from 'redux';
import authReducer from 'redux/reducers/auth/auth.reducer';
import appReducer from 'redux/reducers/app/app.reducer';
import locationsReducer from 'redux/reducers/locations/locations.reducer';
import laboratoriesReducer from 'redux/reducers/laboratories/laboratories.reducer';
import notificationReducer from './notifications/notifications.reducer';
import dashboardReducer from './dashboard/dashboard.reducer';
// @end reducers

// @import types
import * as appTypes from 'types/app/app.actions.types';
import * as AuthTypes from 'types/auth/auth.types';
import * as LocationsTypes from 'types/locations/locations.types';
import * as LaboratoriesActionTypes from 'types/laboratories/laboratories.actions.types';
import * as NotificationTypes from 'types/notification/notification.types';
import * as DashboardTypes from 'types/dashboard/dashboard.types';
// @end types

export interface IStoreApp{
    auth: AuthTypes.IAuthState
    app: appTypes.IAppState
    locations: LocationsTypes.ILocationsState
    laboratories: LaboratoriesActionTypes.LaboratoriesState
    notifications: NotificationTypes.NotificationsState
    dashboard: DashboardTypes.DashboardState
}

export default combineReducers({
    auth: authReducer,
    app: appReducer,
    locations: locationsReducer,
    laboratories: laboratoriesReducer,
    notifications: notificationReducer,
    dashboard: dashboardReducer
})