import i18nConfig from 'config/i18n.config';

export type ITypeLanguages = 'es' | 'en'

class I18nUtil{

    constructor(){
    }

    public changeLanguage = (language: ITypeLanguages) => {
        i18nConfig.changeLanguage(language)
    }

    public t = (key: string) => {
        return i18nConfig.t(key)
    }

}

const i18nUtil = new I18nUtil()

export default i18nUtil