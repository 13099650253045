// @import dependencies
import React, { CSSProperties, useEffect, useState } from 'react'
import moment from 'moment'
// @end dependencies

// @import components
import LabInProgress from 'components/commons/LabInProgress/LabInProgress'
import PopUpComponent from 'components/commons/PopUp/PopUpComponent'
import TextComponent from 'components/commons/Text/TextComponent'
import TimerComponent from 'components/commons/TimerComponent/TimerComponent'
import TitlePageComponent from 'components/commons/TitlePage/TitlePageComponent'
import TooltipComponent from 'components/commons/TooltipComponent/TooltipComponent'
import { MdNotificationsNone } from 'react-icons/md'
import { RiMoonClearLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { GrFavorite } from 'react-icons/gr'
import NotificationsList from '../NotificationsList/NotificationsList'
import SearchLaboratories from '../SearchLaboratories/SearchLaboratories'
// @end components

// @import types
import { IStoreApp } from 'redux/reducers'
// @end types

// @import services
// @end services

// @import hooks
import { useChangeTheme } from 'hooks/useChangeTheme/useChangeTheme';
import { useSelector } from 'react-redux'
import { useLaboratory } from 'hooks/useLaboratory/useLaboratory'
import { useDashboard } from 'hooks/useDashboard/useDashboard';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './CustomHeaderModule.scss'
// @end styles

interface ICustomHeaderModuleProps {
    className?: string
    style?: CSSProperties
    id?: string
    
}

const CustomHeaderModule: React.FC<ICustomHeaderModuleProps> = (props) => {
    
    const [inProgress, updateInProgress] = useState<boolean>(false);
    const [showNotifications, updateShowNotifications] = useState<boolean>(false);

    const theme = useChangeTheme();
    const hookLabs = useLaboratory();
    const headerConfig = useSelector((store: IStoreApp) => store?.app?.systemConfig?.header);
    const currentReservation = useSelector((store: IStoreApp) => store.laboratories.currentReservation);
    const unreadNotifications = useSelector((store: IStoreApp) => store.notifications.unread);
    const dashboard = useDashboard();

    useEffect(() => {
        validateReservation()
    }, [currentReservation])

    const validateReservation = () => {
        if (currentReservation) {
            if ( moment(currentReservation.date).isBefore(new Date())) {
                updateInProgress(true);
            } else {
                updateInProgress(false);
            }
        } else {
            updateInProgress(false);
        }
    }

    const handleChangeTheme = () => {
        theme.toggle();
    }

    const backgroundColor = headerConfig?.backgroundColor !== undefined
                            ? headerConfig?.backgroundColor
                            : dashboard?.reservation?.publicSettings?.header?.backgroundColor

    return(
        <div
            className={`custom_header_module-layout ${props.className ? props.className : ''}`}
            id={props.id}
            style={{
                ...props.style,
                backgroundColor,
            }}
        >
            {headerConfig?.modules?.includes('icon') ?
                <div>
                    <img src={headerConfig?.icon} alt="Header logo" />
                </div>
            :null}
            {headerConfig?.modules?.includes('title') ?
            <TitlePageComponent
                className='title-header'
            >
                {headerConfig?.title || dashboard?.reservation?.publicSettings?.header?.title || 'Vlesim'}
            </TitlePageComponent>
            :null}
            {/* {headerConfig?.modules?.includes('search_labs') ?
            <SearchLaboratories className='input-search-header' />
            :null} */}
            {/* <div className="container-icons-header">
                {headerConfig?.modules?.includes('reservation') && currentReservation ?
                    inProgress ?
                        <LabInProgress
                            reservation={currentReservation}
                        />
                    :
                    <div>
                        <TextComponent
                            type='label'
                            style={{fontWeight: 'bold'}}
                        >
                            Tu reserva inicia en
                        </TextComponent>
                        <TimerComponent
                            size='small'
                            initialDate={currentReservation.date}
                            limitTime={currentReservation.lab.config?.duration}
                            offsetSeconds={currentReservation.lab.config?.duration ? -currentReservation.lab.config?.duration : undefined}
                            withoutProgress
                            onEndTimer={() => validateReservation()}
                        />
                    </div>
                : null}
                {headerConfig?.modules?.includes('notifications') ?
                <PopUpComponent
                    show={showNotifications}
                    placement='auto'
                    onClickClose={() => updateShowNotifications(false)}
                    content={<NotificationsList/>}
                    hideClose
                >
                    <IconHeader
                        number={unreadNotifications}
                        onClick={() => updateShowNotifications(!showNotifications)}
                    >
                        <MdNotificationsNone/>
                    </IconHeader>
                </PopUpComponent>
                :null}
                {headerConfig?.modules?.includes('favorites') ?
                <Link
                    to='/favorites'
                >
                    <TooltipComponent
                        text='Favoritos'
                        placement='bottom'
                    >
                        <IconHeader
                            number={hookLabs.favorites.length}
                        >
                            <GrFavorite/>
                        </IconHeader>
                    </TooltipComponent>
                </Link>
                :null}
                {headerConfig?.modules?.includes('theme') ?
                <div 
                    className="change-theme-icon-header"
                    onClick={handleChangeTheme}
                >
                    <RiMoonClearLine/>
                </div>
                :null}
            </div> */}
        </div>
    );
}

// ============================================
// @INFO Icono para el header
// ============================================
interface IIconHeaderProps {
    className?: string
    style?: CSSProperties
    children?: any
    number?: number
    onClick?: () => void
}

const IconHeader: React.FC<IIconHeaderProps> = (props) => {
    return(
        <div
            className={`icon_header-layout ${props.className ? props.className : ''}`}
            style={props.style}
            onClick={props.onClick}
        >
            {props.number ?
                <div className="number-icon-header">
                    {props.number}
                </div>
            :null}
            {props.children}
        </div>
    );
}

export default CustomHeaderModule;