import moment from "moment"
import store from "redux/store"
import HTTPUtil from "utils/http/http.util"
import { IData, IParamsGetData, ITypesFilterData, IParamsExportDataXlsx } from "types/data/data.types"

class DataService{

    private token: string | undefined
    private storeObject = store
    
    constructor(){
        this.token = this.storeObject.getState().auth.token
    }

    /**
     * @INFO Obtener los datos de un dispositivo
     * @param _params 
     */
    public getData = async (_params: IParamsGetData) => {
        const response = await HTTPUtil.send({
            method: 'POST',
            data: {..._params},
            url: 'api/data',    
            headers: {
                "token": this.token
            }
        })
        return response
    }

    /**
     * @INFO Generar un pdf con los datos filtrados
     * @param _params 
     */
    public exportXlsx = async (_params: IParamsExportDataXlsx) => {
        const response = await HTTPUtil.send({
            method: 'POST',
            data: {..._params},
            url: 'api/data/export-xlsx',    
            headers: {
                "token": this.token
            }
        })
        return response
    }

    /**
     * @INFO Filtrar variables desde los datos
     * @param _data 
     * @param key 
     */
    public filterVariablesFromData = (_data: IData[], key: ITypesFilterData | string) => {
        let response: any[] = []
        // @INFO Validaciones
        if(!_data?.length) return response
        if(!key || typeof key !== 'string') return response
        // @INFO Rescatar los valores según la key
        response = _data.reduce((accum: any[], item) => {
            const aux = this.getDataFromRoot(key, {...item})
            if(aux){
                accum.push(aux)
            }else{
                accum.push(0)
            }
            return accum
        },[])
        return response
    }   

    /**
     * @INFO Obtener la data de la variable para graficar
     * @param _data 
     * @param key 
     */
    public getVariableDataForChart = (_data: IData[], key: ITypesFilterData | string) => {
        let response: any = {
            labels: [],
            data: []
        }
        // @INFO Validaciones
        if(!_data?.length) return response
        if(!key || typeof key !== 'string') return response
        // @INFO Organizar la data
        const varData = this.filterVariablesFromData(_data, key)
        const varDate = this.filterVariablesFromData(_data, 'date')
        response.labels = [...varDate]
        response.data = [...varData]
        return response 
    }

    /**
     * @INFO Obtener la fecha de los datos formateada
     * @param _data 
     * @param key 
     */
    public getDateFormate = (_data: IData[]) => {
        let response: any[] = [] 
        // @INFO Validaciones
        if(!_data?.length) return response
        // Encontrar y formatear la data
        const dateVector = this.filterVariablesFromData(_data, 'date')
        response = dateVector.reduce((accum: any[], item) => {
            const aux = new Date(item)
            const newFormat = moment(aux).format("MMM DD HH:mm:ss")
            accum.push(newFormat)
            return accum
        },[])
        return response
    }

    /**
     * @INFO Encontrar información desde una ruta
     * @param root 
     * @param data 
     * @returns 
     */
    public getDataFromRoot = (root: string, data: any) => {
        const roots = root.split('.')
        const value = roots.reduce((acum: any, item: string) => {
            if(acum === undefined || acum === null) return undefined
            acum = acum[item]
            return acum
        }, {...data})
        return value
    }
}

export default DataService