import React, { CSSProperties } from 'react'
import Editor, { loader } from "@monaco-editor/react";
import { lenguajeExample } from './examples/index';
import { useSelector } from 'react-redux';
import { IStoreApp } from 'redux/reducers/index';


import './CodeEditor.scss'

export type TypeLanguageCodeEditor = 'c' | 'javascript' | 'python'

interface ICodeEditorProps {
    language: TypeLanguageCodeEditor
    defaultValue?: string
    className?: string
    style?: CSSProperties
    onChange?: (value?: string)=>any
}

const CodeEditor: React.FC<ICodeEditorProps> = (props) => {

    const app = useSelector((store: IStoreApp) => store.app)

    return(
        <div
            className={`code_editor-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <Editor
                // height="90vh"
                height="100%"
                defaultLanguage={props.language}
                // @ts-ignore
                defaultValue={props.defaultValue ? props.defaultValue : (lenguajeExample[props.language] ? lenguajeExample[props.language] : '' )}
                onChange={props.onChange}
                theme={app.theme === 'dark' ? 'vs-dark' : 'vs-light'}
            />
        </div>
    );
}

export default CodeEditor;