import * as FavoriteTypes from 'types/favorite/favorite.types'
import store from 'redux/store'
import graphQLUtil from "utils/graphQL/graphQL.util"
import { gql } from "@apollo/client"

// @import actions
import * as LaboratoriesActions from 'redux/reducers/laboratories/laboratories.actions';
// @end actions

class FavoriteService{

    private storeObject = store

    constructor(){
        
    }

    /**
     * @INFO Traer la lista de favoritos
     * @param _params 
     */
     public list = async (_params?: FavoriteTypes.IParamsListFavorite):Promise<FavoriteTypes.IFavorite[]> => {
        if (!this.storeObject.getState().auth?.token) return [];
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query favorites($token: String!){
                        favorites(token: $token){
                            ${FavoriteTypes.fullFavoriteQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                }
            }
        })
        if(response?.data?.favorites?.length){
            return response.data.favorites
        }else{
            return []
        }
    }

    /**
     * @INFO Crear un nuevo favorito
     * @param _params 
     * @returns 
    */
    public create = async (_params: FavoriteTypes.IParamsNewFavorite)
    : Promise<FavoriteTypes.IFavorite | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation newFavorite($token: String!, $lab: ID!){
                        newFavorite(token: $token, lab: $lab){
                            ${FavoriteTypes.fullFavoriteQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    lab: _params.lab
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.newFavorite){
            const newArrayFavorites = [...this.storeObject.getState().laboratories.favorites, response.data.newFavorite];
            this.storeObject.dispatch(LaboratoriesActions.getLaboratoryFavoritesAction(newArrayFavorites));
            return response.data.newFavorite
        }else{
            return undefined
        }
    }

    /**
     * @INFO Eliminar un favorito
     * @param _params 
     * @returns 
    */
    public delete = async (_params: FavoriteTypes.IParamsRemoveFavorite)
    : Promise<FavoriteTypes.IFavorite | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation removeFavorite($token: String!, $lab: ID!){
                        removeFavorite(token: $token, lab: $lab){
                            ${FavoriteTypes.fullFavoriteQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    lab: _params.lab
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.removeFavorite){
            const newArrayFavorites = [...this.storeObject.getState().laboratories.favorites];
            const idx = newArrayFavorites.findIndex((f) => f.id === response.data.removeFavorite.id);
            newArrayFavorites.splice(idx, 1);
            this.storeObject.dispatch(LaboratoriesActions.getLaboratoryFavoritesAction(newArrayFavorites));
            return response.data.removeFavorite
        }else{
            return undefined
        }
    }
}

export default FavoriteService