import React, { useEffect, useState } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import './MasterLayout.scss'
import RouterService from 'services/router/routerService';
import { routesApp } from 'routes/routes';
import AuthService from 'services/auth/authService';
import { useDispatch, useSelector } from 'react-redux'
import { IStoreApp } from 'redux/reducers/index';
import * as AuthActions from 'redux/reducers/auth/auth.actions'
import LoadingPage from 'components/screens/LoadingPage/LoadingPage';
import { createGlobalStyle } from 'styled-components'
import storeService from 'services/store/storeService';
import { ITypeThemeApp } from 'types/app/app.actions.types';
import ColorsService from 'services/colors/colorsService';
import { updateThemeAppAction } from 'redux/reducers/app/app.actions';
import * as AppActions from 'redux/reducers/app/app.actions'
import i18nUtil from 'utils/i18n/i18n.util';
import * as LaboratoryActions from 'redux/reducers/laboratories/laboratories.actions';
import useReservation from 'hooks/useReservation/useReservation';
import AppService from 'services/app/appService';

export interface MasterLayoutProps {
    
}

interface IGlobalStyleProps {
    style: string
}

const GlobalStyle = createGlobalStyle<IGlobalStyleProps>`
  ${(props) => props.style}
`
 
const MasterLayout: React.SFC<MasterLayoutProps> = (props) => {

    const routerService = new RouterService()
    const appService = new AppService();
    const authService = new AuthService()
    const hasData = useSelector((store: IStoreApp) => store.auth.hasData)
    const dispatch = useDispatch()
    const colorsService = new ColorsService()
    const [style, updateStyle] = useState<string>('')
    const language = useSelector((store: IStoreApp) => store.app.language)
    const userLang = useSelector((store: IStoreApp) => store?.auth?.user?.lang)
    const hookReservation = useReservation({ startReservationReview: true });

    useEffect(() => {
        initConfig();
        storeService?.subscribe('app.theme', updateTheme, true)
        return () => {
            storeService?.unsubscribe('app.theme', updateTheme)
        }
    }, [])

    useEffect(() => {
        if(language){
            i18nUtil.changeLanguage(language)
        }
    },[language])

    useEffect(() => {
        if(userLang && userLang !== language){
            dispatch(AppActions.updateLanguageAction(userLang))
        }
    }, [userLang])

    useEffect(() => {
        if(hasData){
            dispatch(LaboratoryActions.getReservationsAction());
        }
    }, [hasData])

    const initConfig = async () => {
        // @INFO Esperar que el store se actualice
        await appService.asyncPause(100);
        // @INFO Revisar si el usuario esta autenticado
        let token = authService.getToken()
        // @INFO Revisar si esta autenticado desde el juego
        if (!token) {
            token = routerService.getGameToken()
        }
        if(token && !hasData){
            // @INFO Si hay token y no hay data hago el login con token
            dispatch(AuthActions.loginByTokenAction({token: String(token)}))
        }
        // @INFO Establecer el tema por defecto
        const currentTheme = colorsService.getThemeColorStorage()
        if(currentTheme){
            dispatch(updateThemeAppAction(currentTheme))
        }else{
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                // dark mode
                dispatch(updateThemeAppAction('dark'))
            }
        }
    }

    const updateTheme = (type: ITypeThemeApp) => {
        if(!type) return
        const styleTheme = colorsService.getThemeStringBody(type)
        updateStyle(styleTheme)
        colorsService.setThemeColorStorage(type)
    }

    return (  
        <div className="master_layout-layout">
            {/* <LoadingPage/> */}
            {(authService.getToken() || routerService.getGameToken()) && !hasData ?
                <LoadingPage/>
            :
                <BrowserRouter>
                    <Switch>
                        {routesApp.map((itemRoute) => (
                            routerService.renderRoute(itemRoute)
                        ))}
                    </Switch>
                </BrowserRouter>
            }
            <GlobalStyle
                style={style}
            />
        </div>
    );
}
 
export default MasterLayout;