import React, { CSSProperties, useState, useEffect } from 'react'
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import EditorJsComponent from 'components/commons/EditorJsComponent/EditorJsComponent';
import SubtitleComponent from 'components/commons/Subtitle/SubtitleComponent';
import { Modal } from 'react-bootstrap';

import './ModalEditorJs.scss'

interface IModalEditorJsProps {
    open: boolean
    className?: string
    style?: CSSProperties
    title?: string
    name?: string
    value?: any
    onClose?: (data?: any) => any
    onChangeForm?: (data: any) => any
}

const ModalEditorJs: React.FC<IModalEditorJsProps> = (props) => {

    const [data, updateData] = useState<any | undefined>(undefined)

    useEffect(() => {
        updateData(props.value)
    }, [props.value, props.open])

    /**
     * @INFO Cerrar el modal
     */
    const handleCloseModal = () => {
        if(props.onClose){
            props.onClose()
        }
    }

    /**
     * @INFO Enviar el onChangeForm del editor
     * @param api 
     * @param data 
     */
    const handleChangeEditor = (api: any, data: any) => {
        updateData(data)
    }

    /**
     * @INFO Click a guardar en el modal
     */
    const handleSaveModal = () => {
        if(props.onChangeForm){
            const response = {
                target: {
                    name: props.name ? props.name : 'editor',
                    value: data
                }
            }
            props.onChangeForm(response)
        }
        if(props.onClose){
            props.onClose()
        }
    }

    return(
        <Modal
            className={`modal_change_password-layout ${props.className ? props.className : ''}`}
            style={props.style}
            onHide={() => handleCloseModal()}
            show={props.open}
            centered
            backdrop="static"
            keyboard={false}
            dialogClassName="container-dialog-class-name-editor-js"
        >
            <Modal.Header >
                <SubtitleComponent>
                    {props.title ? props.title : 'Editor'}
                </SubtitleComponent> 
            </Modal.Header>
            <Modal.Body>
                <div
                    className={`modal_editor_js-layout ${props.className ? props.className : ''}`}
                    style={props.style}
                >
                    <EditorJsComponent
                        data={data}
                        onChange={handleChangeEditor}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonComponent
                    onClick={handleCloseModal}
                >
                    Cancelar
                </ButtonComponent>
                <ButtonComponent
                    variant='primary'
                    onClick={handleSaveModal}
                >
                    Guardar
                </ButtonComponent>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalEditorJs;