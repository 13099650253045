export enum BannerLocation {
    LANDING_VLESIM = 'landingVlesim',
    LANDING_SMART_FACTORY = 'landingSmartFactory',
    LABORATORIES_PAGE = 'laboratoriesPage'
}

export interface IBanner{
    id?: string
    title?: string
    description?: string
    image?: string
    action?: {
        web: {
            name: string
            route: string
        }
        movil: {
            name: string
            route: string
        }
    }
    startDate?: Date
    finishDate?: Date
    locations?: BannerLocation[]
}

export interface IBannerFilter {
    locations?: BannerLocation[]
}

export interface IParamsBannersList{
    onlyAvailable?: boolean
    filter?: IBannerFilter
}

export interface IParamsBannerCreate{
    banner: any
}

export interface IParamsBannerUpdate{
    banner: any
    id: string
}

export interface IParamsBannerDelete{
    id: string
}

export interface IParamsBannerGetById{
    id: string
}

export const fullBannerQuery = `
    id
    title
    description
    image
    action{
        web{
            name
            route
        }
        movil{
            name
            route
        }
    }
    startDate
    finishDate
    locations
`