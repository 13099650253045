import { useEffect, useState } from 'react'
import { IUseTimerInput, IUseTimerOutput } from './useTimer.types';

export const useTimer = (_params?: IUseTimerInput): IUseTimerOutput => {
  const [currentSeconds, updateCurrentSeconds] = useState<number>(_params?.initialValue ? _params?.initialValue : 0)
  const [triggerNewSecond, updateTriggerNewSecond] = useState<any>({})
  const [isRunning, updateIsRunning] = useState<boolean>(false)
  const [totalSeconds, updateTotalSeconds] = useState<number>(currentSeconds)
  const [seconds, updateSeconds] = useState<number>(0)
  const [minutes, updateMinutes] = useState<number>(0)
  const [hours, updateHours] = useState<number>(0)
  const [days, updateDays] = useState<number>(0)

  useEffect(() => {
    const interval = setInterval(handleNewSecond,1000)
    return () => {
      clearInterval(interval)
    }
  },[])

  useEffect(() => {
    calculateTime()
  },[currentSeconds])

  useEffect(() => {
    if(!isRunning) return
    const orientation: 'up' | 'down' = _params?.orientation ? _params.orientation : 'up'
    if(orientation === 'up'){
      updateCurrentSeconds(currentSeconds+1)
    }else if (orientation === 'down'){
      if(currentSeconds > 0 || _params?.timeNegative){
        updateCurrentSeconds(currentSeconds-1)
      }
    }
  },[triggerNewSecond])

  const calculateTime = () => {
    if(_params?.stream){
      const _days = Math.trunc(currentSeconds / 86400)
      const _hours = Math.trunc((currentSeconds - (_days*84600)) / 3600)
      const _minutes = Math.trunc((currentSeconds - (_days*84600) - (_hours*3600)) / 60)
      const _seconds =  Math.trunc(currentSeconds - (_days*84600) - (_hours*3600) - (_minutes*60))
      updateTotalSeconds(currentSeconds)
      updateDays(_days)
      updateHours(_hours)
      updateMinutes(_minutes)
      updateSeconds(_seconds)
    }
  }

  const handleNewSecond = () => {
    updateTriggerNewSecond({})
  }

  // ==========================================
  // @INFO Funciones de respuesta
  // ==========================================

  const getSeconds = () => {
    return currentSeconds
  }

  const setTime = (_seconds: number) => {
    updateCurrentSeconds(_seconds)
  }

  const reset = () => {
    updateCurrentSeconds(_params?.initialValue ? _params.initialValue : 0)
  }

  const start = () => {
    updateIsRunning(true)
  }

  const pause = () => {
    updateIsRunning(false)
  }

  return {
    isRunning,
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    getSeconds,
    setTime,
    reset,
    start,
    pause
  }

}
