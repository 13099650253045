import React, { CSSProperties, MutableRefObject, useState } from 'react'
import FullCalendar, {EventSourceInput} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from "@fullcalendar/timegrid";
import { DateClickArg } from '@fullcalendar/interaction';
import interactionPlugin from '@fullcalendar/interaction';

// @import styles
import './CalendarComponent.scss';
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
// @end styles

export type TypesCalendarView = 'dayGridMonth' | 'timeGridDay';

interface ICalendarComponentProps {
    className?: string
    style?: CSSProperties
    events: EventSourceInput
    contentHeight?: string
    calendarRef?: MutableRefObject<any>
    disableDayView?: boolean
    dateClick?: (arg: DateClickArg) => any
    onChangeView?: (view: TypesCalendarView) => void
}

const CalendarComponent: React.FC<ICalendarComponentProps> = (props) => {

    const [view, updateView] = useState<TypesCalendarView>('dayGridMonth');

    const handleDateClick = (arg: DateClickArg) => {
        if(props.dateClick){
          props.dateClick(arg)
        }
    }

    const handleChangeView = (data: any) => {
        if(props.onChangeView){
            updateView(data.view.type)
            props.onChangeView(data.view.type);
        }
    }

    return(
        <div
            className={`calendar_component-layout ${view} ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <FullCalendar
                headerToolbar={{
                    start: 'prev,next',
                    center: 'title',
                    end: props.disableDayView ? undefined : 'dayGridMonth,timeGridDay'
                }}
                plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
                initialView="dayGridMonth"
                events={props.events}
                locale={'es'}
                eventDisplay={'background'}
                aspectRatio={1}
                dateClick={handleDateClick}
                contentHeight={props.contentHeight}
                viewDidMount={handleChangeView}
                ref={props.calendarRef}
                slotDuration='00:15:00'
            />
        </div>
    );
}

export default CalendarComponent;