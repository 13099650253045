// @import dependencies
import React, { CSSProperties } from 'react'
// @end dependencies

// @import components
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import styles
import './VRContainer.scss'
// @end styles

interface IVRContainerProps {
    url: string
    className?: string
    style?: CSSProperties
    main?: boolean
}

const VRContainer: React.FC<IVRContainerProps> = (props) => {
    return(
        <div
            className={`vr_container-layout ${props.main ? 'main' : ''} ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <iframe 
                src={props.url} 
                frameBorder={0}
                className='frame-virtual-reality'
            />  
        </div>
    );
}

export default VRContainer;
