import React from 'react'
import './App.css';
import MasterLayout from './components/layouts/MasterLayout/MasterLayout';

// @INFO Redux
import { Provider } from 'react-redux'
import store from './redux/store'

function App() {
  return (
    <div className="App">
      <Provider  store={store}>
        <MasterLayout/>
      </Provider>
    </div>
  );
}

export default App;
