export const cExample = `
    // Este es un código de ejemplo para lenguaje C
    #include<iostream.h>

    using namespace std;

    main(){
        // Aquí va tu código
        while(1){
            
        }
    }
`