import { fullTagQuery, Tag } from "types/tag/tag.types"

export interface Plan {
    id: string
    name: string
    custom: boolean
    description: string
    amount: number
    currency: string
    interval: string
    intervalCount: number
    trialDays: number
    features: string[]
    tag: Tag
}

export interface ParamsPlansList {
}

export interface ParamsPlanCreate{
    plan: Omit<Plan, 'id'>
}

export interface ParamsPlanUpdate{
    plan: Partial<Omit<Plan, 'id'>>
    id: string
}

export interface ParamsPlanDelete{
    id: string
}

export interface ParamsPlanGetById{
    id: string
}

export const fullPlanQuery = `
    id
    name
    custom
    description
    amount
    currency
    interval
    intervalCount
    trialDays
    features
    tag {
        ${fullTagQuery}
    }
`
