import TableComponent, { IKeyTable } from 'components/commons/TableComponent/TableComponent';
import { FC, useEffect, useMemo, useState } from 'react'
import SocketService from 'services/socket/socketService';
import * as ToolTypes from 'types/tool/tool.types';

import './TableModuleDashboard.scss'

interface TableModuleDashboardProps{
  tool: ToolTypes.ITool
}

const TableModuleDashboard: FC<TableModuleDashboardProps> = ({ tool }) => {

  const [headers, setHeaders] = useState<string[]>([])
  const [rows, setRows] = useState<string[][]>([])

  const headersToRender = useMemo(() => {
    return headers.map<IKeyTable>((head, idx) => ({ key: String(idx), name: head, type: 'text' }))
  }, [headers])

  const socketService = new SocketService()

  useEffect(() => {
    if(tool?.category?.config?.hasEvent && tool.config?.event){
        socketService.listenEvent(tool.config?.event, handleNewData)
    }
    return () => {
        if(tool?.category?.config?.hasEvent && tool.config?.event){
            socketService.removeListenEvent(tool.config?.event, handleNewData)
        }
    }
  },[])

  const handleNewData = (_data: any) => {
    if (_data?.data?.headers?.length) {
      setHeaders(_data?.data?.headers);
    }
    if (_data?.data?.rows?.length) {
        setRows(_data?.data?.rows);
    }
  }

  return (
    <div className="table_module_dashboard--layout">
      <TableComponent
          keys={headersToRender}
          rows={rows}
      />
    </div>
  )
}

export default TableModuleDashboard
