import React, { CSSProperties, useEffect, useState } from 'react'

import './EditorJsComponent.scss'

// Dependencias para editor
// @ts-ignore
import Embed from '@editorjs/embed'
// @ts-ignore
import Header from '@editorjs/header'
// @ts-ignore
import Image from '@editorjs/image'
// @ts-ignore
import LinkE from '@editorjs/link'
// @ts-ignore
import Paragraph from '@editorjs/paragraph'
// @ts-ignore
import List from '@editorjs/list'
// @ts-ignore
import Raw from '@editorjs/raw'
// @ts-ignore
import Checklist from '@editorjs/checklist'
// @ts-ignore
import EditorJS from 'react-editor-js'
import store from 'redux/store'
// import Quote from '@editorjs/quote'
const Quote = require('@editorjs/quote');
const Table = require('@editorjs/table')

interface IEditorJsComponentProps {
    className?: string
    style?: CSSProperties
    disabled?: boolean
    data?: any
    onChange?: (API: any, data?: any) => any
}

const EditorJsComponent: React.FC<IEditorJsComponentProps> = (props) => {

    const [reset, updateReset] = useState(false)

  useEffect(() => {
    updateReset(true)
    setTimeout(() => {
      updateReset(false)
    }, 100)
  }, [props.disabled])

  const storeObject = store

  /**
   * ! Falta personalizar las APIs para diferentes tipos de recursos
   */
  // Configuración del editor
  const EDITOR_TOOLS = {
    embed: {
        class: Embed,
        inlineToolbar: true
    },
    header: {
        class: Header,
        inlineToolbar: true
    },
    image: {
        class: Image,
        // config: {
        //     endpoints: {
        //         byFile: `${external_api.backend.url}/api/academic-resource-editor/${props.allianceId as string}/image/upload-by-file`,
        //         byUrl: `${external_api.backend.url}/api/academic-resource-editor/${props.allianceId as string}/image/upload-by-url`
        //     },
        //     additionalRequestHeaders: {
        //         'Authorization': storeObject.getState().auth.user?.token ? storeObject.getState().auth.user?.token : ''
        //     },
        //     additionalRequestData: {
        //         academic_resource: props.academicResource ? props.academicResource : ''
        //     }
        // }
    },
    linkTool: {
        class: LinkE,
        // config: {
        //     endpoint: `${external_api.backend.url}/api/academic-resource-editor/${props.allianceId as string}/link/fetch-url`
        // }
    },
    quote: {
        class: Quote,
        inlineToolbar: true,
        shortcut: 'CMD+SHIFT+O',
        config: {
            quotePlaceholder: 'Enter a quote',
            captionPlaceholder: 'Quote\'s author',
        },
    },
    paragraph: {
        class: Paragraph,
        inlineToolbar: true
    },
    list: {
        class: List,
        inlineToolbar: true
    },
    raw: {
        class: Raw,
        inlineToolbar: true
    },
    checklist: {
        class: Checklist,
        inlineToolbar: true
    },
    table: {
        class: Table,
        inlineToolbar: true
    },
  }

    return(
        <div
            className={`editor_js_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            {!reset ?
                <EditorJS
                    tools={EDITOR_TOOLS}
                    data={props.data}
                    readOnly={props.disabled}
                    onChange={props.onChange}
                    autofocus={true}
                />
          : null}
        </div>
    );
}

export default EditorJsComponent;