// @import dependencies
import React, { CSSProperties, useEffect, useState } from 'react'
// @end dependencies

// @import components
import LabelComponent from 'components/commons/LabelComponent/LabelComponent';
import InputComponent from 'components/commons/Input/InputComponent';
// @end components

// @import types
import { IStoreApp } from 'redux/reducers';
import * as ToolTypes from 'types/tool/tool.types';
// @end types

// @import services
// @end services

// @import hooks
import useValidator from 'hooks/useFormValidator/useFormValidator';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './RangeInputComponent.scss'
// @end styles

interface IRangeInputComponentProps {
    className?: string
    style?: CSSProperties
    id?: string
    label?: string
    defaultValue?: ToolTypes.RangeValue
    value?: ToolTypes.RangeValue
    required?: boolean
    onChange?: (range: ToolTypes.RangeValue) => void 
}

const RangeInputComponent: React.FC<IRangeInputComponentProps> = (props) => {

    const {t} = useTranslation();
    const [initalRender, updateInitialRender] = useState<boolean>(false);
    const form = useValidator([
        { name: 'min', required: props.required !== undefined ? props.required : true, messageError: t('obligatory_field') },
        { name: 'max', required: props.required !== undefined ? props.required : true, messageError: t('obligatory_field') },
    ])
    const theme = useSelector((store: IStoreApp) => store.app.theme)

    useEffect(() => {
        if(props.defaultValue){
            form.setValues({
                min: String(props.defaultValue.min),
                max: String(props.defaultValue.max)
            });
        }
    },[])

    useEffect(() => {
        sendOnChange(form.getValues())
    }, [form.values['min'], form.values['max']])
    
    useEffect(() => {
        if (props.value) {
            form.setValues({
                min: String(props.value.min),
                max: String(props.value.max)
            });
        }
    }, [props.value?.min, props.value?.max])

    const sendOnChange = (_range: ToolTypes.RangeValue) => {
        if(props.onChange && initalRender){
            props.onChange(_range);
        }else{
            updateInitialRender(true);
        }
    }

    return(
        <div
            className={`range_input_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            {props.label ?
                <LabelComponent>
                    {props.label}
                </LabelComponent>
            :null}
            <div className="container-inputs-range">
                <InputComponent
                    name='min'
                    label='Mínimo'
                    type='number'
                    value={form.values['min']}
                    onChange={form.handleChange}
                    invalid={form.errors['min'] ? true : false}
                    invalidText={form.errors['min']}
                    background={theme === 'dark' ? 'background-login' : undefined}
                />
                <InputComponent
                    name='max'
                    label='Máximo'
                    type='number'
                    value={form.values['max']}
                    onChange={form.handleChange}
                    invalid={form.errors['max'] ? true : false}
                    invalidText={form.errors['max']}
                    background={theme === 'dark' ? 'background-login' : undefined}
                />
            </div>
        </div>
    );
}

export default RangeInputComponent;