import { IColorsTheme } from "types/colors/colors.types"
import { ITypeThemeApp } from '../../types/app/app.actions.types';

const lightColorsObject: IColorsTheme = {
    primary: '#03133B',
    primaryInverter: '#0C9CF2', 
    secondaryInverter: '#03133B',
    primary2: '#0C9CF2',
    secondary: '#00FAEC',
    accent: '#02EB48',
    background: '#FFF',
    background2: '#efefef',
    background3: '#e5e5e5',
    backgroundLogin: '#FFF',
    backgroundInverter: '#323232',
    backgroundInput: '#efefef',
    shadow: '2px 2px 12px -6px rgba(0, 0, 0, 0.6)',
    shadowPrimary: '5px 4px 14px -6px var(--primary)',
    shadowButtonPrimary: '0 0 6px 0.05rem var(--primary)',
    shadowButtonSecondary: '0 0 6px 0.05rem var(--secondary)',
    text: '#000',
    text2: '#777',
    gradientPrimary: 'linear-gradient(90deg, rgba(0, 51, 146, 1) 0%, rgba(0, 51, 146, 0.49343487394957986) 46%, rgba(0, 51, 146, 0.16010154061624648) 100%)',
    fontTitle: "'Lato', sans-serif",
    fontText: "'Lato', sans-serif",
}

const darkColorsObject: IColorsTheme = {
    primary: '#0C9CF2',
    primaryInverter: '#03133B', 
    secondaryInverter: '#0C9CF2', 
    primary2: '#0C9CF2',
    secondary: '#00FAEC',
    accent: '#02EB48',
    background: '#323232',
    background2: '#323232',
    background3: '#e5e5e5',
    backgroundLogin: '#010E10',
    backgroundInverter: '#FFF',
    backgroundInput: '#010E10',
    shadow: '2px 2px 12px -6px rgba(0, 0, 0, 1)',
    shadowPrimary: '5px 4px 14px -6px var(--primary)',
    shadowButtonPrimary: '0 0 6px 0.05rem var(--primary)',
    shadowButtonSecondary: '0 0 6px 0.05rem var(--secondary)',
    text: '#FFF',
    text2: '#979797',
    gradientPrimary: 'linear-gradient(90deg, rgba(0, 51, 146, 1) 0%, rgba(0, 51, 146, 0.49343487394957986) 46%, rgba(0, 51, 146, 0.16010154061624648) 100%)',
    fontTitle: "'Lato', sans-serif",
    fontText: "'Lato', sans-serif",
}

class ColorsService{
    constructor(){

    }

    /**
     * @INFO Obtener el tema de la app en formato string con "body" para cambiar los estilos
     */
    public getThemeStringBody = (type: ITypeThemeApp) => {
        const regExpColor = new RegExp(/([A-Z]*)([a-z]+)([0-9]*)/g)
        let colorsString: string = ''
        let theme: string = ''
        let keys: string[] = []
        let themeColors: IColorsTheme = {...lightColorsObject}
        if(type === 'light'){
            themeColors = {...lightColorsObject}
        }else if(type === 'dark'){
            themeColors = {...darkColorsObject}
        }
        keys = Object.keys(themeColors)
        if(keys.length){
            colorsString = keys.reduce((accum: string, itemKey) => {
                const words = itemKey.match(regExpColor)
                if(words?.length){
                    const colorCSS = words.reduce((accum2: string, itemWord, idxWord) => {
                        const lower = itemWord.toLowerCase()
                        if(idxWord === 0 && words.length === 1){
                            // @ts-ignore
                            accum2 = `--${lower}: ${themeColors[itemKey]}; `
                        }else if(idxWord === 0){
                            accum2 = `--${lower}`
                        }else if(idxWord === (words.length-1)){
                            // @ts-ignore
                            accum2 = `${accum2}-${lower}: ${themeColors[itemKey]}; `
                        }else{
                            accum2 = `${accum2}-${lower}`
                        }
                        return accum2
                    }, '')
                    accum = `${accum} ${colorCSS}`
                }
                return accum
            }, '')
            theme = `body{${colorsString}}`
        }
        return theme
    }

    /**
     * @INFO Obtener color del body en CSS
     * @param color : color a buscar;
     * @returns 
     */
    public getColorFromCSS = (color: string): string => {
        const style = getComputedStyle(document.body)
        const response = style.getPropertyValue(color)
        return response
    }

    /**
     * @INFO Obtener todo el tema con types desde el body en CSS
     * @returns 
     */
    public getThemeColorsFromCSS = (): IColorsTheme => {
        const style = getComputedStyle(document.body)
        const colors: IColorsTheme = {
          primary: style.getPropertyValue('--primary'),
          primaryInverter: style.getPropertyValue('--primary-inverter'),
          secondaryInverter: style.getPropertyValue('--secondary-inverter'),
          primary2: style.getPropertyValue('--primary2'),
          secondary: style.getPropertyValue('--secondary'),
          accent: style.getPropertyValue('--accent'),
          background: style.getPropertyValue('--background'),
          background2: style.getPropertyValue('--background2'),
          background3: style.getPropertyValue('--background3'),
          backgroundLogin: style.getPropertyValue('--background-login'),
          backgroundInverter: style.getPropertyValue('--background-inverter'),
          backgroundInput: style.getPropertyValue('--background-input'),
          shadow: style.getPropertyValue('--shadow'),
          shadowPrimary: style.getPropertyValue('--shadow-primary'),
          shadowButtonPrimary: style.getPropertyValue('--shadow-button-primary'),
          shadowButtonSecondary: style.getPropertyValue('--shadow-button-secondary'),
          text: style.getPropertyValue('--text'),
          text2: style.getPropertyValue('--text2'),
          gradientPrimary: style.getPropertyValue('--gradient-primary'),
          fontTitle: style.getPropertyValue('--font-title'),
          fontText: style.getPropertyValue('--font-text'),
        }
        return colors
    }

    /**
     * @INFO Establecer el valor del tema en el localStorage
     * @param theme 
     */
    public setThemeColorStorage = (theme: ITypeThemeApp) => {
        localStorage.setItem('theme', theme)
    }

    /**
     * @INFO Obtener el valor del tema desde el localStorage
     */
    public getThemeColorStorage = () => {
        const theme = localStorage.getItem('theme') as ITypeThemeApp | undefined
        if(theme){
            return theme
        }else{
            return undefined
        }
    }
}

export default ColorsService