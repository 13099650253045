import TitlePageComponent from 'components/commons/TitlePage/TitlePageComponent'
import React, { CSSProperties } from 'react'

import './NotFoundPage.scss'

interface INotFoundPageProps {
    className?: string
    style?: CSSProperties
    
}

const NotFoundPage: React.FC<INotFoundPageProps> = (props) => {
    return(
        <div
            className={`note_found_page-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <TitlePageComponent>
                Página no encontrada
            </TitlePageComponent>
        </div>
    );
}

export default NotFoundPage;