import { FC, CSSProperties, useEffect, useState } from 'react'

import HandImg from 'assets/images/landing/customLandings/SmartFactory/hand.png'

import './SmartFactoryPlan.scss'
import { Plan } from 'types/plan/plan.types'
import PlanService from 'services/plan/planService'
import PlanCard from 'components/modules/PlanCard/PlanCard'

interface SmartFactoryPlanProps{
    className?: string
    style?: CSSProperties
}

const SmartFactoryPlan: FC<SmartFactoryPlanProps> = ({ className, style }) => {
    const [plans, setPlans] = useState<Plan[]>([])

    const planService = new PlanService()

    useEffect(() => { const getPlans = async () => {
        const response = await planService.list()
            setPlans(response)
        }
        getPlans()
    }, [])

    return (
        <div className={`smart_factory_plan--layout ${className ? className : ''}`} style={style}>
            <div className="smart_factory_plan--explication">
                <div className="smart_factory_plan--image">
                    <img src={HandImg} alt="" />
                </div>
                <div className="smart_factory_plan--content">
                    <h1>Adquiere tu subscripción</h1>
                    <p>I4.0 Smart Factory te ofrece el mejor plan y los mejores costos a la medida de tus necesidades.</p>
                </div>
            </div>
            <div className="smart_factory_plan--container-cards">
                {plans?.map((plan) => (
                    <PlanCard
                        className='smart_factory_plan--plan-card'
                        key={plan.id}
                        plan={plan}
                        showPrice
                        path='/login?redirectTo=/plans'
                        style={{
                            backgroundColor: 'rgba(0,0,0, $alpha: 0.2)'
                        }}
                    />
                ))}
            </div>
        </div>
    )
}

export default SmartFactoryPlan
