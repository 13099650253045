import { IUseChangeThemeOutput } from "./useChangeTheme.types"
import { ITypeThemeApp } from 'types/app/app.actions.types';
import { updateThemeAppAction } from 'redux/reducers/app/app.actions';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreApp } from 'redux/reducers/index';

export const useChangeTheme = (): IUseChangeThemeOutput => {

    const dispatch = useDispatch()
    const theme = useSelector((store: IStoreApp) => store.app.theme)

    const set = (_theme: ITypeThemeApp) => {
        dispatch(updateThemeAppAction(_theme))
    }

    const toggle = () => {
        if(theme === 'light'){
            dispatch(updateThemeAppAction('dark'))
        }else if(theme === 'dark'){
            dispatch(updateThemeAppAction('light'))
        }
    }

    return {
        theme,
        set,
        toggle
    }
}