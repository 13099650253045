import { gql } from "@apollo/client"
import store from "redux/store"
import graphQLUtil from "utils/graphQL/graphQL.util"
import * as BannerTypes from 'types/banners/banners.types'

class BannerService{
    
    private storeObject = store

    constructor(){

    }

    /**
     * @INFO Traer la lista de banners
     * @param _params 
     */
     public list = async (_params?: BannerTypes.IParamsBannersList):Promise<BannerTypes.IBanner[]> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query banners($onlyAvailable: Boolean, $filter: BannerFilter){
                        banners(onlyAvailable: $onlyAvailable, filter: $filter){
                            ${BannerTypes.fullBannerQuery}
                        }
                    }
                `,
                variables: {
                    onlyAvailable: _params?.onlyAvailable,
                    filter: _params?.filter
                }
            }
        })
        if(response?.data?.banners?.length){
            return response.data.banners
        }else{
            return []
        }
    }

    /**
     * @INFO Crear un nuevo banner
     * @param _params 
     * @returns 
     */
     public create = async (_params: BannerTypes.IParamsBannerCreate)
     : Promise<BannerTypes.IBanner | undefined> => {
         if(!_params) return
         const response = await graphQLUtil.send({
             method: 'mutate',
             data: {
                 mutation: gql`
                     mutation newBanner($token: String!, $banner: BannerInput!){
                         newBanner(token: $token, banner: $banner){
                             ${BannerTypes.fullBannerQuery}
                         }
                     }
                 `,
                 variables: {
                     token: this.storeObject.getState().auth?.token,
                     banner: {..._params.banner}
                 }
             }
         },
         {
             alertOnFailed: true
         })
         if(response?.data?.newBanner){
             return response.data.newBanner
         }else{
             return undefined
         }
     }

     /**
     * @INFO Actualizar un banner
     * @param _params 
     * @returns 
     */
    public update = async (_params: BannerTypes.IParamsBannerUpdate)
    : Promise<BannerTypes.IBanner | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation updateBanner($token: String!, $id: ID!, $banner: UpdateBannerInput!){
                        updateBanner(token: $token, id: $id, banner: $banner){
                            ${BannerTypes.fullBannerQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    banner: {..._params.banner},
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.updateBanner){
            return response.data.updateBanner
        }else{
            return undefined
        }
    }

    /**
     * @INFO Eliminar un banner
     * @param _params 
     * @returns 
     */
     public delete = async (_params: BannerTypes.IParamsBannerDelete)
     : Promise<BannerTypes.IBanner | undefined> => {
         if(!_params) return
         const response = await graphQLUtil.send({
             method: 'mutate',
             data: {
                 mutation: gql`
                     mutation removeBanner($token: String!, $id: ID!){
                         removeBanner(token: $token, id: $id){
                             ${BannerTypes.fullBannerQuery}
                         }
                     }
                 `,
                 variables: {
                     token: this.storeObject.getState().auth?.token,
                     id: _params.id
                 }
             }
         },
         {
             alertOnFailed: true
         })
         if(response?.data?.removeBanner){
             return response.data.removeBanner
         }else{
             return undefined
         }
     }

    /**
     * @INFO Obtener banner por ID
     * @param _params 
     */
    public getById = async (_params: BannerTypes.IParamsBannerGetById)
    :Promise<BannerTypes.IBanner | undefined> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query banner($token: String!, $id: ID!){
                        banner(token: $token, id: $id){
                            ${BannerTypes.fullBannerQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params?.id
                }
            }
        })
        if(response?.data?.banner){
            return response.data.banner
        }else{
            return undefined
        }
    }

}

export default BannerService