// @import dependencies
import React, { CSSProperties } from 'react'
// @end dependencies

// @import components
import { HiCheckCircle } from 'react-icons/hi';
import TextComponent from '../Text/TextComponent';
// @end components

// @import types
import * as NotificationTypes from 'types/notification/notification.types';
// @end types

// @import services
import NotificationService from 'services/notification/notification.service';
// @end services

// @import hooks
import useMoment from 'hooks/useMoment/useMoment';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
// @end hooks

// @import actions
import * as NotificationActions from 'redux/reducers/notifications/notifications.actions';
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './NotificationItem.scss'
// @end styles

interface INotificationItemProps {
    className?: string;
    style?: CSSProperties;
    id?: string;
    hideIcon?: boolean;
    title?: string;
    description?: string;
    date?: Date;
    type?: NotificationTypes.Notification['type'];
    data?: NotificationTypes.Notification['data'];
    read?: boolean;
}

const NotificationItem: React.FC<INotificationItemProps> = (props) => {
    
    const {moment} = useMoment();
    const history = useHistory();
    const dispatch = useDispatch();

    const notificationService = new NotificationService()

    const handleClick = async () => {
        // Marcar como notificación leída en caso de que no esté
        if (!props.read && props.id) {
            const response = await notificationService.update({
                id: props.id,
                notification: {
                    read: true
                }
            })
            if (response) {
                dispatch(NotificationActions.updateNotificationAction(response));
            }
        }
        // Redirigir a la pantalla de dashboard de laboratorio
        if (props.data && props.type === 'reservation') {
            history.push(`/dashboard-laboratory/${props.data.message}`)
        }
    }
    
    return(
        <div
            className={`notification_item-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
            onClick={handleClick}
        >
            {!props.hideIcon ? 
                <div className={`icon-notification ${props.read ? 'read' : ''}`}>
                    <HiCheckCircle/>
                </div>
            :null}
            <div>
                <div>
                    <TextComponent
                        type='label'
                        className='text-notification'
                    >
                        {props.title}
                    </TextComponent>
                    <TextComponent
                        type='label'
                        className='text-notification'
                        style={{marginLeft: 'auto'}}
                    >
                        {moment(props.date).format('YYYY/MM/DD')}
                    </TextComponent>
                </div>
                <TextComponent
                    type='text'
                    style={{color: 'var(--text2)'}}
                    className='text-notification'
                >
                    {props.description}
                </TextComponent>
                <TextComponent
                    type='text'
                    style={{color: 'var(--primary2)'}}
                    className='text-notification'
                >
                    {props.type}
                </TextComponent>
            </div>
        </div>
    );
}

export default NotificationItem;