// @import_dependencies
import moment from 'moment'
// @end

// @import services
// @end

// @import utilities
// @end

// @import types
// @end

class TimeService {

  /*===============================================
  =            Estructura de un metodo            =
  ================================================
    // La estructura de un metodo debe ser la siguiente:
    public methodName = () => {}
  /*======  End of Estructura de un metodo  =====*/

  constructor () {}

  /**
   * @INFO Formatear segundos a diferentes formatos
   * @param _seconds
   * @returns
   */
  public secondsFormatHMS = (_seconds: number, _format: 'HH:MM:SS' | 'MM:SS' | 'HH:MM' | 'HHh MMm') => {
    let result = ''
    switch(_format){
      case 'HH:MM:SS':
        result = new Date(_seconds * 1000).toISOString().substr(11, 8);
        break;
      case 'MM:SS':
        result = new Date(_seconds * 1000).toISOString().substr(14, 5);
        break;
      case 'HH:MM':
        result = new Date(_seconds * 1000).toISOString().substr(11, 5);
        break;
      case 'HHh MMm':
        result = new Date(_seconds * 1000).toISOString().substr(11, 5);
        result = `${result.split(':')[0]}h ${result.split(':')[1]}m`;
        break;
      default:
        break;
    }
    return result
  }

  /**
   * @INFO Obtener la diferencia en segundos entre dos fechas
   * @param a : Fecha mayor
   * @param b : Fecha menor
   */
  getDifferenceSeconds = (a: Date, b: Date): number => {
    const end = moment(a);
    const start = moment(b);
    const duration = moment.duration(end.diff(start));
    const response = duration.asSeconds();
    return response;
  }

}

export default TimeService
