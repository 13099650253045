// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import types
import * as LaboratoryTypes from 'types/laboratories/laboratories.types';
import * as ToolTypes from 'types/tool/tool.types';
import { Layout } from 'react-grid-layout';
// @end types

// @import components
import GridLayout, {WidthProvider} from 'react-grid-layout';
import VRContainer from './components/VRContainer/VRContainer';
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import ContainerModuleDashboard from './components/ContainerModuleDashboard/ContainerModuleDashboard';
import NotebookDashboard from './components/NotebookDashboard/NotebookDashboard';
import ChartToolDashboard from './components/ChartToolDashboard/ChartToolDashboard';
import CameraModuleDashboard from './components/CameraModuleDashboard/CameraModuleDashboard';
import SourceModuleDashboard from './components/SourceModuleDashboard/SourceModuleDashboard';
import CodeEditorModuleDashboard from './components/CodeEditorModuleDashboard/CodeEditorModuleDashboard';
import ToolBarDashboard from './components/ToolBarDashboard/ToolBarDashboard';
import TimerComponent from 'components/commons/TimerComponent/TimerComponent';
import TextComponent from 'components/commons/Text/TextComponent';
import PreparingLoading from 'components/commons/PreparingLoading/PreparingLoading';
import ButtonModuleDashboard from 'components/screens/DashboardLaboratoryPage/components/ButtonModuleDashboard/ButtonModuleDashboard';
import { XarmModuleDashboard } from './components/XarmModuleDashboard/XarmModuleDashboard';
import PushButtonModuleDashboard from './components/PushButtonModuleDashboard/PushButtonModuleDashboard';
// @end components

// @import services
import LaboratoryService from 'services/laboratory/laboratoryService';
import ReservationsService from 'services/reservations/reservationsService';
import ScreenNotification from 'services/screenNotification/screenNotification';
// @end services

// @import hooks
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';
import { useDispatch, useSelector } from 'react-redux';
import { useDashboard } from 'hooks/useDashboard/useDashboard';
// @end hooks

// @import actions
import * as DashboardActions from 'redux/reducers/dashboard/dashboard.actions';
// @end actions

// @import utils
// @end utils

// @import functions
import { DashboardLaboratoryFunctions } from './DashboardLaboratoryFunctions';
// @end functions

// @import assets
import NotAllowedImg from 'assets/images/dashboard/no_permitido2.png';
// @end assets

// @import styles
import './DashboardLaboratoryPage.scss'
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import { MdPublish } from 'react-icons/md';
import CopyTextComponent from 'components/commons/CopyTextComponent/CopyTextComponent';
import { DASHBOARD_SITE } from 'config/globals';
import ModalPublicSettings from 'components/modules/ModalPublicSettings/ModalPublicSettings';
import ImageModuleDashboard from './components/ImageModuleDashboard/ImageModuleDashboard';
import RenderDashboardItems from './components/RenderDashboardItems/RenderDashboardItems';
import TableModuleDashboard from './components/TableModuleDashboard/TableModuleDashboard';
import { IStoreApp } from '../../../redux/reducers/index';
// @end styles

const ReactGridLayout = WidthProvider(GridLayout);

interface IDashboardLaboratoryPageProps {
    className?: string
    style?: CSSProperties
    history?: any
}

const DashboardLaboratoryPage: React.FC<IDashboardLaboratoryPageProps> = (props) => {

    const [laboratory, updateLaboratory] = useState<LaboratoryTypes.ILaboratory | undefined>(undefined);
    const [tools, updateTools] = useState<ToolTypes.ITool[]>([]);
    const [toolsToRender, updateToolsToRender] = useState<ToolTypes.ToolToRender[]>([]);
    const [validAccess, updateValidAccess] = useState<boolean>(false);
    const [openPublicReservation, updateOpenPublicReservation] = useState<boolean>(false);
    const container = useResizeDetector();
    const dashboardHook = useDashboard();
    const { reservation, reservations } = dashboardHook
    const dashboardConfig = useSelector((store: IStoreApp) => store.app?.systemConfig?.dashboard)

    // Para manejar el layout
    const [loading, updateLoading] = useState<boolean>(true);
    const [labLayout, updateLabLayout] = useState<Layout[]>([]);

    const params = useParams<{idLab: string}>();
    const dispatch = useDispatch();

    const {t} = useTranslation()

    const dashboardLaboratoryFunctions = new DashboardLaboratoryFunctions();

    // @Servicios
    const laboratoryService = new LaboratoryService();
    const reservationsService = new ReservationsService();
    const screenNotification = new ScreenNotification();

    useEffect(() => {
        return () => {
            dispatch(DashboardActions.setReservationAction(undefined));
        }
    }, [])

    useEffect(() => {
        if (dashboardHook.inicialized) {
            validateAccess();
        }
    }, [dashboardHook.inicialized])

    useEffect(() => {
        if(laboratory){
            let _tools = dashboardLaboratoryFunctions.getToolsFromLaboratory(laboratory);
            if (laboratory?.config?.widgets?.length) {
                _tools = [...laboratory.config.widgets, ..._tools]
            }
            updateTools(_tools);
        }
    },[laboratory])

    useEffect(() => {
        if (reservation?.id && labLayout) {
            dispatch(DashboardActions.updateReservationDashboardAction({
                id: reservation.id,
                layout: labLayout
            }))
        }
    }, [labLayout])

    const handleChangeLayout = (newLayout: Layout[]) => {
        updateLabLayout(newLayout)
    }

    /**
     * @INFO Validar el acceso al laboratorio
     */
    const validateAccess = async () => {
        if(!params.idLab) return 
        if (dashboardHook.dashboardType === 'normal') {
            const response = await laboratoryService.validateAccess({lab: params.idLab})
            if (response?.allow) {
                updateValidAccess(response.allow);
                updateLaboratory(response.reservation.lab);
                dispatch(DashboardActions.setReservationAction(response.reservation));
                dispatch(DashboardActions.setReservationsAction(response.reservations));
            } else {
                dispatch(DashboardActions.setReservationAction(undefined));
                updateValidAccess(false);
                updateLaboratory(undefined);
            }
        } else if(dashboardHook.dashboardType === 'only_view') {
            const response = await reservationsService.verifyPublished({id: params.idLab})
            if (response) {
                updateValidAccess(true);
                updateLaboratory(response.lab);
                dispatch(DashboardActions.setReservationAction(response));
            } else {
                dispatch(DashboardActions.setReservationAction(undefined));
                updateValidAccess(false);
                updateLaboratory(undefined);
            }
        }
        updateLoading(false);
    }

    /**
     * @INFO Obtener el componente a renderizar segun la categoria de la Tool
     * @param _tool 
     */
    const getToolLenderByCategory = (_tool: ToolTypes.ITool) => {
        if(_tool?.category?.config?.isLineChart || _tool?.category?.config?.isBarChart){
            return (
                <ContainerModuleDashboard
                    tool={_tool}
                    title={_tool.displayTitle}
                    className='contianer-chart-tool'
                    showFilterReport={dashboardHook?.reservation?.publicSettings?.chart?.enableReports || dashboardHook.dashboardType === 'normal'}
                    showFilterData={dashboardHook?.reservation?.publicSettings?.chart?.enableFilterData || dashboardHook.dashboardType === 'normal'}
                >
                    <ChartToolDashboard
                        tool={_tool}
                    />
                </ContainerModuleDashboard>
            )
        }
        if(_tool?.category?.config?.isCamera){
            return (
                <ContainerModuleDashboard
                    tool={_tool}
                    title={_tool.displayTitle}
                >
                    <CameraModuleDashboard
                        tool={_tool}
                    />
                </ContainerModuleDashboard>
            )
        }   
        if(_tool?.category?.config?.isSource){
            return (
                <ContainerModuleDashboard
                    tool={_tool}
                    title={_tool.displayTitle}
                >
                    <SourceModuleDashboard
                        tool={_tool}
                    />
                </ContainerModuleDashboard>
            )
        }
        if(_tool?.category?.config?.isButton){
            return (
                <ContainerModuleDashboard
                    tool={_tool}
                    title={_tool.displayTitle}
                >
                    <ButtonModuleDashboard
                        tool={_tool}
                    />
                </ContainerModuleDashboard>
            )
        }
        if(_tool?.category?.config?.isPushbutton){
            return (
                <ContainerModuleDashboard
                    tool={_tool}
                    title={_tool.displayTitle}
                >
                    <PushButtonModuleDashboard
                        tool={_tool}
                    />
                </ContainerModuleDashboard>
            )
        }
        if(_tool?.category?.config?.isCodeEditor){
            return (
                <ContainerModuleDashboard
                    tool={_tool}
                    title={_tool.displayTitle}
                >
                    <CodeEditorModuleDashboard
                        tool={_tool}
                        tools={tools}
                    />
                </ContainerModuleDashboard>
            )
        }
        if(_tool?.category?.config?.isRoboticArm){
            return (
                <ContainerModuleDashboard
                    tool={_tool}
                    title={_tool.displayTitle}
                >
                    <XarmModuleDashboard
                        tool={_tool}
                    />
                </ContainerModuleDashboard>
            )
        }
        if(_tool?.category?.config?.isImage){
            return (
                <ContainerModuleDashboard
                    tool={_tool}
                    title={_tool.displayTitle}
                >
                    <ImageModuleDashboard
                        tool={_tool}
                    />
                </ContainerModuleDashboard>
            )
        }
        if(_tool?.category?.config?.isTable){
            return (
                <ContainerModuleDashboard
                    tool={_tool}
                    title={_tool.displayTitle}
                >
                    <TableModuleDashboard
                        tool={_tool}
                    />
                </ContainerModuleDashboard>
            )
        }

        return null
    }

    const generateLayout = () => {
        const components: any[] = [];
        let currentX = 0;
        const _labLayout: Layout[] = [];
        toolsToRender.filter((t) => t.show).map((_tool) => {
            let layoutProperties = dashboardLaboratoryFunctions.getToolLayoutPropertiesFromCategory(_tool, currentX);
            if ( reservation?.layout?.length) {
                const auxLayout = reservation.layout?.find((l) => l.i === _tool.id);
                if (auxLayout) {
                    layoutProperties = {...auxLayout};
                }
            }
            _labLayout.push(layoutProperties);
            currentX = layoutProperties.w + layoutProperties.x;
            components.push((
                <div
                    key={_tool.id}
                    data-grid={layoutProperties}
                >
                    {getToolLenderByCategory(_tool)}
                </div>
            ))
        });
        // Entorno de realidad virtual
        // Notebook
        return components;
    }

    /**
     * @INFO Termina el timer, se debe realizar una accion segun el timer terminado
     * @param type 
     */
    const handleEndTimer = (type: 'hardware' | 'laboratory') => {
        if (type === 'hardware') {

        } else if (type === 'laboratory') {
            // Redirigir a pantalla de finalizacion de laboratorio para dejar recomendaciones y calificación
            if (props.history) {
                props.history.push(`/feedback-lab/${laboratory?.id}`);
            }
        }
    }

    const handleClickPublish = async () => {
        updateOpenPublicReservation(true);
    }

    const handlePublishedLab = () => {
        updateOpenPublicReservation(false);
        screenNotification.showSuccessTimer();
    }

    const isDashboardsSelectorEnabled = dashboardConfig?.dashboardsSelector !== undefined ? dashboardConfig?.dashboardsSelector : true
    const isPublicInfoEnabled = dashboardConfig?.publicInfo !== undefined ? dashboardConfig?.publicInfo : true

    return(
        <AppLayout
            history={props.history}
            headerTitle='Laboratorios virtuales'
        >
            <div
                className={`dashboard_laboratory_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
                ref={container.ref}
                key={reservation?.id}
            >
                {validAccess ? 
                    <>
                        {reservation?.id && dashboardHook.dashboardType === 'normal' ?
                            <>
                                {isDashboardsSelectorEnabled && (
                                    <div style={{ marginRight: 'auto' }}>
                                        <RenderDashboardItems reservations={reservations} />
                                    </div>
                                )}
                                {isPublicInfoEnabled && (
                                    <div className="container-published-info">
                                        {reservation?.published ?
                                            <div>
                                                <TextComponent
                                                    type='label'
                                                >
                                                    Dashboard público
                                                </TextComponent>
                                                <CopyTextComponent
                                                    text={`${DASHBOARD_SITE}/dashboard-laboratory/${reservation?.id}`}
                                                    className='copy-text-dashboard'
                                                    linkToText
                                                />
                                            </div>
                                        :null}
                                        <ButtonComponent
                                            onClick={handleClickPublish}
                                            variant='primary'
                                        >
                                            <ButtonComponent.Icon>
                                                <MdPublish/>
                                            </ButtonComponent.Icon>
                                            Publicar
                                        </ButtonComponent>
                                    </div>                                  
                                )}
                            </>
                        :null}
                        <div className="container-timer-header-dashboard">
                            {laboratory?.category?.config?.hasHardwareDuration && laboratory?.config?.hardwareDuration ?
                                <TimerComponent
                                    size='small'
                                    limitTime={laboratory.config.hardwareDuration}
                                    onEndTimer={() => handleEndTimer('hardware')}
                                />
                            : null}
                            {laboratory?.category?.config?.hasDuration && laboratory?.config?.duration ?
                                <TimerComponent
                                    size='small'
                                    limitTime={laboratory.config.duration}
                                    onEndTimer={() => handleEndTimer('laboratory')}
                                    initialDate={reservation?.date}
                                />
                            : null}
                            {tools?.length && reservation ?
                                <ToolBarDashboard
                                    tools={tools}
                                    className='tool-bar-dashboard'
                                    defaultLayout={reservation?.layout}
                                    onChangeToolsToRender={(newTools) => updateToolsToRender(newTools)}
                                />
                            :null}
                        </div>
                        {laboratory?.config?.virtualReality?.resources?.web ?
                            <ContainerModuleDashboard
                                title='Realidad virtual'
                            >
                                <VRContainer
                                    url={laboratory?.config?.virtualReality?.resources?.web}
                                    main
                                />
                            </ContainerModuleDashboard>
                        : null}
                        {laboratory?.id && laboratory?.category?.name === 'Computación en la nube' ?
                            <ContainerModuleDashboard
                                title='Notebook'
                            >
                                <NotebookDashboard
                                    laboratory={laboratory}
                                />
                            </ContainerModuleDashboard>
                        :null}
                        {toolsToRender?.length && reservation ?
                            <ReactGridLayout
                                cols={40}
                                rowHeight={30}
                                width={container.width ? container.width : 1200}
                                style={{ width: '100%' }}
                                className='layout'
                                onLayoutChange={(lab) => handleChangeLayout(lab)}
                                isDraggable={dashboardHook.dashboardType === 'normal' ? true : false}
                                isResizable={dashboardHook.dashboardType === 'normal' ? true : false}
                                draggableHandle={'.drag-dashboard-element'}
                            >
                                {generateLayout()}
                            </ReactGridLayout>
                        :null}
                    </>
                : loading ?
                    <PreparingLoading/>
                :
                    <div
                        className='not-allowed-content'
                    >
                        <TextComponent
                            type='h3'
                        >
                            No tienes acceso a este contenido...
                        </TextComponent>
                        <img src={NotAllowedImg} alt="" />
                    </div>
                }
                <ModalPublicSettings
                    open={openPublicReservation}
                    onClose={() => updateOpenPublicReservation(false)}
                    onSaved={() => handlePublishedLab()}
                />
            </div>
        </AppLayout>
    );
}

export default DashboardLaboratoryPage;