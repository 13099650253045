// import { useColors } from 'hooks/useColors';
import { IPositionHelpSteps, ITypeTypesHelpSteps, IStepHelpSteps } from 'types/helpSteps/helpStepsTypes';
import React, { useState, useEffect, CSSProperties, Fragment } from 'react';
import { IoMdHelpCircleOutline } from 'react-icons/io';
import Joyride, {
  Styles,
  Step,
  CallBackProps,
  Placement
} from 'react-joyride'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import './../helpSteps.scss'
import { useTranslation } from 'react-i18next';
import ClickButtonForNext from './components/clickButtonForNext';
import ColorsService from 'services/colors/colorsService';

interface IElementToClick{
  target: string
  callback: any
}

interface IOptionsChangeStep{
  type: 'next' | 'prev'
  currentStep: number
  totalSteps: number
  step?: IStepHelpSteps
  steps?: IStepHelpSteps[]
}

interface IHelpStepsLayoutProps {
  steps: IStepHelpSteps[]
  styleJoyride?: Styles
  className?: string
  style?: CSSProperties
  continuous?: boolean
  showProgress?: boolean
  disableOverlayClose?: boolean
  showSkipButton?: boolean
  position?: IPositionHelpSteps    // Posición en la pantalla, debe estar el prop fixed=true
  type?: ITypeTypesHelpSteps  // Estilos por defecto para el componente por defecto 'floating'
  fixed?: boolean   // Si se establece en true se puede utilizar el prop 'position' con cualquier 'type'
  onUpdate?:(data?: CallBackProps)=> void
  onNext?:(data?: CallBackProps)=> void
  onReset?:(data?: CallBackProps)=> void
  onClose?:(data?: CallBackProps)=> void
  onPrev?:(data?: CallBackProps)=> void
}

const HelpStepsLayout: React.FC<IHelpStepsLayoutProps> = (props) => {

  const [steps, updateSteps] = useState<Step[]>([])
  const [run, updateRun] = useState<boolean>(false)
  const [stepIndex, updateStepIndex] = useState<number>(0)
  const [elementsClick, updateElementsClick] = useState<IElementToClick[]>([])
  const [triggerStepIndex, updateTriggerStepIndex] = useState<object>({})
  const [clicked, updateClicked] = useState<boolean>(false)
  // const [colors] = useColors()
  const {t} = useTranslation()

  // @INFO Servicios
  const colorsService = new ColorsService()

  /**
   * @INFO Actualizar los step validos
   */
  useEffect(() => {
    if(props.steps?.length){
      setTimeout(() => {
        // Incluir solo los pasos que tienen un target valido
        const newSteps = validateSteps([...props.steps])
        updateSteps(newSteps)
      }, 200)
    }
  }, [props.steps])

  /**
   * @INFO Trigger para avanzar de step
   */
  useEffect(() => {
    if(run){
      const size = steps.length
      // console.log('Callback button: ', size, stepIndex);
      if(stepIndex < (size-1)){
        updateStepIndex((stepIndex+1))
      }
    }
  },[triggerStepIndex])

  /**
   * @INFO Eliminar listeners de click
   */
  const removeListeners = () => {
    if(elementsClick.length){
      elementsClick.forEach((element, idx) => {
        document.querySelector(elementsClick[idx].target)?.removeEventListener('click', elementsClick[idx].callback)
      })
    }
  }

  /**
   * @INFO Click en elemento personalizado para avanzar de step
   */
  const handleClickNext = () => {
    updateClicked(true)
    setTimeout(() => {
      updateTriggerStepIndex({})
      // console.log('Callback button');
    }, 200)
  }

  /**
   * @INFO Validar los pasos que tienen target validos
   * @param _steps
   */
  const validateSteps = (_steps: IStepHelpSteps[]) => {
    removeListeners()
    const auxElementsClick: IElementToClick[] = []
    const defaultPlacement: Placement | undefined = 'left'
    const defaultPlacementBeacon: Placement | undefined = undefined
    let isValid: boolean = false    // Sirve para agregar el siguiente elemento sin verificar existencia
    const response: Step[] = _steps.reduce((acum: Step[], item) => {
      if(item.target){
        if(isValid){
          isValid = false
          acum.push({
            placement: defaultPlacement,
            placementBeacon: defaultPlacementBeacon,
            ...item,
          })
        }else if(typeof item.target === 'string'){
          const element = document.querySelector(item.target)
          if(element){
            let newStep: Step
            // Revisar las acciones custom
            if(item.custom?.clickForNextStep?.enable){
              const copyItem = {...item}
              delete copyItem.content
              const newContent = item.custom?.clickForNextStep?.content
              newStep = {
                placement: defaultPlacement,
                placementBeacon: defaultPlacementBeacon,
                spotlightClicks: true,
                styles: {
                  buttonNext: {
                    display: 'none'
                  }
                },
                ...copyItem,
                content: (
                  newContent ? newContent :
                  <ClickButtonForNext
                    onClick={()=>{}}
                  />
                ),
              }
              const newElementClick: IElementToClick = {
                target: copyItem.target as string,
                callback: handleClickNext
              }
              element.addEventListener('click', newElementClick.callback)
              auxElementsClick.push(newElementClick)
              isValid=true
            }else{
              newStep = {
                placement: defaultPlacement,
                placementBeacon: defaultPlacementBeacon,
                ...item,
              }
            }
            if(!acum.length) newStep.disableBeacon = true
            acum.push(newStep)
          }
        } // !Importante: Falta el caso donde el target es HTML (De preferencia utilizar selectores por ID)
      }
      return acum
    }, [])
    if(!response.length){
      updateRun(false)
    }
    updateElementsClick(auxElementsClick)
    return response
  }

  /**
   * @INFO Cambiar el paso teniendo en cuenta la configuración custom
   * @param data
   */
  const changeStep = (data: IOptionsChangeStep) => {
    // Encontrar el siguiente paso
    let step: IStepHelpSteps | undefined = undefined
    const auxSteps = data.steps ? data.steps : steps
    if(data.type === 'prev'){
      if(data.currentStep > 0){
        step = auxSteps[data.currentStep-1]
      }
    }else if(data.type === 'next'){
      if(data.currentStep < (data.totalSteps-1)){
        step = auxSteps[data.currentStep+1]
      }
    }
    // Ejecutar acciones de [custom.before]
    if(step?.custom?.before){
      if(step?.custom?.before.clickElement){
        const elementToClick = step.custom?.before.clickElement
        const queryElement = document.querySelector(elementToClick.target)
        if(queryElement){
          // @ts-ignore
          queryElement.click()
        }
      }
    }
    // Incrementar o decrementar el index teniendo en cuenta el delay
    let delayStep: number = 0
    if(step?.custom?.before?.clickElement?.delay){
      delayStep = step?.custom?.before?.clickElement?.delay
    }
    if(delayStep){
      setTimeout(() => {
        if(data.type === 'next'){
          if(data.currentStep < (data.totalSteps-1)){
            updateStepIndex(data.currentStep+1)
          }else{
            updateStepIndex(0)
            updateRun(false)
          }
        }else if(data.type === 'prev'){
          if(data.currentStep > 0){
            updateStepIndex(data.currentStep - 1)
          }else{
            updateStepIndex(0)
            updateRun(false)
          }
        }
      }, delayStep)
    }else{
      if(data.type === 'next'){
        if(data.currentStep < (data.totalSteps-1)){
          updateStepIndex(data.currentStep+1)
        }else{
          updateStepIndex(0)
          updateRun(false)
        }
      }else if(data.type === 'prev'){
        if(data.currentStep > 0){
          updateStepIndex(data.currentStep - 1)
        }else{
          updateStepIndex(0)
          updateRun(false)
        }
      }
    }
  }

  /**
   * @INFO Referencia para mandar callback en las acciones del modulo ayuda
   * @param data
   */
  const handleCallback = (data: CallBackProps) => {
    // console.log('Callback: ', data);
    switch (data.action) {
      case 'prev':
        const sendPrevData: IOptionsChangeStep = {
          type: 'prev',
          currentStep: data.index,
          totalSteps: data.size,
          step: data.step
        }
        if( data.lifecycle === 'complete'){
          changeStep(sendPrevData)
        }else if(data.lifecycle === 'ready'){
          if(data.type.includes('error')){
            changeStep(sendPrevData)
          }
        }
        if(props.onPrev)
        props.onPrev(data)
        break
      case 'next':
        if(clicked){
          updateClicked(false)
        }else{
          const sendNextData: IOptionsChangeStep = {
            type: 'next',
            currentStep: data.index,
            totalSteps: data.size,
            step: data.step
          }
          if( data.lifecycle === 'complete'){
            changeStep(sendNextData)
          }else if(data.lifecycle === 'ready'){
            if(data.type.includes('error')){
              changeStep(sendNextData)
            }
          }
        }
        if(props.onNext)
        props.onNext(data)
        break
      case 'reset':
        if(props.onReset)
        props.onReset(data)
        break
      case 'close':
        updateRun(false)
        updateStepIndex(0)
        if(props.onClose)
        props.onClose(data)
        break
      case 'skip':
        updateRun(false)
        updateStepIndex(0)
        break
      case 'update':
        if(props.onUpdate)
        props.onUpdate(data)
        break
      case 'start':
        const sendNextData: IOptionsChangeStep = {
          type: 'next',
          currentStep: data.index,
          totalSteps: data.size,
          step: data.step
        }
        if(data.lifecycle === 'ready'){
          if(data.type.includes('error')){
            changeStep(sendNextData)
          }
        }
        break
      default:
        break
    }
  }

  /**
   * @INFO Obtener la posición según los props
   * @returns posición en CSS inline
   */
  const getPosition = () => {
    let response: CSSProperties = {}
    const sizeOffset = '40px'
    if(props.fixed || props.type === undefined || props.type === 'sidebar-icon'){
      if(props.type === undefined || props.type === 'sidebar-icon'){
        response = {
          left: '10px',
          bottom: '30px'
        }
      }else{
        response = {
          left: sizeOffset,
          bottom: sizeOffset
        }
      }
      if(props.position){
        if(props.position.vertical){
          switch(props.position.vertical){
            case 'top':
              response.bottom = undefined
              response.top = sizeOffset
              break;
            case 'middle':
              response.bottom = undefined
              response.top = 'calc(50vh - 30px)'
              break;
            case 'bottom':
              response.bottom = sizeOffset
              response.top = undefined
              break;
            default:
              break;
          }
        }
        if(props.position.horizontal){
          switch(props.position.horizontal){
            case 'start':
              response.right = undefined
              response.left = sizeOffset
              break;
            case 'middle':
              response.right = undefined
              response.left = 'calc(50vw - 30px)'
              break;
            case 'end':
              response.right = sizeOffset
              response.left = undefined
              break;
            default:
              break;
          }
        }
        const aux = {...props.position}
        delete aux.vertical
        delete aux.horizontal
        response = {
          ...response,
          ...aux
        }

      }
    }

    return response
  }

  /**
   * @INFO Obtener el componente según el tipo
   * @param type
   */
  const getComponentFromType = (_type?: ITypeTypesHelpSteps) => {
    let type: ITypeTypesHelpSteps = 'sidebar-icon'
    if(_type){
      type = _type
    }
    switch(type){
      case 'floating':
        return(
          <button
            onClick={handleClickRun}
            className={`btn float-help-button ${props.fixed ? 'fixed_help-position' : ''} ${props.className}`}
            style={{
              ...props.style,
              ...getPosition()
            }}
          >
            <IoMdHelpCircleOutline className='icon-help' />
          </button>
        )
      case 'sidebar-icon':
        return(
          <button
            onClick={handleClickRun}
            className={`btn btn_help-sidebar fixed_help-position ${props.className}`}
            style={{
              ...props.style,
              ...getPosition()
            }}
          >
            <OverlayTrigger
              placement='right'
              overlay={<Tooltip id='tooltip_help_module' >{t('help_module.tooltip')}</Tooltip>}
            >
              <IoMdHelpCircleOutline className='icon-help' />
            </OverlayTrigger>
          </button>
        )
      case 'button':
        return(
          <button
            onClick={handleClickRun}
            className={`btn btn-primary ${props.fixed ? 'fixed_help-position' : ''} ${props.className}`}
            style={{
              ...props.style,
              ...getPosition()
            }}
          >
            {props.children}
          </button>
        )
      case 'div':
        return(
          <div
            onClick={handleClickRun}
            className={`${props.fixed ? 'fixed_help-position' : ''} ${props.className}`}
            style={{
              ...props.style,
              ...getPosition()
            }}
          >
            {props.children}
          </div>
        )
      default:
        return(
          null
        )
    }
  }

  /**
   * @INFO Actualizar el estado de run con clic
   */
  const handleClickRun = () => {
    if(props.steps?.length){
      if(run){
        updateRun(false)
      }else{
        // Incluir solo los pasos que tienen un target valido
        const newSteps = validateSteps([...props.steps])
        updateSteps(newSteps)
        const startData: IOptionsChangeStep = {
          currentStep: -1,
          type: 'next',
          totalSteps: newSteps.length,
          steps: newSteps
        }
        changeStep(startData)
        updateRun(true)
      }
    }else{
      updateRun(false)
    }
  }

    return (
        <Fragment>
            {steps.length ?
            <>
              {run ?
              <Joyride
                steps={steps}
                styles={
                  {
                    options: {
                      primaryColor: colorsService.getColorFromCSS('--primary')
                    },
                    ...props.styleJoyride
                  }
                }
                callback={handleCallback}
                run={run}
                continuous={props.continuous !== undefined ? props.continuous : true}
                showProgress={props.showProgress !== undefined ? props.showProgress : true}
                disableOverlayClose={props.disableOverlayClose !== undefined ? props.disableOverlayClose : true}
                showSkipButton={props.showSkipButton !== undefined ? props.showSkipButton : true}
                scrollOffset={80}
                // scrollToFirstStep
                locale={{
                  next: `${t('help_module.labels.next')}`,
                  close: `${t('help_module.labels.close')}`,
                  last: `${t('help_module.labels.last')}`,
                  skip: `${t('help_module.labels.skip')}`,
                  back: `${t('help_module.labels.back')}`,
                }}
                stepIndex={stepIndex}
              />: null}
              {getComponentFromType(props.type)}
            </>
             : null}
        </Fragment>
    );
};

export default HelpStepsLayout;
