import React, {CSSProperties, ChangeEvent, KeyboardEventHandler} from 'react'
import LabelComponent from '../LabelComponent/LabelComponent'
import './InputComponent.scss'

export type ITypesInputComponent = 'text' | 'number' | 'date' | 'password' | 'file' | 'textarea' | 'time' | 'datetime-local' | 'datetime'
export interface InputComponentProps {
    className?: string
    classNameInput?: string
    style?: CSSProperties
    styleInput?: CSSProperties
    value?: string
    type?: ITypesInputComponent
    name?: string
    ref?: any
    placeholder?: string
    disabled?: boolean
    required?: boolean
    children?: any
    valid?: boolean
    validText?: string
    invalid?: boolean
    invalidText?: string
    label?: string
    hint?: string
    background?: 'background-login' | 'background' | 'background2' | 'background-input'
    id?: string
    onChange?: (e: ChangeEvent<HTMLInputElement>)=>any
    onEnter?: (value?: string)=>any
}

interface IInputComponentSubComponents{
    Icon: React.FC<IIconInputProps>
}
 
const InputComponent: React.FC<InputComponentProps> & IInputComponentSubComponents = (props) => {

    const handleKeyUp = (e: any) => {
        if(props.onEnter){
            if(e.key === 'Enter' || e.keyCode === 13){
                props.onEnter(e.target.value)
            }
        }
    }

    return (  
        <div
            className={`input-group input_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            {props.label ?
                <LabelComponent>
                    {props.label}
                </LabelComponent>
            :null}
            <div
                className='container-inputs_component-layout'
            >
                {props.children}
                {props.type !== 'textarea' ?
                    <input 
                        type={props.type ? props.type : 'text'} 
                        className={`form-control ${props.valid ? 'is-valid' : ''} ${props.invalid ? 'is-invalid' : ''} ${props.children ? 'icon-spacing' : ''} ${props.classNameInput ? props.classNameInput : ''}`} 
                        placeholder={props.placeholder}
                        ref={props.ref}
                        onChange={props.onChange}
                        name={props.name}
                        value={props.value}
                        disabled={props.disabled}
                        required={props.required}
                        id={props.id}
                        onKeyUp={(e) => handleKeyUp(e)}
                        style={{
                            backgroundColor: props.background ? `var(--${props.background})` : undefined,
                            ...props.styleInput
                        }}
                    />
                :
                    <textarea 
                        className={`form-control ${props.valid ? 'is-valid' : ''} ${props.invalid ? 'is-invalid' : ''} ${props.children ? 'icon-spacing' : ''} ${props.classNameInput ? props.classNameInput : ''}`} 
                        placeholder={props.placeholder}
                        ref={props.ref}
                        onChange={props.onChange as any}
                        name={props.name}
                        value={props.value}
                        disabled={props.disabled}
                        required={props.required}
                        id={props.id}
                        onKeyUp={(e) => handleKeyUp(e)}
                        style={{
                            backgroundColor: props.background ? `var(--${props.background})` : undefined,
                            ...props.styleInput
                        }}
                    /> 
                }
                {props.valid && props.validText ? 
                    <div className="valid-feedback">
                        {props.validText}
                    </div>
                : null}
                {props.invalid && props.invalidText ? 
                    <div className="invalid-feedback">
                        {props.invalidText}
                    </div>
                : null}
            </div>
            {props.hint ?
                <LabelComponent>
                    {props.hint}
                </LabelComponent>
            :null}
        </div>
    );
}

interface IIconInputProps{
    children?: any
    className?: string
    style?: CSSProperties
}

const Icon: React.FC<IIconInputProps> = (props) => {
    return(
        <div className="container_icon-input">
            {props.children}
        </div>
    )
}

InputComponent.Icon = Icon
 
export default InputComponent;