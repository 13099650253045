// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import MultiSelectComponent from 'components/commons/MultiSelectComponent/MultiSelectComponent';
// @end components

// @import types
import * as ToolCategoryTypes from 'types/toolCategory/toolCategory.types';
import { IOptionSelect } from 'components/commons/MultiSelectComponent/MultiSelectComponent';
// @end types

// @import services
import ToolCategoryService from 'services/toolCategory/toolCategoryService'
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ToolCategoriesSelect.scss'
// @end styles

interface IToolCategoriesSelectProps {
    className?: string
    style?: CSSProperties
    id?: string
    label?: string
    defaultValue?: string[]
    onChange?: (id?: string[], toolCategories?: ToolCategoryTypes.IToolCategory[]) => any
    onChangeOne?: (id?: string, toolCategory?: ToolCategoryTypes.IToolCategory) => any
}

const ToolCategoriesSelect: React.FC<IToolCategoriesSelectProps> = (props) => {

    const [toolCategories, updateToolCategories] = useState<ToolCategoryTypes.IToolCategory[]>([])
    const [resetSelect, updateResetSelect] = useState<boolean>(false)

    // @INFO Servicios
    const toolCategoryService = new ToolCategoryService()

    useEffect(() => {
        getToolCategories()
    },[])

    useEffect(() => {
        updateResetSelect(true)
        setTimeout(() => {
          updateResetSelect(false)
        },1)
    }, [toolCategories])
    

    /**
     * @INFO Obtener las categorías de herramientas
     */
    const getToolCategories = async () => {
        const _categories = await toolCategoryService.list()
        updateToolCategories(_categories)
    }

    /**
     * @INFO Cambia el selector 
     * @param _options 
     */
    const handleChangeSelect = (_options: IOptionSelect[]) => {
        const _tools = toolCategories?.filter((_t) => _options.find((_o) => _o.value === _t.id))
        const _tool = _tools?.length ? _tools[0] : undefined
        if(props.onChangeOne){
            props.onChangeOne(_tool?.id, _tool)
        }
        if(props.onChange){
            props.onChange(_tools?.map((_t) => _t.id) as string[], _tools)
        }
    }

    /**
     * @INFO Obtener las opciones por defecto
     * @returns 
     */
    const getDefaultOptions = () => {
        const _options = toolCategories?.reduce((accum: IOptionSelect[], item) => {
            if(props.defaultValue?.find((_d) => _d === item.id)){
                accum.push({
                    label: item.name ? item.name : '',
                    value: item.id ? item.id : ''
                })
            }
            return accum
        },[])
        return _options
    }

    return(
        <div
            className={`tool_categories_select-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            {!resetSelect ?
                <MultiSelectComponent
                    options={toolCategories.map((_cat) => { return {label: _cat.name ? _cat.name : '', value: _cat.id ? _cat.id : ''} })}
                    disableMulti
                    label={props.label}
                    defaultValue={getDefaultOptions()}
                    onChange={(data) => handleChangeSelect(data)}
                />
            :null}
        </div>
    );
}

export default ToolCategoriesSelect;