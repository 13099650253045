// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import MultiSelectComponent from 'components/commons/MultiSelectComponent/MultiSelectComponent';
// @end components

// @import types
import { IOptionSelect } from 'components/commons/MultiSelectComponent/MultiSelectComponent';
import * as ToolTypes from 'types/tool/tool.types';
// @end types

// @import services
import ToolService from 'services/tool/toolService'
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ToolSelectModule.scss'
// @end styles

interface IToolSelectModuleProps {
    className?: string
    style?: CSSProperties
    id?: string
    label?: string
    defaultValue?: string[]
    isMultiple?: boolean
    onChange?: (id?: string[], toolCategories?: ToolTypes.ITool[]) => any
    onChangeOne?: (id?: string, toolCategory?: ToolTypes.ITool) => any
}

const ToolSelectModule: React.FC<IToolSelectModuleProps> = (props) => {

    const [tools, updateTools] = useState<ToolTypes.ITool[]>([])
    const [resetSelect, updateResetSelect] = useState<boolean>(false)

    // @INFO Servicios
    const toolService = new ToolService()

    useEffect(() => {
        getTools()
    },[])

    useEffect(() => {
        updateResetSelect(true)
        setTimeout(() => {
          updateResetSelect(false)
        },1)
    }, [tools, props.defaultValue?.length])

    /**
     * @INFO Obtener las herramientas
     */
    const getTools = async () => {
        const _tools = await toolService.list()
        updateTools(_tools)
    }

    /**
     * @INFO Cambia el selector 
     * @param _options 
     */
    const handleChangeSelect = (_options: IOptionSelect[]) => {
        const _tools = tools?.filter((_t) => _options.find((_o) => _o.value === _t.id))
        const _tool = _tools?.length ? _tools[0] : undefined
        if(props.onChangeOne){
            props.onChangeOne(_tool?.id, _tool)
        }
        if(props.onChange){
            props.onChange(_tools?.map((_t) => _t.id) as string[], _tools)
        }
    }

    /**
     * @INFO Obtener las opciones por defecto
     * @returns 
     */
    const getDefaultOptions = () => {
        const _options = tools?.reduce((accum: IOptionSelect[], item) => {
            if(props.defaultValue?.find((_d) => _d === item.id)){
                accum.push({
                    label: item.name ? item.name : '',
                    value: item.id ? item.id : ''
                })
            }
            return accum
        },[])
        return _options
    }
    
    return(
        <div
            className={`tool_select_module-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            {!resetSelect ?
                <MultiSelectComponent
                    options={tools.map((_cat) => { return {label: _cat.name ? _cat.name : '', value: _cat.id ? _cat.id : ''} })}
                    disableMulti={!props.isMultiple}
                    label={props.label}
                    defaultValue={getDefaultOptions()}
                    onChange={(data) => handleChangeSelect(data)}
                />
            :null}
        </div>
    );
}

export default ToolSelectModule;