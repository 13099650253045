// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
import shortId from 'shortid';
// @end dependencies

// @import components
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import ChartComponent from 'components/commons/ChartComponent/ChartComponent';
import PanelComponent from 'components/commons/PanelComponent/PanelComponent';
// @end components

// @import types
import * as ToolTypes from 'types/tool/tool.types'
import { ChildrenModuleDashboardData } from '../ContainerModuleDashboard/ContainerModuleDashboard';
// @end types

// @import services
import SocketService from 'services/socket/socketService';
import DataService from 'services/data/dataService';
// @end services

// @import hooks
import { useResizeDetector } from 'react-resize-detector';
import { useDashboard } from 'hooks/useDashboard/useDashboard';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ChartToolDashboard.scss'
// @end styles

interface IChartToolDashboardProps {
    tool: ToolTypes.ITool
    className?: string
    style?: CSSProperties
    id?: string
    data?: any[]
    onChangeData?: (data: ChildrenModuleDashboardData) => void;    
}

export interface IGraphItem{
    name: string
    chartLabel: string
    show: boolean
    variableKey: string
    data: any[]
    _id: string
    limits?: ToolTypes.RangeValue
}

const ChartToolDashboard: React.FC<IChartToolDashboardProps> = (props) => {

    const [data, updateData] = useState<any[]>([])
    const [charts, updateCharts] = useState<IGraphItem[]>([])
    const [labels, updateLabels] = useState<any[]>([])
    const [triggerNewData, updateTriggerNewData] = useState<any>(undefined)
    const containerCharts = useResizeDetector();
    const dashboardHook = useDashboard();

    // @INFO Servicios
    const socketService = new SocketService()
    const dataService = new DataService()

    useEffect(() => {
        const newCharts: IGraphItem[] = []
        props.tool?.config?.variables?.forEach((_var) => {
            newCharts.push({
                name: _var.name,
                chartLabel: _var.unit,
                show: true,
                variableKey: _var.variableKey,
                limits: _var.limits,
                data: [],
                _id: shortId.generate()
            })
        })
        updateCharts([...newCharts])
        // @INFO Suscribirme al evento
        if(props.tool?.category?.config?.hasEvent && props.tool.config?.event){
            socketService.listenEvent(props.tool.config?.event, handleNewData)
        }
        return () => {
            if(props.tool?.category?.config?.hasEvent && props.tool.config?.event){
                socketService.removeListenEvent(props.tool.config?.event, handleNewData)
            }
        }
    },[])

    useEffect(() => {
        if(triggerNewData){
            updateData([
                ...data,
                {...triggerNewData}
            ])
            updateTriggerNewData(undefined)
        }
    },[triggerNewData])

    useEffect(() => {
        // @INFO Organizar la data para graficar
        if(data.length){
            updateLabels(dataService.getDateFormate([...data]))
            const newCharts = [...charts]
            newCharts.forEach((_ch, _idx) => {
                newCharts[_idx].data = dataService.filterVariablesFromData([...data], `data.${_ch.variableKey}`)
            })
            updateCharts([...newCharts])
        }
    },[data])

    useEffect(() => {
        if (props.onChangeData) {
            props.onChangeData({
                charts: charts
            })
        }
    }, [charts])

    useEffect(() => {
        if (props.data?.length) {
            updateData(props.data);
        }
    }, [props.data])

    /**
     * @INFO Click para ver una grafica
     * @param _id 
     */
    const handleClickGraphic = (_id: string) => {
        const _idx = charts.findIndex((_g) => _g._id === _id)
        if(_idx >= 0){
            const newGraphics = [...charts]
            newGraphics[_idx].show = !newGraphics[_idx].show
            updateCharts([...newGraphics])
        }
    }

    /**
     * @INFO Llega un nuevo dato del socket
     * @param _data 
     */
    const handleNewData = (_data: any) => {
        if(_data){
            updateTriggerNewData(_data)
        }
    }

    const getStyleOfColumns = () => {
        const style: CSSProperties = {
            gridTemplateColumns: 'repeat(2, calc(50% - 8px))'
        };
        const width = containerCharts.width || 600;
        if(width < 990){
            style.gridTemplateColumns = '100%'
        }
        return style;
    }

    const getChartType = () => {
        if (props.tool?.category?.config?.isLineChart) {
            return ToolTypes.ChartType.LINE
        }
        if (props.tool?.category?.config?.isBarChart) {
            return ToolTypes.ChartType.BAR
        }
    }

    return(
        <div
            className={`chart_tool_dashboard-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >       
            <PanelComponent
                height={'100%'}
                panelWidth='250px'
                collapse={dashboardHook.reservation?.publicSettings?.chart?.enableSidebar || dashboardHook.dashboardType === 'normal' ? true : false}
                defaultOpen={false}
                panel={
                    <div className="container-list-buttons">
                        {charts.map((_g) => (
                            <ItemGraphicButton
                                key={_g._id}
                                graph={_g}
                                onClick={() => handleClickGraphic(_g._id)}
                            />
                        ))}
                    </div>
                }
            >
                <div 
                    ref={containerCharts.ref} 
                    className="container-charts-component"
                    style={getStyleOfColumns()}
                >
                    {charts.filter((_g) => _g.show).map((_g) => (
                        <ChartComponent
                            key={_g._id}
                            label={_g.name}
                            chartLabel={_g.chartLabel}
                            data={_g.data}
                            labels={labels}
                            style={{ width: '100%' }}
                            limits={_g.limits}
                            type={getChartType()}
                        />
                    ))}
                </div>
            </PanelComponent>
        </div>
    );
}

// =========================================================
// @INFO Item de boton para seleccionar grafica
// =========================================================
interface IItemGraphicButtonProps {
    graph: IGraphItem
    className?: string
    style?: CSSProperties
    id?: string
    onClick?: () => any
}

const ItemGraphicButton: React.FC<IItemGraphicButtonProps> = (props) => {
    return(
        <div
            className={`item_graphic_button-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <ButtonComponent
                onClick={props.onClick}
                variant={props.graph.show ? 'primary2' : undefined}
            >
                {props.graph.name}
            </ButtonComponent>
        </div>
    );
}

export default ChartToolDashboard;