import stepsHome from './screens/home'


/**
 * @INFO Pasos de las diferentes screens
 * * IMPORTANTE: el nombre de la propiedad debe tener el mismo nombre que el type
 */
const stepsData = {
  home: stepsHome
}

export default stepsData
