import { FC } from 'react'

import './SmartFactoryNewExperience.scss'

import ButtonComponent from 'components/commons/Button/ButtonComponent'
import VideoComponent from 'components/commons/VideoComponent/VideoComponent'

interface SmartFactoryNewExperienceProps{

}

const SmartFactoryNewExperience: FC<SmartFactoryNewExperienceProps> = () => {
    return (
        <div id='Caracteristicas' className="smart_factory_new_experience--layout">
            <div className="smart_factory_new_experience--video">
                <VideoComponent
                    platform='YouTube'
                    id='gh3wCtDMTxc'
                />
            </div>
            <div className="smart_factory_new_experience--text">
                <h2>Nuevas experiencias de simulación</h2>
                <p>Un aplicativo enfocado a la enseñanza de las nuevas tecnologias y procesos de producción , donde el jugador tiene control sobre el entorno y se divierte mientras descubre.</p>
                <ButtonComponent
                    variant='accent'
                    className='smart_factory_new_experience--button'
                >
                    Ver más
                </ButtonComponent>
            </div>
        </div>
    )
}

export default SmartFactoryNewExperience
