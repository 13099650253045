// @import dependencies
import React, { CSSProperties, ElementType } from 'react'
// @end dependencies

// @import components
import {
  Dropdown
} from 'react-bootstrap'
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './DropDownComponent.scss'
// @end styles

interface IDropDownComponentProps {
  title: string
  children?: any
  className?: string
  classNameToggle?: string
  style?: CSSProperties
  id?: string

}

interface IDropDownComponentSubComponents{
  Item: React.FC<IItemProps>
}

const DropDownComponent: React.FC<IDropDownComponentProps> & IDropDownComponentSubComponents = (props) => {
  return(
    <Dropdown
      className={`drop_down_component-layout ${props.className ? props.className : ''}`}
      style={props.style}
      id={props.id}
    >
      <Dropdown.Toggle
        className={`drop-down-toggle ${props.classNameToggle}`}
      >
        {props.title}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {props.children}
      </Dropdown.Menu>
    </Dropdown>
  );
}

// =====================================================
// @INFO Item del dropdown
// =====================================================
interface IItemProps {
  className?: string
  style?: CSSProperties
  id?: string
  children?: any
  // @INFO Para el item de react bootstrap
  active?: boolean
  as?: ElementType
  disabled?: boolean
  eventKey?: string | number
  href?: string
  bsPrefix?: string
  onClick?: () => any
}

const Item: React.FC<IItemProps> = (props) => {
  return(
    <Dropdown.Item
      className={`item_dropdown_component-layout ${props.className ? props.className : ''}`}
      style={props.style}
      id={props.id}
      // @INFO Para el item de react bootstrap
      active={props.active}
      as={props.as}
      disabled={props.disabled}
      eventKey={props.eventKey}
      href={props.href}
      bsPrefix={props.bsPrefix}
      onClick={props.onClick}
    >
      {props.children}
    </Dropdown.Item>
  );
}

DropDownComponent.Item = Item

export default DropDownComponent;
