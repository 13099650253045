import socketUtil from 'utils/socket/socket.util';
import * as JitsiTypes from 'types/jitsi/jitsi.types';
class SocketService {

    constructor(){
        
    }

    // ==================== Para la propiedad event de las herramientas ================
    public listenEvent = (event: string, callback: any) => {
        socketUtil.on(event, callback)
    }
    
    public removeListenEvent = (event: string, callback: any) => {
        socketUtil.removeEventListener(event, callback)
    }
    
    public emitEvent = (event: string, data: any) => {
        socketUtil.emit(event, data);
    }
    
    // ============================= Para la camara ===========================
    public startMeetingEmit = (toolId: string) => {
        socketUtil.emit(`startMeeting`, {toolId});
    }
    
    public finishMeetingEmit = (toolId: string) => {
        socketUtil.emit(`finishMeeting`, {toolId});
    }

    public infoMeetingEmit = (toolId: string) => {
        socketUtil.emit(`infoMeeting`, {toolId});
    }
    
    public changeCameraMeetingEmit = (toolId: string, device: JitsiTypes.IJitsiDevice) => {
        socketUtil.emit(`changeCameraMeeting`, {toolId, data: device});
    }

    public startMeetingListen = (toolId: string, callback: Function) => {
        socketUtil.on(`startMeeting:${toolId}`, callback);
    }
    
    public finishMeetingListen = (toolId: string, callback: Function) => {
        socketUtil.on(`finishMeeting:${toolId}`, callback);
    }

    public infoMeetingListen = (toolId: string, callback: Function) => {
        socketUtil.on(`infoMeeting:${toolId}`, callback);
    }
    
    public changeCameraMeetingListen = (toolId: string, callback: Function) => {
        socketUtil.on(`changeCameraMeeting:${toolId}`, callback);
    }
    
    public startMeetingRemoveListen = (toolId: string, callback: Function) => {
        socketUtil.removeEventListener(`startMeeting:${toolId}`, callback);
    }
    
    public finishMeetingRemoveListen = (toolId: string, callback: Function) => {
        socketUtil.removeEventListener(`finishMeeting:${toolId}`, callback);
    }

    public infoMeetingRemoveListen = (toolId: string, callback: Function) => {
        socketUtil.removeEventListener(`infoMeeting:${toolId}`, callback);
    }
    
    public changeCameraMeetingRemoveListen = (toolId: string, callback: Function) => {
        socketUtil.removeEventListener(`changeCameraMeeting:${toolId}`, callback);
    }
}

export default SocketService