// @import dependencies
import React, { CSSProperties } from 'react'
// @end dependencies

// @import components
import { Modal } from 'react-bootstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import ReserveLaboratoryModule from '../ReserveLaboratoryModule/ReserveLaboratoryModule';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ModalReserveLaboratory.scss'
// @end styles

interface IModalReserveLaboratoryProps {
    open: boolean
    idLab: string
    duration?: number
    className?: string
    style?: CSSProperties
    onClose?: (data?: any) => any
}

const ModalReserveLaboratory: React.FC<IModalReserveLaboratoryProps> = (props) => {

    /**
     * @INFO Cerrar el modal
     */
    const handleCloseModal = () => {
        if(props.onClose){
            props.onClose()
        }
    }

    return(
        <Modal
            className={`modal_change_password-layout ${props.className ? props.className : ''}`}
            style={props.style}
            onHide={() => handleCloseModal()}
            show={props.open}
            centered
            backdrop="static"
            keyboard={false}
            size='lg'
        >
            <Modal.Body>
                <div
                    className={`modal_reserve_laboratory-layout ${props.className ? props.className : ''}`}
                    style={props.style}
                >
                    <div 
                        className="close-modal-button"
                        onClick={() => handleCloseModal()}
                    >
                        <AiOutlineCloseCircle/>
                    </div>
                    <ReserveLaboratoryModule
                        idLab={props.idLab}
                        duration={props.duration}
                        onReserveSaved={props.onClose}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}


export default ModalReserveLaboratory;