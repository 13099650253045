import { IRouteApp } from "types/routes/routes.types";

// @import Screens
import LoginPage from 'components/screens/LoginPage/LoginPage';
import HomePage from 'components/screens/HomePage/HomePage';
import RegisterPage from 'components/screens/RegisterPage/RegisterPage';
import ProfilePage from 'components/screens/ProfilePage/ProfilePage';
import LaboratoriesPage from 'components/screens/LaboratoriesPage/LaboratoriesPage';
import LaboratoryPreviewPage from 'components/screens/LaboratoryPreviewPage/LaboratoryPreviewPage';
import HomeCatalogerPage from 'components/screens/HomeCatalogerPage/HomeCatalogerPage';
import ListCatalogerItemsPage from "components/screens/ListCatalogerItemsPage/ListCatalogerItemsPage";
import CreateLaboratoryPage from 'components/screens/Cataloger/CreateLaboratoryPage/CreateLaboratoryPage';
import DashboardLaboratoryPage from "components/screens/DashboardLaboratoryPage/DashboardLaboratoryPage";
import CreateBannerPage from 'components/screens/Cataloger/CreateBannerPage/CreateBannerPage';
import HomeLandingPage from 'components/screens/LandingPage/HomeLandingPage/HomeLandingPage';
import FavoritesPage from 'components/screens/FavoritesPage/FavoritesPage';
import ContactLandingPage from 'components/screens/LandingPage/ContactLandingPage/ContactLandingPage';
import CreateToolCategoryPage from 'components/screens/Cataloger/CreateToolCategoryPage/CreateToolCategoryPage';
import CreateToolPage from 'components/screens/Cataloger/CreateToolPage/CreateToolPage';
import CreateHardwarePage from "components/screens/Cataloger/CreateHardwarePage/CreateHardwarePage";
import CreateLaboratoryCategoryPage from "components/screens/Cataloger/CreateLaboratoryCategoryPage/CreateLaboratoryCategoryPage";
import MyLabsPage from 'components/screens/MyLabsPage/MyLabsPage';
import FinishLaboratoryPage from 'components/screens/FinishLaboratoryPage/FinishLaboratoryPage';
import CodeAuthenticationPage from 'components/screens/CodeAuthenticationPage/CodeAuthenticationPage';
import SmartFactoryPage from 'components/screens/CustomLandings/SmartFactoryPage/SmartFactoryPage';
import PlansPage from "components/screens/PlansPage/PlansPage";
import PaymentPage from "components/screens/PaymentPage/PaymentPage";
import PaymentStatusPage from 'components/screens/PaymentStatusPage/PaymentStatusPage';
import CustomPlanPage from "components/screens/CustomPlanPage/CustomPlanPage";
import TermsAndConditionsPage from "components/screens/TermsAndConditionsPage/TermsAndConditionsPage";
// @end

export const routesApp: IRouteApp[] = [
    {
        component: HomePage,
        path: '/home',
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['dashboard_page']
        }
    },
    {
        component: ProfilePage,
        path: '/profile',
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['profile_page']
        }
    },
    {
        component: FavoritesPage,
        path: '/favorites',
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['favorite_page']
        }
    },
    {
        component: LaboratoriesPage,
        path: '/laboratories',
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['laboratories_page']
        }
    },
    {
        component: LaboratoryPreviewPage,
        path: '/laboratory-preview',
        pathVariables: ['idLab'],
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['laboratory_preview_page']
        }
    },
    {
        component: HomeCatalogerPage,
        path: '/cataloger',
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['cataloger_home_page']
        }
    },
    {
        component: ListCatalogerItemsPage,
        path: '/cataloger-list',
        pathVariables: ['category'],
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['cataloger_list_page']
        }
    },
    {
        component: DashboardLaboratoryPage,
        path: '/dashboard-laboratory',
        pathVariables: ['idLab'],
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['laboratory_dashboard_page']
        }
    },
    {
        component: MyLabsPage,
        path: '/my-labs',
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['my_labs_page']
        }
    },
    {
        component: FinishLaboratoryPage,
        pathVariables: ['idLab'],
        path: '/feedback-lab',
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['laboratory_dashboard_page']
        }
    },
    {
        component: CodeAuthenticationPage,
        path: '/code-authentication',
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
        }
    },
    {
        component: PlansPage,
        path: '/plans',
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
        }
    },
    {
        component: PaymentPage,
        path: '/payment',
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
        }
    },
    {
        component: PaymentStatusPage,
        path: '/payment',
        pathVariables: ['status'],
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
        }
    },
    {
        component: CustomPlanPage,
        path: '/custom-plan',
        pathVariables: ['planId'],
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
        }
    },
    // ==================================================
    // Cataloger routes 
    // ==================================================
    {
        component: CreateLaboratoryPage,
        path: '/create-laboratory',
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['cataloger_feature_page']
        }
    },
    {
        component: CreateLaboratoryPage,
        path: '/edit-laboratory',
        pathVariables: ['idLab'],
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['cataloger_feature_page']
        }
    },
    {
        component: CreateLaboratoryCategoryPage,
        path: '/create-laboratory-category',
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['cataloger_feature_page']
        }
    },
    {
        component: CreateLaboratoryCategoryPage,
        path: '/edit-laboratory-category',
        pathVariables: ['idLaboratoryCategory'],
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['cataloger_feature_page']
        }
    },
    {
        component: CreateBannerPage,
        path: '/create-banner',
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['cataloger_feature_page']
        }
    },
    {
        component: CreateBannerPage,
        path: '/edit-banner',
        pathVariables: ['idBanner'],
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['cataloger_feature_page']
        }
    },
    {
        component: CreateToolCategoryPage,
        path: '/create-tool-category',
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['cataloger_feature_page']
        }
    },
    {
        component: CreateToolCategoryPage,
        path: '/edit-tool-category',
        pathVariables: ['idToolCategory'],
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['cataloger_feature_page']
        }
    },
    {
        component: CreateToolPage,
        path: '/create-tool',
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['cataloger_feature_page']
        }
    },
    {
        component: CreateToolPage,
        path: '/edit-tool',
        pathVariables: ['idTool'],
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['cataloger_feature_page']
        }
    },
    {
        component: CreateHardwarePage,
        path: '/create-hardware',
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['cataloger_feature_page']
        }
    },
    {
        component: CreateHardwarePage,
        path: '/edit-hardware',
        pathVariables: ['idHardware'],
        exact: true,
        loading: true,
        private: {
            state: true,
            conditions: ['auth'],
            permissions: ['cataloger_feature_page']
        }
    },
    // ==================================================
    // Landing routes 
    // ==================================================
    {
        component: HomeLandingPage,
        path: '/',
        exact: true
    },
    {
        component: ContactLandingPage,
        path: '/contact',
        exact: true
    },
    {
        component: LoginPage,
        path: '/login',
        exact: true
    },
    {
        component: RegisterPage,
        path: '/register',
        exact: true
    },
    {
        component: SmartFactoryPage,
        path: '/smart-factory',
        exact: true
    },
    {
        component: TermsAndConditionsPage,
        path: '/terms-and-conditions',
        exact: true
    },
]