export type ITypeLaboratoryCategory = 'Realidad Virtual' | 'Computación en la nube'

export interface ILaboratoryCategoryState{
    list: ILaboratoryCategory[]
    loadingList: boolean    
    errorList?: any
}

export interface ILaboratoryCategory{
    id?: string
    name?: ITypeLaboratoryCategory
    description?: string
    config?: ILabCategoryConfig
    image?: string
}

export interface IParamsLaboratoryCategoryList{

}

export interface IParamsLaboratoryCategoryCreate{
    category: ILabCategoryInput
}

export interface IParamsLaboratoryCategoryUpdate{
    category: Partial<ILabCategoryInput>
    id: string
}

export interface IParamsLaboratoryCategoryDelete{
    id: string
}

export interface IParamsLaboratoryCategoryGetById{
    id: string
}

interface ILabCategoryInput{
    name: string
    description: string
    image?: string
    imageFile?: File
    config?: ILabCategoryConfig 
}

interface ILabCategoryConfig{
    hasHardware?: boolean
    hasDuration?: boolean
    hasHardwareDuration?: boolean
    hasVirtualReality?: boolean
    hasMultipleDashboards?: boolean
}

export const fullLaboratoryCategoryQuery = `
    id
    name
    description
    image
    config{
        hasHardware
        hasDuration
        hasHardwareDuration
        hasVirtualReality
        hasMultipleDashboards
    }
`