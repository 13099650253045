// @import types
import ReservationsService from 'services/reservations/reservationsService';
import * as DashboardTypes from 'types/dashboard/dashboard.types';
import * as ReservationTypes from 'types/reservations/reservations.types';
// @end types

export const setReservationAction = (reservation?: ReservationTypes.IReservation) => (
    (dispatch: Function) => {
        dispatch(setReservation(reservation));
    }
)

const setReservation = (reservation?: ReservationTypes.IReservation): DashboardTypes.DashboardActionsTypes => ({
    type: DashboardTypes.SET_RESERVATION_DASHBOARD,
    payload: reservation
})

export const setReservationsAction = (reservations?: ReservationTypes.IReservation[]) => (
    (dispatch: Function) => {
        dispatch(setReservations(reservations?.length ? reservations : []));
    }
)

const setReservations = (reservations: ReservationTypes.IReservation[]): DashboardTypes.DashboardActionsTypes => ({
    type: DashboardTypes.SET_RESERVATIONS_DASHBOARD,
    payload: reservations
})

export const updateReservationDashboardAction = (reservation: Partial<ReservationTypes.IReservation> & { id: string }) => (
    async (dispatch: Function) => {
        dispatch(updateReservationDashboard());
        const reservationsService = new ReservationsService()
        const { id, ...paramsRequest } = reservation
        const response = await reservationsService.updateReserve({
            id,
            reservation: paramsRequest,
        })
        if (response) {
            dispatch(updateReservationDashboardSuccess(response))
        } else {
            dispatch(updateReservationDashboardError())
        }
    }
)

const updateReservationDashboard = (): DashboardTypes.DashboardActionsTypes => ({
    type: DashboardTypes.UPDATE_RESERVATION_DASHBOARD,
    payload: undefined
})

const updateReservationDashboardSuccess = (reservation: ReservationTypes.IReservation): DashboardTypes.DashboardActionsTypes => ({
    type: DashboardTypes.UPDATE_RESERVATION_DASHBOARD_SUCCESS,
    payload: reservation
})

const updateReservationDashboardError = (): DashboardTypes.DashboardActionsTypes => ({
    type: DashboardTypes.UPDATE_RESERVATION_DASHBOARD_ERROR,
    payload: undefined
})
