import * as LaboratoryTypes from 'types/laboratories/laboratories.types';

export interface IFavorite{
    id: string
    user: string
    lab: LaboratoryTypes.ILaboratory
    currentPrice: number
    date: Date
}

export interface IParamsNewFavorite{
    lab: string
}

export interface IParamsRemoveFavorite{
    lab: string
}

export interface IParamsListFavorite{
    
}

export const fullFavoriteQuery = `
    id
    user
    lab{
        ${LaboratoryTypes.fullLaboratoryQuery}
    }
    currentPrice
    date
`