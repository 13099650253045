import store from 'redux/store'
import graphQLUtil from "utils/graphQL/graphQL.util"
import { gql } from "@apollo/client"

import * as LicenseTypes from 'types/license/license.types';

class LicenseService{

    private storeObject = store

    constructor(){

    }

    /**
     * @INFO Traer la lista de licenses
     * @param _params 
     */
    public list = async (_params?: LicenseTypes.ParamsLicensesList): Promise<LicenseTypes.License[]> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query licenses($token: String!){
                        licenses(token: $token){
                            ${LicenseTypes.fullLicenseQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token
                }
            }
        })
        if(response?.data?.licenses?.length){
            return response.data.Licenses
        }else{
            return []
        }
    }
    
    /**
     * @INFO Traer un license por id
     * @param _params 
     */
    public getById = async (_params: LicenseTypes.ParamsLicenseGetById): Promise<LicenseTypes.License | undefined> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query license($token: String!, $id: ID!){
                        license(token: $token, id: $id){
                            ${LicenseTypes.fullLicenseQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        })
        if(response?.data?.license){
            return response.data.License
        }else{
            return undefined
        }
    }

    /**
     * @INFO Crear un license
     * @param _params 
     * @returns 
     */
    public create = async (_params: LicenseTypes.ParamsLicenseCreate): Promise<LicenseTypes.ResponseLicenseCreate | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation newLicense($token: String!, $license: LicenseInput!){
                        newLicense(token: $token, license: $license){
                            ${LicenseTypes.fullLicenseCreateQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    license: {..._params.license}
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.newLicense){
            return response.data.newLicense
        }else{
            return undefined
        }
    }

    /**
     * @INFO Crear un license con una tarjeta existente
     * @param _params 
     * @returns 
     */
     public newLicenseExistingCard = async (_params: LicenseTypes.ParamsNewLicenseExistingCard): Promise<LicenseTypes.ResponseLicenseCreate | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation newLicenseExistingCard($token: String!, $license: LicenseInputExistingCard!){
                        newLicenseExistingCard(token: $token, license: $license){
                            ${LicenseTypes.fullLicenseCreateQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    license: {..._params.license}
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.newLicenseExistingCard){
            return response.data.newLicenseExistingCard
        }else{
            return undefined
        }
    }

    /**
     * @INFO Actualizar un license
     * @param _params 
     * @returns 
     */
    public update = async (_params: LicenseTypes.ParamsLicenseUpdate)
    : Promise<LicenseTypes.License | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation updateLicense($token: String!, $id: ID!, $license: UpdateLicenseInput!){
                        updateLicense(token: $token, id: $id, license: $license){
                            ${LicenseTypes.fullLicenseQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    license: {..._params.license},
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.updateLicense){
            return response.data.updateLicense
        }else{
            return undefined
        }
    }

    /**
     * @INFO Eliminar un license
     * @param _params 
     * @returns 
     */
    public delete = async (_params: LicenseTypes.ParamsLicenseDelete): Promise<LicenseTypes.License | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation removeLicense($token: String!, $id: ID!){
                        removeLicense(token: $token, id: $id){
                            ${LicenseTypes.fullLicenseQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.removeLicense){
            return response.data.removeLicense
        }else{
            return undefined
        }
    }

    /**
     * @INFO Traer la lista de medios de pago
     */
     public getPayments = async (): Promise<LicenseTypes.Payment[]> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query payments($token: String!){
                        payments(token: $token){
                            ${LicenseTypes.fullPaymentQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token
                }
            }
        })
        if(response?.data?.payments?.length){
            return response.data.payments
        }else{
            return []
        }
    }
}

export default LicenseService