// @import dependencies
import React from 'react'
// @end dependencies

// @import components
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import HomeCarousel from 'components/commons/HomeCarousel/HomeCarousel';
import CardCategoryHome from './components/CardCategoryHome/CardCategoryHome';
import HistoryReserveHome from './components/HistoryReserveHome/HistoryReserveHome';
import CardCalendarHome from './components/CardCalendarHome/CardCalendarHome';
import Slider from 'react-slick';
import SimpleArrowComponent from 'components/commons/SimpleArrowComponent/SimpleArrowComponent';
// @end components

// @import services
import HelpStepsService from 'services/helpSteps/helpStepsService';
// @end services

// @import hooks
import { useTranslation } from 'react-i18next';
// @end hooks

// @import hooks
import { useLaboratory } from 'hooks/useLaboratory/useLaboratory';
import useWindowWidth from 'hooks/useWindowWidth/useWindowWidth';
// @end hooks

// @import styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './HomePage.scss';
// @end styles

export interface HomePageProps {
    history?: any
}
 
const HomePage: React.SFC<HomePageProps> = (props) => { 
    
    const {t} = useTranslation()
    const hookLabs = useLaboratory();
    const { windowWidth } = useWindowWidth();

    // @INFO Servicios 
    const helpStepsService = new HelpStepsService()

    const handleClickcategory = () => {
        if(props.history){
            props.history.push('/laboratories')
        }
    }

    const getNumberItemsCarousel = () => {
        if (windowWidth <= 960) {
            return 1;
        } else if (windowWidth <= 1200) {
            return 2;
        } else {
            return 4;
        }
    }

    return ( 
        <AppLayout
            headerTitle='Dashboard'
            history={props.history}
            fetchLabCategories
        >
            <div className="home_page-layout">
                {helpStepsService.renderHelpModule('home')}
                <HomeCarousel
                    id='banner_module'
                />
                <Slider
                    dots
                    adaptiveHeight={true}
                    infinite={getNumberItemsCarousel() < hookLabs.categories?.length ? true : false}
                    arrows={true}
                    autoplay
                    draggable={false}
                    autoplaySpeed={4000}
                    speed={500}
                    slidesToShow={getNumberItemsCarousel()}
                    slidesToScroll={1}
                    nextArrow={<SimpleArrowComponent type='next' />}
                    prevArrow={<SimpleArrowComponent type='prev' />}
                >
                    {hookLabs.categories.map((item) => (
                        <CardCategoryHome
                            key={item.id}
                            title={item.name ? item.name : ''}
                            description={item.description ? item.description : ''}
                            image={item.image}
                            myProgress={50}
                            totalProgress={20}
                            sliderItem
                            onClick={() => handleClickcategory()}
                        />
                    ))}
                </Slider>
                <div className="container-history-calendar">
                    <HistoryReserveHome
                        id='history_home'
                    />
                    <CardCalendarHome
                        id='calendar_home'
                    />
                </div>
            </div>      
        </AppLayout>
    );
}
 
export default HomePage;