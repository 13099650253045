import { IStepHelpSteps } from "types/helpSteps/helpStepsTypes"

const homeSteps: IStepHelpSteps[] = [
    {
        target: '#banner_module',
        content: 'Aquí encontraras las nuevas noticias acerca de laboratorios y promociones'
    },
    {
        target: '#categories_home',
        content: 'Estas son las categorías principales de los laboratorios'
    },
    {
        target: '#history_home',
        content: 'Este es el historial de los laboratorios que has realizado'
    },
    {
        target: '#calendar_home',
        content: 'En este calendario pueden encontrar tus próximas reservas'
    },
]

export default homeSteps