import * as ToolCategoryTypes from 'types/toolCategory/toolCategory.types'
import * as JitsiTypes from 'types/jitsi/jitsi.types';
import { TypeLanguageCodeEditor } from 'components/commons/CodeEditor/CodeEditor';

export type TypesStepsCodeEditor = 'compile' | 'upload';
export enum ChartType {
    LINE = 'line',
    BAR = 'bar',
}

export interface ITool{
    id?: string
    category?: ToolCategoryTypes.IToolCategory
    name?: string
    displayTitle?: string
    description?: string
    config?: IToolConfig
    apiToken?: string
}

export interface ToolToRender extends ITool{
    show: boolean
}

export interface IToolConfig{
    samplingTime?: number
    voltageRange?: RangeValue
    currentRange?: RangeValue
    initialVoltage?: number
    initialCurrent?: number
    storagePeriod?: number
    storageEnabled?: boolean
    storageSampleTime?: number
    storageSampleReport?: number
    event?: string;
    variables?:  IVariableTool[];
    codeEditor?: CodeEditor;
    language?: TypeLanguageCodeEditor;
    supportedLanguages?: TypeLanguageCodeEditor[];
    url?: string
    jitsi?: {
        toolbarButtons: JitsiTypes.ITypeToolbarButtonsJitsi[];
    };
}

export interface CodeEditor{
    associatedTool?: string;
    language?: string;
}

export interface IVariableTool{
    name: string
    unit: string
    variableKey: string
    limits?: RangeValue
}

export interface IParamsToolCreate{
    tool: IToolInput
}

export interface IParamsToolUpdate{
    tool: IUpdateToolInput
    id: string
}

export interface IParamsToolDelete{
    id: string
}

export interface IParamsToolList{

}

export interface IParamsToolGetById{
    id: string
}

interface IToolInput{
    category: string
    name: string
    displayTitle: string
    description: string
    config?: IToolConfig
}

interface IUpdateToolInput{
    name?: string
    displayTitle?: string
    category?: string
    description?: string
    config?: IToolConfig
}

export interface RangeValue{
    min: number
    max: number
}

export interface CameraInfoMeeting{
    // cameras: {
    //     id: string;
    //     name: string;
    // }[];
    devices: JitsiTypes.IAvailableDeviceJitsi;
    roomName: string;
}

export interface CodeEditorResponse{
    status: boolean;
    step: TypesStepsCodeEditor;
    errors?: string;
    logs?: string[];
}

export const fullToolQuery = `
    id
    category{
        ${ToolCategoryTypes.fullToolCategoryQuery}
    }
    name
    displayTitle
    description
    apiToken
    config{
        samplingTime
        storagePeriod
        storageEnabled
        language
        supportedLanguages
        voltageRange{
            min
            max
        }
        currentRange{
            min
            max
        }
        initialVoltage
        initialCurrent
        event
        variables{
            name
            unit
            variableKey
            limits{
                min
                max
            }
        }
        codeEditor{
            associatedTool
            language
        }
        jitsi{
            toolbarButtons
        }
        url
    }
`