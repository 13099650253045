import CheckBoxComponent from 'components/commons/CheckBoxComponent/CheckBoxComponent'
import React, { CSSProperties, useState, useEffect } from 'react'

import './FilterCheckArrayList.scss'

interface IFilterCheckArrayListProps {
    items: IItemList[]
    type: 'check' | 'radio'
    className?: string
    style?: CSSProperties
    defaultValue?: string[]
    defaultItems?: IItemList[]
    name?: string
    onFilter?: (keys: string[], items?: IItemList[]) => any
    onChangeForm?: (data: any) => any
}

interface IItemList{
    label: string
    value: string
  }
  
  interface ICheckItem extends IItemList{
    active: boolean
  }

const FilterCheckArrayList: React.FC<IFilterCheckArrayListProps> = (props) => {

    const [checks, updateChecks] = useState<ICheckItem[]>([])
    const [checksToRender, updateChecksToRender] = useState<ICheckItem[]>([])
    const [triggerLoadDefault, updateTriggerLoadDefault] = useState<any>({})
    const [defaultLoaded, updateDefaultLoaded] = useState<boolean>(false)

    useEffect(() => {
        if(props.items?.length){
            const newChecks = props.items.reduce((accum: ICheckItem[], item) => {
                accum.push({
                ...item,
                active: false
                })
                return accum
            },[])
            updateChecks([...newChecks])
        }else{
            updateChecks([])
        }
    },[props.items])

    // @INFO Filtrar por texto
    useEffect(() => {
        if(!defaultLoaded && checks.length){
            updateTriggerLoadDefault({})
        }
        updateChecksToRender([...checks])
    },[checks])

    // @INFO Establecer estado por defecto
    useEffect(() => {
        loadDefaultValues()
    },[triggerLoadDefault])

    // useEffect(() => {
    //     if(!defaultLoaded && checks.length){
    //         updateTriggerLoadDefault({})
    //     }
    // }, [checks])

    /**
     * @INFO Cargar data por defecto de "defaultValue"
     */
    const loadDefaultValues = () => {
        if(props.defaultValue?.length){
            updateDefaultLoaded(true)
            props.defaultValue.forEach((item) => {
                const data: any = {
                    target: {
                        value: item
                    }
                }
                handleChangeCheck({...data})
            })
        }
    }

    /**
     * @INFO Cambia el estado del check
     * @param e
     */
    const handleChangeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        const id = e.target.value
        const aux = [...checks]
        const idx = aux.findIndex((item) => item.value === id)
        if(idx >= 0){
            aux[idx].active = !aux[idx].active
            updateChecks([...aux])
            sendOnChangeForm([...aux])
        }
    }

    /**
     * @INFO Cambia el estado del radio button
     * @param e
     */
    const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
        const id = e.target.value
        const aux = [...checks]
        // @INFO Cargar el nuevo estado
        const idx = aux.findIndex((item) => item.value === id)
        if(idx >= 0){
            if(aux[idx].active){
                // @INFO Reset estados
                aux.forEach((item, idxItem) => aux[idxItem].active = false)
            }else{
                // @INFO Reset estados
                aux.forEach((item, idxItem) => aux[idxItem].active = false)
                aux[idx].active = true
            }
            sendOnChangeForm([...aux])
            updateChecks([...aux])
        }
    }

    /**
     * @INFO Enviar el evento de onChangeForm
     * @param checks 
     */
    const sendOnChangeForm = (_checks: any[]) => {
        if(props.onChangeForm){
            const activeChecks = _checks.filter((item) => item.active)
            const aux = {
                target:{
                    name: props.name ? props.name : 'filter',
                    value: activeChecks.map((item) => item.value)
                }
            }
            props.onChangeForm({...aux})
        }
    }

    return(
        <div
            className={`filter_check_array_list-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <div className="container-options-filter">
                {
                checksToRender?.map((item) => (
                    props.type === 'check' ?
                    <CheckBoxComponent
                    key={item.value}
                    label={item.label}
                    value={item.value}
                    checked={item.active}
                    onChange={handleChangeCheck}
                    />
                    :
                    <CheckBoxComponent
                    key={item.value}
                    label={item.label}
                    value={item.value}
                    checked={item.active}
                    onChange={handleChangeRadio}
                    // name='radio_item_filter'
                    />
                ))
                }
            </div>
        </div>
    );
}

export default FilterCheckArrayList;