// @import dependencies
import React, { CSSProperties, useEffect } from 'react'
// @end dependencies

// @import components
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import TextComponent from '../Text/TextComponent';
import { MdTimer } from 'react-icons/md';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
import { useTimer } from 'hooks/useTimer/useTimer';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './TimerComponent.scss'
import 'react-circular-progressbar/dist/styles.css';
import TimeService from 'services/time/timeService';
// @end styles

interface ITimerComponentProps {
    size: 'small' | 'normal' | 'large'
    limitTime?: number
    className?: string
    style?: CSSProperties
    id?: string
    withoutProgress?: boolean
    hideTimerIcon?: boolean
    initialDate?: Date
    offsetSeconds?: number
    onEndTimer?: () => void
}

const TimerComponent: React.FC<ITimerComponentProps> = (props) => {

    const timeService = new TimeService()
    const totalSeconds = props.limitTime ? props.limitTime + (props.offsetSeconds ? props.offsetSeconds : 0) : 10;
    const initialSeconds = props.initialDate ? (totalSeconds - timeService.getDifferenceSeconds(new Date(), props.initialDate)) : totalSeconds;
    const timer  = useTimer({
        initialValue: initialSeconds, 
        stream: true, 
        orientation: 'down'
    });

    useEffect(() => {
        timer.start();
    }, []);

    useEffect(() => {
        if(Math.trunc(timer.totalSeconds) === 0 && props.onEndTimer){
            props.onEndTimer();
        }
    }, [timer.totalSeconds]);

    return(
        <div
            className={`timer_component-layout ${props.size} ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            {props.withoutProgress ?
                <div className="container-timer-indicator">
                    {!props.hideTimerIcon ? <MdTimer/> : null}
                    <TextComponent>
                        {timer.hours ? `${timer.hours}h ` : ''}{timer.minutes ? `${timer.minutes}m ` : ''}{timer.seconds ? `${timer.seconds}s ` : ''}
                    </TextComponent>
                </div>
            :
                <div className="container-with-progress">
                    <CircularProgressbarWithChildren
                        value={Math.round(timer.totalSeconds*100/totalSeconds)}
                        styles={buildStyles({
                            pathColor: 'var(--primary2)',
                            trailColor: '#F1F1F1'
                        })}
                    >
                        <div className="container-timer-indicator">
                            {!props.hideTimerIcon ? <MdTimer/> : null}
                            <TextComponent>
                                {timer.hours ? `${timer.hours}h ` : ''}{timer.minutes ? `${timer.minutes}m ` : ''}{timer.seconds ? `${timer.seconds}s ` : ''}
                            </TextComponent>
                        </div>
                    </CircularProgressbarWithChildren>
                </div>
            }
        </div>
    );
}

export default TimerComponent;