import * as LocationsTypes from 'types/locations/locations.types'
import * as LocationsActionTypes from 'types/locations/locations.actions.types'

const initialState: LocationsTypes.ILocationsState = {
    countries: [],
    provinces: [],
    cities: []
}

export default function(state: LocationsTypes.ILocationsState = initialState, action: any): LocationsTypes.ILocationsState{
    switch(action.type){
        case LocationsActionTypes.UPDATE_COUNTRIES:
            return {
                ...state,
                countries: action.payload
            }
        case LocationsActionTypes.UPDATE_PROVINCES:
            return {
                ...state,
                provinces: action.payload
            }
        case LocationsActionTypes.UPDATE_CITIES:
            return {
                ...state,
                cities: action.payload
            }
        default: 
            return {
                ...state
            }
    }
}