import { SystemConfig } from "types/app/app.actions.types";
import SmartFactory from 'assets/images/landing/customLandings/SmartFactory/SmartFactoryColor.png'

const config: SystemConfig = {
    public_routes: [],
    block_routes: ['/', '', '/contact', '/login', '/register'],
    modules: ['HEADER'],
    default_redirect: 'https://vlesim.com',
    dashboard_type: 'normal',
    header: {
        type: 'custom',
        modules: ['icon'],
        defaultEnabled: true,
        backgroundColor: '#03133B',
        icon: SmartFactory,
    },
    dashboard: {
        publicInfo: false,
        dashboardsSelector: false,
    }
}

export default config;