// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import InputComponent from 'components/commons/Input/InputComponent';
import SubtitleComponent from 'components/commons/Subtitle/SubtitleComponent';
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import ToolCategoriesSelect from 'components/modules/ToolCategoriesSelect/ToolCategoriesSelect';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import VariablesFormModule from 'components/modules/VariablesFormModule/VariablesFormModule';
import RangeInputComponent from 'components/commons/RangeInputComponent/RangeInputComponent';
import BreadcrumbComponent from 'components/commons/BreadcrumbComponent/breadcrumbComponent';
import TextComponent from 'components/commons/Text/TextComponent';
import CopyTextComponent from 'components/commons/CopyTextComponent/CopyTextComponent';
import LabelComponent from 'components/commons/LabelComponent/LabelComponent';
import ToolSelectModule from 'components/modules/ToolSelectModule/ToolSelectModule';
import DurationComponent from 'components/commons/DurationComponent/DurationComponent';
import CheckBoxComponent from 'components/commons/CheckBoxComponent/CheckBoxComponent';
import CodeLanguageSelect from 'components/modules/CodeLanguageSelect/CodeLanguageSelect';
import { Form } from 'react-bootstrap';
// @end components

// @import types
import { IStoreApp } from 'redux/reducers';
import { IFieldFormValidator } from 'hooks/useFormValidator/useFormValidator.types';
import * as ToolCategoryTypes from 'types/toolCategory/toolCategory.types'
import * as ToolTypes from 'types/tool/tool.types'
// @end types

// @import services
import ScreenNotification from 'services/screenNotification/screenNotification';
import ToolService from 'services/tool/toolService';
import ObjectService from 'services/object/objectService';
// @end services

// @import hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useValidator from 'hooks/useFormValidator/useFormValidator';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './CreateToolPage.scss'
import JitsiSelectModule from '../../../modules/JitsiSelectModule/JitsiSelectModule';
import InputFileComponent from 'components/commons/InputFileComponent/InputFileComponent';
// @end styles

interface ICreateToolPageProps {
    className?: string
    style?: CSSProperties
    id?: string
    history?: any
}

const CreateToolPage: React.FC<ICreateToolPageProps> = (props) => {

    const params = useParams<{idTool?: string}>()
    const {t} = useTranslation()
    const theme = useSelector((store: IStoreApp) => store.app.theme)

    const form = useValidator([
        { name: 'name', required: true, messageError: t('obligatory_field') },
        { name: 'displayTitle', required: true, messageError: t('obligatory_field') },
        { name: 'description', required: true, messageError: t('obligatory_field') },
        { name: 'category', required: true, messageError: t('obligatory_field') },
        // @INFO Configuraciones de la herramienta
    ])

    const [categorySelected, updateCategorySelected] = useState<ToolCategoryTypes.IToolCategory | undefined>(undefined)
    const [tool, updateTool] = useState<ToolTypes.ITool | undefined>(undefined)
    const [loading, updateLoading] = useState<boolean>(false)
    const [triggerAddFields, updateTriggerAddFields] = useState<any>({})
    const [triggerRemoveFields, updateTriggerRemoveFields] = useState<any>({})

    // @INFO Servicios
    const screenNotification = new ScreenNotification();
    const toolService = new ToolService();
    const objectService = new ObjectService();

    useEffect(() => {
        getInitialData()
    }, [])

    useEffect(() => {
        setValuesForm()
    },[tool])

    useEffect(() => {
        if(categorySelected){
            updateTriggerAddFields({})
        }
    }, [categorySelected?.id])

    useEffect(() => {
        configFieldsAdd({...categorySelected})
    }, [triggerAddFields])
    
    useEffect(() => {
        configFieldsRemove({...categorySelected})
    }, [triggerRemoveFields])

    /**
     * @INFO Obtener la data inicial para el componente
     */
    const getInitialData = async () => {
        getTool()
    }

    /**
     * @INFO Establecer los valores de la herramienta
     */
    const setValuesForm = () => {
        if(tool){
            form.setValues({
                ...tool,
                category: tool.category?.id
            })
            updateCategorySelected(tool.category)
        }
    }

    /**
     * @INFO Obtener la herramienta
     */
    const getTool = async () => {
        if(!params.idTool) {
            const auxTool: ToolTypes.ITool = {
                name: 'Sin titulo',
                description: 'Sin descripción',
                displayTitle: 'Sin nombre'
            }
            updateTool({...auxTool})
        } else{
            const newTool = await toolService.getById({ id: params.idTool })
            if(newTool){
                updateTool(newTool)
            }
        }
    }

    /**
     * @INFO Cambia el selector de categoría
     * @param _id 
     * @param _category 
     */
    const handleChangeCategory = (_id?: string, _category?: ToolCategoryTypes.IToolCategory) => {
        if(_id && _category){
            form.setValue('category', _id)
            updateCategorySelected(_category)
        }
    }

    /**
     * @INFO Click en crear o actualizar la herramienta
     */
    const handleClickCreateOrUpdate = async () => {
        updateLoading(true)
        // @INFO Obtener el objeto completo del formulario
        const valuesAux = form.getValues()
        const values = objectService.filterPropertyByType(valuesAux, [
            { name: 'config.voltageRange.__typename', type: 'number' },
            { name: 'config.currentRange.__typename', type: 'number' },
            { name: 'config.imageFile', type: 'object' },
        ])
        // @INFO Enviar la petición
        let response: any = undefined
        if(params.idTool){
            response = await toolService.update({
                tool: values,
                id: params.idTool
            })
            if(response){
                screenNotification.showSuccessTimer()
            }
        }else{
            response = await toolService.create({
                tool: values
            })
            if(response){
                await screenNotification.showSuccessTimer()
                if(props.history){
                    props.history.push(`/edit-tool/${response.id}`)
                }
            }
        }
        updateLoading(false)
    }

    /**
     * @INFO Configurar los campos del formulario que se van a agregar
     */
     const configFieldsAdd = (_category?: ToolCategoryTypes.IToolCategory) => {
        const addFields: IFieldFormValidator[] = []

        if(_category?.config?.hasVoltageRange){
            let initialValue = objectService.transformPropertyFromRoot(tool?.config?.voltageRange, [{ root: 'min', transform: 'string' }, { root: 'max', transform: 'string' }]);
            if (initialValue?.min === undefined) {
                initialValue = {min: '0', max: '0'};
            }
            addFields.push({ 
                name: 'config.voltageRange', 
                required: true, 
                messageError: t('obligatory_field'), 
                value: initialValue 
            });
        }

        if(_category?.config?.hasCurrentRange){
            let initialValue = objectService.transformPropertyFromRoot(tool?.config?.currentRange, [{ root: 'min', transform: 'string' }, { root: 'max', transform: 'string' }]);
            if (initialValue?.min === undefined) {
                initialValue = {min: '0', max: '0'};
            }
            addFields.push({ 
                name: 'config.currentRange', 
                required: true, 
                messageError: t('obligatory_field'), 
                value: initialValue 
            })
        }

        if(_category?.config?.hasVoltage){
            addFields.push({ name: 'config.initialVoltage', required: true, messageError: t('obligatory_field'), value: String(tool?.config?.initialVoltage) })
        }

        if(_category?.config?.hasCurrent){
            addFields.push({ name: 'config.initialCurrent', required: true, messageError: t('obligatory_field'), value: String(tool?.config?.initialCurrent) })
        }

        if(_category?.config?.hasStorage){
            // addFields.push({ name: 'config.storageSampleTime', required: true, messageError: t('obligatory_field'), value: tool?.config?.storageSampleTime })
            // addFields.push({ name: 'config.storageSampleReport', required: true, messageError: t('obligatory_field'), value: tool?.config?.storageSampleReport })
            addFields.push({ name: 'config.storagePeriod', required: true, messageError: t('obligatory_field'), value: tool?.config?.storagePeriod })
            addFields.push({ name: 'config.storageEnabled', messageError: t('obligatory_field'), value: tool?.config?.storageEnabled })
        }
        
        if(_category?.config?.hasSamplingTime){
            addFields.push({ name: 'config.samplingTime', required: true, messageError: t('obligatory_field'), value: String(tool?.config?.samplingTime) })
        }
        
        if(_category?.config?.hasVariables){
            addFields.push({ 
                name: 'config.variables', 
                required: true, 
                messageError: t('obligatory_field')
            })
        }

        if(_category?.config?.isCodeEditor){
            addFields.push({ name: 'config.codeEditor.associatedTool', required: true, messageError: t('obligatory_field'), value: tool?.config?.codeEditor?.associatedTool });
            addFields.push({ name: 'config.codeEditor.language', required: true, messageError: t('obligatory_field'), value: tool?.config?.codeEditor?.language });
            addFields.push({ name: 'config.supportedLanguages', required: true, messageError: t('obligatory_field'), value: tool?.config?.supportedLanguages });
        }

        if(_category?.config?.isCamera){
            addFields.push({ name: 'config.jitsi.toolbarButtons', required: true, messageError: t('obligatory_field'), value: tool?.config?.jitsi?.toolbarButtons });
        }
        
        if(_category?.config?.canDeployCode){
            addFields.push({ name: 'config.language', required: true, messageError: t('obligatory_field'), value: tool?.config?.language });
        }

        if (_category?.config?.isImage) {
            addFields.push({ name: 'config.imageFile', required: true, messageError: t('obligatory_field'), value: tool?.config?.url, loadKey: 'config.url' })
        }
                
        // @INFO Configurar el formulario
        form.addFields(addFields)
        updateTriggerRemoveFields({})
    }

    /**
     * @INFO Configurar los campos del formulario que se van a quitar
     * @param _category 
     */
    const configFieldsRemove = (_category?: ToolCategoryTypes.IToolCategory) => {
        const removeFields: string[] = []

        if(!_category?.config?.hasVoltageRange){
            removeFields.push('config.voltageRange')
        }

        if(!_category?.config?.hasCurrentRange){
            removeFields.push('config.currentRange')
        }

        if(!_category?.config?.hasVoltage){
            removeFields.push('config.initialVoltage')
        }

        if(!_category?.config?.hasCurrent){
            removeFields.push('config.initialCurrent')
        }

        if(!_category?.config?.hasStorage){
            // removeFields.push('config.storageSampleTime')
            // removeFields.push('config.storageSampleReport')
            removeFields.push('config.storagePeriod')
            removeFields.push('config.storageEnabled')
        }
        
        if(!_category?.config?.hasSamplingTime){
            removeFields.push('config.samplingTime')
        }
        
        if(!_category?.config?.hasVariables){
            removeFields.push('config.variables')
        }

        if(!_category?.config?.isCodeEditor){
            removeFields.push('config.codeEditor.associatedTool')
            removeFields.push('config.codeEditor.language')
            removeFields.push('config.supportedLanguages')
        }

        if(!_category?.config?.isCamera){
            removeFields.push('config.jitsi.toolbarButtons');
        }
        
        if(!_category?.config?.canDeployCode){
            removeFields.push('config.language')
        }

        if (!_category?.config?.isImage) {
            removeFields.push('config.imageFile')
        }

        // @INFO Configurar el formulario
        form.removeFields(removeFields)
    }
    
    return(
        <AppLayout
            history={props.history}
            headerTitle='Catalogo'
        >
            <div
                className={`create_tool_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
                id={props.id}
            >
                <BreadcrumbComponent
                    style={{
                        marginBottom: '20px'
                    }}
                >
                    <BreadcrumbComponent.Item
                        title='Catalogador'
                        path='/cataloger'
                    />
                    <BreadcrumbComponent.Item
                        title='Lista'
                        path='/cataloger-list/tool'
                    />
                    <BreadcrumbComponent.Item
                        title={tool?.name ? tool.name : 'Nueva herramienta'}
                        current
                    />
                </BreadcrumbComponent>
                <SubtitleComponent
                    style={{ marginBottom: '15px' }}
                >
                    {params.idTool ? 'Editar herramienta' : 'Crear herramienta'}
                </SubtitleComponent>
                {tool?.apiToken ?
                    <div
                        style={{
                            marginBottom: '20px'
                        }}
                    >
                        <TextComponent
                            type='label'
                        >
                            Token: 
                        </TextComponent>
                        <CopyTextComponent
                            text={tool.apiToken}
                            style={{
                                width: '80%'
                            }}
                        />
                    </div> 
                :null}
                {tool?.id ?
                    <div
                        style={{
                            marginBottom: '20px'
                        }}
                    >
                        <TextComponent
                            type='label'
                        >
                            ID: 
                        </TextComponent>
                        <CopyTextComponent
                            text={tool.id}
                            style={{
                                width: '250px'
                            }}
                        />
                    </div> 
                :null}
                <div className="container-form">
                    <InputComponent
                        name='name'
                        label='Nombre'
                        value={form.values['name']}
                        onChange={form.handleChange}
                        invalid={form.errors['name'] ? true : false}
                        invalidText={form.errors['name']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <InputComponent
                        name='displayTitle'
                        label='Nombre para mostrar'
                        value={form.values['displayTitle']}
                        onChange={form.handleChange}
                        invalid={form.errors['displayTitle'] ? true : false}
                        invalidText={form.errors['displayTitle']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <InputComponent
                        name='description'
                        label='Descripción'
                        value={form.values['description']}
                        onChange={form.handleChange}
                        invalid={form.errors['description'] ? true : false}
                        invalidText={form.errors['description']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    {categorySelected || !params.idTool ?
                        <ToolCategoriesSelect
                            label='Categoría'
                            defaultValue={[categorySelected?.id as string]}
                            onChangeOne={handleChangeCategory}
                        />
                    :null}
                    <SubtitleComponent
                        style={{
                            gridColumn: '1/3'
                        }}
                    >
                        Configuración:
                    </SubtitleComponent>
                    {form.hasField('config.storagePeriod') ?
                        <DurationComponent
                            name='config.storagePeriod'
                            label='Periodo de almacenamiento'
                            value={form.values['config.storagePeriod']}
                            onChange={form.handleChange}
                            invalid={form.errors['config.storagePeriod'] ? true : false}
                            invalidText={form.errors['config.storagePeriod']}
                            background={theme === 'dark' ? 'background-login' : undefined}
                        />
                    :null}
                    {form.hasField('config.storageEnabled') ?
                        <CheckBoxComponent
                            name='config.storageEnabled'
                            checked={form.values['config.storageEnabled']}
                            label='Habilitar almacenamiento'
                            onChange={form.handleChange}
                        />
                    :null}
                    {form.hasField('config.initialVoltage') ?
                        <InputComponent
                            type='number'
                            name='config.initialVoltage'
                            label='Voltaje inicial'
                            value={form.values['config.initialVoltage']}
                            onChange={form.handleChange}
                            invalid={form.errors['config.initialVoltage'] ? true : false}
                            invalidText={form.errors['config.initialVoltage']}
                            background={theme === 'dark' ? 'background-login' : undefined}
                        />
                    :null}
                    {form.hasField('config.initialCurrent') ?
                        <InputComponent
                            type='number'
                            name='config.initialCurrent'
                            label='Corriente inicial'
                            value={form.values['config.initialCurrent']}
                            onChange={form.handleChange}
                            invalid={form.errors['config.initialCurrent'] ? true : false}
                            invalidText={form.errors['config.initialCurrent']}
                            background={theme === 'dark' ? 'background-login' : undefined}
                        />
                    :null}
                    {form.hasField('config.voltageRange') && form.values['config.voltageRange'] ?
                        <RangeInputComponent
                            label="Rango de voltaje"
                            defaultValue={tool?.config?.voltageRange}
                            onChange={(_range) => form.setValue('config.voltageRange', _range)}
                        />
                    :null}
                    {form.hasField('config.currentRange') && form.values['config.currentRange'] ?
                        <RangeInputComponent
                            label="Rango de corriente"
                            defaultValue={tool?.config?.currentRange}
                            onChange={(_range) => form.setValue('config.currentRange', _range)}
                        />
                    :null}
                    {form.hasField('config.storageSampleTime') ?
                        <InputComponent
                            type='number'
                            name='config.storageSampleTime'
                            label='Periodo para almacenar datos'
                            value={form.values['config.storageSampleTime']}
                            onChange={form.handleChange}
                            invalid={form.errors['config.storageSampleTime'] ? true : false}
                            invalidText={form.errors['config.storageSampleTime']}
                            background={theme === 'dark' ? 'background-login' : undefined}
                        />
                    :null}
                    {form.hasField('config.storageSampleReport') ?
                        <InputComponent
                            type='number'
                            name='config.storageSampleReport'
                            label='Frecuencia para generar reportes'
                            value={form.values['config.storageSampleReport']}
                            onChange={form.handleChange}
                            invalid={form.errors['config.storageSampleReport'] ? true : false}
                            invalidText={form.errors['config.storageSampleReport']}
                            background={theme === 'dark' ? 'background-login' : undefined}
                        />
                    :null}
                    {form.hasField('config.samplingTime') ?
                        <InputComponent
                            type='number'
                            name='config.samplingTime'
                            label='Tiempo de muestreo'
                            value={form.values['config.samplingTime']}
                            onChange={form.handleChange}
                            invalid={form.errors['config.samplingTime'] ? true : false}
                            invalidText={form.errors['config.samplingTime']}
                            background={theme === 'dark' ? 'background-login' : undefined}
                        />
                    :null}
                    {form.hasField('config.variables') ?
                        <VariablesFormModule
                            style={{
                                gridColumn: '1/3'
                            }}
                            label='Variables'
                            defaultValue={tool?.config?.variables}
                            onChange={(_vars) => form.setValue('config.variables', _vars)}
                        />
                    :null}
                    {form.hasField('config.codeEditor.language') ?
                        <Form.Group 
                            controlId="exampleForm.ControlSelect1"
                        >
                            <LabelComponent>
                                Lenguaje
                            </LabelComponent>
                            <Form.Control 
                                as="select"
                                name='config.codeEditor.language'
                                onChange={form.handleChange}
                                value={form.values['config.codeEditor.language']}
                            >
                                <option selected>-- Lenguaje --</option>
                                <option value="javascript">Javascript</option>
                                <option value="python">Python</option>
                                <option value="c">C</option>
                            </Form.Control>
                            {form.errors['config.codeEditor.language'] ? 
                                <LabelComponent style={{ color: '#dc3545', fontWeight: 400 }} >
                                    {form.errors['config.codeEditor.language']}
                                </LabelComponent>
                            : null}
                        </Form.Group>
                    :null}
                    {form.hasField('config.codeEditor.associatedTool') ?
                        <ToolSelectModule
                            label='Herramienta'
                            defaultValue={tool?.config?.codeEditor?.associatedTool ? [tool?.config?.codeEditor?.associatedTool] : undefined}
                            onChangeOne={(_id) => form.setValue('config.codeEditor.associatedTool', _id)}
                        />
                    :null}
                    {form.hasField('config.jitsi.toolbarButtons') ?
                        <JitsiSelectModule
                            isMultiple
                            label='Opciones de Jitsi'
                            defaultValue={tool?.config?.jitsi?.toolbarButtons?.length ? tool?.config?.jitsi?.toolbarButtons : undefined}
                            onChange={(_id) => form.setValue('config.jitsi.toolbarButtons', _id)}
                        />
                    :null}
                    {form.hasField('config.language') ?
                        <CodeLanguageSelect
                            label='Lenguaje soportado'
                            defaultValue={tool?.config?.language ? [tool?.config?.language] : undefined}
                            onChangeOne={(_id) => form.setValue('config.language', _id)}
                        />
                    :null}
                    {form.hasField('config.supportedLanguages') ?
                        <CodeLanguageSelect
                            isMultiple
                            label='Lenguajes compatibles'
                            defaultValue={tool?.config?.supportedLanguages ? tool?.config?.supportedLanguages : undefined}
                            onChange={(_ids) => form.setValue('config.supportedLanguages', _ids)}
                        />
                    :null}
                    {form.hasField('config.imageFile') ?
                        <InputFileComponent
                            as='image'
                            name='config.imageFile'
                            label='Imagen'
                            value={form.values['config.imageFile']}
                            onChange={form.handleChange}
                            invalid={form.errors['config.imageFile'] ? true : false}
                            invalidText={form.errors['config.imageFile']}
                        />
                    :null}
                    <div
                        style={{ gridColumn: '1/3', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                    >
                        <ButtonComponent
                            onClick={handleClickCreateOrUpdate}
                            variant='primary'
                            disabled={form.isInvalid()}
                            loading={loading}
                        >
                            {params.idTool ? 'Guardar' : 'Crear'}
                        </ButtonComponent>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

export default CreateToolPage;