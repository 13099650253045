// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import MultiSelectComponent, { IOptionSelect } from 'components/commons/MultiSelectComponent/MultiSelectComponent'
// @end components

// @import types
import * as JitsiTypes from 'types/jitsi/jitsi.types';
import { TOOLBAR_JITSI_BUTTONS } from 'types/jitsi/jitsi.types'
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './JitsiSelectModule.scss'
// @end styles

interface IJitsiSelectModuleProps {
    className?: string
    style?: CSSProperties
    id?: string
    label?: string
    defaultValue?: string[]
    isMultiple?: boolean
    onChange?: (id?: string[], toolbarButtons?: JitsiTypes.ITypeToolbarButtonsJitsi[]) => any
    onChangeOne?: (id?: string, toolbarButton?: JitsiTypes.ITypeToolbarButtonsJitsi) => any
}

const JitsiSelectModule: React.FC<IJitsiSelectModuleProps> = (props) => {
    
    const [resetSelect, updateResetSelect] = useState<boolean>(false)
    
    useEffect(() => {
        updateResetSelect(true)
        setTimeout(() => {
          updateResetSelect(false)
        },1)
    }, [props.defaultValue?.length])

    /**
     * @INFO Cambia el selector 
     * @param _options 
     */
     const handleChangeSelect = (_options: IOptionSelect[]) => {
        const buttons = TOOLBAR_JITSI_BUTTONS?.filter((_t) => _options.find((_o) => _o.value === _t))
        const button = buttons?.length ? buttons[0] : undefined
        if(props.onChangeOne){
            props.onChangeOne(button, button)
        }
        if(props.onChange){
            props.onChange(buttons?.map((_t) => _t) as string[], buttons)
        }
    }

    /**
     * @INFO Obtener las opciones por defecto
     * @returns 
     */
    const getDefaultOptions = () => {
        const _options = TOOLBAR_JITSI_BUTTONS?.reduce((accum: IOptionSelect[], item) => {
            if(props.defaultValue?.find((_d) => _d === item)){
                accum.push({
                    label: item,
                    value: item
                })
            }
            return accum
        },[])
        return _options
    }

    return(
        <div
            className={`jitsi_select_module-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            {!resetSelect ?
                <MultiSelectComponent
                    options={TOOLBAR_JITSI_BUTTONS.map((b) => { return {label: b, value: b} })}
                    disableMulti={!props.isMultiple}
                    label={props.label}
                    defaultValue={getDefaultOptions()}
                    onChange={(data) => handleChangeSelect(data)}
                />
            :null}
        </div>
    );
}

export default JitsiSelectModule;