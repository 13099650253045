import React from 'react';
import styled from 'styled-components'
import shortId from 'shortid';
import parse from 'html-react-parser';

export interface ChecklistEditorJsProps {
  data: {items: {text: string, checked: boolean}[]}
}

const Container = styled.div`
  width: 100%;
  margin-bottom: 15px;
  .cdx-checklist__item{
    display: flex;
    box-sizing: content-box;
    .cdx-checklist__item-checkbox{
      display: inline-block;
      flex-shrink: 0;
      position: relative;
      width: 20px;
      height: 20px;
      margin: 5px;
      margin-left: 0;
      margin-right: 7px;
      border-radius: 50%;
      border: 1px solid #d0d0d0;
      background: #fff;
      cursor: pointer;
      user-select: none;
      &::after{
        position: absolute;
        top: 6px;
        left: 5px;
        width: 9px;
        height: 4px;
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        background: transparent;
        content: '';
        opacity: 0;
        transform: rotate(-45deg);
      }
    }
    .cdx-checklist__item-text{
      outline: none;
      flex-grow: 1;
      padding: 5px 0;
      color: var(--text);
    }
    &.cdx-checklist__item--checked .cdx-checklist__item-checkbox{
      background: #388ae5;
      border-color: #388ae5;
      &::after{
        opacity: 1;
      }
    }
  }
`;

const ChecklistEditorJs: React.SFC<ChecklistEditorJsProps> = (props) => {
  return (
    <Container>
      {props.data?.items?.map(item => (
        <div key={shortId.generate()} className={`cdx-checklist__item ${item.checked ? 'cdx-checklist__item--checked' : ''}`}>
          <span className='cdx-checklist__item-checkbox' ></span>
          <div className='cdx-checklist__item-text' contentEditable={false} >
            {parse(item.text)}
          </div>
        </div>
      ))}
    </Container>
  );
}

export default ChecklistEditorJs;
