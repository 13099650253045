import { FC } from 'react'

import './SmartFactoryInfoCard.scss'

interface SmartFactoryInfoCardProps{
    img: string
    title: string
    description: string
}

const SmartFactoryInfoCard: FC<SmartFactoryInfoCardProps> = ({ img, title, description }) => {
    return (
        <div
            className="smart_factory_info_card--layout"
            style={{ backgroundImage: `url(${img})` }}
        >
            <div className="smart_factory_info_card--info">
                <div className="smart_factory_info_card--blur"></div>
                    <h3>{title}</h3>
                    <p>{description}</p>
            </div>
        </div>
    )
}

export default SmartFactoryInfoCard

