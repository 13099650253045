import store from 'redux/store'
import graphQLUtil from "utils/graphQL/graphQL.util"
import { gql } from "@apollo/client"

import * as LaboratoryCategoryTypes from 'types/laboratoryCategory/laboratoryCategory.types'

class laboratoryCategoryService{

    private storeObject = store

    constructor(){

    }

    /**
     * @INFO Listar las categorias de laboratorios
     * @param _params 
     */
    public list = async (_params?: LaboratoryCategoryTypes.IParamsLaboratoryCategoryList):Promise<LaboratoryCategoryTypes.ILaboratoryCategory[]> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query labCategories($token: String!){
                        labCategories(token: $token){
                            ${LaboratoryCategoryTypes.fullLaboratoryCategoryQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token
                }
            }
        })
        if(response?.data?.labCategories?.length){
            return response.data.labCategories
        }else{
            return []
        }
    }

    /**
     * @INFO Traer un laboratoryCategory por id
     * @param _params 
     */
    public getById = async (_params: LaboratoryCategoryTypes.IParamsLaboratoryCategoryGetById): Promise<LaboratoryCategoryTypes.ILaboratoryCategory | undefined> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query labCategory($token: String!, $id: ID!){
                        labCategory(token: $token, id: $id){
                            ${LaboratoryCategoryTypes.fullLaboratoryCategoryQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        })
        if(response?.data?.labCategory){
            return response.data.labCategory
        }else{
            return undefined
        }
    }

    /**
     * @INFO Crear una categoria de laboratorio
     * @param _params 
     * @returns 
     */
    public create = async (_params: LaboratoryCategoryTypes.IParamsLaboratoryCategoryCreate): Promise<LaboratoryCategoryTypes.ILaboratoryCategory | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation newLabCategory($token: String!, $labCategory: LabCategoryInput!){
                        newLabCategory(token: $token, labCategory: $labCategory){
                            ${LaboratoryCategoryTypes.fullLaboratoryCategoryQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    labCategory: {..._params.category}
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.newLabCategory){
            return response.data.newLabCategory
        }else{
            return undefined
        }
    }

    /**
     * @INFO Actualizar una categoria de laboratorio
     * @param _params 
     * @returns 
     */
    public update = async (_params: LaboratoryCategoryTypes.IParamsLaboratoryCategoryUpdate)
    : Promise<LaboratoryCategoryTypes.ILaboratoryCategory | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation updateLabCategory($token: String!, $id: ID!, $labCategory: UpdateLabCategoryInput!){
                        updateLabCategory(token: $token, id: $id, labCategory: $labCategory){
                            ${LaboratoryCategoryTypes.fullLaboratoryCategoryQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    labCategory: {..._params.category},
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.updateLabCategory){
            return response.data.updateLabCategory
        }else{
            return undefined
        }
    }

    /**
     * @INFO Eliminar una categoría de laboratorio
     * @param _params 
     * @returns 
     */
    public delete = async (_params: LaboratoryCategoryTypes.IParamsLaboratoryCategoryDelete): Promise<LaboratoryCategoryTypes.ILaboratoryCategory | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation removeLabCategory($token: String!, $id: ID!){
                        removeLabCategory(token: $token, id: $id){
                            ${LaboratoryCategoryTypes.fullLaboratoryCategoryQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.removeLabCategory){
            return response.data.removeLabCategory
        }else{
            return undefined
        }
    }

}

export default laboratoryCategoryService