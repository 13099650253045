import * as NotificationTypes from 'types/notification/notification.types';

const initialState: NotificationTypes.NotificationsState = {
    notifications: [],
    unread: 0
};

const notificationReducer = (
    state: NotificationTypes.NotificationsState = initialState,
    action: NotificationTypes.NotificationActionTypes
): NotificationTypes.NotificationsState => {
    switch(action.type){
        case NotificationTypes.GET_NOTIFICATIONS:
        case NotificationTypes.UPDATE_NOTIFICATION:
            return {
                ...state,
                notifications: action.payload
            }
        case NotificationTypes.SET_UNREAD_NOTIFICATIONS:
            return {
                ...state,
                unread: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export default notificationReducer;