// @import dependencies
import React, { useEffect, useState } from 'react';
// @end dependencies

// @import types
import * as UseReservationTypes from './useReservation.types';
import * as ReservationTypes from 'types/reservations/reservations.types';
import { IStoreApp } from 'redux/reducers/index';
// @end types

// @import services
import ReservationsService from 'services/reservations/reservationsService';
import TimeService from 'services/time/timeService';
import ScreenNotification from 'services/screenNotification/screenNotification';
// @end services

// @import hooks
import { useSelector, useDispatch } from 'react-redux';
import useMoment from 'hooks/useMoment/useMoment';
// @end hooks

// @import actions
import * as LaboratoryActions from 'redux/reducers/laboratories/laboratories.actions';
// @end actions

const useReservation = (options?: UseReservationTypes.UseReservationInput): UseReservationTypes.UseReservationOutput => {
    
    const { moment } = useMoment();
    const reservations = useSelector((store: IStoreApp) => store.laboratories.reservations);
    const currentReservation = useSelector((store: IStoreApp) => store.laboratories.currentReservation);
    const dispatch = useDispatch();
    const [triggerReview, updateTriggerReview] = useState<any>(undefined);

    // @INFO Services
    const reservationsService = new ReservationsService();
    const screenNotification = new ScreenNotification();
    const timeService = new TimeService();

    useEffect(() => {
        if (options?.startReservationReview) {
            setInterval(() => {
                updateTriggerReview({});
            }, 10000)
        }
    },[]);

    useEffect(() => {
        reviewReservations();
    }, [triggerReview, reservations.length])

    /**
     * @INFO Revisar las reservas que estan por iniciar
     */
    const reviewReservations = () => {
        let find: boolean = false;
        reservations.forEach((r) => {
            if (r.date) {
                const seconds = timeService.getDifferenceSeconds(r.date, new Date());
                if (seconds <= 5*60 && seconds > 0) {
                    if (currentReservation?.id !== r.id) {
                        dispatch(LaboratoryActions.setCurrentReservationAction(r));
                    }
                    find = true;
                } else if (seconds <= 0 && r.lab?.config?.duration && ((seconds + r.lab?.config?.duration) > 0)) {
                    if (currentReservation?.id !== r.id) {
                        dispatch(LaboratoryActions.setCurrentReservationAction(r));
                    }
                    find = true;
                }
            }
        });

        if (!find && currentReservation) {
            dispatch(LaboratoryActions.setCurrentReservationAction(undefined));
        }
    }

    const searchByDateBetween = (date: Date) => {
        const response = reservations.find((r) => {
            const startDate = moment(r.date);
            const endDate = moment(r.date).add(r.lab.config?.duration, 's');
            if (moment(date).isBetween(startDate, endDate)) {
                return true;
            } else {
                return false;
            }
        });
        return response;
    }

    const getReservationDateFormated = (reservation: ReservationTypes.IReservation) => {
        let response = '';
        if (reservation?.date) {
            response = `${response}${moment(reservation.date).format('HH:mm')} `;
        }
        if(reservation?.lab?.category?.config?.hasDuration) {
            response = `${response}- ${moment(reservation.date).add(reservation.lab.config?.duration, 's').format('HH:mm')} `;
        }
        return response;
    }

    const deleteReservation = async (reservation: ReservationTypes.IReservation): Promise<ReservationTypes.IReservation | undefined> => {
        let returnResponse:ReservationTypes.IReservation | undefined = undefined;
        const confirm = await screenNotification.showAskDelete();
        if (confirm.isConfirmed) {
            const response = await reservationsService.deleteReserve({ id: reservation.id });
            if (response) {
                dispatch(LaboratoryActions.deleteReservationAction(reservation));
                await screenNotification.showSuccessTimer();
                returnResponse = response;
            }
        }
        return returnResponse;
    }

    const updateReservation = async (params: ReservationTypes.UpdateReserveParams): Promise<ReservationTypes.IReservation | undefined> => {
        let returnResponse:ReservationTypes.IReservation | undefined = undefined;
        const response = await reservationsService.updateReserve({ id: params.id, reservation: params.reservation });
        if (response) {
            dispatch(LaboratoryActions.updateReservationAction(response));
            await screenNotification.showSuccessTimer();
            returnResponse = response;
        }
        return returnResponse;
    }
    
    const createReservation = async (params: ReservationTypes.INewReserveParams): Promise<ReservationTypes.IReservation | undefined> => {
        let returnResponse:ReservationTypes.IReservation | undefined = undefined;
        const response = await reservationsService.newReserve({ lab: params.lab, date: params.date });
        if (response) {
            dispatch(LaboratoryActions.addReservationAction(response));
            await screenNotification.showSuccessTimer();
            returnResponse = response;
        }
        return returnResponse;
    }

    return {
        reservations,
        getReservationDateFormated,
        searchByDateBetween,
        deleteReservation,
        updateReservation,
        createReservation
    }
}

export default useReservation;