import React, { CSSProperties, useEffect, useState } from 'react'

// @import components
import TableComponent from 'components/commons/TableComponent/TableComponent';
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { Link } from 'react-router-dom'
// @end components

// @import types
import * as LaboratoryCategoryTypes from 'types/laboratoryCategory/laboratoryCategory.types'
import * as LaboratoryTypes from 'types/laboratories/laboratories.types'
import * as AppTypes from 'types/app/app.types'
import * as BannerTypes from 'types/banners/banners.types'
import * as ToolCategoryTypes from 'types/toolCategory/toolCategory.types'
import * as ToolTypes from 'types/tool/tool.types'
import * as HardwareTypes from 'types/hardware/hardware.types'
// @end types

// @import hooks
import { useParams } from 'react-router-dom';
// @end hooks

// @import services
import LaboratoryCategoryService from 'services/laboratoryCategory/laboratoryCategoryService'
import LaboratoryService from 'services/laboratory/laboratoryService';
import BannerService from 'services/banner/bannerService';
import ToolCategoryService from 'services/toolCategory/toolCategoryService';
import ToolService from 'services/tool/toolService'
import HardwareService from 'services/hardware/hardwareService'
import ScreenNotification from 'services/screenNotification/screenNotification';
// @end services

// @import utils
import ListCatalogerItemsFunctions from './ListCatalogerItemsFunctions';
// @end utils

import './ListCatalogerItemsPage.scss'
import BreadcrumbComponent from 'components/commons/BreadcrumbComponent/breadcrumbComponent';

interface IListCatalogerItemsPageProps {
    className?: string
    style?: CSSProperties
    history?: any
}

const ListCatalogerItemsPage: React.FC<IListCatalogerItemsPageProps> = (props) => {

    const [laboratories, updateLaboratories] = useState<LaboratoryTypes.ILaboratory[]>([])
    const [laboratoryCategories, updateLaboratoryCategories] = useState<LaboratoryCategoryTypes.ILaboratoryCategory[]>([])
    const [banners, updateBanners] = useState<BannerTypes.IBanner[]>([])
    const [toolCategories, updateToolCategories] = useState<ToolCategoryTypes.IToolCategory[]>([])
    const [tools, updateTools] = useState<ToolTypes.ITool[]>([])
    const [hardwares, updateHardwares] = useState<HardwareTypes.IHardware[]>([])
    const params = useParams<{category: AppTypes.ITypeCategoryListCataloger}>()

    // @Servicios
    const laboratoryCategoryService = new LaboratoryCategoryService()
    const laboratoryService = new LaboratoryService()
    const bannerService = new BannerService()
    const toolCategoryService = new ToolCategoryService()
    const listCatalogerItemsFunctions = new ListCatalogerItemsFunctions()
    const toolService = new ToolService()
    const hardwareService = new HardwareService()
    const screenNotification = new ScreenNotification()

    useEffect(() => {
        getDataList()
    }, [])

    /**
     * @INFO Obtener la data para la lista 
     */
    const getDataList = async () => {
        if(params.category === 'laboratory'){
            await getLaboratories()
        }else if(params.category === 'banner'){
            await getBanners()
        }else if(params.category === 'tool-category'){
            await getToolCategories()
        }else if(params.category === 'tool'){
            await getTools()
        }else if(params.category === 'hardware'){
            await getHardwares()
        }else if(params.category === 'laboratory-category'){
            await getLaboratoryCategories()
        }
    }

    /**
     * @INFO Obtener laboratorios
     */
    const getLaboratories = async () => {
        const labs = await laboratoryService.list({ limit: 30, page: 1 })
        updateLaboratories(labs)
    }
    
    /**
     * @INFO Obtener categorías de laboratorios
     */
    const getLaboratoryCategories = async () => {
        const _categories = await laboratoryCategoryService.list()
        updateLaboratoryCategories(_categories)
    }

    /**
     * @INFO Obtener la lista de banners
     */
    const getBanners = async () => {
        const _banners = await bannerService.list()
        updateBanners(_banners)
    }

    /**
     * @INFO Obtener la lista de categorías de herramientas
     */
    const getToolCategories = async () => {
        const _categories = await toolCategoryService.list()
        updateToolCategories(_categories)
    }
    
    /**
     * @INFO Obtener la lista de herramientas
     */
    const getTools = async () => {
        const _tools = await toolService.list()
        updateTools(_tools)
    }

    /**
     * @INFO Obtener la lista de hardwares
     */
    const getHardwares = async () => {
        const _hardwares = await hardwareService.list()
        updateHardwares(_hardwares)
    }

    // ======================================================================
    // Funciones para el dataTable
    // ======================================================================

    /**
     * @INFO Obtener los items para el dataTable
     * @returns 
     */
    const getListTable = () => {
        if(params.category === 'laboratory'){
            return laboratories
        }else if(params.category === 'banner'){
            return banners
        }else if(params.category === 'tool-category'){
            return toolCategories
        }else if(params.category === 'tool'){
            return tools
        }else if(params.category === 'hardware'){
            return hardwares
        }else if(params.category === 'laboratory-category'){
            return laboratoryCategories
        }
        return []
    }

    /**
     * @INFO Click en editar del dataTable
     */
    const handleClickEdit = (_data: any) => {
        listCatalogerItemsFunctions.handleClickElement(params.category, 'edit', _data, props.history)
    }
    
    /**
     * @INFO Click en editar del dataTable
     */
    const handleClickDelete = async (_data: any) => {
        const status = await listCatalogerItemsFunctions.handleClickElement(params.category, 'delete', _data, props.history)
        if(status){
            // @INFO Elemento eliminado correctamente
            getDataList()
            screenNotification.showSuccessTimer()
        }
    }

    return(
        <AppLayout
            history={props.history}
            headerTitle='Catalogo'
        >
            <div
                className={`list_cataloger_items_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
            >
                <BreadcrumbComponent
                    style={{
                        marginBottom: '20px'
                    }}
                >
                    <BreadcrumbComponent.Item
                        title='Catalogador'
                        path='/cataloger'
                    />
                    <BreadcrumbComponent.Item
                        title='Lista'
                        current
                    />
                </BreadcrumbComponent>
                <div className="container-list-items">
                    <div className="container-actions">
                        <Link
                            to={`/create-${params.category}`}
                        >
                            <ButtonComponent>
                                <ButtonComponent.Icon>
                                    <IoIosAddCircleOutline color='var(--primary)' />
                                </ButtonComponent.Icon>
                                Nuevo
                            </ButtonComponent>
                        </Link>
                    </div>
                    <TableComponent
                        keys={listCatalogerItemsFunctions.getKeysDataTable(params.category)}
                        rows={getListTable()}
                        showEdit
                        showDelete
                        onClickEdit={handleClickEdit}
                        onClickDelete={handleClickDelete}
                    />
                </div>
            </div>
        </AppLayout>
    );
}

export default ListCatalogerItemsPage;