export interface IObjectFilterPropertyByType{
    name: string
    type: any
}

export interface ObjectTransformParams{
    root: string
    transform: TypesTransformObject
}

export type TypesTransformObject = 'string' | 'number'

class ObjectService{
    constructor(){

    }

    /**
     * @INFO Filtrar las propiedades de un objeto por el tipo que debe tener (si no es se asigna undefined)
     * @param _object 
     * @param type 
     */
    public filterPropertyByType = (_object: any, _filters : IObjectFilterPropertyByType[]) =>{
        if(!_object || typeof _object !== 'object') return _object
        let newObject = {..._object}
        _filters.forEach((itemFilter) => {
            const aux = this.getDataFromRoot(itemFilter.name, newObject)
            if(aux && typeof aux !== itemFilter.type){
                newObject = this.updatePropertyFromRoot(itemFilter.name, newObject, undefined)
            }
        })
        return {...newObject}
    }

    /**
     * @INFO Obtener el valor en la propiedad de un objeto por media de la ruta (algo.algo.algo)
     * @param root 
     * @param _object 
     * @returns 
     */
    public getDataFromRoot = (root: string, _object: any) => {
        const roots = root.split('.')
        const value = roots.reduce((acum: any, item: string) => {
            if(acum === undefined || acum === null) return undefined
            acum = acum[item]
            return acum
        }, {..._object})
        return value
    }

    /**
     * @INFO Actualizar el valor de un objeto mediante una key (algo.algo.algo)
     * @param path 
     * @param object 
     * @param value 
     * @returns 
     */
    public updatePropertyFromRoot = (path: string, object: any, value: any) => {
        var valuePath = path.split('.').filter((item) => item.length),
            last = valuePath.pop(),
            temp = object;
    
        for (let i = 0; i < valuePath.length; i++) {
            temp = temp[valuePath[i]];
        }
        // @ts-ignore
        temp[last] = value;
        return object
    }

    /**
     * @INFO Transformar un valor de una propiedad del objeto
     * @param paths 
     * @param object 
     * @param value 
     */
    public transformPropertyFromRoot = (_object: any, _transforms: ObjectTransformParams[]) => {
        if(!_object || typeof _object !== 'object') return _object
        let newObject = {..._object}
        _transforms.forEach((transform) => {
            const aux = this.getDataFromRoot(transform.root, newObject)
            if(aux!==undefined && typeof aux !== transform.transform){
                switch(transform.transform){
                    case 'number':
                        newObject = this.updatePropertyFromRoot(transform.root, newObject, Number(aux));
                        break;
                    case 'string':
                        newObject = this.updatePropertyFromRoot(transform.root, newObject, String(aux));
                        break;
                    default:
                        break;
                }
            }
        })
        return {...newObject}
    }
}

export default ObjectService