// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import ColorPicker from 'components/commons/ColorPicker/ColorPicker'
import InputComponent from 'components/commons/Input/InputComponent'
import SwitchComponent from 'components/commons/switch'
import TextComponent from 'components/commons/Text/TextComponent'
import ButtonComponent from 'components/commons/Button/ButtonComponent'
// @end components

// @import types
// @end types

// @import services
import ReservationsService from 'services/reservations/reservationsService'
// @end services

// @import hooks
import { useChangeTheme } from 'hooks/useChangeTheme/useChangeTheme';
import useValidator from 'hooks/useFormValidator/useFormValidator';
import { useDashboard } from 'hooks/useDashboard/useDashboard';
import { useDispatch } from 'react-redux';
// @end hooks

// @import actions
import * as DashboardActions from 'redux/reducers/dashboard/dashboard.actions';
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './PublicSettingsReservation.scss'
// @end styles

interface IPublicSettingsReservationProps {
    className?: string
    style?: CSSProperties
    id?: string
    onCancel?: () => void
    onSaved?: () => void
}

const PublicSettingsReservation: React.FC<IPublicSettingsReservationProps> = (props) => {
    
    const dashboard = useDashboard();
    const dispatch = useDispatch();
    const reservationsService = new ReservationsService();
    const [loading, updateLoading] = useState<boolean>(false);

    const form = useValidator([
        // Header
        { name: 'header.enabled', value: false },
        { name: 'header.title', value: '' },
        { name: 'header.backgroundColor', value: '#03133B' },
        // Gráfica
        { name: 'chart.enableReports', value: false },
        { name: 'chart.enableFilterData', value: false },
        { name: 'chart.enableSidebar', value: false },
        // Fuente
        { name: 'source.enableVoltage', value: false },
        { name: 'source.enableCurrent', value: false },
        // Botón
        { name: 'button.enable', value: false },
        // Pulsador
        { name: 'pushButton.enable', value: false },
        { name: 'pushButton.selectIcons', value: false },
    ]);
    const theme = useChangeTheme();

    useEffect(() => {
        if (dashboard.reservation?.publicSettings) {
            form.setValues(dashboard.reservation.publicSettings);
        }
    }, [dashboard.reservation?.publicSettings])

    const handleClickSave = async () => {
        updateLoading(true);
        const params = form.getValues();
        console.log('Valores a guardar: ', params);
        await dashboard.updateReservation({
            publicSettings: params
        });
        const response = await reservationsService.publish({
            id: dashboard?.reservation?.id as string
        });
        if (response) {
            dispatch(DashboardActions.setReservationAction(response));
            if (props.onSaved) {
                props.onSaved();
            }
        }
        updateLoading(false);
    }
    
    return(
        <div
            className={`public_settings_reservation-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <TextComponent
                type='subtitle'
            >
                Configuración de publicación
            </TextComponent>
            <div className="item-section">
                <ItemPublicConfig
                    title='Header'
                    checked={form.values['header.enabled']}
                    onClick={() => form.setValue('header.enabled', !form.values['header.enabled'])}
                />
                {form.values['header.enabled'] ?
                    <>
                        <hr />
                        <div
                            className='container-input-form'
                        >
                            <InputComponent
                                label='Titulo'
                                placeholder='Titulo'
                                name='header.title'
                                value={form.values['header.title']}
                                onChange={form.handleChange}
                                background={theme.theme === 'light' ? 'background2' : 'background-login'}
                            />
                            <ColorPicker
                                label='Color de fondo'
                                onChange={(c) => form.setValue('header.backgroundColor', c)}
                                value={form.values['header.backgroundColor']}
                            />
                        </div>
                    </>
                :null}
            </div>
            <div className="item-section">
                <ItemPublicConfig
                    title='Gráficas'
                    hideCheck
                />
                <>
                    <hr />
                    <div
                        className='container-input-form'
                    >
                        <SwitchComponent
                            label='Habilitar reportes'
                            checked={form.values['chart.enableReports']}
                            onClick={() => form.setValue('chart.enableReports', !form.values['chart.enableReports'])}
                        />
                        <SwitchComponent
                            label='Habilitar filtro'
                            checked={form.values['chart.enableFilterData']}
                            onClick={() => form.setValue('chart.enableFilterData', !form.values['chart.enableFilterData'])}
                        />
                        <SwitchComponent
                            label='Habilitar sidebar'
                            checked={form.values['chart.enableSidebar']}
                            onClick={() => form.setValue('chart.enableSidebar', !form.values['chart.enableSidebar'])}
                        />
                    </div>
                </>
            </div>
            <div className="item-section">
                <ItemPublicConfig
                    title='Fuentes'
                    hideCheck
                />
                <>
                    <hr />
                    <div
                        className='container-input-form'
                    >
                        <SwitchComponent
                            label='Habilitar Voltaje'
                            checked={form.values['source.enableVoltage']}
                            onClick={() => form.setValue('source.enableVoltage', !form.values['source.enableVoltage'])}
                        />
                        <SwitchComponent
                            label='Habilitar Corriente'
                            checked={form.values['source.enableCurrent']}
                            onClick={() => form.setValue('source.enableCurrent', !form.values['source.enableCurrent'])}
                        />
                    </div>
                </>
            </div>
            <div className="item-section">
                <ItemPublicConfig
                    title='Botones'
                    hideCheck
                />
                <>
                    <hr />
                    <div
                        className='container-input-form'
                    >
                        <SwitchComponent
                            label='Habilitar Botón'
                            checked={form.values['button.enable']}
                            onClick={() => form.setValue('button.enable', !form.values['button.enable'])}
                        />
                    </div>
                </>
            </div>
            <div className="item-section">
                <ItemPublicConfig
                    title='Pulsadores'
                    hideCheck
                />
                <>
                    <hr />
                    <div
                        className='container-input-form'
                    >
                        <SwitchComponent
                            label='Habilitar pulsadores'
                            checked={form.values['pushButton.enable']}
                            onClick={() => form.setValue('pushButton.enable', !form.values['pushButton.enable'])}
                        />
                        <SwitchComponent
                            label='Habilitar selector de icono'
                            checked={form.values['pushButton.selectIcons']}
                            onClick={() => form.setValue('pushButton.selectIcons', !form.values['pushButton.selectIcons'])}
                        />
                    </div>
                </>
            </div>
            <div className="container-actions-settings">
                <ButtonComponent
                    variant='secondary'
                    onClick={props.onCancel}
                >
                    Cancelar
                </ButtonComponent>
                <ButtonComponent
                    variant='primary'
                    onClick={handleClickSave}
                    loading={loading}
                >
                    Publicar
                </ButtonComponent>
            </div>
        </div>
    );
}

// ======================================================
// Item de menu para cada sección
// ======================================================
interface IItemPublicConfigProps {
    title: string;
    checked?: boolean;
    className?: string
    style?: CSSProperties
    id?: string
    hideCheck?: boolean;
    onClick?: () => void;
}

const ItemPublicConfig: React.FC<IItemPublicConfigProps> = (props) => {
    return(
        <div
            className={`item_public_config-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <TextComponent
                type='text'
                style={{fontWeight: 'bold'}}
            >
                {props.title}
            </TextComponent>
            {!props.hideCheck && props.checked !== undefined && props.onClick ?
                <SwitchComponent
                    checked={props.checked}
                    onClick={props.onClick}
                />
            :null}
        </div>
    );
}


export default PublicSettingsReservation;