// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react';
// @end dependencies

// @import components
import { MdPowerSettingsNew } from 'react-icons/md';
// @end components

// @import types
import * as ToolTypes from 'types/tool/tool.types';
// @end types

// @import services
import SocketService from 'services/socket/socketService';
// @end services

// @import hooks
import { useDashboard } from 'hooks/useDashboard/useDashboard';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ButtonModuleDashboard.scss'
// @end styles

interface IButtonModuleDashboardProps {
    tool: ToolTypes.ITool
    className?: string
    style?: CSSProperties
    id?: string
}

const ButtonModuleDashboard: React.FC<IButtonModuleDashboardProps> = (props) => {
    
    const [buttonState, updateButtonState] = useState<boolean>(false);
    const dashboard = useDashboard();

    // @INFO Servicios
    const socketService = new SocketService()

    useEffect(() => {
        // @INFO Suscribirme al evento
        if(props.tool?.category?.config?.hasEvent && props.tool.config?.event){
            socketService.listenEvent(props.tool.config?.event, handleNewData)
        }
        // @INFO Solicitar el estado del botón
        getButtonState();
        return () => {
            if(props.tool?.category?.config?.hasEvent && props.tool.config?.event){
                socketService.removeListenEvent(props.tool.config?.event, handleNewData)
            }
        }
    },[])

    const getButtonState = () => {
        socketService.emitEvent(`${props.tool?.category?.name}:web`, {
            toolEvent: `${props.tool.config?.event}/state`,
        });
    }

    /**
     * @INFO Llega un nuevo dato del socket
     * @param _data 
     */
    const handleNewData = (_data: any) => {
        if (_data?.state !== undefined) {
            updateButtonState(_data?.state);
        }
    }

    const handleClickButton = () => {
        if (dashboard.dashboardType === 'normal' || (dashboard.dashboardType === 'only_view' && dashboard.reservation?.publicSettings?.button?.enable)) {
            const newState = !buttonState;
            updateButtonState(newState);
            sendDataSocket(newState);
        }
    }

    const sendDataSocket = (state: boolean) => {
        socketService.emitEvent(`${props.tool?.category?.name}:web`, {
            toolEvent: props.tool.config?.event,
            payload: {
                state
            }
        });
    }

    return(
        <div
            className={`button_module_dashboard-layout ${props.className ? props.className : ''}`}
            style={{
                ...props.style
            }}
            id={props.id}
        >
            <div 
                className={`container-icon-button ${buttonState ? 'active' : ''}`}
                onClick={handleClickButton}
            >
                <MdPowerSettingsNew/>
            </div>
        </div>
    );
}

export default ButtonModuleDashboard;