import * as appTypes from 'types/app/app.actions.types'

const initialState: appTypes.IAppState = {
    theme: localStorage.getItem('theme') ? localStorage.getItem('theme') as appTypes.ITypeThemeApp : 'dark',
    sidebarState: false,
    language: 'es',
    systemType: 'default'
}

const appReducer = (state: appTypes.IAppState = initialState, action: any): appTypes.IAppState => {
    switch(action.type){
        case appTypes.UPDATE_THEME:
            return {
                ...state,
                theme: action.payload
            }
        case appTypes.UPDATE_SIDEBAR_STATE:
            return {
                ...state,
                sidebarState: action.payload
            }
        case appTypes.UPDATE_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }
        case appTypes.UPDATE_SYSTEM_CONFIG:
            return {
                ...state,
                systemConfig: action.payload
            }
        case appTypes.UPDATE_SYSTEM_TYPE:
            return {
                ...state,
                systemType: action.payload
            }
        default: 
            return {...state}
    }
}

export default appReducer;