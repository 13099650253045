import store from 'redux/store'
import graphQLUtil from "utils/graphQL/graphQL.util"
import { gql } from "@apollo/client"

// @import types
import * as LaboratoriesTypes from 'types/laboratories/laboratories.types'
import * as ReservationTypes from 'types/reservations/reservations.types';
import { IValidateLaboratoryResponse } from 'types/laboratories/laboratories.types';
// @end types

class LaboratoryService{

    private storeObject = store

    constructor(){

    }

    /**
     * @INFO Traer la lista de laboratorios
     * @param _params 
     */
    public list = async (_params?: LaboratoriesTypes.IParamsLaboratoriesList) => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query labs($token: String!, $page: Float, $limit: Float, $filter: LabFilter){
                        labs(token: $token, page: $page, limit: $limit, filter: $filter){
                            ${LaboratoriesTypes.fullLaboratoryQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    limit: _params?.limit,
                    page: _params?.page,
                    filter: _params?.filter
                }
            }
        })
        if(response?.data?.labs?.length){
            return response.data.labs
        }else{
            return []
        }
    }
   
    /**
     * @INFO Buscar laboratorios
     * @param _params 
     */
    public searchLabs = async (_params: LaboratoriesTypes.ParamsSearchLabs) => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query searchLabs($token: String!, $search: String!){
                        searchLabs(token: $token, search: $search){
                            ${LaboratoriesTypes.fullLaboratoryQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    search: _params?.search
                }
            }
        })
        if(response?.data?.searchLabs?.length){
            return response.data.searchLabs
        }else{
            return []
        }
    }

    /**
     * @INFO Obtener laboratorio por ID
     * @param _params 
     */
    public getById = async (_params: LaboratoriesTypes.IParamsLaboratoriesGetById)
    :Promise<LaboratoriesTypes.ILaboratory | undefined> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query lab($token: String, $id: ID!){
                        lab(token: $token, id: $id){
                            ${LaboratoriesTypes.fullLaboratoryQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params?.id
                }
            }
        })
        if(response?.data?.lab){
            return response.data.lab
        }else{
            return undefined
        }
    }

    /**
     * @INFO Crear un nuevo laboratorio
     * @param _params : es any porque existen muchas configuraciones
     * @returns 
     */
    public create = async (_params: any)
    : Promise<LaboratoriesTypes.ILaboratory | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation newLab($token: String!, $lab: LabInput!){
                        newLab(token: $token, lab: $lab){
                            ${LaboratoriesTypes.fullLaboratoryQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    lab: {..._params}
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.newLab){
            return response.data.newLab
        }else{
            return undefined
        }
    }
    
    /**
     * @INFO Actualizar un laboratorio
     * @param _params 
     * @returns 
     */
    public update = async (_params: any, id: string)
    : Promise<LaboratoriesTypes.ILaboratory | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation updateLab($token: String!, $id: ID!, $lab: UpdateLabInput!){
                        updateLab(token: $token, id: $id, lab: $lab){
                            ${LaboratoriesTypes.fullLaboratoryQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    lab: {..._params},
                    id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.updateLab){
            return response.data.updateLab
        }else{
            return undefined
        }
    }
    
    /**
     * @INFO Agregar un laboratorio a la lista de laboratorios
     * @param _params 
     * @returns 
     */
    public addToMyLabs = async (_params: LaboratoriesTypes.ParamsAddToMyLabs)
    : Promise<boolean | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation addToMyLabs($token: String!, $lab: ID!){
                        addToMyLabs(token: $token, lab: $lab)
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    lab: _params.lab
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.addToMyLabs){
            return response.data.addToMyLabs
        }else{
            return undefined
        }
    }

    /**
     * @INFO Traer la lista de mis laboratorios
     * @param _params 
     */
    public myLabs = async (_params?: LaboratoriesTypes.ParamsMyLabs): Promise<LaboratoriesTypes.ILaboratory[]> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query myLabs($token: String!){
                        myLabs(token: $token){
                            ${LaboratoriesTypes.fullLaboratoryQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token
                }
            }
        })
        if(response?.data?.myLabs?.length){
            return response.data.myLabs
        }else{
            return []
        }
    }
    
    /**
     * @INFO Traer la lista de mis laboratorios activos
     * @param _params 
     */
    public myActiveLabs = async (_params?: LaboratoriesTypes.ParamsMyActiveLabs): Promise<LaboratoriesTypes.ILaboratory[]> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query myActiveLabs($token: String!){
                        myActiveLabs(token: $token){
                            ${LaboratoriesTypes.fullLaboratoryQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token
                }
            }
        })
        if(response?.data?.myActiveLabs?.length){
            return response.data.myActiveLabs
        }else{
            return []
        }
    }
    
    /**
     * @INFO Verificar si el usuario tiene acceso a un laboratorio
     * @param _params 
     */
    public validateAccess = async (_params: LaboratoriesTypes.ParamsValidAccessLab): Promise<IValidateLaboratoryResponse | undefined> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query validateAccess($token: String!, $lab: ID!){
                        validateAccess(token: $token, lab: $lab){
                            allow
                            reservation{
                                ${ReservationTypes.fullReservationQuery}
                            }
                            reservations{
                                ${ReservationTypes.fullReservationQuery}
                            }
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    lab: _params?.lab
                }
            }
        })
        if(response?.data?.validateAccess){
            return response.data.validateAccess
        }else{
            return undefined
        }
    }
}

export default LaboratoryService