import React, { CSSProperties } from 'react'
import { Table, Form } from 'react-bootstrap'
import shortid from 'shortid'
import { MdModeEdit, MdDelete } from 'react-icons/md'
import { AiOutlineEye } from 'react-icons/ai'
import { IoMdOpen } from 'react-icons/io'
import './TableComponent.scss'
import TooltipComponent from '../TooltipComponent/TooltipComponent'
import ImageComponent from '../ImageComponent/ImageComponent'
import useMoment from 'hooks/useMoment/useMoment'
import CopyTextComponent from '../CopyTextComponent/CopyTextComponent'
import CheckBoxComponent from 'components/commons/CheckBoxComponent/CheckBoxComponent';

export interface IKeyTable{
    key: string
    name?: string
    type?: 'switch' | 'text' | 'image' | 'copy-text' | 'checkbox'
    formatMoment?: string
}

export interface TableComponentProps {
    keys: IKeyTable[]
    rows: any[]
    className?: string
    style?: CSSProperties
    showDelete?: boolean
    showEdit?: boolean
    showView?: boolean
    showGoTo?: boolean
    onClickSwitch?: (row: number) => any
    onClickDelete?: (item: any)=>any
    onClickEdit?: (item: any)=>any
    onClickView?: (item: any)=>any
    onClickGoTo?: (item: any)=>any
    onClickCheckbox?: (item: any)=>any
}
 
const TableComponent: React.SFC<TableComponentProps> = (props) => {

    const { moment } = useMoment()

    const isActionButton = () => {
        if(
            props.showDelete ||
            props.showEdit ||
            props.showView || 
            props.showGoTo
        ){
            return true
        }else{
            return false
        }
    }

    const handleClickEdit = (item: any) => {
        if(props.onClickEdit){
            props.onClickEdit(item)
        }
    }
    
    const handleClickDelete = (item: any) => {
        if(props.onClickDelete){
            props.onClickDelete(item)
        }
    }
    
    const handleClickView = (item: any) => {
        if(props.onClickView){
            props.onClickView(item)
        }
    }
    
    const handleClickGoTo = (item: any) => {
        if(props.onClickGoTo){
            props.onClickGoTo(item)
        }
    }

    const handleClickCheckbox = (item: any) => {
        if (props.onClickCheckbox) {
            props.onClickCheckbox(item)
        }
    }

    return (  
        <div 
            className={`table_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <Table
                // striped
                bordered
                hover
            >
                <thead>
                    <tr>
                        {props.keys.map((itemHead) => (
                            <th key={shortid.generate()} >{itemHead.name ? itemHead.name: itemHead.key}</th>
                        ))}
                        {isActionButton() ?
                            <th>Acciones</th>
                        :null}
                    </tr>
                </thead>
                <tbody>
                    {props.rows.map((itemRow) => (
                        <tr
                            key={shortid.generate()}
                        >
                            {props.keys.map((itemKey) => (
                                <>
                                    {itemKey.type !== 'image' && itemKey.type !== 'copy-text' ?
                                        <td
                                            key={shortid.generate()}
                                        >
                                            {!itemKey.type || itemKey.type === 'text' ?
                                                itemKey.formatMoment ?
                                                    moment(itemRow[itemKey.key]).format(itemKey.formatMoment)
                                                :
                                                    itemRow[itemKey.key]
                                            :null}
                                            {itemKey.type && itemKey.type === 'switch' ?
                                                <Form.Check
                                                    type='switch'
                                                    checked={itemRow[itemKey.key]}
                                                />
                                            :null}
                                            {itemKey.type && itemKey.type === 'checkbox' ?
                                                <CheckBoxComponent
                                                    checked={itemRow[itemKey.key]}
                                                    onChange={() => handleClickCheckbox(itemRow)}
                                                />
                                            :null}
                                        </td>
                                    :null}
                                    {itemKey.type && itemKey.type === 'image' ?
                                        <td
                                            className='container-image-table'
                                            key={shortid.generate()}
                                        >
                                            <div className="container-image">
                                                <ImageComponent
                                                    src={itemRow[itemKey.key]}
                                                    withZoom
                                                />
                                            </div>
                                        </td>
                                    :null}
                                    {itemKey.type && itemKey.type === 'copy-text' ?
                                    <td
                                        key={shortid.generate()}
                                        className='col-token'
                                    >
                                        <CopyTextComponent
                                            text={itemRow[itemKey.key] as string}
                                        />
                                    </td>
                                    :null}
                                </>
                            ))}
                            {isActionButton() ?
                                <td
                                    className='col-actions'
                                    key={shortid.generate()}
                                >
                                    <div
                                        className='container-actions-table'
                                    >
                                        {props.showEdit ?
                                            <TooltipComponent
                                                text='Editar'
                                            >
                                                <MdModeEdit 
                                                    onClick={()=>handleClickEdit(itemRow)}
                                                    className='icon-table' 
                                                />
                                            </TooltipComponent>
                                        : null}
                                        {props.showDelete ?
                                            <TooltipComponent
                                                text='Eliminar'
                                            >
                                                <MdDelete 
                                                    onClick={()=>handleClickDelete(itemRow)}
                                                    className='icon-table' 
                                                />
                                            </TooltipComponent>
                                        : null}
                                        {props.showView ?
                                            <TooltipComponent
                                                text='Ver'
                                            >
                                                <AiOutlineEye 
                                                    onClick={()=>handleClickView(itemRow)}
                                                    className='icon-table' 
                                                />
                                            </TooltipComponent>
                                        : null}
                                        {props.showGoTo ?
                                            <TooltipComponent
                                                text='Ir'
                                            >
                                                <IoMdOpen 
                                                    onClick={()=>handleClickGoTo(itemRow)}
                                                    className='icon-table' 
                                                />
                                            </TooltipComponent>
                                        : null}
                                    </div>
                                </td>
                            : null}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
 
export default TableComponent;