import Swal, { SweetAlertOptions } from 'sweetalert2'

interface IOptionsShowSuccess{
    timer?: number
    icon?: SweetAlertOptions['icon']
    title?: string
    text?: string
}

interface IOptionsAskDelete{
    icon?: SweetAlertOptions['icon']
    title?: string
    text?: string
    cancelText?: string
    confirmText?: string
}

class ScreenNotification{
    constructor(){

    }

    /**
     * @INFO Mostrar notificación exitosa con timer
     * @param text 
     * @param _options 
     */
    public showSuccessTimer = async (_options?: IOptionsShowSuccess) => {
        await Swal.fire({
            title: _options?.title,
            text: _options?.text ? _options.text : 'Datos guardados correctamente',
            timer: _options?.timer ? _options.timer : 1500,
            icon: _options?.icon ? _options.icon : 'success',
            showCancelButton: false,
            showConfirmButton: false
        })
    }
    
    /**
     * @INFO Mostrar notificación de error con timer
     * @param text 
     * @param _options 
     */
    public showFailedTimer = async (_options?: IOptionsShowSuccess) => {
        await Swal.fire({
            title: _options?.title,
            text: _options?.text ? _options.text : 'Datos guardados correctamente',
            timer: _options?.timer ? _options.timer : 1500,
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: false
        })
    }

    /**
     * @INFO Mostrar una notificación de pregunta antes de eliminar
     * @param _options 
     */
    public showAskDelete = async (_options?: IOptionsAskDelete) => {
        const response = await Swal.fire({
            title: _options?.title ? _options.title : '¿Eliminar?',
            text: _options?.text,
            icon: _options?.icon ? _options.icon : 'warning',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: _options?.confirmText ? _options.confirmText : 'Eliminar',
            cancelButtonText: _options?.cancelText ? _options.cancelText :'Cancelar'
        })
        return response
    }

}

export default ScreenNotification