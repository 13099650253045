import React from 'react';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  font-family: var(--font-text);
  font-size: 1.2rem;
`;

export interface ClickButtonForNextProps {
  onClick: ()=>void
}

const ClickButtonForNext: React.SFC<ClickButtonForNextProps> = (props) => {

  const {t} = useTranslation()

  return (
    <Container
      onClick={props.onClick}
    >
      <strong>{t('help_module.custom.click_button')}</strong>
    </Container>
  );
}

export default ClickButtonForNext;
