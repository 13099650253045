import { SystemConfig } from "types/app/app.actions.types";

const config: SystemConfig = {
    public_routes: ['/dashboard-laboratory', '/laboratory-preview'],
    block_routes: ['/', '', '/contact', '/login', '/register'],
    modules: ['HEADER'],
    default_redirect: 'http://localhost:3000/',
    dashboard_type: 'only_view',
    header: {
        type: 'custom',
        modules: ['title', 'theme']
    },
    footer: {
        type: 'custom'
    }
}

export default config;