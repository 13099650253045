import React from 'react';
import styled from 'styled-components'
import parse from 'html-react-parser';

export interface RawEditorJsProps {
  data: {html: string}
}

const Container = styled.div`
  padding: 0.4rem 0;
  width: 100%;
  .container-code{
    min-height: 200px;
    resize: vertical;
    border-radius: 8px;
    border: 0;
    background-color: #1e2128;
    font-family: Menlo, Monaco, Consolas, Courier New, monospace;
    font-size: 12px;
    line-height: 1.6;
    letter-spacing: -0.2px;
    color: #a1a7b6;
    overscroll-behavior: contain;
    width: 100%;
    padding: 8px;
  }
`;

const RawEditorJs: React.SFC<RawEditorJsProps> = (props) => {
  return (
    <Container>
      <textarea
        className='container-code'
        disabled
      >
        {parse(props.data.html)}
      </textarea>
    </Container>
  );
}

export default RawEditorJs;
