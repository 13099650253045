export type ITypeToolbarButtonsJitsi = 
   'camera' |
   'chat' |
   'closedcaptions' |
   'desktop' |
   'download' |
   'embedmeeting' |
   'etherpad' |
   'feedback' |
   'filmstrip' |
   'fullscreen' |
   'hangup' |
   'help' |
   'invite' |
   'livestreaming' |
   'microphone' |
   'mute-everyone' |
   'mute-video-everyone' |
   'participants-pane' |
   'profile' |
   'raisehand' |
   'recording' |
   'security' |
   'select-background' |
   'settings' |
   'shareaudio' |
   'sharedvideo' |
   'shortcuts' |
   'stats' |
   'tileview' |
   'toggle-camera' |
   'videoquality' 

export interface IJitsiDevice{
    deviceId: string
    groupId: string
    kind: 'audioinput' | 'audioOutput' | 'videoInput'
    label: string
}

export interface IAvailableDeviceJitsi{
    audioInput: IJitsiDevice[]
    audioOutput: IJitsiDevice[]
    videoInput: IJitsiDevice[]
}

export interface INewJitsiMeet{
    roomName: string
}

export interface IParamsNewJitsiMeet{

}

export const fullJitsiQuery = `
    roomName
`

export const TOOLBAR_JITSI_BUTTONS: ITypeToolbarButtonsJitsi[] = [
    'camera' ,
   'chat' ,
   'closedcaptions' ,
   'desktop' ,
   'download' ,
   'embedmeeting' ,
   'etherpad' ,
   'feedback' ,
   'filmstrip' ,
   'fullscreen' ,
   'hangup' ,
   'help' ,
   'invite' ,
   'livestreaming' ,
   'microphone' ,
   'mute-everyone' ,
   'mute-video-everyone' ,
   'participants-pane' ,
   'profile' ,
   'raisehand' ,
   'recording' ,
   'security' ,
   'select-background' ,
   'settings' ,
   'shareaudio' ,
   'sharedvideo' ,
   'shortcuts' ,
   'stats' ,
   'tileview' ,
   'toggle-camera' ,
   'videoquality'
]