// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import TextComponent from 'components/commons/Text/TextComponent';
import Slider from 'react-slick';
import { AiFillTool } from 'react-icons/ai';
import TooltipComponent from 'components/commons/TooltipComponent/TooltipComponent';
// @end components

// @import types
import * as ToolTypes from 'types/tool/tool.types'
import { Layout } from 'react-grid-layout';
// @end types

// @import services
// @end services

// @import hooks
import { useResizeDetector } from 'react-resize-detector';
import { useDashboard } from 'hooks/useDashboard/useDashboard';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ToolBarDashboard.scss';
// @end styles

interface IToolBarDashboardProps {
    tools: ToolTypes.ITool[]
    className?: string
    style?: CSSProperties
    id?: string
    defaultLayout?: Layout[]
    onChangeToolsToRender: (tools: ToolTypes.ToolToRender[]) => void
}

const ToolBarDashboard: React.FC<IToolBarDashboardProps> = (props) => {

    const [toolsToRender, updateToolsToRender] = useState<ToolTypes.ToolToRender[]>([]);
    const dashboardHook = useDashboard();
    const container = useResizeDetector();

    useEffect(() => {
        if(props.tools){
            const newTools: ToolTypes.ToolToRender[] = props.tools.reduce((accumTools: ToolTypes.ToolToRender[], tool) => {
                let show = false;
                if (props.defaultLayout?.length && props.defaultLayout.find((l) => l.i === tool.id)) {
                    show = true;
                }
                accumTools.push({
                    ...tool,
                    show
                })
                return accumTools;
            }, []);
            updateToolsToRender(newTools);
        }
    }, []);

    useEffect(() => {
        const showTools = toolsToRender?.filter((t) => t.show);
        if (showTools) {
            props.onChangeToolsToRender(showTools);
        }
    },[toolsToRender])

    const getNumberItems = () => {
        const width = container.width ? container.width : 1000;
        const minWidthCard = 220;
        const numberItems = Math.trunc(width / minWidthCard);
        return numberItems;
    }

    const handleClickTool = (id: string) => {
        const idx = toolsToRender.findIndex((t) => t.id === id);
        if(idx >= 0){
            const newTools = [...toolsToRender];
            newTools[idx].show = !newTools[idx].show;
            updateToolsToRender([...newTools]);
        }
    }

    return(
        dashboardHook.dashboardType === 'normal' ?
        <div
            className={`tool_bar_dashboard-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
            ref={container.ref}
        >
            {toolsToRender?.length ?
                <Slider
                    dots={false}
                    adaptiveHeight={true}
                    infinite={false}
                    arrows
                    autoplay={false}
                    draggable={false}
                    autoplaySpeed={4000}
                    speed={500}
                    slidesToShow={getNumberItems()}
                    slidesToScroll={1}
                >
                    {toolsToRender.map((t) => (
                        <ItemToolBar
                            name={t.displayTitle ? t.displayTitle : ''}
                            active={t.show}
                            key={t.id}
                            onClick={() => handleClickTool(t.id ? t.id : '')}
                        />
                    ))}
                </Slider>
            :null}
        </div>
        :null
    );
}

// ==========================================================
// Item de toolbar
// ==========================================================
interface IItemToolBarProps {
    name: string
    active?: boolean
    className?: string
    style?: CSSProperties
    id?: string
    onClick?: () => void
}

const ItemToolBar: React.FC<IItemToolBarProps> = (props) => {
    return(
        <div
            className={`item_tool_bar-layout ${props.active ? 'active' : ''} ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
            onClick={props.onClick}
        >
            <TooltipComponent
                text={props.name}
            >
                <div className="container-content-item-tool-bar">
                    <AiFillTool
                        className='icon-item-tool-bar'
                    />
                    <TextComponent>
                        {props.name}
                    </TextComponent>
                </div>
            </TooltipComponent>
        </div>
    );
}


export default ToolBarDashboard;