import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import Scrollspy from 'react-scrollspy'

import './SmartFactoryNavBar.scss'

import SmartFactory from 'assets/images/landing/customLandings/SmartFactory/SmartFactoryColor.png'
import BackgroundLogo from 'assets/images/landing/customLandings/SmartFactory/ImageHeader.png'

import FacebookLogo from 'assets/images/landing/customLandings/SmartFactory/facebook.png'
import WhatsappLogo from 'assets/images/landing/customLandings/SmartFactory/whatsapp.png'
import TwitterLogo from 'assets/images/landing/customLandings/SmartFactory/twitter.png'
import YoutubeLogo from 'assets/images/landing/customLandings/SmartFactory/youtube.png'
import ShareService from 'services/share/shareService'
import useWindowWidth from 'hooks/useWindowWidth/useWindowWidth'
import { BiMenu } from 'react-icons/bi'
import { AiOutlineCloseCircle } from 'react-icons/ai'

interface SmartFactoryNavBarProps {
    className?: string
}

const linkItems = ['Inicio', 'Caracteristicas', 'Funcionalidades']

const SmartFactoryNavBar: FC<SmartFactoryNavBarProps> = ({ className }) => {

    const [open, updateOpen] = useState<boolean>(false);
    const { windowWidth } = useWindowWidth();

    const shareService = new ShareService()

    return (
        <Scrollspy items={linkItems} currentClassName='' className={`smart_factory_nav_bar--layout ${className ? className : ''}`}>
            <div className="smart_factory_nav_bar--icon">
                <img src={SmartFactory} alt="smart factory logo" />
                <img src={BackgroundLogo} alt='smart factory background logo' />
            </div>

            {windowWidth <= 960 && !open ?
                <div
                    className='container-menu-movil'
                    onClick={() => updateOpen(true)}
                >
                    <BiMenu/>
                </div>
            :null}

            <div className={`smart_factory_nav_bar--actions ${open ? 'open' : ''}`}>
                {windowWidth <= 960 ? 
                    <div 
                        className="close-icon-menu-movil"
                        onClick={() => updateOpen(false)}
                    >
                        <AiOutlineCloseCircle/>
                    </div>
                :null}
                <a className='smart_factory_nav_bar--link' href="#Inicio" >
                    Inicio
                </a>
                <a className='smart_factory_nav_bar--link' href="#Caracteristicas" >
                    Caracteristicas
                </a>
                <a className='smart_factory_nav_bar--link' href="#Funcionalidades" >
                    Funcionalidades
                </a>
                <Link className='smart_factory_nav_bar--link' to="/contact" >
                    Contáctenos
                </Link>
                <Link className='smart_factory_nav_bar--login' to="/login" >
                    Iniciar Sesion
                </Link>
                <div className="smart_factory_nav_bar--social-networks">
                    <a href="https://www.facebook.com/profile.php?id=100083787709486" target='_blank' rel='noreferrer'>
                        <img src={FacebookLogo} alt="facebook" />
                    </a>
                    <a href="https://twitter.com/SmartFactory98" target='_blank' rel='noreferrer'>
                        <img src={TwitterLogo} alt="twitter" />
                    </a>
                    <a href="https://youtu.be/gh3wCtDMTxc" target='_blank' rel='noreferrer'>
                        <img src={YoutubeLogo} alt="youtube" />
                    </a>
                    <a href={shareService.getWhatsappChatUrl('573167171881')} target='_blank' rel='noreferrer'>
                        <img src={WhatsappLogo} alt="whatsapp" />
                    </a>
                </div>
            </div>
        </Scrollspy>
    )
}

export default SmartFactoryNavBar
