// @INFO Apollo client
import { 
    ApolloClient, 
    InMemoryCache, 
    MutationOptions, 
    NormalizedCacheObject, 
    OperationVariables, 
    QueryOptions, 
    SubscriptionOptions, 
    WatchQueryOptions 
} from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { base_url } from 'config/globals'
import store from 'redux/store'
import Swal from 'sweetalert2'
import * as AuthActions from 'redux/reducers/auth/auth.actions'

export interface IParamsGQL{
    method: 'query' | 'mutate' | 'subscribe' | 'watch'
    data?: QueryOptions<OperationVariables, any> | MutationOptions<any, OperationVariables> | SubscriptionOptions<OperationVariables, any>,
    query?: QueryOptions<OperationVariables, any>,
    mutate?: MutationOptions<any, OperationVariables>,
    subscribe?: SubscriptionOptions<OperationVariables, any>,
    watch?: WatchQueryOptions<OperationVariables, any>
}

export interface IGraphQLUtilOptions{
    alertOnFailed?: boolean
}

class GraphQLUtil{

    private gqlClient: ApolloClient<NormalizedCacheObject>
    private storeObject = store

    constructor(){  
        this.gqlClient = new ApolloClient({
            uri: base_url,
            cache: new InMemoryCache(),
            link: createUploadLink({uri: base_url}),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'ignore'
                },
                query:{
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'ignore'
                }
            }
        })
    }

    /**
     * @INFO Hacer una petición con gql
     */
    public send = async (_params: IParamsGQL, _options?: IGraphQLUtilOptions) => {
        let response: any = {}
        try{
            response = await this.getFromMethod(_params)
        }catch(e: any){
            console.log('Catch: ', e);
            if(e.response){
                response = e.response
            }else{
                response = {}
            }
        }
        if(response?.graphQLErrors?.length){
            // @INFO Mostrar mensaje de error
            if(_options?.alertOnFailed){
                let errorMessage = ""
                if(response?.graphQLErrors?.length){
                    response?.graphQLErrors.forEach((item: any) => {
                        errorMessage += `${item.message} - `
                    })
                }
                Swal.fire({
                    text: errorMessage,
                    icon: 'error',
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false
                })
            }
        }
        if(response?.errors?.length){
            // @INFO Mostrar mensaje de error
            if(_options?.alertOnFailed){
                let errorMessage = ""
                response?.errors.forEach((item: any) => {
                    errorMessage += `${item.message} - `
                })
                Swal.fire({
                    text: errorMessage,
                    icon: 'error',
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false
                })
            }
            // @INFO Si el TOKEN ya no es valido muestro el alerta y cierro sesión
            const _invalidToken = response?.errors?.find((_e: any) => _e.status === "EXPIRED_TOKEN")
            if(_invalidToken){
                await Swal.fire({
                    text: 'Tu sesión ha expirado, debes iniciar sesión de nuevo.',
                    icon: 'error',
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false
                })
                // @ts-ignore
                this.storeObject.dispatch(AuthActions.logoutAction())
                window.location.reload()
            }
        }
        // console.log('Response graphQL string: ', JSON.stringify(response));
        // console.log('Response graphQL: ', response);
        return response
    }

    /**
     * @INFO Hacer petición según el método 
     * @param _params 
     */
    private getFromMethod = async (_params: IParamsGQL) => {
        switch(_params.method){
            case 'query':
                return this.gqlClient.query({..._params.data as QueryOptions<OperationVariables, any>, errorPolicy: 'all'})
            case 'mutate':
                return this.gqlClient.mutate({..._params.data as MutationOptions<any, OperationVariables>, errorPolicy: 'all'})
            case 'subscribe':
                return this.gqlClient.subscribe({..._params.data as SubscriptionOptions<OperationVariables, any>, errorPolicy: 'all'})
            case 'watch':
                if(_params.watch){
                    return this.getWatchQuery(_params.watch)
                }
                break;
            default:
                return new Promise((resolve, reject) => resolve({}))
        }
        return new Promise((resolve, reject) => resolve({}))
    }

    /**
     * @INFO Obtener la respuesta de un watch
     * @param watch 
     */
    private getWatchQuery = async (watch: WatchQueryOptions<OperationVariables, any>) => {
        return new Promise((resolve, reject) => {
            this.gqlClient.watchQuery({...watch, errorPolicy: 'all'}).subscribe((data: any) => {
                resolve(data)
            }, (error) => {
                resolve(error)
            })
        })
    }
}

const graphQLUtil = new GraphQLUtil()

export default graphQLUtil