import store from 'redux/store'
import graphQLUtil from "utils/graphQL/graphQL.util"
import { gql } from "@apollo/client"

import * as HardwareTypes from 'types/hardware/hardware.types'

class HardwareService{

    private storeObject = store

    constructor(){

    }

    /**
     * @INFO Traer la lista de hardware
     * @param _params 
     */
    public list = async (_params?: HardwareTypes.IParamsHardwareList): Promise<HardwareTypes.IHardware[]> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query hardwares($token: String!){
                        hardwares(token: $token){
                            ${HardwareTypes.fullHardwareQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token
                }
            }
        })
        if(response?.data?.hardwares?.length){
            return response.data.hardwares
        }else{
            return []
        }
    }
    
    /**
     * @INFO Traer un hardware por id
     * @param _params 
     */
    public getById = async (_params: HardwareTypes.IParamsHardwareGetById): Promise<HardwareTypes.IHardware | undefined> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query hardware($token: String!, $id: ID!){
                        hardware(token: $token, id: $id){
                            ${HardwareTypes.fullHardwareQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        })
        if(response?.data?.hardware){
            return response.data.hardware
        }else{
            return undefined
        }
    }

    /**
     * @INFO Crear un hardware
     * @param _params 
     * @returns 
     */
    public create = async (_params: HardwareTypes.IParamsHardwareCreate): Promise<HardwareTypes.IHardware | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation newHardware($token: String!, $hardware: HardwareInput!){
                        newHardware(token: $token, hardware: $hardware){
                            ${HardwareTypes.fullHardwareQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    hardware: {..._params.hardware}
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.newHardware){
            return response.data.newHardware
        }else{
            return undefined
        }
    }

    /**
     * @INFO Actualizar un hardware
     * @param _params 
     * @returns 
     */
    public update = async (_params: HardwareTypes.IParamsHardwareUpdate)
    : Promise<HardwareTypes.IHardware | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation updateHardware($token: String!, $id: ID!, $hardware: UpdateHardwareInput!){
                        updateHardware(token: $token, id: $id, hardware: $hardware){
                            ${HardwareTypes.fullHardwareQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    hardware: {..._params.hardware},
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.updateHardware){
            return response.data.updateHardware
        }else{
            return undefined
        }
    }

    /**
     * @INFO Eliminar un hardware
     * @param _params 
     * @returns 
     */
    public delete = async (_params: HardwareTypes.IParamsHardwareDelete): Promise<HardwareTypes.IHardware | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation removeHardware($token: String!, $id: ID!){
                        removeHardware(token: $token, id: $id){
                            ${HardwareTypes.fullHardwareQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.removeHardware){
            return response.data.removeHardware
        }else{
            return undefined
        }
    }
}

export default HardwareService