// @import dependencies
import React, { CSSProperties } from 'react'
// @end dependencies

// @import components
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './CodeEditorResponse.scss'
import * as ToolTypes from 'types/tool/tool.types';
import TextComponent from 'components/commons/Text/TextComponent';
// @end styles

interface ICodeEditorResponseProps {
    className?: string
    style?: CSSProperties
    id?: string
    response: ToolTypes.CodeEditorResponse;
}

const CodeEditorResponse: React.FC<ICodeEditorResponseProps> = (props) => {

    const getSuccessMessage = () => {
        switch(props.response.step) {
            case 'compile':
                return 'Código compilado correctamente.';
            case 'upload':
                return 'Código subido correctamente.';
            default: 
                return 'Código compilado correctamente.';
        }
    }

    return(
        <div
            className={`code_editor_response-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <TextComponent
                type='label'
            >
                {props.response.step}:
            </TextComponent>
            <div className="container-message">
                {props.response?.errors ?
                    <TextComponent
                        type='label'
                        style={{
                            color: 'var(--error)'
                        }}
                    >
                        {props.response.errors}
                    </TextComponent>
                : null}
                {props.response?.status ?
                    <TextComponent
                        type='label'
                        style={{
                            color: 'var(--success)'
                        }}
                    >
                        {getSuccessMessage()}
                    </TextComponent>
                : null}
            </div>
            {props.response?.logs?.length ?
                <>
                    <TextComponent
                        type='label'
                    >
                        Logs:
                    </TextComponent>
                    <div className="container-message">
                        {props.response.logs.map(l => (
                            <TextComponent
                                type='label'
                            >
                                {l}
                            </TextComponent>
                        ))}
                    </div>
                </>
            : null}
        </div>
    );
}

export default CodeEditorResponse;