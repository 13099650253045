import React, { CSSProperties } from 'react'
import { AiFillCamera } from 'react-icons/ai';

import './ImgProfileComponent.scss'

interface IImgProfileComponentProps {
    className?: string
    style?: CSSProperties
    src: string
    alt?: string
    onLoadFile?: (file: File) => any
}

const ImgProfileComponent: React.FC<IImgProfileComponentProps> = (props) => {

    const handleLoadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!e.target.files?.length) return
        const file = e.target.files[0]
        if(props.onLoadFile){
            props.onLoadFile(file)
        }
    }

    return(
        <div
            className={`img_profile_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <div className="container">
                <label htmlFor="input_img_profile">
                    <img src={props.src} alt={props.alt} />
                    <div className="container-icon-camera">
                        <AiFillCamera/>
                    </div>
                </label>
                <input 
                    type="file" 
                    style={{display: 'none'}} 
                    id='input_img_profile' 
                    onChange={handleLoadFile}
                />
            </div>
        </div>
    );
}

export default ImgProfileComponent;