import React, { CSSProperties, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import InputComponent from 'components/commons/Input/InputComponent';
import useValidator from 'hooks/useFormValidator/useFormValidator';
import { useTranslation } from 'react-i18next';
import { RiLockPasswordFill } from 'react-icons/ri'
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import SubtitleComponent from 'components/commons/Subtitle/SubtitleComponent';

import './ModalChangePassword.scss'
import { useSelector } from 'react-redux';
import { IStoreApp } from '../../../redux/reducers/index';

interface IModalChangePasswordProps {
    className?: string
    style?: CSSProperties
    open: boolean
    onClose?: (data?: any) => any
}

const ModalChangePassword: React.FC<IModalChangePasswordProps> = (props) => {

    const {t} = useTranslation()
    const theme = useSelector((store: IStoreApp) => store.app.theme)

    const formModal = useValidator([
        {name: 'currentPassword', value: '', required: true, messageError:t('obligatory_field') },
        {name: 'newPassword', value: '', required: true, messageError:t('obligatory_field') },
        {name: 'newPassword2', value: '', required: true, messageError:t('obligatory_field'), validators: [{type: 'equal', key: 'newPassword'}] },
    ])

    useEffect(() => {
        formModal.resetForm()
    },[props.open])

    const handleCloseModal = () => {
        if(props.onClose){
            props.onClose()
        }
    }

    const handleChangePassword = () => {
        const aux = formModal.getValues()
        const data = {
            currentPassword: aux.currentPassword,
            newPassword: aux.newPassword
        }
        if(props.onClose){
            props.onClose(data)
        }
    }

    return(
        <Modal
            className={`modal_change_password-layout ${props.className ? props.className : ''}`}
            style={props.style}
            onHide={() => handleCloseModal()}
            show={props.open}
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header >
                <SubtitleComponent>
                    {t('profile.change_password.name')}
                </SubtitleComponent> 
            </Modal.Header>
            <Modal.Body>
                <div className="container-form-change-password">
                    <InputComponent
                        label={t('profile.change_password.current_password')}
                        name='currentPassword'
                        value={formModal.values['currentPassword']}
                        onChange={formModal.handleChange}
                        invalid={formModal.errors['currentPassword'] ? true : false}
                        invalidText={formModal.errors['currentPassword']}
                        style={{width: '300px'}}
                        type='password'
                        background={theme === 'dark' ? 'background-login' : undefined }
                    >
                        <InputComponent.Icon>
                            <RiLockPasswordFill/>
                        </InputComponent.Icon>
                    </InputComponent>
                    <InputComponent
                        label={t('profile.change_password.new_password')}
                        name='newPassword'
                        value={formModal.values['newPassword']}
                        onChange={formModal.handleChange}
                        invalid={formModal.errors['newPassword'] ? true : false}
                        invalidText={formModal.errors['newPassword']}
                        style={{width: '300px'}}
                        type='password'
                        background={theme === 'dark' ? 'background-login' : undefined }
                    >
                        <InputComponent.Icon>
                            <RiLockPasswordFill/>
                        </InputComponent.Icon>
                    </InputComponent>
                    <InputComponent
                        label={t('profile.change_password.config_new_password')}
                        name='newPassword2'
                        value={formModal.values['newPassword2']}
                        onChange={formModal.handleChange}
                        invalid={formModal.errors['newPassword2'] ? true : false}
                        invalidText={formModal.errors['newPassword2']}
                        style={{width: '300px'}}
                        type='password'
                        background={theme === 'dark' ? 'background-login' : undefined }
                    >
                        <InputComponent.Icon>
                            <RiLockPasswordFill/>
                        </InputComponent.Icon>
                    </InputComponent>
                    <div className="container-buttons">
                        <ButtonComponent
                            variant='secondary'
                            onClick={() => handleCloseModal()}
                        >
                            {t('profile.change_password.cancel')}
                        </ButtonComponent>
                        <ButtonComponent
                            variant='primary'
                            onClick={handleChangePassword}
                            disabled={formModal.isInvalid()}
                        >
                            {t('profile.change_password.save')}
                        </ButtonComponent>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalChangePassword;