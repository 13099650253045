// @import dependencies
import ButtonComponent from 'components/commons/Button/ButtonComponent'
import InputComponent from 'components/commons/Input/InputComponent'
import React, { CSSProperties, useState } from 'react'
import { BiLogIn } from 'react-icons/bi'
// @end dependencies

// @import components
// @end components

// @import types
// @end types

// @import services
import AuthService from 'services/auth/authService';
// @end services

// @import hooks
import useValidator from 'hooks/useFormValidator/useFormValidator';
import { useTranslation } from 'react-i18next'
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './CodeAuthenticationModule.scss'
import { useSelector } from 'react-redux';
import { IStoreApp } from '../../../redux/reducers/index';
import ScreenNotification from 'services/screenNotification/screenNotification';
// @end styles

interface ICodeAuthenticationModuleProps {
  className?: string
  style?: CSSProperties
  id?: string
}

const CodeAuthenticationModule: React.FC<ICodeAuthenticationModuleProps> = (props) => {
  
  const {t} = useTranslation()
  const token = useSelector((store: IStoreApp) => store?.auth?.token)
  
  const [loading, setLoading] = useState<boolean>(false)
  const form = useValidator([
    { name: 'code', required: true, messageError: t('obligatory_field'), validators: [
      { type: 'length', min: 8, max: 8 },
    ]}
  ])

  const authService = new AuthService()
  const screenNotification = new ScreenNotification()

  const handleClickLogin = async () => {
    const code = form.getValues().code
    if (!code || !token) return
    setLoading(true)
    const response = await authService.loginByCode({
      code,
      token
    })
    if (response) {
      screenNotification.showSuccessTimer({ text: 'Inicio de sesión exitoso' })
    } else {
      screenNotification.showFailedTimer({ text: 'No se pudo iniciar sesión' })
    }
    setLoading(false)
  }

  return(
    <div
      className={`code_authentication_module-layout ${props.className ? props.className : ''}`}
      style={props.style}
      id={props.id}
    >
        <InputComponent
            placeholder='Ingresa el código de 8 digitos'
            name='code'
            onChange={form.handleChange}
            value={form.values['firstName']}
            invalid={form.errors['code']}
            invalidText={form.errors['code']}
        />
        <ButtonComponent
          variant='primary'
          disabled={form.isInvalid()}
          loading={loading}
          onClick={handleClickLogin}
        >
          <ButtonComponent.Icon>
            <BiLogIn/>
          </ButtonComponent.Icon>
          Iniciar Sesión
        </ButtonComponent>
    </div>
  );
}

export default CodeAuthenticationModule;
