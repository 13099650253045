export interface Occupation{
    id?: string;
    name?: string;
}

export interface ParamsOccupationCreate{
    name: string
}

export interface ParamsOccupationUpdate{
    name: string
    id: string
}

export interface ParamsOccupationDelete{
    id: string
}

export interface ParamsOccupationList{

}

export interface ParamsOccupationGetById{
    id: string
}

export const fullOccupationQuery = `
    id
    name
`;
