// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import MultiSelectComponent from 'components/commons/MultiSelectComponent/MultiSelectComponent';
// @end components

// @import types
import { IOptionSelect } from 'components/commons/MultiSelectComponent/MultiSelectComponent';
import * as AuthTypes from 'types/auth/auth.types';
// @end types

// @import services
import UserService from 'services/user/userService';
// @end services

// @import hooks
import useTriggerReset from 'hooks/useTriggerReset/useTriggerReset';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './UsersSelectModule.scss'
// @end styles

interface IUsersSelectModuleProps {
    className?: string
    style?: CSSProperties
    id?: string
    label?: string
    defaultValue?: string[]
    initialUsers?: AuthTypes.IUser[]
    isMultiple?: boolean
    onChange?: (id?: string[]) => void
    onChangeOne?: (id?: string) => void
}

const UsersSelectModule: React.FC<IUsersSelectModuleProps> = (props) => {

    const [users, updateUsers] = useState<AuthTypes.IUser[]>(props.initialUsers?.length ? props.initialUsers : []);
    const [search, updateSearch] = useState<string>('');
    const [init, updateInit] = useState<boolean>(false);
    const reset = useTriggerReset();

    // @INFO Servicios
    const userService = new UserService();

    // useEffect(() => {
    //     reset.trigger();
    // }, [users]);

    useEffect(() => {
        if (search.trim().length >= 3) {
            getUsers(search);
        } else if(init) {
            updateUsers([]);
        } else {
            updateInit(true);
        }
    }, [search]);

    /**
     * @INFO Obtener los usuarios
     */
    const getUsers = async (_search: string) => {
        const _users = await userService.searchUsers({ search });
        updateUsers(_users);
    }

    /**
     * @INFO Cambia el selector 
     * @param _options 
     */
    const handleChangeSelect = (_options: IOptionSelect[]) => {
        const _usersId = _options?.map((_t) => _t.value);
        const _user = _usersId[0] || undefined;
        if(props.onChangeOne){
            props.onChangeOne(_user);
        }
        if(props.onChange){
            props.onChange(_usersId);
        }
    }

    /**
     * @INFO Obtener las opciones por defecto
     * @returns 
     */
    const getDefaultOptions = () => {
        const _options = users?.reduce((accum: IOptionSelect[], item) => {
            if(props.defaultValue?.find((_d) => _d === item.id)){
                accum.push({
                    label: `${item.firstName} ${item.lastName}`,
                    value: item.id ? item.id : ''
                })
            }
            return accum
        },[])
        return _options
    }

    /**
     * @INFO Cambia el input del buscador
     * @param _text 
     */
    const handleChangeInput = (_text: string) => {
        updateSearch(_text);
    }

    return(
        <div
            className={`users_select_module-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            {!reset.reset ?
                <MultiSelectComponent
                    options={users.map((_cat) => { return {label: `${_cat.firstName} ${_cat.lastName}`, value: _cat.id ? _cat.id : ''} })}
                    disableMulti={!props.isMultiple}
                    label={props.label}
                    defaultValue={getDefaultOptions()}
                    onChange={(data) => handleChangeSelect(data)}
                    onInputChange={handleChangeInput}
                    disableAutoOption
                />
            :null}
        </div>
    );
}

export default UsersSelectModule;