// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react';
// @end dependencies

// @import components
import MultiSelectComponent from 'components/commons/MultiSelectComponent/MultiSelectComponent';
// @end components

// @import types
import { IOptionSelect } from 'components/commons/MultiSelectComponent/MultiSelectComponent';
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './CodeLanguageSelect.scss';
// @end styles

interface ICodeLanguageSelectProps {
    className?: string
    style?: CSSProperties
    id?: string
    label?: string
    defaultValue?: string[]
    isMultiple?: boolean
    onChange?: (id?: string[], toolCategories?: IOptionSelect[]) => any
    onChangeOne?: (id?: string, toolCategory?: IOptionSelect) => any
}

const CodeLanguageSelect: React.FC<ICodeLanguageSelectProps> = (props) => {

    const [languages, updateLanguages] = useState<IOptionSelect[]>([
        {
            label: 'Javascript',
            value: 'javascript'
        },
        {
            label: 'Python',
            value: 'python'
        },
        {
            label: 'C',
            value: 'c'
        },
    ])
    const [resetSelect, updateResetSelect] = useState<boolean>(false)

    useEffect(() => {
        updateResetSelect(true)
        setTimeout(() => {
          updateResetSelect(false)
        },1)
    }, [props.defaultValue?.length])

    /**
     * @INFO Cambia el selector 
     * @param _options 
     */
    const handleChangeSelect = (_options: IOptionSelect[]) => {
        const _languages = languages?.filter((_t) => _options.find((_o) => _o.value === _t.value))
        const _language = _languages?.length ? _languages[0] : undefined
        if(props.onChangeOne){
            props.onChangeOne(_language?.value, _language)
        }
        if(props.onChange){
            props.onChange(_languages?.map((_t) => _t.value) as string[], _languages)
        }
    }

    /**
     * @INFO Obtener las opciones por defecto
     * @returns 
     */
    const getDefaultOptions = () => {
        const _options = languages?.reduce((accum: IOptionSelect[], item) => {
            if(props.defaultValue?.find((_d) => _d === item.value)){
                accum.push(item);
            }
            return accum
        },[])
        return _options
    }

    return(
        <div
            className={`code_language_select-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            {!resetSelect ?
                <MultiSelectComponent
                    options={languages}
                    disableMulti={!props.isMultiple}
                    label={props.label}
                    defaultValue={getDefaultOptions()}
                    onChange={(data) => handleChangeSelect(data)}
                />
            :null}
        </div>
    );
}

export default CodeLanguageSelect;