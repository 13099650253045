import React, { CSSProperties } from 'react'

import './ProgressBarComponent.scss'
import TextComponent from '../Text/TextComponent';

interface IProgressBarComponentProps {
    className?: string
    style?: CSSProperties
    progress: number
    title?: string
    showProgress?: boolean
    asInline?: boolean
    height?: string
    width?: string
    color?: string
}

const ProgressBarComponent: React.FC<IProgressBarComponentProps> = (props) => {
    return(
        <div
            className={`progress_bar-layout ${props.asInline ? 'as-inline' : ''} ${props.className ? props.className : ''}`}
            style={{
                ...props.style,
                width: props.width
            }}
        >
            {(props.title || props.showProgress) && !props.asInline ?
                <div className="text-progress-container">
                    {props.title ? 
                        <TextComponent>
                            {props.title}
                        </TextComponent>
                    :null}
                    {props.showProgress ?
                        <TextComponent
                            className='progress-number-text'
                        >
                            {props.progress}%
                        </TextComponent>
                    :null}
                </div>
            :null}
            <div>
                <div 
                    className="container-progress-background"
                    style={{height: props.height}}
                >
                    <div 
                        className="progress-component"
                        style={{
                            width: `${props.progress}%`,
                            background: props.color
                        }}
                    />
                </div>
            </div>
            {props.asInline ?
                <div className="text-progress-container">
                    {props.showProgress ?
                        <TextComponent
                            className='progress-number-text'
                        >
                            {props.progress}%
                        </TextComponent>
                    :null}
                </div>
            :null}
        </div>
    );
}

export default ProgressBarComponent;