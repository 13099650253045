// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
import { IStoreApp } from 'redux/reducers';
// @end dependencies

// @import components
import { Dropdown } from 'react-bootstrap';
import { BiCommand } from 'react-icons/bi';
import { MdMenu, MdCreate, MdPayment } from 'react-icons/md';
import { GrTechnology } from 'react-icons/gr';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { BsFillPersonFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { RiDeviceFill } from 'react-icons/ri';
// @end components

// @import types
import { ITypeThemeApp } from 'types/app/app.actions.types';
import { ITypesPermissionsRoute } from 'types/routes/routes.types';
// @end types

// @import services
import PermissionsService from 'services/permissions/permissionsService';
import RouterService from 'services/router/routerService';
// @end services

// @import hooks
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// @end hooks

// @import actions
import * as AuthActions from 'redux/reducers/auth/auth.actions'
import * as AppActions from 'redux/reducers/app/app.actions'
// @end actions

// @import utils
import { HOME_ROUTE } from 'config/globals';
// @end utils

// @import assets
import vlesimIcon from 'assets/images/logoVlesim1.png'
import vlesimIcon2 from 'assets/images/logoVlesim2.svg'
// @end assets

// @import styles
import './Sidebar.scss'
import { AiFillInfoCircle } from 'react-icons/ai';
// @end styles

interface ISidebarProps {
    history?: any 
    className?: string
    style?: CSSProperties
    onChangeOpen?: (open: boolean) => any
}

const Sidebar: React.FC<ISidebarProps> = (props) => {

    const open = useSelector((store: IStoreApp) => store.app.sidebarState)
    const user = useSelector((store: IStoreApp) => store.auth?.user)
    const theme = useSelector((store: IStoreApp) => store?.app?.theme)
    const dispatch = useDispatch()
    const {t} = useTranslation()

    // @Servicios
    const routerService = new RouterService()

    useEffect(() => {
        if(props.onChangeOpen){
            props.onChangeOpen(open)
        }
    },[open])

    const handleClickLogout = () => {
        dispatch(AuthActions.logoutAction())
    }

    const handleUpdateOpenSidebar = (state: boolean) => {
        dispatch(AppActions.updateSidebarStateAction(state))
    }

    const handleGoHome = () => {
        if(props.history){
            props.history.push(HOME_ROUTE)
        }
    }

    return(
        <div
            className={`sidebar-layout ${open ? '' : 'colapse'} ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <div className="container-image-sidebar">
                <img 
                    src={open ? vlesimIcon : 
                    vlesimIcon2} className='logo-vlesim-sidebar' 
                    onClick={handleGoHome}
                /> 
                {open ?
                    <IoIosArrowBack 
                        className='icon-arrow-sidebar' 
                        onClick={() => handleUpdateOpenSidebar(!open)}
                    />
                : 
                    <IoIosArrowForward 
                        className='icon-arrow-sidebar' 
                        onClick={() => handleUpdateOpenSidebar(!open)}
                    />
                }
            </div>
            <div 
                className="menu-movil-sidebar"
                onClick={() => handleUpdateOpenSidebar(!open)}
            >
                <MdMenu/>
            </div>
            <Dropdown
                className={`menu-profile-sidebar ${routerService.getCurrentRoute() === '/profile' ? 'white' : ''}`}
            >
                <Dropdown.Toggle 
                    variant="success" 
                    id="dropdown-basic"
                    className='btn-menu-sidebar'
                >
                    <img 
                        src={user?.avatar}
                        className='img-avatar-sidebar' 
                    />
                    <div className="content-text-person-sidebar">
                        <p> {t('sidebar.hello')}, <strong>{user?.firstName}</strong> </p>   
                        <p>{user?.email} </p>
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={handleClickLogout} >{t('sidebar.logout')}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <div className="container-icons-sidebar">
                <ItemSidebar
                    text={t('sidebar.dashboard.name')}
                    history={props.history}
                    linkTo={HOME_ROUTE}
                    linksActive={[HOME_ROUTE]}
                    theme={theme}
                    permissions={['dashboard_menu']}
                >
                    <BiCommand/>
                </ItemSidebar>
                <ItemSidebar
                    text={t('sidebar.profile.name')}
                    linkTo='/profile'
                    linksActive={['/profile']}
                    history={props.history}
                    theme={theme}
                    permissions={['profile_menu']}
                >
                    <BsFillPersonFill/>
                </ItemSidebar>
                <ItemSidebar
                    text={t('sidebar.my_labs.name')}
                    linkTo='/my-labs'
                    linksActive={['/my-labs']}
                    history={props.history}
                    theme={theme}
                    permissions={['my_labs_menu']}
                >
                    <RiDeviceFill/>
                </ItemSidebar>
                <ItemSidebar
                    text={t('sidebar.plans.name')}
                    linkTo='/plans'
                    linksActive={['/plans']}
                    history={props.history}
                    theme={theme}
                    permissions={[]}
                >
                    <MdPayment/>
                </ItemSidebar>
                <ItemSidebar
                    text={t('sidebar.catalog.name')}
                    linkTo='/laboratories'
                    linksActive={[
                        '/laboratories', 
                        '/laboratory-preview', 
                        '/dashboard-laboratory',
                        '/favorites'
                    ]}
                    history={props.history}
                    theme={theme}
                    permissions={['laboratories_menu']}
                >
                    <GrTechnology/>
                </ItemSidebar>
                <ItemSidebar
                    text={t('sidebar.cataloger.name')}
                    linkTo='/cataloger'
                    linksActive={[
                        '/cataloger', 
                        '/cataloger-list', 
                        '/create-laboratory', 
                        '/edit-laboratory', 
                        '/create-banner', 
                        '/edit-banner',
                        '/create-tool-category',
                        '/edit-tool-category',
                        '/create-tool',
                        '/edit-tool',
                        '/create-hardware',
                        '/edit-hardware'
                    ]}
                    history={props.history}
                    theme={theme}
                    permissions={['cataloger_menu']}
                >
                    <MdCreate/>
                </ItemSidebar>
                <ItemSidebar
                    text={t('sidebar.terms_and_conditions.name')}
                    linkTo='/terms-and-conditions'
                    linksActive={[]}
                    history={props.history}
                    theme={theme}
                    permissions={[]}
                    target="_blank"
                >
                    <AiFillInfoCircle/>
                </ItemSidebar>
            </div>
        </div>
    );
}

// =============================================
// @INFO Item del sidebar
// =============================================
interface IItemSidebarProps {
    text: string
    linksActive: string[]
    linkTo: string
    permissions: ITypesPermissionsRoute[]
    className?: string
    style?: CSSProperties
    children?: any
    history?: any
    theme?: ITypeThemeApp
    target?: string
}

const ItemSidebar: React.FC<IItemSidebarProps> = (props) => {

    // @INFO Servicios
    const permissionsService = new PermissionsService()

    /**
     * @INFO Saber si esta en alguna de las rutas activas
     * @returns 
     */
    const isActive = () => {
        let active = false
        active = props.linksActive?.reduce((accum: boolean, item) => {
            if(!accum){
                accum = getIsActive(item)
            }
            return accum
        }, active)
        return active
    }

    /**
     * @INFO Saber si una ruta es actual o no
     * @param _route 
     * @returns 
     */
    const getIsActive = (_route: string) => {
        const currentPath = window.location.href
        if(_route === '/'){
            if(window.location.pathname === '/'){
                return true
            }else{
                return false
            }
        }else{
            if(currentPath.includes(_route)){
                return true
            }else{
                return false
            }
        }
    }

    return(
        permissionsService.checkList(props.permissions) ?
            <Link
                to={props.linkTo}
                className={`item_sidebar-layout ${isActive() ? 'active' : ''} ${props.className ? props.className : ''} ${props.theme === 'dark' ? 'dark-theme' : ''}`}
                style={props.style}
                target={props.target}
            >
                {props.children}
                <p>{props.text}</p>
            </Link>
        : null
    );
}


export default Sidebar;