// @import dependencies
import React, { CSSProperties } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { MdDone } from 'react-icons/md'
import { BounceLoader } from 'react-spinners'
import TextComponent from '../Text/TextComponent'
// @end dependencies

// @import components
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './SavingIndicator.scss'
// @end styles

type TypesStateSavingIndicator = 'saved' | 'saving' | 'failed'

interface ISavingIndicatorProps {
    state: TypesStateSavingIndicator;
    className?: string
    style?: CSSProperties
    id?: string
    withText?: boolean;
}

const SavingIndicator: React.FC<ISavingIndicatorProps> = (props) => {

    const getIndicator = () => {
        switch(props.state) {
            case 'saved':
                return (
                    <MdDone
                        className='done-icon-indicator'
                    />
                )
            case 'saving':
                return (
                    <BounceLoader
                        color='var(--accent)'
                        size={19}
                    />
                )
            case 'failed':
                return (
                    <AiOutlineCloseCircle
                        className='failed-icon-indicator'
                    />
                )
            default: 
                return null;
        }
    }

    const getText = () => {
        switch(props.state) {
            case 'saved':
                return 'Guardado'
            case 'saving':
                return 'Guardando...'
            case 'failed':
                return 'Error al guardar'
            default: 
                return '';
        }
    }

    return(
        <div
            className={`saving_indicator-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            
            {getIndicator()}
            {props.withText ? 
                <TextComponent
                    type='label'
                    style={{
                        fontWeight: 700
                    }}
                >
                    {getText()}
                </TextComponent>
            : null}
        </div>
    );
}

export default SavingIndicator;