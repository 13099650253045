// @import dependencies
import React, { CSSProperties, useState, MouseEvent } from 'react'
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import styled from 'styled-components';
// @end dependencies

// @import components
import { FaFacebookF, FaLink, FaLinkedinIn, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { MdShare } from 'react-icons/md';
import PopUpComponent from 'components/commons/PopUp/PopUpComponent';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ShareModule.scss'
// @end styles

interface IShareModuleProps {
  url: string
  className?: string
  style?: CSSProperties
  id?: string
  networks?: TypeSocialNetworksShare[]
  relativePath?: boolean
}

type TypeSocialNetworksShare = 'facebook' | 'twitter' | 'linkedin' | 'email' | 'whatsapp'

const ContainerIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ShareModule: React.FC<IShareModuleProps> = (props) => {
  
  const [open, updateOpen] = useState<boolean>(false)

  const getUrl = () => {
    if (props.relativePath) {
      return `${window.location.origin}${props.url}`
    } else {
      return props.url;
    }
  }

  const handleClickOpen = (e: MouseEvent<SVGElement, globalThis.MouseEvent>) => {
    e.stopPropagation();
    updateOpen(!open);
  }

  return(
    <div
      className={`share_module-layout ${props.className ? props.className : ''}`}
      style={props.style}
      id={props.id}
    >
      <PopUpComponent
        show={open}
        hideClose
        style={{
          padding: '10px',
          minHeight: 'fit-content'
        }}
        content={
          <ContainerIcons>
            {!props.networks || props.networks.includes('facebook') ?
              <FacebookShareButton url={getUrl()}>
                <FaFacebookF className='icon-social-media-share' size="1.3rem"/>
              </FacebookShareButton>
            :null}
            {!props.networks || props.networks.includes('twitter') ?
              <TwitterShareButton url={getUrl()}>
                <FaTwitter className='icon-social-media-share' size="1.3rem"/>
              </TwitterShareButton>
            :null}
            {!props.networks || props.networks.includes('linkedin') ?
              <LinkedinShareButton url={getUrl()}>
                <FaLinkedinIn className='icon-social-media-share' size="1.3rem"/>
              </LinkedinShareButton>
            :null}
            {!props.networks || props.networks.includes('email') ?
              <EmailShareButton url={getUrl()}>
                <FaLink className='icon-social-media-share' size="1.3rem"/>
              </EmailShareButton>
            :null}
            {!props.networks || props.networks.includes('whatsapp') ?
              <WhatsappShareButton url={getUrl()}>
                <FaWhatsapp className='icon-social-media-share' size="1.3rem"/>
              </WhatsappShareButton>
            :null}
          </ContainerIcons>
        }
      >
        <MdShare
          onClick={(e) => handleClickOpen(e)}
          className='icon-share-module'
        />
      </PopUpComponent>
      
    </div>
  );
}

export default ShareModule;
