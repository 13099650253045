// @import types
import * as LaboratoriesActionTypes from 'types/laboratories/laboratories.actions.types';
import * as LaboratoriesTypes from 'types/laboratories/laboratories.types';
import * as LaboratoryCategoryTypes from 'types/laboratoryCategory/laboratoryCategory.types';
import * as FavoriteTypes from 'types/favorite/favorite.types';
import * as ReservationTypes from 'types/reservations/reservations.types';
// @end types

// @import service
import ReservationsService from 'services/reservations/reservationsService';
// @end service

export const getMyLabsAction = (labs: LaboratoriesTypes.ILaboratory[]) => {
    return async (dispatch: Function) => {
        dispatch(getMyLabs(labs));
    }
}

const getMyLabs = (labs: LaboratoriesTypes.ILaboratory[]) => ({
    type: LaboratoriesActionTypes.GET_MY_LABS,
    payload: labs
})

export const getMyActiveLabsAction = (labs: LaboratoriesTypes.ILaboratory[]) => {
    return async (dispatch: Function) => {
        dispatch(getMyActiveLabs(labs));
    }
}

const getMyActiveLabs = (labs: LaboratoriesTypes.ILaboratory[]) => ({
    type: LaboratoriesActionTypes.GET_MY_ACTIVE_LABS,
    payload: labs
})

export const getLaboratoryCategoriesAction = (categories: LaboratoryCategoryTypes.ILaboratoryCategory[]) => {
    return async (dispatch: Function) => {
        dispatch(getLaboratoryCategories(categories));
    }
}

const getLaboratoryCategories = (categories: LaboratoryCategoryTypes.ILaboratoryCategory[]) => ({
    type: LaboratoriesActionTypes.GET_LABORATORY_CATEGORIES,
    payload: categories
})

export const getLaboratoryFavoritesAction = (favorites: FavoriteTypes.IFavorite[]) => {
    return async (dispatch: Function) => {
        dispatch(getLaboratoryFavorites(favorites));
    }
}

const getLaboratoryFavorites = (favorites: FavoriteTypes.IFavorite[]) => ({
    type: LaboratoriesActionTypes.GET_LABORATORY_FAVORITES,
    payload: favorites
})

export const getReservationsAction = () => {
    return async (dispatch: Function) => {
        const reservationsService = new ReservationsService();
        const reservations: ReservationTypes.IReservation[] = await reservationsService.listReservations();
        dispatch(getReservations(reservations));
    }
}

const getReservations = (reservations: ReservationTypes.IReservation[]) => ({
    type: LaboratoriesActionTypes.GET_RESERVATIONS,
    payload: reservations
})

export const addReservationAction = (reservation: ReservationTypes.IReservation) => {
    return async (dispatch: Function) => {
        dispatch(addReservation(reservation));
    }
}

const addReservation = (reservation: ReservationTypes.IReservation) => ({
    type: LaboratoriesActionTypes.ADD_RESERVATION,
    payload: reservation
})

export const deleteReservationAction = (reservation: ReservationTypes.IReservation) => {
    return async (dispatch: Function) => {
        dispatch(deleteReservation(reservation));
    }
}

const deleteReservation = (reservation: ReservationTypes.IReservation) => ({
    type: LaboratoriesActionTypes.DELETE_RESERVATION,
    payload: reservation
})

export const updateReservationAction = (reservation: ReservationTypes.IReservation) => {
    return async (dispatch: Function) => {
        dispatch(updateReservation(reservation));
    }
}

const updateReservation = (reservation: ReservationTypes.IReservation) => ({
    type: LaboratoriesActionTypes.UPDATE_RESERVATION,
    payload: reservation
})

export const setCurrentReservationAction = (reservation?: ReservationTypes.IReservation) => {
    return async (dispatch: Function) => {
        dispatch(setCurrentReservation(reservation));
    }
}

const setCurrentReservation = (reservation?: ReservationTypes.IReservation) => ({
    type: LaboratoriesActionTypes.SET_CURRENT_RESERVATION,
    payload: reservation
})

