import React, { CSSProperties, useState, useEffect } from 'react'
import { Carousel } from 'react-bootstrap';
import BannerService from 'services/banner/bannerService';
import * as BannerTypes from 'types/banners/banners.types'
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import TitlePageComponent from 'components/commons/TitlePage/TitlePageComponent';
import TextComponent from 'components/commons/Text/TextComponent';

import './HomeCarousel.scss'
import { Link } from 'react-router-dom';

interface IHomeCarouselProps {
    className?: string
    classNameItem?: string
    style?: CSSProperties
    styleItem?: CSSProperties
    id?: string
    onlyAvailable?: boolean
    filter?: BannerTypes.IBannerFilter
}

const HomeCarousel: React.FC<IHomeCarouselProps> = (props) => {

    const [banners, updateBanners] = useState<BannerTypes.IBanner[]>([])

    // @INFO Servicios
    const bannerService = new BannerService()

    useEffect(() => {
        getBanners()
    }, [])

    // @INFO Obtener la lista de banners
    const getBanners = async () => {
        const _banners = await bannerService.list({
            onlyAvailable: props.onlyAvailable,
            filter: props.filter
        })
        updateBanners(_banners)
    }

    return(
        banners.length ?
            <Carousel
                className={`home_carousel-layout ${props.className ? props.className : ''}`}
                style={props.style}
                nextLabel={''}
                prevLabel={''}
                // activeIndex={0}
                onChange={() => {}}
                id={props.id}
                controls={false}
            >
                {banners?.length ? 
                    banners.map((_item) => (
                        <Carousel.Item
                            className={`item-carousel-home ${props.classNameItem ? props.classNameItem : ''}`}
                            style={props.styleItem}
                            key={_item.id}
                        >
                            <img
                                src={_item.image}
                                alt='prueba'
                            />
                            <div className="container-info-banner">
                                <TitlePageComponent
                                    style={{ color: 'white' }}
                                >
                                    {_item.title}
                                </TitlePageComponent>
                                <TextComponent
                                    style={{ color: 'white' }}
                                >
                                    {_item.description}
                                </TextComponent>
                                {_item?.action?.web ?
                                    <Link
                                        to={{
                                            pathname: _item.action.web.route
                                        }}
                                        target='_blank'
                                    >
                                        <ButtonComponent
                                            className='button-banner-action'
                                        >
                                            {_item.action.web.name}
                                        </ButtonComponent>
                                    </Link>
                                :null}
                            </div>
                        </Carousel.Item>
                    ))
                :null}
            </Carousel>
        :
        null
    );
}

export default HomeCarousel;