import { IKeyTable } from "components/commons/TableComponent/TableComponent"
import ScreenNotification from "services/screenNotification/screenNotification"
import * as AppTypes from 'types/app/app.types'

// @import services
import LaboratoryCategoryService from 'services/laboratoryCategory/laboratoryCategoryService'
import LaboratoryService from 'services/laboratory/laboratoryService';
import BannerService from 'services/banner/bannerService';
import ToolCategoryService from 'services/toolCategory/toolCategoryService';
import ToolService from 'services/tool/toolService'
import HardwareService from 'services/hardware/hardwareService'
// @end services

class ListCatalogerItemsFunctions{

    private screenNotification = new ScreenNotification()

    private laboratoryCategoryService = new LaboratoryCategoryService()
    private laboratoryService = new LaboratoryService()
    private bannerService = new BannerService()
    private toolCategoryService = new ToolCategoryService()
    private toolService = new ToolService()
    private hardwareService = new HardwareService()

    constructor(){

    }

    /**
     * @INFO Obtener las keys para el datatable
     */
     public getKeysDataTable = (_category: AppTypes.ITypeCategoryListCataloger) => {
        let keys: IKeyTable[] = []
        if(_category === 'laboratory'){
            keys = [
                { key: 'thumbnail', name: 'Miniatura', type: 'image'},
                { key: 'name', name: 'Nombre' }, 
                { key: 'description', name: 'Descripción' },
                { key: 'price', name: 'Precio (USD)' },
                { key: 'id', name: 'ID', type: 'copy-text' },
            ]
        }else if(_category === 'banner'){
            keys = [
                { key: 'title', name: 'Titulo' }, 
                { key: 'description', name: 'Descripción' },
                { key: 'startDate', name: 'Inicio', formatMoment: 'DD [de] MMMM [de] YYYY' },
                { key: 'finishDate', name: 'Fin', formatMoment: 'DD [de] MMMM [de] YYYY' },
            ]
        }else if(_category === 'tool-category'){
            keys = [
                { key: 'name', name: 'Nombre' },
                { key: 'description', name: 'Descripción' }
            ]
        }else if(_category === 'tool'){
            keys = [
                { key: 'name', name: 'Nombre' },
                { key: 'description', name: 'Descripción' },
                { key: 'id', name: 'ID', type: 'copy-text' },
                { key: 'apiToken', name: 'Token', type: 'copy-text' },
            ]
        }else if(_category === 'hardware'){
            keys = [
                { key: 'name', name: 'Nombre' },
                { key: 'state', name: 'Estado' }
            ]
        }else if(_category === 'laboratory-category'){
            keys = [
                { key: 'image', name: 'Imagen', type: 'image' },
                { key: 'name', name: 'Nombre' },
            ]
        }
        return keys
    }

    /**
     * TODO: Eliminar un elemento
     * @INFO Click a un elemento de la lista
     * @param _category 
     * @param _type 
     * @param _data 
     */
    public handleClickElement = async (_category: AppTypes.ITypeCategoryListCataloger, _type: 'edit' | 'delete', _data: any, _history: any) => {
        switch(_type){
            case 'edit':
                const route = `/edit-${_category}/${_data.id}`
                if(_history && _history.push){
                    _history.push(route)
                }
                break
            case 'delete':
                // @INFO Preguntar si se debe eliminar
                const response = await this.screenNotification.showAskDelete()
                if(response?.isConfirmed){
                    const responseDelete = await this.deleteElement(_category, _data)
                    return responseDelete
                }
                break 
            default:
                break
        }
    }

    /**
     * @INFO Eliminar un elemento
     * @param _category 
     * @param _data 
     */
    private deleteElement = async (_category: AppTypes.ITypeCategoryListCataloger, _data: any) => {
        let response: any | undefined = undefined
        switch(_category){
            case 'laboratory':
                // response = await this.laboratoryService
                break;
            case 'laboratory-category':
                response = await this.laboratoryCategoryService.delete({ id: _data.id })
                break;
            case 'tool':
                response = await this.toolService.delete({ id: _data.id })
                break;
            case 'tool-category':
                response = await this.toolCategoryService.delete({ id: _data.id })
                break;
            case 'banner':
                response = await this.bannerService.delete({ id: _data.id })
                break;
            case 'hardware':
                response = await this.hardwareService.delete({ id: _data.id })
                break;
            default:
                break;
        }
        if(response){
            return true
        }else{
            return false
        }
    }
}

export default ListCatalogerItemsFunctions