import React, { CSSProperties, useState, useEffect } from 'react'
import InputComponent from 'components/commons/Input/InputComponent';

import './DateFilterComponent.scss'
import ButtonComponent from '../Button/ButtonComponent';
import LabelComponent from '../LabelComponent/LabelComponent';
import moment from 'moment';

interface IDateFilterComponentProps {
    className?: string
    style?: CSSProperties
    defaultSince?: Date
    defaultTo?: Date
    defaultPoints?: number
    loading?: boolean
    showPoints?: boolean
    title?: string
    textButton?: string
    onSearch?: (since: Date, to: Date, points?: number) => any
}

const DateFilterComponent: React.FC<IDateFilterComponentProps> = (props) => {

    const [since, updateSince] = useState<Date>(props.defaultSince ? props.defaultSince : new Date())
    const [to, updateTo] = useState<Date>(props.defaultTo ? props.defaultTo : new Date())
    const [points, updatePoints] = useState<number>(props.defaultPoints ? props.defaultPoints : 0)

    useEffect(() => {

    },[since, to])

    const handleChangeDay = (e: React.ChangeEvent<HTMLInputElement>, type: 'since' | 'to') => {
        const aux = new Date(e.target.value)
        // aux.setDate(aux.getDate()+1)
        if(type === 'since'){
            updateSince(aux)
        }else if(type === 'to'){
            updateTo(aux)
        }
    }

    const handleClickSearch = () => {
        if(props.onSearch){
            props.onSearch(since, to, points)
        }
    }

    return(
        <div
            className={`date_filter_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <LabelComponent
                style={{textDecoration: 'underline', marginBottom: '8px'}}
            >
                {props.title ? props.title : 'Filtrar por fecha'}
            </LabelComponent>
            <div
                className='container-actions'
            >
                <InputComponent
                    type='datetime-local'
                    placeholder='Desde'
                    label="Desde"
                    value={moment(since).format('YYYY-MM-DD HH:mm').replace(' ', 'T')}
                    onChange={(e) => handleChangeDay(e, 'since')}
                />
                <InputComponent
                    type='datetime-local'
                    placeholder='Desde'
                    label="Hasta"
                    value={moment(to).format('YYYY-MM-DD HH:mm').replace(' ', 'T')}
                    onChange={(e) => handleChangeDay(e, 'to')}
                />
                {props.showPoints ?
                    <InputComponent
                        type='number'
                        placeholder='# de puntos'
                        value={points.toString()}
                        onChange={(e) => updatePoints(Number(e.target.value))}
                    />
                :null}
                <ButtonComponent
                    variant='primary'
                    onClick={handleClickSearch}
                    loading={props.loading}
                >
                    {props.textButton ? props.textButton : 'Filtrar'}
                </ButtonComponent>
            </div>
        </div>
    );
}

export default DateFilterComponent;