import React, { CSSProperties, useState, useEffect } from 'react'
import { ChartData, Line, Bar } from 'react-chartjs-2';
import TextComponent from '../Text/TextComponent';
import { ChartOptions } from 'chart.js'
import * as chartjs from "chart.js";

import * as zoom from "chartjs-plugin-zoom";


// @import hooks
import { useResizeDetector } from 'react-resize-detector';
import { useSelector } from 'react-redux';
// @end hooks

// @import types
import * as ToolTypes from 'types/tool/tool.types';
import { IStoreApp } from 'redux/reducers/index';
// @end types

// @import styles
import './ChartComponent.scss'
// @end styles

export interface ChartComponentProps {
    className?: string
    style?: CSSProperties
    label?: string
    chartLabel?: string
    limits?: ToolTypes.RangeValue
    labels?: any[]
    data?: any[]
    options?: ChartOptions
    type?: ToolTypes.ChartType
}

const defaultDataOptions: ChartData<chartjs.ChartData> = {
  labels: [],
  datasets: [
    {
      label: 'Data',
      data: [],
      fill: false,
      backgroundColor: '#0C9CF2',
      borderColor: '#03133B',
    },
  ]
};

const defaultOptions: ChartOptions = {
  scales: {
    yAxes: [
      {
        gridLines: {
          color: 'white',
        },
        ticks: {
          fontColor: 'white'
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          color: 'white'
        },
        ticks: {
          fontColor: 'white'
        }
      }
    ]
  },
  legend: {
    labels: {
      fontColor: 'white'
    }
  },
  animation: {
    duration: 0 
  },
};
 
const ChartComponent: React.SFC<ChartComponentProps> = (props) => {

    const [data, updateData] = useState<ChartData<chartjs.ChartData>>(defaultDataOptions);
    const [options, updateOptions] = useState<ChartOptions>(defaultOptions);
    const containerChart = useResizeDetector();
    const theme = useSelector((store: IStoreApp) => store.app.theme)

    useEffect(() => {
      const newData = {
        labels: props.labels?.length ? props.labels : [],
        datasets: [{
          data: props.data?.length ? props.data : [],
          label: props.chartLabel ? props.chartLabel : 'Data',
          fill: false,
          backgroundColor: theme === 'light' ? '#0C9CF2' : '#03133B',
          borderColor: theme === 'light' ? '#03133B' : '#0C9CF2',
        }],
        animation: false
      }
      // @INFO Configurar limites
      if (props.limits?.min) {
        const newDatasetMin = {
          data: props.labels?.length ? props.labels?.map((l) => props.limits?.min ? props.limits.min : 0) : [],
          label: 'Min',
          fill: false,
          backgroundColor: '#02EB48',
          borderColor: '#02EB48',
          pointRadius: 0,
          pointHitRadius: 0
        }
        newData.datasets.push(newDatasetMin);
      }
      if (props.limits?.max) {
        const newDatasetMax = {
          data: props.labels?.length ? props.labels?.map((l) => props.limits?.max ? props.limits.max : 0) : [],
          label: 'Max',
          fill: false,
          backgroundColor: '#02EB48',
          borderColor: '#02EB48',
          pointRadius: 0,
          pointHitRadius: 0
        }
        newData.datasets.push(newDatasetMax);
      }
      updateData(newData)
    },[props.chartLabel, props.labels, props.data, theme])

    useEffect(() => {
      updateOptions({
        ...options,
        scales: {
          yAxes: [
            {
              gridLines: {
                color: theme === 'light' ? '#d4d4d4' : '#777',
              },
              ticks: {
                fontColor: theme === 'light' ? 'black' : 'white',
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                color: theme === 'light' ? '#d4d4d4' : '#777'
              },
              ticks: {
                fontColor: theme === 'light' ? 'black' : 'white'
              }
            }
          ]
        },
        legend: {
          labels: {
            fontColor: theme === 'light' ? 'black' : 'white'
          }
        },
      })
    }, [theme])

    const getChart = () => {
      switch (props.type) {
        case ToolTypes.ChartType.LINE:
          return (
            <Line
              data={data} 
              options={props.options ? props.options : options} 
              type='line'
              width={getWidthChart()}
              height={getHeigthChart()}
            />
          );
        case ToolTypes.ChartType.BAR:
          return (
            <Bar
              data={data}
              options={props.options ? props.options : options}
              width={getWidthChart()}
              height={getHeigthChart()}
            />
          );
        default:
          return (
            <Line
              data={data} 
              options={props.options ? props.options : options} 
              type='line'
              width={getWidthChart()}
              height={getHeigthChart()}
            />
          );
      }
    };

    const getHeigthChart = () => {
      return containerChart.width ? containerChart.width*0.49 : undefined
    }

    const getWidthChart = () => {
      return containerChart.width
    }

    return (  
        <div ref={containerChart.ref} className="chart_component-layout">
          {props.label ?
          <TextComponent
            style={{
              fontWeight: 500, 
              fontSize: '0.9rem', 
              textDecoration: 'underline'
            }}
          >
            {props.label}
          </TextComponent>
          :null}
          {getChart()}
        </div>
    );
}
 
export default ChartComponent;