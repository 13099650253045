import React, { CSSProperties, useEffect, useState } from 'react'

// @import components
import { Form } from 'react-bootstrap';
import LabelComponent from 'components/commons/LabelComponent/LabelComponent';
import InputComponent from 'components/commons/Input/InputComponent';
import DurationComponent from 'components/commons/DurationComponent/DurationComponent';
import InputFileComponent from 'components/commons/InputFileComponent/InputFileComponent';
import HardwareSelectModule from 'components/modules/HardwareSelectModule/HardwareSelectModule';
// @end components

// @import hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useTriggerReset from 'hooks/useTriggerReset/useTriggerReset';
// @end hooks

// @import types
import * as LaboratoryCategoryTypes from 'types/laboratoryCategory/laboratoryCategory.types'
import * as LaboratoryTypes from 'types/laboratories/laboratories.types'
import { IStoreApp } from 'redux/reducers';
import { IUseFormValidator } from 'hooks/useFormValidator/useFormValidator.types';
// @end types

import './RenderOptionsCategory.scss'
import LaboratoryDashboardForm from 'components/screens/Cataloger/CreateLaboratoryPage/components/LaboratoryDashboardForm/LaboratoryDashboardForm';

interface IRenderOptionsCategoryProps {
    category: LaboratoryCategoryTypes.ILaboratoryCategory
    form: IUseFormValidator
    laboratory?: LaboratoryTypes.ILaboratory
    className?: string
    style?: CSSProperties
}

const RenderOptionsCategory: React.FC<IRenderOptionsCategoryProps> = (props) => {

    const {t} = useTranslation()
    const theme = useSelector((store: IStoreApp) => store.app.theme)
    const resetSelect = useTriggerReset()

    useEffect(() => {
        if(props.laboratory){
            resetSelect.trigger()
        }
    },[props.laboratory])

    return(
        <div
            className={`render_options_category-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            {props.form.hasField('dashboards') ? 
                <LaboratoryDashboardForm
                    label='Dashboards'
                    defaultValue={props.laboratory?.dashboards}
                    onChange={(data) => props.form.setValue('dashboards', data)}
                />
            : null}
            {props.category?.config?.hasDuration ?
                <DurationComponent
                    name='config.duration'
                    label='Duración'
                    value={props.form.values['config.duration']}
                    onChange={props.form.handleChange}
                    invalid={props.form.errors['config.duration'] ? true : false}
                    invalidText={props.form.errors['config.duration']}
                    background={theme === 'dark' ? 'background-login' : undefined}
                />
            :null}
            {props.category?.config?.hasHardwareDuration ?
                <DurationComponent
                    name='config.hardwareDuration'
                    label='Duración de hardware'
                    value={props.form.values['config.hardwareDuration']}
                    onChange={props.form.handleChange}
                    invalid={props.form.errors['config.hardwareDuration'] ? true : false}
                    invalidText={props.form.errors['config.hardwareDuration']}
                    background={theme === 'dark' ? 'background-login' : undefined}
                />
            :null}
            {props.category?.config?.hasHardware && props.form.hasField('config.hardware') && !resetSelect.reset ?
                <HardwareSelectModule
                    label='Hardwares'
                    onChange={(_ids) => props.form.setValue('config.hardware', _ids)}
                    isMultiple
                    defaultValue={props.laboratory?.config?.hardware?.map((_h) => _h.id as string)}
                />
            :null}
            {props.category?.config?.hasVirtualReality ?
                <>
                    <Form.Group 
                        controlId="exampleForm.ControlSelect1"
                    >
                        <LabelComponent>
                            Plataforma de video
                        </LabelComponent>
                        <Form.Control 
                            as="select"
                            name='config.virtualReality.video.platform'
                            onChange={props.form.handleChange}
                            value={props.form.values['config.virtualReality.video.platform']}
                        >
                            <option selected>-- Plataforma --</option>
                            <option value="YouTube">YouTube</option>
                        </Form.Control>
                        {props.form.errors['config.virtualReality.video.platform'] ? 
                            <LabelComponent style={{ color: '#dc3545', fontWeight: 400 }} >
                                {props.form.errors['config.virtualReality.video.platform']}
                            </LabelComponent>
                        : null}
                    </Form.Group>
                    <InputComponent
                        name='config.virtualReality.video.id'
                        label='Id de video'
                        value={props.form.values['config.virtualReality.video.id']}
                        onChange={props.form.handleChange}
                        invalid={props.form.errors['config.virtualReality.video.id'] ? true : false}
                        invalidText={props.form.errors['config.virtualReality.video.id']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <InputFileComponent
                        as='file'
                        name='config.virtualReality.resources.webFile'
                        label='Recurso Web'
                        value={props.form.values['config.virtualReality.resources.webFile']}
                        onChange={props.form.handleChange}
                        invalid={props.form.errors['config.virtualReality.resources.webFile'] ? true : false}
                        invalidText={props.form.errors['config.virtualReality.resources.webFile']}
                    />
                    <InputFileComponent
                        as='file'
                        name='config.virtualReality.resources.windowsFile'
                        label='Recurso Windows'
                        value={props.form.values['config.virtualReality.resources.windowsFile']}
                        onChange={props.form.handleChange}
                        invalid={props.form.errors['config.virtualReality.resources.windowsFile'] ? true : false}
                        invalidText={props.form.errors['config.virtualReality.resources.windowsFile']}
                    />
                    <InputFileComponent
                        as='file'
                        name='config.virtualReality.resources.androidFile'
                        label='Recurso Android'
                        value={props.form.values['config.virtualReality.resources.androidFile']}
                        onChange={props.form.handleChange}
                        invalid={props.form.errors['config.virtualReality.resources.androidFile'] ? true : false}
                        invalidText={props.form.errors['config.virtualReality.resources.androidFile']}
                    />
                    <InputFileComponent
                        as='file'
                        name='config.virtualReality.resources.iosFile'
                        label='Recurso IOS'
                        value={props.form.values['config.virtualReality.resources.iosFile']}
                        onChange={props.form.handleChange}
                        invalid={props.form.errors['config.virtualReality.resources.iosFile'] ? true : false}
                        invalidText={props.form.errors['config.virtualReality.resources.iosFile']}
                    />
                </>
            :null}
        </div>
    );
}

export default RenderOptionsCategory;