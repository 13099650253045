import React, { useEffect, useState } from 'react'
import { Moment, MomentInput } from 'moment'
import { useSelector } from 'react-redux'
import { IStoreApp } from 'redux/reducers/index';

import momentTz from 'moment-timezone';
const moment = require('moment/min/moment-with-locales');

interface IUseMomentParams{
  locale?: string
}

interface IUseMomentResponse{
  moment: (input?: MomentInput, strict?: boolean) => Moment;
  userLocale: string;
  timeZone: string;
}

const useMoment = (_params?: IUseMomentParams)
:IUseMomentResponse => {

  const userLocale = useSelector((store: IStoreApp) => store?.auth?.user?.lang)
  const [timeZone, updateTimeZone] = useState<string>(momentTz.tz.guess());

  useEffect(() => {
    // @INFO Configurar el idioma del usuario en el moment
    if(userLocale){
      moment.locale(userLocale)
    }
  }, [userLocale])

  return {
    moment: moment as any,
    userLocale: userLocale ? userLocale : '',
    timeZone
  }
}

export default useMoment;
