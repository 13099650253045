// @import dependencies
import ButtonComponent from 'components/commons/Button/ButtonComponent'
import TextComponent from 'components/commons/Text/TextComponent'
import TitlePageComponent from 'components/commons/TitlePage/TitlePageComponent'
import React, { CSSProperties } from 'react'
import { MdDone } from 'react-icons/md'
// @end dependencies

// @import components
// @end components

// @import types
import * as PlanTypes from 'types/plan/plan.types'
// @end types

// @import services
// @end services

// @import hooks
import { useHistory } from 'react-router-dom';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './PlanCard.scss'
// @end styles

interface IPlanCardProps {
    plan: PlanTypes.Plan
    className?: string
    style?: CSSProperties
    id?: string
    active?: boolean
    showPrice?: boolean
    path?: string
}

const PlanCard: React.FC<IPlanCardProps> = (props) => {

    const history = useHistory()

    const active = props.active !== undefined ? props.active : Boolean(props.plan.tag)

    const handleClick = () => {
        if (props.path && history) {
            history.push(props.path)
        }
    }

    return(
        <div
            className={`plan_card-layout ${active ? 'active' : ''} ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            {active ?
                <ButtonComponent
                    variant='primaryInverter'
                    style={{ 
                        color: 'white', 
                        width: 'fit-content', 
                        marginLeft: 'auto',
                        marginBottom: '15px'
                    }}
                >
                    {props.plan.tag.name}
                </ButtonComponent>
            :null}
            {(active || props.showPrice) && !props.plan.custom ?
                <div className="container-price-plan">
                    <TitlePageComponent
                        className='text-plan-card'
                    >
                        {`$${String(props.plan.amount)} ${props.plan.currency.toUpperCase()}`}
                    </TitlePageComponent>
                    <TextComponent
                        className='text-plan-card'
                    >
                        {`/${props.plan.interval}`}
                    </TextComponent>
                </div>
            :null}
            <TitlePageComponent
                className='text-plan-card'
            >
                {props.plan.name}
            </TitlePageComponent>
            <TextComponent
                className='text-plan-card'
            >
                {props.plan.description}.
            </TextComponent>
            <div className="container-benefist-plan">
                {props.plan.features?.map((feature) => (
                    <div className="item-benefist-plan">
                        <div className="circle-check">
                            <MdDone/>
                        </div>
                        <TextComponent
                            className='text-plan-card'
                        >
                            {feature}
                        </TextComponent>
                    </div>
                ))}
            </div>
            <ButtonComponent
                className='button-select-plan'
                onClick={handleClick}
            >
                Escoger Plan
            </ButtonComponent>
        </div>
    );
}

export default PlanCard;