// @import dependencies
import React, { CSSProperties, useEffect, useState } from 'react';
// @end dependencies

// @import components
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import TextComponent from 'components/commons/Text/TextComponent';
import PlanCard from 'components/modules/PlanCard/PlanCard';
import HeaderImageCard from 'components/commons/HeaderImageCard/HeaderImageCard';
// @end components

// @import types
import * as PlanTypes from 'types/plan/plan.types'
// @end types

// @import services
import PlanService from 'services/plan/planService';
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
import BackgroundHeaderPlans from 'assets/images/plans/background_header_plans.png'
// @end assets

// @import styles
import './PlansPage.scss'
// @end styles

interface IPlansPageProps {
    className?: string
    style?: CSSProperties
    id?: string
}

const PlansPage: React.FC<IPlansPageProps> = (props) => {

    const [plans, setPlans] = useState<PlanTypes.Plan[]>([])

    const planService = new PlanService()

    useEffect(() => {
        getPlans()
    }, [])

    const getPlans = async () => {
        const response = await planService.list()
        setPlans(response)
    }

    return(
        <AppLayout
            headerTitle='Plans'
        >
            <div
                className={`plans_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
                id={props.id}
            >
                <HeaderImageCard
                    image={BackgroundHeaderPlans}
                    classNameChildren='plans_header--container'
                >
                    <div className="plans_header--container-header">
                        <TextComponent type='h3' >
                            Nuestros planes para tí
                        </TextComponent>
                    </div>
                    <div className="plans_header--container-cards">
                        {plans?.map((plan) => (
                            <PlanCard
                                className={plan.tag ? undefined : 'plans_header--container-card'}
                                key={plan.id}
                                plan={plan}
                                path={`/custom-plan/${plan.id}`}
                                showPrice
                            />
                        ))}
                    </div>
                </HeaderImageCard>
            </div>
        </AppLayout>
    );
}

export default PlansPage;
