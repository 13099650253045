export interface INotebook{
    id?: string
    name?: string
    user?: string
    lab?: string
    url?: string
}

export interface IParamsNotebookList{
    lab?: string
}

export interface IParamsNotebookCreate{
    notebook:{
        name: string
        lab: string
    }
}

export interface IParamsNotebookUpdate{
    id: string
    notebook:{
        name?: string
        lab?: string
    }
}

export interface IParamsNotebookRemove{
    id: string
}

export const fullNotebookQuery = `
    id
    name
    user
    lab
    url
`
