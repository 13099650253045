import graphQLUtil from "utils/graphQL/graphQL.util"
import { gql } from "@apollo/client"
import store from 'redux/store'
import * as NotebookTypes from 'types/notebook/notebook.types'

class NotebookService{

    private storeObject = store

    constructor(){

    }

    /**
     * @INFO Traer la lista de notebooks
     * @param _params 
     */
    public list = async (_params?: NotebookTypes.IParamsNotebookList) => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query notebooks($token: String!, $lab: ID!){
                        notebooks(token: $token, lab: $lab){
                            ${NotebookTypes.fullNotebookQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    lab: _params?.lab
                }
            }
        })
        if(response?.data?.notebooks?.length){
            return response.data.notebooks
        }else{
            return []
        }
    }

    /**
     * @INFO Crear un nuevo notebook
     * @param _params 
     * @returns 
     */
    public create = async (_params: NotebookTypes.IParamsNotebookCreate)
     : Promise<NotebookTypes.INotebook | undefined> => {
         if(!_params) return
         const response = await graphQLUtil.send({
             method: 'mutate',
             data: {
                 mutation: gql`
                     mutation newNotebook($token: String!, $notebook: NotebookInput!){
                         newNotebook(token: $token, notebook: $notebook){
                             ${NotebookTypes.fullNotebookQuery}
                         }
                     }
                 `,
                 variables: {
                     token: this.storeObject.getState().auth?.token,
                     notebook: _params.notebook
                 }
             }
         },
         {
             alertOnFailed: true
         })
         if(response?.data?.newNotebook){
             return response.data.newNotebook
         }else{
             return undefined
         }
     }

    /**
     * @INFO Actualizar un notebook
     * @param _params 
     * @returns 
     */
    public update = async (_params: NotebookTypes.IParamsNotebookUpdate)
    : Promise<NotebookTypes.INotebook | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation updateNotebook($token: String!, $id: ID!, $notebook: UpdateNotebookInput!){
                        updateNotebook(token: $token, id: $id, notebook: $notebook){
                            ${NotebookTypes.fullNotebookQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    notebook: _params.notebook,
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.updateNotebook){
            return response.data.updateNotebook
        }else{
            return undefined
        }
    }
    
    /**
     * @INFO Eliminar un notebook
     * @param _params 
     * @returns 
     */
    public remove = async (_params: NotebookTypes.IParamsNotebookRemove)
    : Promise<NotebookTypes.INotebook | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation removeNotebook($token: String!, $id: ID!){
                        removeNotebook(token: $token, id: $id){
                            ${NotebookTypes.fullNotebookQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.removeNotebook){
            return response.data.removeNotebook
        }else{
            return undefined
        }
    }

}

export default NotebookService