import store from 'redux/store';
class PermissionsService{

    private storeObject = store

    constructor(){

    }

    /**
     * @INFO Verificar si el usuario tiene un permiso
     * @param _permission 
     * @returns 
     */
    public check = (_permission: string) => {
        return this.getPermissionsList().includes(_permission)
    }

    /**
     * @INFO Verificar si el usuario cumple con todos los permisos necesarios
     * @param _permissions 
     * @returns
     */
    public checkList = (_permissions: string[]) => {
        const permitted = _permissions?.reduce((accum: boolean, item) => {
            if(!this.check(item)){
                accum = false
            }
            return accum
        }, true)
        return permitted
    }

    /**
     * @INFO Obtener el listado de permisos que tiene el usuario
     * @returns 
     */
    public getPermissionsList = () => {
        let permissions = this.storeObject.getState().auth?.user?.role?.permissions
        if(permissions?.length){
            return [...permissions]
        }else{
            return []
        }
    }
}

export default PermissionsService