// @import dependencies
import React, { CSSProperties } from 'react'
// @end dependencies

// @import components
import TextComponent from '../Text/TextComponent';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './FreeTextComponent.scss'
// @end styles

interface IFreeTextComponentProps {
    className?: string
    style?: CSSProperties
    id?: string
    
}

const FreeTextComponent: React.FC<IFreeTextComponentProps> = (props) => {
    return(
        <div
            className={`free_text_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <TextComponent>
                Gratis
            </TextComponent>
        </div>
    );
}

export default FreeTextComponent;