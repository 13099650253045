// @import dependencies
import React, { CSSProperties, useState } from 'react'
// @end dependencies

// @import components
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import ListGridControl, { TypeListGridControl } from 'components/commons/ListGridControl/ListGridControl';
import BreadcrumbComponent from 'components/commons/BreadcrumbComponent/breadcrumbComponent';
import PreviewLaboratoryCard from 'components/modules/PreviewLaboratoryCard/PreviewLaboratoryCard';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
import { useResizeDetector } from 'react-resize-detector';
import { useTranslation } from 'react-i18next';
import { useLaboratory } from 'hooks/useLaboratory/useLaboratory';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './MyLabsPage.scss'
// @end styles

interface IMyLabsPageProps {
    className?: string
    style?: CSSProperties
    id?: string
    history?: any
}

const MyLabsPage: React.FC<IMyLabsPageProps> = (props) => {

    const [listGrid, updateListGrid] = useState<TypeListGridControl>('grid');
    const hookLabs = useLaboratory();
    const detectorContainer = useResizeDetector();
    const {t} = useTranslation()

    const handleClickGetLab = (idLab?: string) => {
        if(props.history && idLab){
            props.history.push(`/laboratory-preview/${idLab}`)
        }
    }

    return(
        <AppLayout
            history={props.history}
            headerTitle='Mis laboratorios'
            fetchMyLabs
        >
            <div
                className={`my_labs_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
                id={props.id}
                ref={detectorContainer.ref}
            >
                <BreadcrumbComponent>
                    <BreadcrumbComponent.Item
                        title='Mis laboratorios'
                        current
                    />
                </BreadcrumbComponent>
                <ListGridControl
                    value={listGrid}
                    onChange={(value) => updateListGrid(value)}
                />
                <div 
                    className={`container-list-cards-preview ${listGrid === 'list' ? 'list' : ''}`}
                >
                    {hookLabs.myActiveLabs.map((itemLab) => (
                        <PreviewLaboratoryCard
                            key={itemLab.id}
                            laboratory={itemLab}
                            list={detectorContainer.width && detectorContainer.width > 960 ? listGrid === 'list' ? true : false : undefined}
                            free={!itemLab.price ? true : false}
                            addToMyLabs
                            hideFavorite
                            hideRating
                            hideShare
                            onClickGet={() => handleClickGetLab(itemLab.id)}
                        />
                    ))}
                    {hookLabs.myInactiveLabs.map((itemLab) => (
                        <PreviewLaboratoryCard
                            key={itemLab.id}
                            laboratory={itemLab}
                            list={detectorContainer.width && detectorContainer.width > 960 ? listGrid === 'list' ? true : false : undefined}
                            free={!itemLab.price ? true : false}
                            addToMyLabs
                            hideFavorite
                            hideRating
                            hideShare
                            onClickGet={() => handleClickGetLab(itemLab.id)}
                        />
                    ))}
                </div>
            </div>
        </AppLayout>
    );
}

export default MyLabsPage;