import React, {CSSProperties} from 'react';
import {Form} from 'react-bootstrap'
import './../switch.scss'
import shortId from 'shortid';
import TextComponent from 'components/commons/Text/TextComponent';

interface ISwitchLayoutProps {
  checked: boolean
  style?: CSSProperties
  className?: string
  disabled?: boolean
  size?: 'small' | 'large'
  label?: string
  onClick: ()=>void
}

const SwitchLayout: React.FC<ISwitchLayoutProps> = (props) => {

  const handleClick = () => {
    if(props.disabled)return
    props.onClick()
  }

    return (
        <div
          className={`switch-component ${props.size ? props.size : ''} ${props.className ? props.className : ''}`}
          style={props.style}
        >
          <Form.Check
            id={shortId.generate()}
            type='switch'
            checked={props.checked}
            disabled={props.disabled}
            onClick={handleClick}
            onChange={()=>{}}
          />
          {props.label ?
          <TextComponent
            type='label'
          >
            {props.label}
          </TextComponent>
          :null}
        </div>
    );
};

export default SwitchLayout;
