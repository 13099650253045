import * as AuthTypes from 'types/auth/auth.types'

export const UPDATE_THEME = '[APP] UPDATE_THEME'
export const UPDATE_SIDEBAR_STATE = '[APP] UPDATE_SIDEBAR_STATE'
export const UPDATE_LANGUAGE = '[APP] UPDATE_LANGUAGE'
export const UPDATE_SYSTEM_CONFIG = '[APP] UPDATE_SYSTEM_CONFIG'
export const UPDATE_SYSTEM_TYPE = '[APP] UPDATE_SYSTEM_TYPE'

export type ITypeThemeApp = 'light' | 'dark';
export type TypeSystemType = 'default' | 'custom';
export type TypeDashboardType = 'normal' | 'only_view';
export type TypeModulesHeader = 'search_labs' | 'title' | 'icon' | 'notifications' | 'favorites' | 'theme' | 'reservation';
export type TypeModulesSystem = 
'HEADER' |
'SIDEBAR' ;

export interface IAppState{
    theme: ITypeThemeApp
    sidebarState: boolean 
    language: AuthTypes.IUser['lang']
    systemType: TypeSystemType
    systemConfig?: SystemConfig
}

export interface SystemConfig{
    public_routes?: string[];
    block_routes?: string[];
    modules?: TypeModulesSystem[];
    default_redirect?: string;
    dashboard_type?: TypeDashboardType;
    header?: {
        type: TypeSystemType;
        modules?: TypeModulesHeader[];
        title?: string;
        icon?: string;
        backgroundColor?: string;
        defaultEnabled?: boolean;
    };
    footer?: {
        type: TypeSystemType;
    };
    dashboard?: {
        publicInfo?: boolean;
        dashboardsSelector?: boolean;
    }
}