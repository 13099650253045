import store from 'redux/store'
import graphQLUtil from "utils/graphQL/graphQL.util"
import { gql } from "@apollo/client"

import * as OccupationTypes from 'types/occupation/occupation.types';

class OccupationService{

    private storeObject = store

    constructor(){

    }

    /**
     * @INFO Traer la lista de ocupaciones
     * @param _params 
     */
     public list = async (_params?: OccupationTypes.ParamsOccupationList): Promise<OccupationTypes.Occupation[]> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query occupations{
                        occupations{
                            ${OccupationTypes.fullOccupationQuery}
                        }
                    }
                `
            }
        })
        if(response?.data?.occupations?.length){
            return response.data.occupations
        }else{
            return []
        }
    }
    
    /**
     * @INFO Traer una ocupacion por id
     * @param _params 
     */
    public getById = async (_params: OccupationTypes.ParamsOccupationGetById): Promise<OccupationTypes.Occupation | undefined> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query occupation($token: String!, $id: ID!){
                        occupation(token: $token, id: $id){
                            ${OccupationTypes.fullOccupationQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        })
        if(response?.data?.occupation){
            return response.data.occupation
        }else{
            return undefined
        }
    }

    /**
     * @INFO Crear una ocupacion
     * @param _params 
     * @returns 
     */
    public create = async (_params: OccupationTypes.ParamsOccupationCreate): Promise<OccupationTypes.Occupation | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation newOccupation($token: String!, $name: ToolCategoryInput!){
                        newOccupation(token: $token, name: $name){
                            ${OccupationTypes.fullOccupationQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    name: _params.name
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.newOccupation){
            return response.data.newOccupation
        }else{
            return undefined
        }
    }

    /**
     * @INFO Actualizar una ocupacion
     * @param _params 
     * @returns 
     */
    public update = async (_params: OccupationTypes.ParamsOccupationUpdate)
    : Promise<OccupationTypes.Occupation | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation updateOccupation($token: String!, $id: ID!, $name: UpdateToolCategoryInput!){
                        updateOccupation(token: $token, id: $id, name: $name){
                            ${OccupationTypes.fullOccupationQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    name: _params.name,
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.updateOccupation){
            return response.data.updateOccupation
        }else{
            return undefined
        }
    }

    /**
     * @INFO Eliminar una ocupacion
     * @param _params 
     * @returns 
     */
    public delete = async (_params: OccupationTypes.ParamsOccupationDelete): Promise<OccupationTypes.Occupation | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation removeOccupation($token: String!, $id: ID!){
                        removeOccupation(token: $token, id: $id){
                            ${OccupationTypes.fullOccupationQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.removeOccupation){
            return response.data.removeOccupation
        }else{
            return undefined
        }
    }
}

export default OccupationService;