// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import SubtitleComponent from 'components/commons/Subtitle/SubtitleComponent';
import InputComponent from 'components/commons/Input/InputComponent';
import CheckBoxComponent from 'components/commons/CheckBoxComponent/CheckBoxComponent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import InputFileComponent from 'components/commons/InputFileComponent/InputFileComponent';
// @end components

// @import types
import { IStoreApp } from 'redux/reducers';
import * as LaboratoryCategoryTypes from 'types/laboratoryCategory/laboratoryCategory.types'
// @end types

// @import services
import LaboratoryCategoryService from 'services/laboratoryCategory/laboratoryCategoryService'
import ScreenNotification from 'services/screenNotification/screenNotification';
import ObjectService from 'services/object/objectService';
// @end services

// @import hooks
import useValidator from 'hooks/useFormValidator/useFormValidator';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './CreateLaboratoryCategoryPage.scss'
import BreadcrumbComponent from 'components/commons/BreadcrumbComponent/breadcrumbComponent';
// @end styles

interface ICreateLaboratoryCategoryPageProps {
    className?: string
    style?: CSSProperties
    id?: string
    history?: any
}

const CreateLaboratoryCategoryPage: React.FC<ICreateLaboratoryCategoryPageProps> = (props) => {
    
    const [loading, updateLoading] = useState<boolean>(false)
    const [laboratoryCategory, updateLaboratoryCategory] = useState<LaboratoryCategoryTypes.ILaboratoryCategory | undefined>(undefined)
    
    const theme = useSelector((store: IStoreApp) => store.app.theme)
    const {t} = useTranslation()

    const form = useValidator([
        { name: 'name', required: true, messageError: t('obligatory_field') },
        { name: 'description', required: true, messageError: t('obligatory_field') },
        { name: 'imageFile', required: true, messageError: t('obligatory_field'), loadKey: 'image' },
        // @INFO Configuraciones de la categoría
        { name: 'config.hasHardware',value: false },
        { name: 'config.hasDuration',value: false },
        { name: 'config.hasHardwareDuration',value: false },
        { name: 'config.hasVirtualReality',value: false },
        { name: 'config.hasMultipleDashboards',value: false },
    ])

    const params = useParams<{idLaboratoryCategory?: string}>()

    // @INFO Servicios
    const laboratoryCategoryService = new LaboratoryCategoryService()
    const screenNotification = new ScreenNotification()
    const objectService = new ObjectService()

    useEffect(() => {
        getInitialData()
    }, [])

    useEffect(() => {
        setValuesForm()
    },[laboratoryCategory])

    /**
     * @INFO Obtener la data inicial para el componente
     */
     const getInitialData = async () => {
        getLaboratoryCategory()
    }

     /**
     * @INFO Establecer los valores del laboratorio en el formulario
     */
    const setValuesForm = () => {
        if(laboratoryCategory){
            form.setValues({...laboratoryCategory})
        }
    }

    /**
     * @INFO Obtener la categoría de laboratorio
     */
    const getLaboratoryCategory = async () => {
        if(!params.idLaboratoryCategory) {
            const auxCategory: LaboratoryCategoryTypes.IParamsLaboratoryCategoryCreate['category'] = {
                name: 'Sin titulo',
                description: 'Sin descripción',
            }
            updateLaboratoryCategory({...auxCategory} as LaboratoryCategoryTypes.ILaboratoryCategory)
        } else{
            const newCategory = await laboratoryCategoryService.getById({ id: params.idLaboratoryCategory })
            if(newCategory){
                updateLaboratoryCategory(newCategory)
            }
        }
    }

    /**
     * @INFO Click en crear o actualizar la categoría
     */
     const handleClickCreateOrUpdate = async () => {
        updateLoading(true)
        // @INFO Obtener el objeto completo del formulario
        const values = form.getValues()
        // @INFO Establecer las propiedades que no son del tipo correcto como indefinidas
        const paramsCreateOrUpdate = objectService.filterPropertyByType({...values}, [
            {name: 'imageFile', type: 'object'},
        ])
        // @INFO Enviar la petición
        let response: any = undefined
        if(params.idLaboratoryCategory){
            response = await laboratoryCategoryService.update({
                category: paramsCreateOrUpdate,
                id: params.idLaboratoryCategory
            })
            if(response){
                screenNotification.showSuccessTimer()
            }
        }else{
            response = await laboratoryCategoryService.create({
                category: paramsCreateOrUpdate
            })
            if(response){
                await screenNotification.showSuccessTimer()
                if(props.history){
                    props.history.push(`/edit-laboratory-category/${response.id}`)
                }
            }
        }
        updateLoading(false)
    }

    return(
        <AppLayout
            history={props.history}
            headerTitle='Catalogo'
        >
            <div
                className={`create_laboratory_category_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
                id={props.id}
            >
                <BreadcrumbComponent
                    style={{
                        marginBottom: '20px'
                    }}
                >
                    <BreadcrumbComponent.Item
                        title='Catalogador'
                        path='/cataloger'
                    />
                    <BreadcrumbComponent.Item
                        title='Lista'
                        path='/cataloger-list/laboratory-category'
                    />
                    <BreadcrumbComponent.Item
                        title={laboratoryCategory?.name ? laboratoryCategory.name : 'Nueva categoría de laboratorio'}
                        current
                    />
                </BreadcrumbComponent>
                <SubtitleComponent
                    style={{ marginBottom: '15px' }}
                >
                    {params.idLaboratoryCategory ? 'Editar categoría de laboratorio' : 'Crear categoría de laboratorio'}
                </SubtitleComponent>
                <div className="container-form">
                    <InputComponent
                        name='name'
                        label='Nombre'
                        value={form.values['name']}
                        onChange={form.handleChange}
                        invalid={form.errors['name'] ? true : false}
                        invalidText={form.errors['name']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <InputComponent
                        name='description'
                        label='Descripción'
                        value={form.values['description']}
                        onChange={form.handleChange}
                        invalid={form.errors['description'] ? true : false}
                        invalidText={form.errors['description']}
                        background={theme === 'dark' ? 'background-login' : undefined}
                    />
                    <InputFileComponent
                        as='image'
                        name='imageFile'
                        label='Imagen'
                        value={form.values['imageFile']}
                        onChange={form.handleChange}
                        invalid={form.errors['imageFile'] ? true : false}
                        invalidText={form.errors['imageFile']}
                    />
                    <SubtitleComponent
                        style={{
                            gridColumn: '1/3'
                        }}
                    >
                        Configuración:
                    </SubtitleComponent>
                    <div className="container-checks-options">
                        <CheckBoxComponent
                            name='config.hasHardware'
                            checked={form.values['config.hasHardware']}
                            label='Hardwares'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.hasDuration'
                            checked={form.values['config.hasDuration']}
                            label='Duración'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.hasHardwareDuration'
                            checked={form.values['config.hasHardwareDuration']}
                            label='Duración de hardware'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.hasVirtualReality'
                            checked={form.values['config.hasVirtualReality']}
                            label='Realidad virtual'
                            onChange={form.handleChange}
                        />
                        <CheckBoxComponent
                            name='config.hasMultipleDashboards'
                            checked={form.values['config.hasMultipleDashboards']}
                            label='Multiples dashboard'
                            onChange={form.handleChange}
                        />
                    </div>
                    <div
                        style={{ gridColumn: '1/3', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                    >
                        <ButtonComponent
                            onClick={handleClickCreateOrUpdate}
                            variant='primary'
                            disabled={form.isInvalid()}
                            loading={loading}
                        >
                            {params.idLaboratoryCategory ? 'Guardar' : 'Crear'}
                        </ButtonComponent>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

export default CreateLaboratoryCategoryPage;