// @import dependencies
import { useEffect, useState, CSSProperties } from 'react';
// @end dependencies

// @import components
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import TextComponent from 'components/commons/Text/TextComponent';
// @end components

// @import services
import SocketService from 'services/socket/socketService';
// @end services

// @import types
import * as ToolTypes from 'types/tool/tool.types'
// @end types

// @import styles
import './XarmModuleDashboard.scss';
// @end styles

interface XarmModuleDashboardProps {
  tool: ToolTypes.ITool
  className?: string
  style?: CSSProperties
  id?: string
}

export const XarmModuleDashboard: React.FC<XarmModuleDashboardProps> = (props) => {
  const servos = [
    'Servo 1',
    'Servo 2',
    'Servo 3',
    'Servo 4',
    'Servo 5',
    'Servo 6',
  ];

  const delta = 50;

  const [servoPosition, setServoPosition] = useState<number[]>([0, 0, 0, 0, 0, 0]);

  // @INFO Services
  const socketService = new SocketService();

  // @INFO Traer información de los servos al inicio
  useEffect(() => {
    socketService.emitEvent('getInfoXarmRobot', { toolId: props.tool?.id });
    socketService.listenEvent(`infoXarmRobot:${props.tool?.id}`, handleNewData);

    return () => {
      socketService.removeListenEvent(`infoXarmRobot:${props.tool?.id}`, handleNewData);
      socketService.emitEvent('finishConnectionXarmRobot', {
        toolId: props.tool?.id,
      });
    };
  }, []);

  const handleNewData = (data: any) => {
    setServoPosition(data.payload.servoPosition);
  };

  // @INFO Mover servos
  const handleMoveServo = (servo: number, delta: number) => {
    socketService.emitEvent('setActionXarmRobot', {
      toolId: props.tool?.id,
      action: 'move',
      payload: {
        servo,
        delta,
      },
    });
  };
  // @INFO Restablecer servos a su posición inicial
  const handleReset = () => {
    socketService.emitEvent('setActionXarmRobot', { toolId: props.tool?.id, action: 'reset' });
  };

  return (
    <div
      className={`xarm_module_dashboard-layout ${props.className ? props.className : ''}`}
      style={props.style}
      id={props.id}
    >
      <ButtonComponent
        variant="primary"
        style={{
          color: 'white',
          padding: '0.5rem 3rem',
        }}
        onClick={handleReset}
      >
        Restablecer posiciones
      </ButtonComponent>
      {servos.map((servo, index) => (
        <div key={index} className="container-controls">
          <ButtonComponent
            variant="accent"
            style={{
              color: 'white',
              padding: '0.5rem 3rem',
            }}
            onClick={() => handleMoveServo(index + 1, -delta)}
          >
            -
          </ButtonComponent>
          <div className="servo-info">
            <TextComponent>{servo}</TextComponent>
            <TextComponent>{servoPosition[index]}</TextComponent>
          </div>
          <ButtonComponent
            variant="accent"
            style={{
              color: 'white',
              padding: '0.5rem 3rem',
            }}
            onClick={() => handleMoveServo(index + 1, delta)}
          >
            +
          </ButtonComponent>
        </div>
      ))}
    </div>
  );
};
