// @import types
import * as LaboratoryTypes from 'types/laboratories/laboratories.types';
import * as ToolTypes from 'types/tool/tool.types';
import { Layout } from 'react-grid-layout';
// @end types

export class DashboardLaboratoryFunctions{
    constructor() {

    }

    public getToolLayoutPropertiesFromCategory = (tool: ToolTypes.ITool, currentX: number = 0, cols: number = 40) => {
        let layoutProperties: Layout = {
            i: tool.id ? tool.id : '',
            x: 0,
            y: 0,
            w: 4,
            h: 2,
            minH: 2,
            minW: 4
        };
        if(tool.category?.config?.isCamera){
            layoutProperties.w = 18;
            layoutProperties.h = 17;
            layoutProperties.minW = 12;
            layoutProperties.minH = 12;
        }
        if(tool.category?.config?.isSource){
            layoutProperties.w = 11;
            layoutProperties.h = 15;
            layoutProperties.minW = 8;
            layoutProperties.minH = 13;
        }
        if(tool.category?.config?.isLineChart || tool?.category?.config?.isBarChart){
            layoutProperties.w = 20;
            layoutProperties.h = 20;
            layoutProperties.minW = 15;
            layoutProperties.minH = 15;
        }
        if(tool.category?.config?.isButton){
            layoutProperties.w = 10;
            layoutProperties.h = 10;
            layoutProperties.minW = 6;
            layoutProperties.minH = 6;
        }
        if(tool.category?.config?.isPushbutton){
            layoutProperties.w = 10;
            layoutProperties.h = 10;
            layoutProperties.minW = 8;
            layoutProperties.minH = 8;
        }
        if(tool.category?.config?.isCodeEditor){
            layoutProperties.w = 40;
            layoutProperties.h = 20;
            layoutProperties.minW = 20;
            layoutProperties.minH = 10;
        }
        if(tool.category?.config?.isRoboticArm){
            layoutProperties.w = 12;
            layoutProperties.h = 15;
            layoutProperties.minW = 12;
            layoutProperties.minH = 15;
        }
        if(tool.category?.config?.isImage){
            layoutProperties.w = 12;
            layoutProperties.h = 7;
            layoutProperties.minW = 5;
            layoutProperties.minH = 5;
        }
        if(tool.category?.config?.isTable){
            layoutProperties.w = 12;
            layoutProperties.h = 7;
            layoutProperties.minW = 5;
            layoutProperties.minH = 5;
        }
        if(currentX + layoutProperties.w > cols){
            layoutProperties.x = 0;
        }else{
            layoutProperties.x = currentX;
        }
        return layoutProperties;
    }

    public getToolsFromLaboratory = (laboratory: LaboratoryTypes.ILaboratory) => {
        let tools: ToolTypes.ITool[] = [];
        // TODO: Se debe elegir solo el hardware que pertenece a la reserva 
        // TODO: En caso de ser con hardwareDuration se debe elegir el hardware asignado
        if (laboratory?.config?.hardware?.length){
            laboratory.config.hardware.forEach((h) => {
                if(h.tools?.length){
                    tools = [...tools, ...h.tools];
                }
            });
        }
        return tools;
    }
}