import React, { CSSProperties, useState } from 'react'
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import TooltipComponent from 'components/commons/TooltipComponent/TooltipComponent';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { IoIosBook, IoMdMail } from 'react-icons/io';
import { MdInvertColors, MdPhoneInTalk } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { updateThemeAppAction } from 'redux/reducers/app/app.actions';
import { IStoreApp } from 'redux/reducers/index';
import { useTranslation } from 'react-i18next';
import StepperComponent from 'components/commons/Stepper/Stepper';
import TitlePageComponent from 'components/commons/TitlePage/TitlePageComponent';
import { BiMapPin } from 'react-icons/bi'
import { RiAccountCircleFill } from 'react-icons/ri'
import InputComponent from 'components/commons/Input/InputComponent';
import TextComponent from 'components/commons/Text/TextComponent';
import useValidator from 'hooks/useFormValidator/useFormValidator';
import LocationsComponent from 'components/modules/LocationsComponent/LocationsComponent';
import AuthService from 'services/auth/authService';
import * as AuthActions from 'redux/reducers/auth/auth.actions'
import OccupationSelectModule from 'components/modules/OccupationSelectModule/OccupationSelectModule';
import { HOME_ROUTE } from 'config/globals';

import vlesimIcon from 'assets/images/logoVlesim1.png'
import imgLight from 'assets/icons/LoginIcons/img_light.svg'
import imgDark from 'assets/icons/LoginIcons/img_dark.svg'

import './RegisterPage.scss'
import { Redirect } from 'react-router-dom';

interface IRegisterPageProps {
    className?: string
    style?: CSSProperties
    history?: any
}

const RegisterPage: React.FC<IRegisterPageProps> = (props) => {

    const [stepIndex, updateStepIndex] = useState<number>(0)
    const dispatch = useDispatch()
    const app = useSelector((store: IStoreApp) => store.app)
    const isAuth = useSelector((store: IStoreApp) => store.auth.isLogged)
    const {t} = useTranslation()

    // @INFO Formularios de los tabs
    const formTab1 = useValidator([
        {name: 'firstName', required: true, value: '', messageError: t('obligatory_field')},
        {name: 'lastName', required: true, value: '', messageError: t('obligatory_field')},
        {name: 'email', required: true, value: '', messageError: t('obligatory_field'), validators: [{type: 'email'}]},
        {name: 'birthday', required: true, value: '', messageError: t('obligatory_field')},
        {name: 'phoneNumber', required: true, value: '', messageError: t('obligatory_field')},
    ])
    const formTab2 = useValidator([
        {name: 'occupation', required: true, value: '', messageError: t('obligatory_field')},
        {name: 'user', required: true, value: '', messageError: t('obligatory_field')},
        {name: 'password', required: true, value: '', messageError: t('obligatory_field')},
    ])
    const [locationData, updateLocationData] = useState<any | undefined>(undefined)

    // @INFO Servicios
    const authService = new AuthService()

    const handleChangeTheme = () => {
        if(app.theme === 'light'){
            dispatch(updateThemeAppAction('dark'))
        }else if(app.theme === 'dark'){
            dispatch(updateThemeAppAction('light'))
        }
    }

    const handleClickStepButton = (type: 'next'| 'prev') => {
        switch(type){
            case 'next':
                if(stepIndex < 2){
                    updateStepIndex((stepIndex+1))
                }
                return
            case 'prev':
                if(stepIndex > 0){
                    updateStepIndex((stepIndex-1))
                }
                return
            default:
                return
        }
    }

    const redirectToLogin = () => {
        if(props.history){
            props.history.push('/login')
        }
    }

    /**
     * @INFO Registrar un usuario
     */
    const handleClickRegister = async () => {
        if(!formTab1.isInvalid() && !formTab2.isInvalid() && locationData){
            const params = {
                ...formTab1.getValues(),
                ...formTab2.getValues(),
                ...locationData
            }
            dispatch(AuthActions.createAccountAction(params))
        }
    }

    return(
        <div
            className={`register_page-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <div 
                className="container-info"
                style={{backgroundColor: app.theme === 'dark' ? '#064D77' : undefined}}
            >
                <div 
                    className="btn-change-theme"
                    onClick={handleChangeTheme}
                >
                    <MdInvertColors/>
                </div>
                <img src={vlesimIcon} className='icon-vlesim' />
                <img 
                    src={app.theme === 'light' ? imgLight : imgDark} 
                    className='icon-section' 
                    alt="" 
                />
                <div className="container-icons">
                    <TooltipComponent
                        text={t('login.questions')}
                    >
                        <div className="icon-menu">
                            <AiFillQuestionCircle/>
                        </div>
                    </TooltipComponent>
                    <TooltipComponent
                        text={t('login.phone')}
                    >
                        <div className="icon-menu">
                            <MdPhoneInTalk/>
                        </div>
                    </TooltipComponent>
                    <TooltipComponent
                        text={t('login.contact')}
                    >
                        <div className="icon-menu">
                            <IoMdMail/>
                        </div>
                    </TooltipComponent>
                </div>
                <div className="container-options">
                    <ButtonComponent
                        className='button-options'
                    >
                        {t('login.options')}
                    </ButtonComponent>
                </div>
            </div>
            <div
                className='container-form-register'
            >
                <div className="form-login">
                    <TitlePageComponent
                        className='login-text'
                    >
                        {t('register.title')}
                    </TitlePageComponent>
                    <StepperComponent
                        showProgress
                        index={stepIndex}
                        onChange={(idx: number) => updateStepIndex(idx)}
                    >
                        <StepperComponent.IconsContent>
                            <StepperComponent.Icon>
                                <RiAccountCircleFill/>
                            </StepperComponent.Icon>
                            <StepperComponent.Icon>
                                <IoIosBook/>
                            </StepperComponent.Icon>
                            <StepperComponent.Icon>
                                <BiMapPin/>
                            </StepperComponent.Icon>
                        </StepperComponent.IconsContent>
                        <StepperComponent.StepperContent>
                            <StepperComponent.Content
                                className='container-fields-form'
                            >
                                <InputComponent
                                    placeholder={t('register.fields.firstName')}
                                    name='firstName'
                                    invalid={formTab1.errors['firstName'] ? true : false}
                                    invalidText={formTab1.errors['firstName']}
                                    value={formTab1.values['firstName']}
                                    onChange={formTab1.handleChange}
                                />
                                <InputComponent
                                    placeholder={t('register.fields.lastName')}
                                    name='lastName'
                                    invalid={formTab1.errors['lastName'] ? true : false}
                                    invalidText={formTab1.errors['lastName']}
                                    value={formTab1.values['lastName']}
                                    onChange={formTab1.handleChange}
                                />
                                <InputComponent
                                    placeholder={t('register.fields.email')}
                                    name='email'
                                    invalid={formTab1.errors['email'] ? true : false}
                                    invalidText={formTab1.errors['email']}
                                    value={formTab1.values['email']}
                                    onChange={formTab1.handleChange}
                                />
                                <InputComponent
                                    type='date'
                                    placeholder={t('register.fields.birthday')}
                                    name='birthday'
                                    invalid={formTab1.errors['birthday'] ? true : false}
                                    invalidText={formTab1.errors['birthday']}
                                    value={formTab1.values['birthday']}
                                    onChange={formTab1.handleChange}
                                />
                                <InputComponent
                                    placeholder={t('register.fields.phoneNumber')}
                                    type='number'
                                    name='phoneNumber'
                                    invalid={formTab1.errors['phoneNumber'] ? true : false}
                                    invalidText={formTab1.errors['phoneNumber']}
                                    value={formTab1.values['phoneNumber']}
                                    onChange={formTab1.handleChange}
                                />
                                <ButtonComponent
                                    variant='primary'
                                    className='btn-login'
                                    onClick={() => handleClickStepButton('next')}
                                    disabled={formTab1.isInvalid()}
                                >
                                    {t('register.next')}
                                </ButtonComponent>
                            </StepperComponent.Content>
                            <StepperComponent.Content
                                className='container-fields-form'
                            >
                                <OccupationSelectModule
                                    label={t('register.fields.occupation')}
                                    onChangeOne={(id) => formTab2.setValue('occupation', id)}
                                    defaultValue={[formTab2.values['occupation']]}
                                />
                                <InputComponent
                                    placeholder={t('register.fields.user')}
                                    name='user'
                                    invalid={formTab2.errors['user'] ? true : false}
                                    invalidText={formTab2.errors['user']}
                                    value={formTab2.values['user']}
                                    onChange={formTab2.handleChange}
                                />
                                <InputComponent
                                    placeholder={t('register.fields.password')}
                                    type='password'
                                    name='password'
                                    invalid={formTab2.errors['password'] ? true : false}
                                    invalidText={formTab2.errors['password']}
                                    value={formTab2.values['password']}
                                    onChange={formTab2.handleChange}
                                />
                                <InputComponent
                                    placeholder={t('register.fields.avatar')}
                                    type='file'
                                    label={t('register.fields.avatar')}
                                />
                                <ButtonComponent
                                    variant='primary'
                                    className='btn-login'
                                    onClick={() => handleClickStepButton('next')}
                                    disabled={formTab2.isInvalid()}
                                >
                                    {t('register.next')}
                                </ButtonComponent>
                            </StepperComponent.Content>
                            <StepperComponent.Content
                                className='container-fields-form'
                            >
                                <LocationsComponent
                                    onComplete={(response) => updateLocationData(response)}
                                    defaultData={locationData}
                                />
                                <ButtonComponent
                                    variant='primary'
                                    className='btn-login'
                                    disabled={!locationData}
                                    onClick={handleClickRegister}
                                >
                                    {t('register.register')}
                                </ButtonComponent>
                            </StepperComponent.Content>
                        </StepperComponent.StepperContent>
                    </StepperComponent>
                    <TextComponent
                        onClick={redirectToLogin}
                        className='create-account-text'
                    >
                        {t('register.go_login')}
                    </TextComponent>
                </div>
            </div>
            {isAuth ?
                <Redirect to={HOME_ROUTE} />
            : null}
        </div>
    );
}

export default RegisterPage;