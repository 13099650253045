import store from 'redux/store'
import graphQLUtil from "utils/graphQL/graphQL.util"
import { gql } from "@apollo/client"

import * as ToolTypes from 'types/tool/tool.types'

class ToolService{

    private storeObject = store

    constructor(){

    }

    /**
     * @INFO Traer la lista de tools
     * @param _params 
     */
    public list = async (_params?: ToolTypes.IParamsToolList): Promise<ToolTypes.ITool[]> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query tools($token: String!){
                        tools(token: $token){
                            ${ToolTypes.fullToolQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token
                }
            }
        })
        if(response?.data?.tools?.length){
            return response.data.tools
        }else{
            return []
        }
    }
    
    /**
     * @INFO Traer un tool por id
     * @param _params 
     */
    public getById = async (_params: ToolTypes.IParamsToolGetById): Promise<ToolTypes.ITool | undefined> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query tool($token: String!, $id: ID!){
                        tool(token: $token, id: $id){
                            ${ToolTypes.fullToolQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        })
        if(response?.data?.tool){
            return response.data.tool
        }else{
            return undefined
        }
    }

    /**
     * @INFO Crear una tool
     * @param _params 
     * @returns 
     */
    public create = async (_params: ToolTypes.IParamsToolCreate): Promise<ToolTypes.ITool | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation newTool($token: String!, $tool: ToolInput!){
                        newTool(token: $token, tool: $tool){
                            ${ToolTypes.fullToolQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    tool: {..._params.tool}
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.newTool){
            return response.data.newTool
        }else{
            return undefined
        }
    }

    /**
     * @INFO Actualizar una tool
     * @param _params 
     * @returns 
     */
    public update = async (_params: ToolTypes.IParamsToolUpdate)
    : Promise<ToolTypes.ITool | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation updateTool($token: String!, $id: ID!, $tool: UpdateToolInput!){
                        updateTool(token: $token, id: $id, tool: $tool){
                            ${ToolTypes.fullToolQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    tool: {..._params.tool},
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.updateTool){
            return response.data.updateTool
        }else{
            return undefined
        }
    }

    /**
     * @INFO Eliminar una tool
     * @param _params 
     * @returns 
     */
    public delete = async (_params: ToolTypes.IParamsToolDelete): Promise<ToolTypes.ITool | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation removeTool($token: String!, $id: ID!){
                        removeTool(token: $token, id: $id){
                            ${ToolTypes.fullToolQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.removeTool){
            return response.data.removeTool
        }else{
            return undefined
        }
    }
}

export default ToolService