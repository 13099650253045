// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import InputComponent from 'components/commons/Input/InputComponent';
import useValidator from 'hooks/useFormValidator/useFormValidator';
import { Modal } from 'react-bootstrap';
// @end components

// @import types
import * as ToolTypes from 'types/tool/tool.types';
// @end types

// @import services
// @end services

// @import hooks
import { useTranslation } from 'react-i18next';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ModalNewFileCodeEditor.scss'
import { FileCodeEditor } from '../CodeEditorModuleDashboard/CodeEditorModuleDashboard';
import CodeLanguageSelect from 'components/modules/CodeLanguageSelect/CodeLanguageSelect';
// @end styles

interface IModalNewFileCodeEditorProps {
    open: boolean;
    tool: ToolTypes.ITool;
    editData?: FileCodeEditor;
    className?: string;
    style?: CSSProperties;
    id?: string;
    onClose?: (data?: Partial<FileCodeEditor>) => any;
}

const ModalNewFileCodeEditor: React.FC<IModalNewFileCodeEditorProps> = (props) => {
    
    const [loading, updateLoading] = useState<boolean>(false)

    const {t} = useTranslation()

    const form = useValidator([
        { name: 'name', required: true, messageError: t('obligatory_field'), value: props.editData ? props.editData.name : undefined },
        { name: 'language', required: true, messageError: t('obligatory_field'), value: props.editData ? props.editData.language : undefined },
    ])

    useEffect(() => {
        if (!props.open) {
            form.setValue('name', '');
            form.setValue('language', undefined);
        }
    }, [props.open]);

    useEffect(() => {
        if (props.editData) {
            form.setValue('name', props.editData.name);
            form.setValue('language', props.editData.language);
        }
    }, [props.editData]);

    const handleCloseModal = (status?: boolean) => {
        if(props.onClose){
            props.onClose(status ? {
                name: form.getValues().name,
                language: form.getValues().language,
                id: props.editData?.id
            }: undefined);
        }
    }
    
    return(
        <Modal
        onHide={() => handleCloseModal()}
        show={props.open}
    >
        <Modal.Header
            closeButton
        >
            <Modal.Title>
                {props.editData ? 'Editar' : 'Nuevo'} archivo
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div
                className={`modal_new_file_code_editor-layout ${props.className ? props.className : ''}`}
                style={props.style}
                id={props.id}
            >
                <InputComponent
                    name='name'
                    label='Nombre'
                    placeholder='Nombre'
                    onChange={form.handleChange}
                    value={form.values?.name}
                    invalid={form.errors?.name ? true : false}
                    invalidText={form.errors?.name}
                />
                 <CodeLanguageSelect
                    label='Lenguaje'
                    defaultValue={props.editData?.language ? [props.editData?.language] : undefined}
                    onChangeOne={(_id) => form.setValue('language', _id)}
                />
            </div>
        </Modal.Body>
        <Modal.Footer>
            <ButtonComponent
                onClick={() => handleCloseModal()}
            >
                Cancelar
            </ButtonComponent>
            <ButtonComponent
                variant='primary'
                disabled={form.isInvalid()}
                onClick={() => handleCloseModal(true)}
                loading={loading}
            >
                {props.editData ? 'Guardar' : 'Crear'}
            </ButtonComponent>
        </Modal.Footer>
    </Modal>
    );
}

export default ModalNewFileCodeEditor;