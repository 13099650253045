import React, { useState, useEffect } from 'react';

// @import types
import * as UseLaboratoryTypes from './useLaboratory.types';
import * as LaboratoriesTypes from 'types/laboratories/laboratories.types';
import * as LaboratoryCategoryTypes from 'types/laboratoryCategory/laboratoryCategory.types';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreApp } from 'redux/reducers/index';
import * as FavoriteTypes from 'types/favorite/favorite.types';
// @end types

// @import services
import LaboratoryService from 'services/laboratory/laboratoryService';
import LaboratoryCategoryService from 'services/laboratoryCategory/laboratoryCategoryService';
// @end services

// @import actions
import * as LaboratoriesActions from 'redux/reducers/laboratories/laboratories.actions';
import FavoriteService from 'services/favorite/favoriteService';
// @end actions

export const useLaboratory = (params?: UseLaboratoryTypes.UseLaboratoryInput): UseLaboratoryTypes.UseLaboratory => {

    const [myLabs, updateMyLabs] = useState<LaboratoriesTypes.ILaboratory[]>([]);
    const [myActiveLabs, updateMyActiveLabs] = useState<LaboratoriesTypes.ILaboratory[]>([]);
    const [myInactiveLabs, updateMyInactiveLabs] = useState<LaboratoriesTypes.ILaboratory[]>([]);
    const [favorites, updateFavorites] = useState<FavoriteTypes.IFavorite[]>([]);
    const [categories, updateCategories] = useState<LaboratoryCategoryTypes.ILaboratoryCategory[]>([])
    const [labStatus, updateLabStatus] = useState<UseLaboratoryTypes.GetLabStatusResponse>({
        exist: false, 
        active: false, 
        inactive: false,
        favorite: false
    });
    const dispatch = useDispatch();
    const laboratories = useSelector((store: IStoreApp) => store.laboratories);

    // @INFO Servicios
    const favoriteService = new FavoriteService()
    const laboratoryService = new LaboratoryService();
    const laboratoryCategoryService = new LaboratoryCategoryService();

    useEffect(() => {
        getMyLabs();
        getLabsCategories();
        getLabsFavorites();
    }, []);

    useEffect(() => {
        let auxMyLabs = laboratories.myLabs;
        let auxMyActiveLabs = laboratories.myActiveLabs;
        let auxMyInactiveLabs = auxMyLabs.filter((l) => !auxMyActiveLabs.find((al) => al.id === l.id));
        updateMyLabs(auxMyLabs);
        updateMyActiveLabs(auxMyActiveLabs);
        updateMyInactiveLabs(auxMyInactiveLabs);
    }, [laboratories.myActiveLabs, laboratories.myLabs]);

    useEffect(() => {
        updateCategories(laboratories.categories);
    }, [laboratories.categories])
    
    useEffect(() => {
        updateFavorites(laboratories.favorites);
    }, [laboratories.favorites])

    useEffect(() => {
        if(params?.idLaboratoryVerify){
            updateLabStatus(getLabStatus(params.idLaboratoryVerify));
        }
    }, [params?.idLaboratoryVerify, myLabs, myActiveLabs, favorites]);

    const getMyLabs = async () => {
        let auxMyLabs = laboratories.myLabs;
        if(!auxMyLabs.length && params?.fetchLabs){
            auxMyLabs = await laboratoryService.myLabs();
            dispatch(LaboratoriesActions.getMyLabsAction(auxMyLabs));
        }
        let auxMyActiveLabs = laboratories.myActiveLabs;
        if(!auxMyActiveLabs.length  && params?.fetchLabs){
            auxMyActiveLabs = await laboratoryService.myActiveLabs();
            dispatch(LaboratoriesActions.getMyActiveLabsAction(auxMyActiveLabs));
        }
        let auxMyInactiveLabs = auxMyLabs.filter((l) => !auxMyActiveLabs.find((al) => al.id === l.id));
        updateMyLabs(auxMyLabs);
        updateMyActiveLabs(auxMyActiveLabs);
        updateMyInactiveLabs(auxMyInactiveLabs);
    }

    const getLabsCategories = async () => {
        let auxCategories = laboratories.categories;
        if (!auxCategories?.length && params?.fetchLabsCategories){
            auxCategories = await laboratoryCategoryService.list();
            dispatch(LaboratoriesActions.getLaboratoryCategoriesAction(auxCategories));
            updateCategories(auxCategories);
        }
    }

    const getLabsFavorites = async () => {
        let auxFavorites = laboratories.favorites;
        if (!auxFavorites?.length && params?.fetchFavorites){
            auxFavorites = await favoriteService.list();
            dispatch(LaboratoriesActions.getLaboratoryFavoritesAction(auxFavorites));
            updateFavorites(auxFavorites);
        }
    }

    const getLabStatus = (id: string): UseLaboratoryTypes.GetLabStatusResponse => {
        let exist: boolean = false;
        let active: boolean = false;
        let inactive: boolean = false;
        let favorite: boolean = false;

        if (myActiveLabs.find((al) => al.id === id)){
            active = true;
            exist = true;
            inactive = false;
        }else if (myInactiveLabs.find((il) => il.id === id)){
            active = false;
            exist = true;
            inactive = true;
        }

        if (favorites.find((f) => f.lab.id === id)){
            favorite = true;
        }

        return {
            exist,
            active,
            inactive,
            favorite
        };
    }

    const addToMyLabs = async (id: string) => {
        const responseExist = getLabStatus(id);
        if (!responseExist.exist){
            await laboratoryService.addToMyLabs({lab: id});
        }
    }

    const compileNewLabStatus = (id: string) => {
        if (params){
            params.idLaboratoryVerify = id;
        } else {
            params = {
                idLaboratoryVerify: id
            };
        }
    }

    return {
        myLabs,
        myActiveLabs,
        myInactiveLabs,
        favorites,
        labStatus,
        categories,
        getLabStatus,
        addToMyLabs,
        compileNewLabStatus
    }
}