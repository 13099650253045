import React, { CSSProperties } from 'react'
import './TitlePageComponent.scss'

export interface TitlePageComponentProps {
    children?: string
    className?: string
    style?: CSSProperties
}
 
const TitlePageComponent: React.SFC<TitlePageComponentProps> = (props) => {
    return (  
        <h2
            className={`title_page-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            {props.children}
        </h2>
    );
}
 
export default TitlePageComponent;