// @import dependencies
import { useChangeTheme } from 'hooks/useChangeTheme/useChangeTheme';
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import InputComponent from '../Input/InputComponent';
import PopUpComponent from '../PopUp/PopUpComponent';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
import {HexColorPicker} from 'react-colorful';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ColorPicker.scss'
// @end styles

interface IColorPickerProps {
    className?: string;
    style?: CSSProperties;
    id?: string;
    value?: string;
    label?: string;
    onChange?: (color: string) => void;
}

const ColorPicker: React.FC<IColorPickerProps> = (props) => {

    const [show, updateShow] = useState<boolean>(false);
    const [color, updateColor] = useState<string>("#559955");
    const theme = useChangeTheme();

    useEffect(() => {
        if (props.value !== undefined) {
            updateColor(props.value);
        }
    }, [props.value])

    const handleChangePicker = (_color: string) => {
        updateColor(_color)
        if (props.onChange) {
            props.onChange(_color);
        }
    }

    return(
        <div
            className={`color_picker-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <PopUpComponent
                show={show}
                onClickClose={() => updateShow(false)}
                content={(
                    <HexColorPicker color={color} onChange={handleChangePicker} />
                )}
            >
                <div className="container-color-field">
                    <InputComponent
                        label={props.label}
                        placeholder='Ej: #FF0000'
                        name='header.title'
                        value={color}
                        onChange={(e) => updateColor(e.target.value)}
                        background={theme.theme === 'light' ? 'background2' : 'background-login'}
                    />
                    <div
                        onClick={() => updateShow(!show)}
                        className="square-color-picker"
                        style={{
                            backgroundColor: color
                        }}
                    ></div>
                </div>
            </PopUpComponent>
        </div>
    );
}

export default ColorPicker;