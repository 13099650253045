import React from 'react';
import styled from 'styled-components'

export interface LinkToolEditorJsProps {
  data: {link: string, meta: {title: string}}
}

const LinkToolContainer = styled.div`
  border: 1px solid rgba(201, 201, 204, 0.48);
  border-radius: 6px;
  box-shadow: 0 0 3px rgb(0 0 0 / 16%);
  padding: 15px;
  width: auto;
  height: auto;
  margin-bottom: 15px;
  &:hover{
    cursor: pointer;
  }
  &>p{
    color: var(--text);
    font-size: 1rem;
    font-weight: 700;
  }
  &>p:last-of-type{
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--gray-500);
  }
`;

const LinkToolEditorJs: React.SFC<LinkToolEditorJsProps> = (props) => {

  const handleClickLink = () => {
    window.open(props.data.link, '_blank', 'noreferrer')
  }

  return (
    <LinkToolContainer onClick={handleClickLink} >
      <p>{props.data.meta.title}</p>
      <p>{props.data.link}</p>
    </LinkToolContainer>
  );
}

export default LinkToolEditorJs;
