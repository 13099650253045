// @import dependencies
import React, { CSSProperties, useEffect, useState, ChangeEvent } from 'react'
// @end dependencies

// @import components
import TextComponent from 'components/commons/Text/TextComponent';
import ReactAutocomplete from 'react-autocomplete';
import LaboratoryService from 'services/laboratory/laboratoryService';
// @end components

// @import types
import * as LaboratoriesTypes from 'types/laboratories/laboratories.types';
// @end types

// @import services
// @end services

// @import hooks
import { useHistory } from 'react-router';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './SearchLaboratories.scss'
import { BiLinkExternal } from 'react-icons/bi';
// @end styles

interface ISearchLaboratoriesProps {
    className?: string
    style?: CSSProperties
    id?: string
    
}

const SearchLaboratories: React.FC<ISearchLaboratoriesProps> = (props) => {
    
    const history = useHistory();

    const [search, updateSearch] = useState<string>("");
    const [laboratories, updateLaboratories] = useState<LaboratoriesTypes.ILaboratory[]>([]);

    const laboratoryService = new LaboratoryService();

    const [reload, updateReload] = useState<boolean>(false);

    useEffect(() => {
        if (reload) {
        window.location.reload()
        updateReload(false)
        }
    }, [reload])

    useEffect(() => {
        if (search.trim().length > 2) {
            getLaboratories();
        } else {
            updateLaboratories([]);
        }
    }, [search])

    const getLaboratories = async () => {
        const labs = await laboratoryService.list({
            filter: {
                search
            }
        });
        updateLaboratories(labs);
    }

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        updateSearch(e.target.value);
    }

    const handleClickSelect = (valor: string) => {
        const currentPath = window.location.href;
        if (currentPath.includes('laboratory-preview')) {
            if (history) {
                history.push(`/laboratory-preview/${valor}`);
                updateReload(true);
            }
        } else {
            if (history) {
                history.push(`/laboratory-preview/${valor}`);
            }
        }
    }

    return(
        <div
            className={`search_laboratories-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <ReactAutocomplete
                items={laboratories}
                // shouldItemRender={(item: LaboratoriesTypes.ILaboratory, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
                getItemValue={(item: LaboratoriesTypes.ILaboratory) => item.id || ''}
                renderItem={(item: LaboratoriesTypes.ILaboratory, highlighted) =>
                    <div
                        key={item.id}
                        style={{ 
                            backgroundColor: highlighted ? '#eee' : 'transparent'
                        }}
                        className='item-search-option'
                    >
                        <ItemOptionSearch
                            laboratory={item}
                        />
                    </div>
                }
                onChange={handleChangeInput}
                value={search}
                onSelect={handleClickSelect}
                inputProps={{
                    placeholder: 'Busca aquí...'
                }}
            />
        </div>
    );
}

// =====================================================
// Item de opción en el select
// =====================================================
interface IItemOptionSearchProps {
    laboratory: LaboratoriesTypes.ILaboratory
    className?: string
    style?: CSSProperties
    id?: string
}

const ItemOptionSearch: React.FC<IItemOptionSearchProps> = (props) => {
    return(
        <div
            className={`item_option_search-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <img src={props.laboratory.thumbnail} alt={`Imagen de ${props.laboratory.name}`} />
            <div className="container-text-item">
                <TextComponent
                    type='text'
                    style={{
                        fontWeight: 600
                    }}
                >
                    {props.laboratory.name}
                </TextComponent>
                <TextComponent
                    type='label'
                >
                    {props.laboratory.description}
                </TextComponent>
            </div>
            <BiLinkExternal
                className='link-icon'
            />
        </div>
    );
}



export default SearchLaboratories;