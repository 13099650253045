// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
import shortid from 'shortid';
// @end dependencies

// @import components
import SavingIndicator from 'components/commons/SavingIndicator/SavingIndicator';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import CodeEditor, { TypeLanguageCodeEditor } from 'components/commons/CodeEditor/CodeEditor';
import PanelComponent from 'components/commons/PanelComponent/PanelComponent';
import TextComponent from 'components/commons/Text/TextComponent';
import { BsUpload } from 'react-icons/bs';
import { BiLoaderCircle } from 'react-icons/bi';
import { IoMdAddCircleOutline } from 'react-icons/io';
import ModalNewFileCodeEditor from '../ModalNewFileCodeEditor/ModalNewFileCodeEditor';
import TooltipComponent from 'components/commons/TooltipComponent/TooltipComponent';
import { MdDeleteForever, MdModeEdit } from 'react-icons/md';
import DropDownComponent from 'components/commons/DropDownComponent/DropDownComponent';
import CodeEditorResponse from '../CodeEditorResponse/CodeEditorResponse';
// @end components

// @import types
import { IStoreApp } from 'redux/reducers/index';
import * as ToolTypes from 'types/tool/tool.types';
// @end types

// @import services
import ScreenNotification from 'services/screenNotification/screenNotification';
import SocketService from 'services/socket/socketService';
// @end services

// @import hooks
import { useDashboard } from 'hooks/useDashboard/useDashboard';
import useTriggerReset from 'hooks/useTriggerReset/useTriggerReset';
import { useTimer } from 'hooks/useTimer/useTimer';
import useMoment from 'hooks/useMoment/useMoment';
import { useSelector } from 'react-redux';
import { useChangeState } from 'hooks/useChangeState/useChangeState';
// @end hooks

// @import actions
// @end actions

// @import utils
import { lenguajeExample } from 'components/commons/CodeEditor/examples';
// @end utils

// @import assets
// @end assets

// @import styles
import './CodeEditorModuleDashboard.scss'
// @end styles

interface ICodeEditorModuleDashboardProps {
    className?: string
    style?: CSSProperties
    id?: string
    tool: ToolTypes.ITool
    tools: ToolTypes.ITool[]
}

interface DataCodeEditor{
    files: FileCodeEditor[];
}

export interface FileCodeEditor{
    text: string;
    id: string;
    name: string;
    lastModification: Date;
    language: TypeLanguageCodeEditor;
}

interface CompileCodeParams{
    toolId: string;
    reservationId: string;
    payload: {
        steps: ToolTypes.TypesStepsCodeEditor[];
        language: string;
        code: any;
    }
}



const CodeEditorModuleDashboard: React.FC<ICodeEditorModuleDashboardProps> = (props) => {
    
    const [openModal, updateOpenModal] = useState<boolean>(false);
    const [save, updateSave] = useState<boolean>(false);
    const [loadingCompile, updateLoadingCompile] = useState<boolean>(false);
    const [loadingUpload, updateLoadingUpload] = useState<boolean>(false);
    const [activeFile, updateActiveFile] = useState<FileCodeEditor | undefined>(undefined);
    const [selectedTool, updateSelectedTool] = useState<ToolTypes.ITool | undefined>(undefined);
    const [editFile, updateEditFile] = useState<FileCodeEditor | undefined>(undefined);
    const [editorResponse, updateEditorResponse] = useState<ToolTypes.CodeEditorResponse | undefined>(undefined);
    const [dataCode, updateDataCode] = useState<DataCodeEditor>({
        files: []
    });

    const dashboard = useDashboard({toolId: props.tool.id});
    const triggerCode = useTriggerReset();
    const {moment} = useMoment();
    const timer = useTimer({ orientation: 'down', initialValue: 3, stream: true });
    const reservationId = useSelector((store: IStoreApp) => store.dashboard.reservation?.id);
    const useChangeTool = useChangeState<ToolTypes.ITool>({variable: selectedTool});

    // Servicios
    const screenNotification = new ScreenNotification();
    const socketService = new SocketService();

    useEffect(() => {
        return () => {
            if (useChangeTool.current) {
                socketService.removeListenEvent(`codeEditorResponse:${reservationId}:${useChangeTool.current?.id}`, handleCodeEditorResponse);
            }
        }
    },[]);

    useEffect(() => {
        if (timer.totalSeconds === 0) {
            timer.pause();
            timer.reset();
            updateSave(true);
        }
    }, [timer.totalSeconds]);

    useEffect(() => {
        if (activeFile?.id) {
            triggerCode.trigger();
        }
    }, [activeFile?.id]);

    useEffect(() => {
        if (dataCode.files.length && !activeFile) {
            updateActiveFile(dataCode.files[0]);
        }
    }, [dataCode]);

    useEffect(() => {
        if (dashboard.savedDataTool) {
            updateDataCode(dashboard.savedDataTool);
        }
    }, [dashboard.savedDataTool]);

    useEffect(() => {
        if (save) {
            dashboard.updateSavedDataTool({...dataCode});
            updateSave(false);
        }
    }, [save]);

    useEffect(() => {
        // @INFO: Eliminar el evento de respuesta de la herramienta anterior
        if (useChangeTool.previous) {
            socketService.removeListenEvent(`codeEditorResponse:${reservationId}:${useChangeTool.previous?.id}`, handleCodeEditorResponse);
        }
        // @INFO: Escuchar el evento de respuesta de la herramienta
        if (useChangeTool.current) {
            socketService.listenEvent(`codeEditorResponse:${reservationId}:${useChangeTool.current.id}`, handleCodeEditorResponse);
        }
    }, [useChangeTool.current])

    const handleCodeEditorResponse = (data: ToolTypes.CodeEditorResponse) => {
        updateLoadingCompile(false);
        updateLoadingUpload(false);
        updateEditorResponse(data);
    }

    const handleCloseModal = async (data?: Partial<FileCodeEditor>) => {
        if (data) {
            let params = {...data, lastModification: new Date()};
            const newData = {...dataCode};
            if (!data.id) {
                params.text = lenguajeExample[data?.language as TypeLanguageCodeEditor];
                params.id = shortid.generate();
                newData.files.push(params as FileCodeEditor);
            } else {
                const idx = newData.files.findIndex((f) => f.id === params.id);
                if (idx >= 0) {
                    newData.files[idx].name = params.name as string;
                    newData.files[idx].lastModification = params.lastModification as Date;
                }
            }
            updateSave(true);
        }
        updateEditFile(undefined);
        updateOpenModal(false);
    }

    const handleClickFile = (_file: FileCodeEditor) => {
        updateActiveFile(_file);
    }

    const handleChangeEditor = (e: string | undefined) => {
        if (!activeFile || !e) return;
        const newFile = {
            ...activeFile,
            text: e,
            lastModification: new Date()
        };
        updateActiveFile(newFile);
        const idx = dataCode.files.findIndex((f) => f.id === newFile.id);
        if (idx >= 0) {
            const newData = {...dataCode};
            newData.files[idx] = newFile;
            updateDataCode(newData);
        }
        if (!timer.isRunning) {
            timer.start();
        }
        timer.reset();
    }

    const handleClickDelete = async (_file: FileCodeEditor) => {
        const result = await screenNotification.showAskDelete();
        if (result.isConfirmed) {
            const idx = dataCode.files.findIndex((f) => f.id === _file.id);
            if (idx >= 0) {
                const newData = {...dataCode};
                if (activeFile?.id === newData.files[idx].id) {
                    updateActiveFile(undefined);
                }
                newData.files.splice(idx, 1);
                updateDataCode(newData);
                updateSave(true);
            }
        }
    }

    const handleClickEdit = (_file: FileCodeEditor) => {
        updateEditFile(_file);
        updateOpenModal(true);
    }

    const handleClickCompileOrUpload = (steps: ToolTypes.TypesStepsCodeEditor[]) => {
        if (steps.length === 2 && steps.includes('compile') && steps.includes('upload')) {
            updateLoadingUpload(true);
        }
        if (steps.length === 1 && steps.includes('compile')) {
            updateLoadingCompile(true);
        }
        if (!props.tool.config?.codeEditor?.associatedTool || !reservationId || !selectedTool?.id) return
        const params: CompileCodeParams = {
            toolId: selectedTool?.id,
            // toolId: props.tool.config?.codeEditor?.associatedTool,
            reservationId,
            payload: {
                steps,
                language: props.tool?.config?.codeEditor?.language as TypeLanguageCodeEditor,
                code: btoa(activeFile?.text as string)
            }
        };
        socketService.emitEvent(`codeEditor`, params);
    }

    const validateToolLanguage = (): boolean => {
        let response = false;
        if (activeFile && selectedTool?.config?.language === activeFile?.language) {
            response = true;
        } 
        return response;
    }
    
    return(
        <div
            className={`code_editor_module_dashboard-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <PanelComponent
                height='100%'
                panelWidth='250px'
                collapse
                panel={
                    <div
                        className='container-list-files'
                    >
                        <TextComponent
                            type='subtitle'
                        >
                            Tus archivos:
                        </TextComponent>
                        <ButtonComponent
                            variant='accent'
                            style={{marginBottom: '15px'}}
                            onClick={() => updateOpenModal(true)}
                        >
                            <ButtonComponent.Icon>
                                <IoMdAddCircleOutline/>
                            </ButtonComponent.Icon>
                            Nuevo archivo
                        </ButtonComponent>
                        {dataCode.files.map((f) => (
                            <ItemFile
                                name={f.name}
                                language={f.language}
                                key={f.id}
                                active={f.id === activeFile?.id}
                                onClick={() => handleClickFile(f)}
                                onClickDelete={() => handleClickDelete(f)}
                                onClickEdit={() => handleClickEdit(f)}
                            />
                        ))}
                    </div>
                }
            >
                <div
                    className='container-code-editor_panel'
                >
                    <div className="header-container-code-editor">
                        <div>
                            <TextComponent
                                type='subtitle'
                            >
                                {activeFile ? activeFile.name : ''}
                            </TextComponent>
                            {activeFile?.lastModification !== undefined ?
                                <TextComponent
                                    type='label'
                                    style={{
                                        color: 'var(--text2)'
                                    }}
                                >
                                    Última modificación: {moment(activeFile?.lastModification).format('MMMM DD [de] YYYY [a las] HH:mm')}
                                </TextComponent>
                            :null}
                        </div>
                        <div
                            style={{
                                marginLeft: 'auto',
                                marginRight: '10px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <SavingIndicator                                
                                state={timer.isRunning ? 'saving' : 'saved'}
                                withText
                                style={{
                                    marginLeft: 'auto'
                                }}
                            />
                            <div
                                className='header-container-code-editor'
                            >
                                <div>
                                    <DropDownComponent
                                        title={!selectedTool?.displayTitle ? '-- Selecciona una herramienta --' : selectedTool.displayTitle}
                                    >
                                        {props.tools.filter((t) => t?.category?.config?.canDeployCode).map((t) => (
                                            <DropDownComponent.Item
                                                key={t.id}
                                                onClick={() => updateSelectedTool(t)}
                                            >
                                                {t.displayTitle}
                                            </DropDownComponent.Item>
                                        ))
                                        }
                                    </DropDownComponent>
                                    {!validateToolLanguage() && selectedTool && activeFile ?
                                        <TextComponent
                                            type='label'
                                            style={{
                                                color: 'var(--error)'
                                            }}
                                        >
                                            El lenguaje debe ser {selectedTool?.config?.language}
                                        </TextComponent>
                                    :null}
                                </div>
                                <ButtonComponent
                                    variant='primary'
                                    disabled={!activeFile || !selectedTool || !validateToolLanguage() || loadingCompile}
                                    onClick={() => handleClickCompileOrUpload(['compile', 'upload'])}
                                    loading={loadingUpload}
                                    className='button-code-editor-module'
                                >
                                    <ButtonComponent.Icon>
                                        <BsUpload/>
                                    </ButtonComponent.Icon>
                                    Subir
                                </ButtonComponent>
                                <ButtonComponent
                                    variant='accent'
                                    disabled={!activeFile || !selectedTool || !validateToolLanguage() || loadingUpload}
                                    onClick={() => handleClickCompileOrUpload(['compile'])}
                                    loading={loadingCompile}
                                    className='button-code-editor-module'
                                >
                                    <ButtonComponent.Icon>
                                        <BiLoaderCircle/>
                                    </ButtonComponent.Icon>
                                    Compilar
                                </ButtonComponent>    
                            </div>
                            
                        </div>
                    </div>
                    {activeFile && !triggerCode.reset ?
                        <>
                            <CodeEditor
                                language={activeFile?.language as TypeLanguageCodeEditor}
                                className='code-editor-module'
                                defaultValue={activeFile.text}
                                onChange={handleChangeEditor}
                            />
                            {editorResponse ?
                                <CodeEditorResponse
                                    response={editorResponse}
                                />
                            : null}
                        </>
                    :
                        <TextComponent
                            type='subtitle'
                        >
                            Crea un archivo para comenzar a utilizar el editor de código.
                        </TextComponent>
                    }
                </div>
            </PanelComponent>
            <ModalNewFileCodeEditor
                tool={props.tool}
                open={openModal}
                onClose={handleCloseModal}
                editData={editFile}
            />
        </div>
    );
}

// ==================================================
// Item de archivo
// ==================================================
interface IItemFileProps {
    className?: string
    style?: CSSProperties
    id?: string
    name: string;
    language: string;
    active?: boolean;
    onClick?: () => void;
    onClickEdit?: () => void;
    onClickDelete?: () => void;
}

const ItemFile: React.FC<IItemFileProps> = (props) => {

    const handleClickEdit = (e: any) => {
        e.stopPropagation();
        if (props.onClickEdit) {
            props.onClickEdit();
        }
    }
    
    const handleClickDelete = (e: any) => {
        e.stopPropagation();
        if (props.onClickDelete) {
            props.onClickDelete();
        }
    }

    return(
        <div
            className={`item_file-layout ${props.active ? 'active' : ''} ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
            onClick={props.onClick}
        >
            <div>
                <TextComponent>
                    {props.name}
                </TextComponent>
                <TextComponent
                    type='label'
                    style={{
                        color: 'var(--primary)'
                    }}
                >
                    {props.language}
                </TextComponent>
            </div>
            <div className="container-actions">
                <TooltipComponent
                    text='Editar'
                >
                    <MdModeEdit
                        onClick={handleClickEdit}
                    />
                </TooltipComponent>
                <TooltipComponent
                    text='Eliminar'
                >
                    <MdDeleteForever
                        onClick={handleClickDelete}
                    />
                </TooltipComponent>
            </div>
        </div>
    );
}


export default CodeEditorModuleDashboard;