// @import dependencies
import TextComponent from 'components/commons/Text/TextComponent';
import React, { CSSProperties } from 'react'
import { Modal } from 'react-bootstrap'
import PublicSettingsReservation from '../PublicSettingsReservation/PublicSettingsReservation';
// @end dependencies

// @import components
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ModalPublicSettings.scss'
// @end styles

interface IModalPublicSettingsProps {
    open: boolean;
    className?: string
    style?: CSSProperties
    id?: string
    onClose?: () => any;
    onSaved?: () => void;
}

const ModalPublicSettings: React.FC<IModalPublicSettingsProps> = (props) => {
    
    const handleCloseModal = (status?: boolean) => {
        if(props.onClose){
            props.onClose();
        }
    }
    
    return(
        <Modal
            onHide={() => handleCloseModal()}
            show={props.open}
            className='custom-modal-public-settings'
            size='lg'
        >
            <Modal.Header
                closeButton
            >
                <Modal.Title>
                    <TextComponent
                        type='h3'
                    >
                        Publicar dashboard
                    </TextComponent>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    className={`modal_public_settings-layout ${props.className ? props.className : ''}`}
                    style={props.style}
                    id={props.id}
                >
                    <PublicSettingsReservation
                        onCancel={handleCloseModal}
                        onSaved={props.onSaved}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalPublicSettings;