import React, { CSSProperties, useState, useEffect } from 'react'
import LocationsService from 'services/locations/locationsService';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreApp } from 'redux/reducers/index';
import * as ActionsLocations from 'redux/reducers/locations/locations.actions'
import { useTranslation } from 'react-i18next';

import './LocationsComponent.scss'

interface IResponseLocationsComponent{
    country: string
    province: string
    city: string
}

interface IDataLocationsComponent{
    country?: string
    province?: string
    city?: string
}

interface ILocationsComponentProps {
    className?: string
    style?: CSSProperties
    defaultData?: IDataLocationsComponent
    onComplete: (response: IResponseLocationsComponent) => any
}

const LocationsComponent: React.FC<ILocationsComponentProps> = (props) => {

    const [formData, updateFormData] = useState<IDataLocationsComponent>({})

    const dispatch = useDispatch()
    const locations = useSelector((store: IStoreApp) => store.locations)
    const {t} = useTranslation()
    const { countries, provinces, cities } = locations

    // @INFO Obtener la data inicial de países
    useEffect(() => {
        if(!countries?.length){
            getCountries()
        }
    }, [])

    // @INFO Si hay una data por defecto la pongo
    useEffect(() => {
        if(props.defaultData){
            updateFormData({...props.defaultData})
            // @INFO traer data para rellenar los campos
            if(
                props.defaultData?.country && 
                (!props.defaultData?.province || !provinces.includes(props.defaultData?.province))
            ){
                getProvinces(props.defaultData.country)
            }
            if(
                props.defaultData?.country && 
                props.defaultData?.province &&
                (!props.defaultData?.city || !cities.includes(props.defaultData?.city))
            ){
                getCities(props.defaultData.country, props.defaultData.province)
            }
        }
    }, [props.defaultData])

    // @INFO Servicios 
    const locationsService = new LocationsService()

    const getCountries = async () => {
        const _countries = await locationsService.getCountries()
        if(_countries){
            dispatch(ActionsLocations.updateCountriesAction(_countries))
            dispatch(ActionsLocations.updateProvincesAction([]))
            dispatch(ActionsLocations.updateCitiesAction([]))
        }
    }

    const getProvinces = async (_country: string) => {
        const _provinces = await locationsService.getProvinces(_country)
        if(_provinces){
            dispatch(ActionsLocations.updateProvincesAction(_provinces))
            dispatch(ActionsLocations.updateCitiesAction([]))
        }
    }

    const getCities = async (_country: string, _province: string) => {
        const _cities = await locationsService.getCities(_country, _province)
        if(_cities){
            dispatch(ActionsLocations.updateCitiesAction(_cities))
        }
    }

    const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let aux = {...formData}
        aux = {
            ...aux,
            [e.target.name]: e.target.value
        }
        // @INFO Que debo traer
        if(e.target.name === 'country'){
            getProvinces(e.target.value)
        }else if(e.target.name === 'province' && aux.country){
            getCities(aux.country, e.target.value)
        }
        // @INFO Actualizo el estado
        updateFormData({...aux})
        // @INFO Revisar si todo esta lleno mando el onComplete
        if(aux.country && aux.province && aux.city){
            if(props.onComplete){
                props.onComplete({
                    country: aux.country,
                    province: aux.province,
                    city: aux.city
                })
            }
        }
    }

    return(
        <div
            className={`locations_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <select 
                className="form-select" 
                aria-label="Default select example"
                name='country'
                onChange={handleChangeSelect}
                value={formData?.country}
            >
                <option selected>-- {t('register.fields.country')} --</option>
                {countries?.map((item) => (
                    <option
                        key={item}
                        value={item}
                    >
                        {item}
                    </option>
                ))}
            </select>
            <select 
                className="form-select" 
                aria-label="Default select example"
                name='province'
                onChange={handleChangeSelect}
                value={formData?.province}
            >
                <option selected>-- {t('register.fields.province')} --</option>
                {provinces?.map((item) => (
                    <option
                        key={item}
                        value={item}
                    >
                        {item}
                    </option>
                ))}
            </select>
            <select 
                className="form-select" 
                aria-label="Default select example"
                name='city'
                onChange={handleChangeSelect}
                value={formData?.city}
            >
                <option selected>-- {t('register.fields.city')} --</option>
                {cities?.map((item) => (
                    <option
                        key={item}
                        value={item}
                    >
                        {item}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default LocationsComponent;