// @import dependencies
import React, { CSSProperties, useState, useEffect, useRef } from 'react'
// @end dependencies

// @import components
import { FaCircle } from 'react-icons/fa';
import { FiCircle } from 'react-icons/fi';
// @end components

// @import types
import * as ToolTypes from 'types/tool/tool.types';
// @end types

// @import services
import SocketService from 'services/socket/socketService';
// @end services

// @import hooks
import { useDashboard } from 'hooks/useDashboard/useDashboard';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './PushButtonModuleDashboard.scss'
import DropDownComponent from '../../../../commons/DropDownComponent/DropDownComponent';
import { AiOutlineArrowDown, AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineArrowUp, AiOutlineReload } from 'react-icons/ai';
// @end styles

interface IPushButtonModuleDashboardProps {
    tool: ToolTypes.ITool;
    className?: string
    style?: CSSProperties
    id?: string
}

type TypeIconPushButton = 'up_arrow' | 'down_arrow' | 'right_arrow' | 'left_arrow' | 'reload'; 

interface SavedDataPushButton{
    icon?: TypeIconPushButton;
}

const PushButtonModuleDashboard: React.FC<IPushButtonModuleDashboardProps> = (props) => {
    
    const buttonRef = useRef<HTMLDivElement>(null);
    const [holdClick, updateHoldClick] = useState<boolean>(false);
    const [savedData, updateSavedData] = useState<SavedDataPushButton>({});
    const [save, updateSave] = useState<boolean>(false);
    const dashboard = useDashboard({toolId: props.tool.id});

    // @INFO Servicios
    const socketService = new SocketService()

    useEffect(() => {
        // @INFO Suscribirme al evento
        if(props.tool?.category?.config?.hasEvent && props.tool.config?.event){
            socketService.listenEvent(props.tool.config?.event, handleNewData)
        }
        return () => {
            if(props.tool?.category?.config?.hasEvent && props.tool.config?.event){
                socketService.removeListenEvent(props.tool.config?.event, handleNewData)
            }
        }
    },[])

    useEffect(() => {
        if (buttonRef) {
            buttonRef.current?.addEventListener('mousedown', (e) => {
                if (e.type === 'mousedown') {
                    updateHoldClick(true);
                }
            });
            buttonRef.current?.addEventListener('mouseup', (e) => {
                if (e.type === 'mouseup') {
                    updateHoldClick(false);
                }
            });
        }
    }, [])

    useEffect(() => {
        sendDataSocket(holdClick);
    }, [holdClick])

    useEffect(() => {
        if (dashboard?.savedDataTool) {
            updateSavedData(dashboard.savedDataTool);
        }
    }, [dashboard?.savedDataTool])

    useEffect(() => {
        if (save) {
            dashboard.updateSavedDataTool({...savedData});
            updateSave(false);
        }
    }, [save])

    /**
     * @INFO Llega un nuevo dato del socket
     * @param _data 
     */
    const handleNewData = (_data: any) => {
    }

    const sendDataSocket = (state: boolean) => {
        if (
            dashboard.dashboardType === 'normal' || 
            dashboard.reservation?.publicSettings?.pushButton?.enable
        ) {
            socketService.emitEvent(`${props.tool?.category?.name}:web`, {
                toolEvent: props.tool.config?.event,
                payload: {
                    state
                }
            });
        }
    }

    const handleClickDropdownItem = (icon?: TypeIconPushButton) => {
        updateSavedData({...savedData, icon: icon})
        updateSave(true);
    }

    const getIconFromName = () => {
        switch(savedData?.icon) {
            case 'down_arrow':
                return(
                    <AiOutlineArrowDown className={holdClick ? `active-icon` : ''} />
                );
            case 'up_arrow':
                return(
                    <AiOutlineArrowUp className={holdClick ? `active-icon` : ''} />
                );
            case 'left_arrow':
                return(
                    <AiOutlineArrowLeft className={holdClick ? `active-icon` : ''} />
                );
            case 'right_arrow':
                return(
                    <AiOutlineArrowRight className={holdClick ? `active-icon` : ''} />
                );
            case 'reload':
                return(
                    <AiOutlineReload className={holdClick ? `active-icon` : ''} />
                );
            default:
                return(null);
        }
    }
    
    return(
        <div
            className={`push_button_module_dashboard-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            {   dashboard.dashboardType === 'normal' ||
                dashboard.reservation?.publicSettings?.pushButton?.selectIcons ?
            <DropDownComponent
                title={savedData?.icon ? savedData.icon : 'Icono'}
            >
                <DropDownComponent.Item
                    onClick={() => handleClickDropdownItem(undefined)}
                >
                    Defecto
                </DropDownComponent.Item>
                <DropDownComponent.Item
                    onClick={() => handleClickDropdownItem('right_arrow')}
                >
                    Flecha derecha
                </DropDownComponent.Item>
                <DropDownComponent.Item
                    onClick={() => handleClickDropdownItem('left_arrow')}
                >
                    Flecha izquierda
                </DropDownComponent.Item>
                <DropDownComponent.Item
                    onClick={() => handleClickDropdownItem('up_arrow')}
                >
                    Flecha arriba
                </DropDownComponent.Item>
                <DropDownComponent.Item
                    onClick={() => handleClickDropdownItem('down_arrow')}
                >
                    Flecha abajo
                </DropDownComponent.Item>
                <DropDownComponent.Item
                    onClick={() => handleClickDropdownItem('reload')}
                >
                    Recargar
                </DropDownComponent.Item>
            </DropDownComponent>
            :null}
            <div 
                className={`container-icon-button ${holdClick ? 'active' : ''}`}
                ref={buttonRef}
            >
                {savedData?.icon ? 
                    getIconFromName()
                :
                    holdClick ?
                        <FaCircle/>
                    : 
                        <FiCircle/>
                }
            </div>
        </div>
    );
}

export default PushButtonModuleDashboard;