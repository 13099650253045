import store from 'redux/store'
import graphQLUtil from "utils/graphQL/graphQL.util"
import { gql } from "@apollo/client"

import * as RatingTypes from 'types/rating/rating.types';

class RatingService{

    private storeObject = store

    constructor(){

    }

    /**
     * @INFO Traer la lista de ratings
     * @param _params 
     */
    public list = async (_params?: RatingTypes.ParamsRatingList): Promise<RatingTypes.Rating[]> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query ratings($token: String!){
                        ratings(token: $token){
                            ${RatingTypes.fullRatingQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token
                }
            }
        })
        if(response?.data?.ratings?.length){
            return response.data.ratings
        }else{
            return []
        }
    }
    
    /**
     * @INFO Traer un rating por id
     * @param _params 
     */
    public getById = async (_params: RatingTypes.ParamsRatingGetById): Promise<RatingTypes.Rating | undefined> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query rating($token: String!, $id: ID!){
                        rating(token: $token, id: $id){
                            ${RatingTypes.fullRatingQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        })
        if(response?.data?.rating){
            return response.data.rating
        }else{
            return undefined
        }
    }

    /**
     * @INFO Crear un rating
     * @param _params 
     * @returns 
     */
    public create = async (_params: RatingTypes.ParamsRatingCreate): Promise<RatingTypes.Rating | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation newRating($token: String!, $rating: RatingInput!){
                        newRating(token: $token, rating: $rating){
                            ${RatingTypes.fullRatingQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    rating: {..._params.rating}
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.newRating){
            return response.data.newRating
        }else{
            return undefined
        }
    }

    /**
     * @INFO Actualizar un rating
     * @param _params 
     * @returns 
     */
    public update = async (_params: RatingTypes.ParamsRatingUpdate)
    : Promise<RatingTypes.Rating | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation updateRating($token: String!, $id: ID!, $rating: UpdateRatingInput!){
                        updateRating(token: $token, id: $id, rating: $rating){
                            ${RatingTypes.fullRatingQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    rating: {..._params.rating},
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.updateRating){
            return response.data.updateRating
        }else{
            return undefined
        }
    }

    /**
     * @INFO Eliminar un rating
     * @param _params 
     * @returns 
     */
    public delete = async (_params: RatingTypes.ParamsRatingDelete): Promise<RatingTypes.Rating | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation removeRating($token: String!, $id: ID!){
                        removeRating(token: $token, id: $id){
                            ${RatingTypes.fullRatingQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.removeRating){
            return response.data.removeRating
        }else{
            return undefined
        }
    }
}

export default RatingService