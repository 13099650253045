// @import dependencies
import React, { CSSProperties, useCallback, useEffect, useState, ChangeEvent } from 'react'
import { Link, useHistory } from 'react-router-dom';
// @end dependencies

// @import components
import InputComponent from 'components/commons/Input/InputComponent';
import TitlePageComponent from 'components/commons/TitlePage/TitlePageComponent';
import ButtonComponent, { IVariantButtonType } from 'components/commons/Button/ButtonComponent';
import TextComponent from 'components/commons/Text/TextComponent';
// @end components

// @import types
import { IStoreApp } from 'redux/reducers';
// @end types

// @import services
// @end services

// @import hooks
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// @end hooks

// @import actions
import * as AuthActions from 'redux/reducers/auth/auth.actions'
// @end actions

// @import utils
import { HOME_ROUTE } from 'config/globals';
// @end utils

// @import assets
// @end assets

// @import styles
import './LoginForm.scss'
import RouterService from 'services/router/routerService';
// @end styles

interface ILoginFormProps {
    className?: string
    style?: CSSProperties
    id?: string
    history?: any
    typeButton?: IVariantButtonType
}

interface IDataLogin{
    email?: string
    password?: string
}

const LoginForm: React.FC<ILoginFormProps> = (props) => {

    const {t} = useTranslation()
    const dispatch = useDispatch()
    const isAuth = useSelector((store: IStoreApp) => store.auth.isLogged)
    const [dataForm, updateDataForm] = useState<IDataLogin | undefined>(undefined)
    const routerService = new RouterService()
    const { redirectTo } = routerService.getParamsURLObject()
    const history = useHistory()

    const getRedirectPath = useCallback(() => {
        const path = redirectTo ? redirectTo : HOME_ROUTE
        return path
    }, [])

    useEffect(() => {
        if (isAuth && history) {
            history.push(getRedirectPath())
        }
    }, [isAuth, getRedirectPath, history])

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        updateDataForm({
            ...dataForm,
            [e.target.name]: e.target.value
        })
    }

    const handleClickLogin = () => {
        if(dataForm?.email && dataForm.password)
        dispatch(AuthActions.loginAction({email: dataForm.email, password: dataForm.password}))
    }

    return(
        <div
            className={`login_form-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <div className="form-login">
                <TitlePageComponent
                    className='login-text'
                >
                    {t('login.title')}
                </TitlePageComponent>
                <InputComponent
                    name='email'
                    placeholder={t('login.fields.email')}
                    invalid={dataForm && !dataForm.email?.length}
                    invalidText='Obligatorio'
                    onChange={handleChangeInput}
                >
                </InputComponent>
                <InputComponent
                    name='password'
                    type='password'
                    placeholder={t('login.fields.password')}
                    invalid={dataForm && !dataForm.password?.length}
                    invalidText='Obligatorio'
                    onChange={handleChangeInput}
                >
                </InputComponent>
                <ButtonComponent
                    variant={props.typeButton ? props.typeButton : 'primary'}
                    className='btn-login'
                    disabled={!dataForm || !dataForm.email?.length || !dataForm.password?.length}
                    onClick={handleClickLogin}
                >
                    {t('login.fields.enter')}
                </ButtonComponent>
                <TextComponent
                    className='forgot-password-login'
                >
                    {t('login.fields.forgot_password')}
                </TextComponent>
                <Link
                    to={'/register'}
                >
                    <TextComponent
                        className='create-account-text'
                    >
                        {t('login.create_account')}
                    </TextComponent>
                </Link>
            </div>
        </div>
    );
}

export default LoginForm;