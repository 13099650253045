// @import dependencies
import React, { CSSProperties, useEffect, useState } from 'react'
// @end dependencies

// @import components
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import { FaRegCalendar } from 'react-icons/fa'
import SubtitleComponent from 'components/commons/Subtitle/SubtitleComponent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import { HiOutlineShoppingBag } from 'react-icons/hi';
import { AiOutlineCloudDownload, AiOutlineGift } from 'react-icons/ai';
import TextComponent from 'components/commons/Text/TextComponent';
import ModalReserveLaboratory from 'components/modules/ModalReserveLaboratory/ModalReserveLaboratory';
import VideoComponent from 'components/commons/VideoComponent/VideoComponent';
import { Link, useParams } from 'react-router-dom';
import RatingComponent from 'components/commons/RatingComponent/RatingComponent';
import BreadcrumbComponent from 'components/commons/BreadcrumbComponent/breadcrumbComponent';
// @end components

// @import hooks
import { useTranslation } from 'react-i18next';
import { useResizeDetector } from 'react-resize-detector';
import { useLaboratory } from 'hooks/useLaboratory/useLaboratory';
// @end hooks

// @import services
import EditorjsService from 'services/editorjs/editorjsService';
import LaboratoryService from 'services/laboratory/laboratoryService';
import TimeService from 'services/time/timeService';
// @end services

// @import types
import * as LaboratoryTypes from 'types/laboratories/laboratories.types'
// @end types

// @import styles
import './LaboratoryPreviewPage.scss'
import { useSelector } from 'react-redux';
import { IStoreApp } from 'redux/reducers/index';
// @end styles

interface ILaboratoryPreviewPageProps {
    className?: string
    style?: CSSProperties
    history?: any
}

const LaboratoryPreviewPage: React.FC<ILaboratoryPreviewPageProps> = (props) => {

    const [laboratory, updateLaboratory] = useState<LaboratoryTypes.ILaboratory | undefined>(undefined)
    const [openReserve, updateOpenReserve] = useState<boolean>(false)
    const isLogged = useSelector((store: IStoreApp) => store.auth?.isLogged);
    
    const params = useParams<{idLab: string}>()

    const hookLab = useLaboratory({ idLaboratoryVerify: params.idLab });
    
    const sizeContainer = useResizeDetector()

    const {t} = useTranslation()

    // @Servicios
    const laboratoryService = new LaboratoryService()
    const timeService = new TimeService();

    useEffect(() => {
        getLaboratory()
    }, [])

    /**
     * @INFO Obtener el laboratorio
     */
    const getLaboratory = async () => {
        if(!params.idLab) return 
        const newLab = await laboratoryService.getById({ id: params.idLab })
        updateLaboratory(newLab)
    }

    const testData = {
        "time" : 1625786004777,
        "blocks" : [
            {
                "id" : "H4-ToF5kWq",
                "type" : "header",
                "data" : {
                    "text" : t('laboratory_preview.empty_content'),
                    "level" : 4
                }
            },
        ]
    }

    // @INFO Servicios
    const editorjsService = new EditorjsService()

    /**
     * @INFO Click en iniciar un laboratorio
     */
    const handleClickStart = () => {
        if(props.history){
            props.history.push(`/dashboard-laboratory/${laboratory?.id}`)
        }
    }

    return(
        <AppLayout
            history={props.history}
            headerTitle='Laboratorios virtuales'
        >      
            <div
                className={`laboratory_preview_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
                ref={sizeContainer.ref}
            >
                <BreadcrumbComponent
                    style={{
                        marginBottom: '20px',
                        gridColumn: '1/3'
                    }}
                >
                    <BreadcrumbComponent.Item
                        title='Laboratorios'
                        path='/laboratories'
                    />
                    <BreadcrumbComponent.Item
                        title={laboratory?.name ? laboratory.name : 'Previsualización'}
                        current
                    />
                </BreadcrumbComponent>
                <div
                    className='card-info-laboratory-section'
                >
                    <div className="header-card-lab">
                        <SubtitleComponent
                            style={{fontWeight: 600}}
                            className='hrader-card-lab-title'
                        >
                            {laboratory?.name}
                        </SubtitleComponent>
                        <div
                            className='container-actions-header-card-lab'
                        >
                            {!hookLab.labStatus.exist && isLogged ?
                                <ButtonComponent
                                    variant='primary'
                                    style={{padding: '0.5rem 0.6rem'}}
                                >
                                    <ButtonComponent.Icon>
                                        <HiOutlineShoppingBag
                                            style={{color: 'var(--accent)'}}
                                        />
                                    </ButtonComponent.Icon>
                                    ${laboratory?.price} {t('laboratory_preview.get')}
                                </ButtonComponent>
                            :null}
                            {laboratory?.category?.config?.hasDuration && isLogged ?
                                <ButtonComponent
                                    variant='accent'
                                    style={{color: 'white'}}
                                    onClick={() => updateOpenReserve(true)}
                                >
                                    <ButtonComponent.Icon>
                                        <FaRegCalendar/>
                                    </ButtonComponent.Icon>
                                    {t('laboratory_preview.reserve')}
                                </ButtonComponent>
                            :null}
                            {isLogged ?
                            <ButtonComponent
                                variant='primary2'
                                style={{color: 'white'}}
                            >
                                <ButtonComponent.Icon>
                                    <AiOutlineGift
                                        style={{color: 'var(--accent)'}}
                                    />
                                </ButtonComponent.Icon>
                                {t('laboratory_preview.gift')}
                            </ButtonComponent>
                            :null}
                            <div>
                                <RatingComponent
                                    readonly
                                    rating={laboratory?.averageRating}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container-info-lab">
                        <div>
                            <img 
                                src={laboratory?.generalScheme} 
                                alt="" 
                            />
                            <TextComponent
                                style={{fontWeight: 700, fontSize: '1.1rem'}}
                            >
                                {t('laboratory_preview.description')}
                            </TextComponent>
                            <TextComponent>
                                {laboratory?.description}
                            </TextComponent>
                        </div>
                        <div>
                            <img 
                                src={laboratory?.thumbnail} 
                                alt="" 
                                className='img-scheme-principal'
                            />
                            {laboratory?.config?.virtualReality?.resources?.windows ? (
                                <Link
                                    to={{
                                        pathname: laboratory.config.virtualReality.resources.windows
                                    }}
                                    target='_blank'
                                >
                                    <ButtonComponent variant='secondary' >
                                        <ButtonComponent.Icon>
                                            <AiOutlineCloudDownload/>
                                        </ButtonComponent.Icon>
                                        Descargar
                                    </ButtonComponent>
                                </Link>
                            ) : null}
                            {laboratory?.config?.virtualReality?.resources?.ios ?
                                <Link
                                    to={{
                                        pathname: laboratory.config.virtualReality.resources.ios
                                    }}
                                    target='_blank'
                                >
                                    <TextComponent>
                                        {t('laboratory_preview.ios_available')}
                                    </TextComponent>
                                </Link>
                            :null}
                            {laboratory?.config?.virtualReality?.resources?.android ?
                                <Link
                                    to={{
                                        pathname: laboratory.config.virtualReality.resources.android
                                    }}
                                    target='_blank'
                                >
                                    <TextComponent>
                                        {t('laboratory_preview.android_available')}
                                    </TextComponent>
                                </Link>
                            :null}
                            {laboratory?.config?.virtualReality?.resources?.windows ?
                                <Link
                                    to={{
                                        pathname: laboratory.config.virtualReality.resources.windows
                                    }}
                                    target='_blank'
                                >
                                    <TextComponent>
                                        {t('laboratory_preview.windows_available')}
                                    </TextComponent>
                                </Link>
                            :null}
                            {laboratory?.category?.name ?
                                <TextComponent>
                                    {t('laboratory_preview.category')}: {laboratory?.category?.name}
                                </TextComponent>
                            :null}
                            {laboratory?.config?.duration ?
                                <TextComponent>
                                    {t('laboratory_preview.duration')}: {timeService.secondsFormatHMS(laboratory?.config?.duration, 'HHh MMm')}
                                </TextComponent>
                            :null}
                            {laboratory?.subarea?.name ?
                                <TextComponent>
                                    {t('laboratory_preview.area')}: {laboratory?.subarea?.name} - {laboratory?.subarea?.area?.name}
                                </TextComponent>
                            :null}
                            <hr style={{marginTop: 'auto', backgroundColor: 'var(--text)', width: '100%'}} />
                            {hookLab.labStatus.active || true ?
                                <ButtonComponent
                                    variant='accent'
                                    style={{color: 'white', padding: '0.6rem', fontSize: '1.1rem'}}
                                    onClick={handleClickStart}
                                >
                                    {t('laboratory_preview.start_lab')}
                                </ButtonComponent>
                            :null}
                        </div>
                    </div>
                </div>
                <div
                    className='card-info-laboratory-section'
                >
                    {laboratory?.config?.virtualReality?.video?.platform && laboratory?.config?.virtualReality?.video?.id ?
                        <VideoComponent
                            platform={laboratory?.config?.virtualReality?.video?.platform}
                            id={laboratory?.config?.virtualReality?.video?.id}
                        />
                    :null}
                    <SubtitleComponent
                        style={{fontWeight: 600}}
                    >
                        {t('laboratory_preview.objectives')}
                    </SubtitleComponent>
                    {editorjsService.jsonToComponent(laboratory?.objectives?.blocks ? laboratory.objectives?.blocks : testData.blocks)}
                </div>
                <div
                    className='card-info-laboratory-section'
                >
                    <SubtitleComponent
                        style={{fontWeight: 600}}
                    >
                        {t('laboratory_preview.procedure')}
                    </SubtitleComponent>
                    {editorjsService.jsonToComponent(laboratory?.procedure?.blocks ? laboratory.procedure?.blocks : testData.blocks)}
                </div>
            </div>
            <ModalReserveLaboratory
                open={openReserve}
                idLab={params.idLab}
                duration={laboratory?.config?.duration}
                onClose={() => updateOpenReserve(false)}
            />
        </AppLayout>
    );
}

export default LaboratoryPreviewPage;