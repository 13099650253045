import React, { CSSProperties } from 'react'
import Youtube from 'react-youtube'

// @import types
import * as LaboratoryTypes from 'types/laboratories/laboratories.types'
// @end types

import './VideoComponent.scss'

interface IVideoComponentProps {
    id: string
    platform: LaboratoryTypes.ITypesPlatformVideo
    className?: string
    style?: CSSProperties
    
}

const VideoComponent: React.FC<IVideoComponentProps> = (props) => {
    return(
        <div
            className={`video_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            {props.platform === 'YouTube' ?
                <Youtube
                    videoId={props.id}
                    containerClassName='video-container-package'
                />
            : null}
        </div>
    );
}

export default VideoComponent;