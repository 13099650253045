export interface IToolCategory{
    id?: string
    name?: string
    description?: string
    config?: IConfigToolCategory
}

export interface IParamsToolCategoryCreate{
    category: IToolCategoryInput
}

export interface IParamsToolCategoryUpdate{
    category: IUpdateToolCategoryInput
    id: string
}

export interface IParamsToolCategoryDelete{
    id: string
}

export interface IParamsToolCategoryList{

}

export interface IParamsToolCategoryGetById{
    id: string
}

interface IToolCategoryInput{
    name: string
    description: string
    config: IConfigToolCategory
}

interface IUpdateToolCategoryInput{
    name?: string
    description?: string
    config?: IConfigToolCategory
}

interface IConfigToolCategory{
    hasEvent?: boolean
    hasSamplingTime?: boolean
    hasVariables?: boolean
    hasVoltageRange?: boolean
    hasCurrentRange?: boolean
    hasVoltage?: boolean
    hasCurrent?: boolean
    hasStorage?: boolean
    hasReplicateMqtt?: boolean
    isLineChart?: boolean
    isBarChart?: boolean
    isCamera?: boolean
    isSource?: boolean
    isButton?: boolean
    isPushbutton?: boolean
    isCodeEditor?: boolean
    isOscilloscope?: boolean
    isRoboticArm?: boolean
    isSlider?: boolean
    isImage?: boolean
    isTable?: boolean
    canDeployCode?: boolean
}

export const fullToolCategoryQuery = `
    id
    name
    description
    config{
        hasEvent
        hasSamplingTime
        hasVariables
        hasVoltageRange
        hasCurrentRange
        hasVoltage
        hasCurrent
        hasStorage
        hasReplicateMqtt
        isLineChart
        isSource
        isCamera
        isBarChart
        isButton
        isPushbutton
        isCodeEditor
        isOscilloscope
        isRoboticArm
        isSlider
        isImage
        isTable
        canDeployCode
    }
`