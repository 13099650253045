// @import dependencies
import React, { CSSProperties, useEffect, useState } from 'react';
// @end dependencies

// @import components
// @end components

// @import types
import * as JitsiTypes from 'types/jitsi/jitsi.types';
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './JitsiComponent.scss';
// @end styles

interface IJitsiComponentProps {
  id: string;
  roomName: string;
  name?: string;
  className?: string;
  classNameContainer?: string;
  style?: CSSProperties;
  styleContainer?: CSSProperties;
  width?: string | number;
  height?: string | number;
  domain?: string;
  toolbarButtons?: JitsiTypes.ITypeToolbarButtonsJitsi[];
  triggerGetDevices?: boolean;
  videoInput?: JitsiTypes.IJitsiDevice;
  onGetAvailableDevices?: (_devices: JitsiTypes.IAvailableDeviceJitsi) => any;
}

const JitsiComponent: React.FC<IJitsiComponentProps> = (props) => {
  const [api, updateApi] = useState<any>(undefined);
  const logoUrl =
    'https://e00-marca.uecdn.es/blogs/una-vida-al-filo/imagenes_posts/2018/07/07/194521_696x465.jpg';

  useEffect(() => {
    createApi();
  }, []);

  useEffect(() => {
    if (props.triggerGetDevices) {
      getDevices();
    }
  }, [props.triggerGetDevices]);

  useEffect(() => {
    if (props.videoInput) {
      setVideoInput(props.videoInput);
    }
  }, [props.videoInput]);

  useEffect(() => {
    if (api) {
      getDevices();
    }
  }, [api]);

  /**
   * @INFO Obtener listado de dispositivos
   */
  const getDevices = async () => {
    if (api && props.onGetAvailableDevices) {
      const response = await api.getAvailableDevices();
      props.onGetAvailableDevices(response);
    }
  };

  /**
   * @INFO Establecer el dispositivo de entrada de video
   * @param _device
   */
  const setVideoInput = (_device: JitsiTypes.IJitsiDevice) => {
    if (api && api.setVideoInputDevice) {
      api.setVideoInputDevice(_device.label, _device.deviceId);
    }
  };

  const createApi = () => {
    // @ts-ignore
    if (window.JitsiMeetExternalAPI) {
      const options = {
        roomName: props.roomName,
        width: props.width ? props.width : '300px',
        height: props.height ? props.height : '533px',
        configOverwrite: {
          prejoinPageEnabled: false,
          startWithAudioMuted: true,
          // startWithVideoMuted: true,
          toolbarButtons: props.toolbarButtons,
          disableFilmstripAutohiding: true,
          disable1On1Mode: false,
          disableInitialGUM: true,
          hideConferenceTimer: true,
        },
        interfaceConfigOverwrite: {
          // overwrite interface properties
          DEFAULT_WELCOME_PAGE_LOGO_URL:
            'https://e00-marca.uecdn.es/blogs/una-vida-al-filo/imagenes_posts/2018/07/07/194521_696x465.jpg',
          DEFAULT_LOGO_URL:
            'https://e00-marca.uecdn.es/blogs/una-vida-al-filo/imagenes_posts/2018/07/07/194521_696x465.jpg',
          BRAND_WATERMARK_LINK: logoUrl,
          SHOW_JITSI_WATERMARK: false,
          SHOW_CHROME_EXTENSION_BANNER: false,
        },
        parentNode: document.querySelector(`#jitsi-iframe-${props.id}`),
        userInfo: {
          displayName: props.name ? props.name : 'Usuario',
        },
      };
      // @ts-ignore
      const _api = new window.JitsiMeetExternalAPI(
        props.domain ? props.domain : '8x8.vc',
        options
      );
      updateApi(_api);
    }
  };

  return (
    <div
      className={`jitsi_component-layout ${
        props.className ? props.className : ''
      }`}
      style={props.style}
      id={props.id}
    >
      <div
        className={`${
          props.classNameContainer ? props.classNameContainer : ''
        }`}
        style={props.styleContainer}
        id={`jitsi-iframe-${props.id}`}
      ></div>
    </div>
  );
};

export default JitsiComponent;
