export const GET_NOTIFICATIONS = "[Notifications] GET_NOTIFICATIONS";
export const SET_UNREAD_NOTIFICATIONS = "[Notifications] SET_UNREAD_NOTIFICATIONS";
export const UPDATE_NOTIFICATION = "[Notifications] UPDATE_NOTIFICATION";

export interface NotificationsState{
    notifications: Notification[];
    unread: number;
}

export interface Notification{
    id?: string;
    user?: string;
    title?: string;
    type?: 'reservation';
    read?: boolean;
    description?: string;
    date?: Date;
    data?: {
        message?: string;
    };
}

export interface ParamsNotificationsList{

}

export interface ParamsNotificationUpdate{
    id: string;
    notification: Partial<Notification>
}

export interface ResponseNotificationsList{
    notifications: Notification[];
    unreadCount: number;
}

interface GetNotificationsAction{
    type: typeof GET_NOTIFICATIONS;
    payload: Notification[];
}

interface UpdateNotificationsAction{
    type: typeof UPDATE_NOTIFICATION;
    payload: Notification[];
}

interface SetUnreadNotificationsAction{
    type: typeof SET_UNREAD_NOTIFICATIONS;
    payload: number;
}

export type NotificationActionTypes = GetNotificationsAction | SetUnreadNotificationsAction | UpdateNotificationsAction;

export const fullNotificationQuery = `
    id
    user
    title
    type
    read
    description
    date
    data
`;

export const fullNotificationListQuery = `
    notifications{
        ${fullNotificationQuery}
    }
    unreadCount
`