// @import types
import * as DashboardTypes from 'types/dashboard/dashboard.types';
// @end types

const initialState: DashboardTypes.DashboardState = {
    reservation: undefined,
    reservations: [],
    loading: false,
    error: false,
}

const dashboardReducer = (
    state: DashboardTypes.DashboardState = initialState,
    action: DashboardTypes.DashboardActionsTypes
): DashboardTypes.DashboardState => {
    switch(action.type) {
        case DashboardTypes.UPDATE_RESERVATION_DASHBOARD_SUCCESS:
        case DashboardTypes.SET_RESERVATION_DASHBOARD:
            return {
                ...state,
                reservation: action.payload,
                reservations: state.reservations.map(
                    (r) => r.id === action.payload?.id ? action.payload : r,
                ),
                loading: false,
                error: false,
            }
        case DashboardTypes.SET_RESERVATIONS_DASHBOARD:
            return {
                ...state,
                reservations: action.payload
            }
        case DashboardTypes.UPDATE_RESERVATION_DASHBOARD:
                return {
                    ...state,
                    loading: true,
                    error: false,
                }
        case DashboardTypes.UPDATE_RESERVATION_DASHBOARD_ERROR:
                return {
                    ...state,
                    loading: false,
                    error: true,
                }
        default:
            return {
                ...state
            }
    }
};

export default dashboardReducer;