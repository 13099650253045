import dashboard from './dashboard.sytem-config';
import departamental from './departamental.system-config';
import smartFactory from './smartFactory.system-config';

const systemConfigs = {
    dashboard,
    departamental,
    smartFactory,
}

export default systemConfigs;