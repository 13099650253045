// @import dependencies
import React, { CSSProperties, useState, cloneElement, useEffect } from 'react'
// @end dependencies

// @import components
import SubtitleComponent from 'components/commons/Subtitle/SubtitleComponent';
import { HiDocumentReport } from 'react-icons/hi';
import TooltipComponent from 'components/commons/TooltipComponent/TooltipComponent';
import PopUpComponent from 'components/commons/PopUp/PopUpComponent';
import DateFilterComponent from 'components/commons/DateFilterComponent/DateFilterComponent';
import { RiFilterFill } from 'react-icons/ri';
import { AiOutlineDrag } from 'react-icons/ai';
// @end components

// @import types
import { IGraphItem } from '../ChartToolDashboard/ChartToolDashboard';
import * as ToolTypes from 'types/tool/tool.types';
import { IStoreApp } from 'redux/reducers/index';
// @end types

// @import services
import SocketService from 'services/socket/socketService';
// @end services

// @import hooks
import useMoment from 'hooks/useMoment/useMoment';
import { useSelector } from 'react-redux';
import { useDashboard } from 'hooks/useDashboard/useDashboard';
// @end hooks

// @import actions
// @end actions

// @import styles
import './ContainerModuleDashboard.scss'
// @end styles

interface IContainerModuleDashboardProps {
    title?: string
    main?: boolean
    children: any
    className?: string
    style?: CSSProperties
    showFilterReport?: boolean
    showFilterData?: boolean
    tool?: ToolTypes.ITool
}

export interface ChildrenModuleDashboardData{
    charts?: IGraphItem[];
}

const ContainerModuleDashboard: React.FC<IContainerModuleDashboardProps> = (props) => {

    const [openReportFilter, updateOpenReportFilter] = useState<boolean>(false);
    const [openFilterData, updateOpenFilterData] = useState<boolean>(false);
    const [childrenData, updateChildrenData] = useState<ChildrenModuleDashboardData>({});
    const [loadingReport, updateLoadingReport] = useState<boolean>(false);
    const [loadingFilter, updateLoadingFilter] = useState<boolean>(false);
    const [data, updateData] = useState<any[] | undefined>(undefined);
    const user = useSelector((store: IStoreApp) => store.auth?.user);
    const { timeZone } = useMoment();
    const dashboard = useDashboard();

    const socketService = new SocketService();

    useEffect(() => {
        if (props.showFilterReport && props.tool) {
            socketService.listenEvent(`reportResponse:${user?.id}:${props.tool.id}`, handleReportResponse);
        }
        if (props.showFilterData && props.tool) {
            socketService.listenEvent(`toolDataResponse:${user?.id}:${props.tool.id}`, handleFilterDataResponse);
        }
        return () => {
            if (props.showFilterReport && props.tool) {
                socketService.removeListenEvent(`reportResponse:${props.tool.id}`, handleReportResponse);
            }
            if (props.showFilterData && props.tool) {
                socketService.removeListenEvent(`toolDataResponse:${props.tool.id}`, handleFilterDataResponse);
            }
        }
    }, [])

    const handleReportResponse = (data: {file?: string}) => {
        updateLoadingReport(false);
        if (data?.file) {
            window.open(data.file, '_blank', 'noreferrer');
            updateOpenReportFilter(false);
        }
    }

    const handleFilterDataResponse = (data: {data: any[]}) => {
        updateLoadingFilter(false);
        if (data?.data) {
            updateData(data.data);
        }
    }

    const handleChangeChildrenDate = (data: ChildrenModuleDashboardData) => {
        updateChildrenData(data);
    }

    const getChildren = () => {
        const extraProps = {
            onChangeData: handleChangeChildrenDate,
            data: data
        }
        return cloneElement(props.children, extraProps)
    }

    const handleClickReport = (since: Date, to: Date) => {
        if (props.tool) {
            updateLoadingReport(true);
            const params = {
                toolId: props.tool.id,
                userId: user?.id,
                payload: {
                    from: since,
                    to: to,
                    timezone: timeZone,
                    variables: childrenData?.charts?.filter((c) => c.show)?.map((c) => c.variableKey)
                }
            };
            socketService.emitEvent('getReport', params);
        }
    }
    
    const handleClickFilterData = (since: Date, to: Date) => {
        if (props.tool) {
            updateLoadingFilter(true);
            const params = {
                toolId: props.tool.id,
                userId: user?.id,
                payload: {
                    from: since,
                    to: to
                }
            };
            socketService.emitEvent('getToolData', params);
        }
    }

    return(
        <div
            className={`container_module_dashboard-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <div className="header-container-module drag-dashboard-element">
                {dashboard?.dashboardType === 'normal' ? <AiOutlineDrag className='icon-drag-dashboard-item' />: null}
                {props.title ?
                    <SubtitleComponent
                        style={{
                            color: 'var(--text)',
                            fontWeight: 600
                        }}
                    >
                        {props.title}
                    </SubtitleComponent>
                :null}
                <div className="container-extra-items-container-module-dashboard">
                    {props.showFilterReport ?
                        <PopUpComponent
                            show={openReportFilter}
                            content={
                                <div>
                                    <DateFilterComponent
                                        textButton='Generar'
                                        title='Generar reporte'
                                        loading={loadingReport}
                                        onSearch={handleClickReport}
                                    />
                                </div>
                            }
                            onClickClose={() => updateOpenReportFilter(false)}
                            placement='bottom-end'
                        >
                            <TooltipComponent
                                text='Generar reporte'
                            >
                                <HiDocumentReport
                                    className='report-icon-tool'
                                    onClick={() => updateOpenReportFilter(true)}
                                />
                            </TooltipComponent>
                        </PopUpComponent>
                    :null}
                    {props.showFilterData ?
                        <PopUpComponent
                            show={openFilterData}
                            content={
                                <div>
                                    <DateFilterComponent
                                        textButton='Filtrar'
                                        title='Filtrar datos'
                                        loading={loadingFilter}
                                        onSearch={handleClickFilterData}
                                    />
                                </div>
                            }
                            onClickClose={() => updateOpenFilterData(false)}
                            placement='bottom-end'
                        >
                            <TooltipComponent
                                text='Filtrar datos'
                            >
                                <RiFilterFill
                                    className='report-icon-tool'
                                    onClick={() => updateOpenFilterData(true)}
                                />
                            </TooltipComponent>
                        </PopUpComponent>
                    :null}
                </div>
            </div>
            <div className="container-children-module">
                {getChildren()}
            </div>
        </div>
    );
}

export default ContainerModuleDashboard;
