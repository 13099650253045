// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react'
// @end dependencies

// @import components
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import InputComponent from 'components/commons/Input/InputComponent';
import TextComponent from 'components/commons/Text/TextComponent';
// @end components

// @import types
import * as ToolTypes from 'types/tool/tool.types';
import { IValidatorFieldFormValidator, IFieldFormValidator } from 'hooks/useFormValidator/useFormValidator.types';
// @end types

// @import services
import SocketService from 'services/socket/socketService';
// @end services

// @import hooks
import useValidator from 'hooks/useFormValidator/useFormValidator';
import { useTranslation } from 'react-i18next';
import { useDashboard } from 'hooks/useDashboard/useDashboard';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './SourceModuleDashboard.scss'
// @end styles

interface ISourceModuleDashboardProps {
    tool: ToolTypes.ITool
    className?: string
    style?: CSSProperties
    id?: string
}

const SourceModuleDashboard: React.FC<ISourceModuleDashboardProps> = (props) => {

    const validatorsVoltage: IValidatorFieldFormValidator[] = props.tool.category?.config?.hasVoltageRange ? [ { type: 'range', ...props.tool.config?.voltageRange } ] : [];
    const validatorsCurrent: IValidatorFieldFormValidator[] = props.tool.category?.config?.hasCurrentRange ? [ { type: 'range', ...props.tool.config?.currentRange } ] : [];
    const [voltage, updateVoltage] = useState(props.tool.config?.initialVoltage ? props.tool.config?.initialVoltage : 0);
    const [current, updateCurrent] = useState(props.tool.config?.initialCurrent ? props.tool.config?.initialCurrent : 0);
    const [triggerNewData, updateTriggerNewData] = useState<any>(undefined)
    const {t} = useTranslation()
    const form = useValidator([])
    const dashboard = useDashboard();

    // @INFO Servicios
    const socketService = new SocketService()

    useEffect(() => {
        // @INFO Organizar los campos del formulario
        const fields: IFieldFormValidator[] = [];
        if(props.tool?.category?.config?.hasVoltage){
            fields.push(
                { name: 'voltage', required: true, messageError: t('obligatory_field'), validators: validatorsVoltage, value: voltage }
            )
        }
        if(props.tool?.category?.config?.hasCurrent){
            fields.push(
                { name: 'current', required: true, messageError: t('obligatory_field'), validators: validatorsCurrent, value: current }
            )
        }
        form.addFields(fields);

        // @INFO Suscribirme al evento
        if(props.tool?.category?.config?.hasEvent && props.tool.config?.event){
            socketService.listenEvent(props.tool.config?.event, handleNewData)
            // @INFO Emitir el evento con los voltajes iniciales
            if(props.tool?.category?.config?.hasVoltage || props.tool?.category?.config?.hasCurrent){
                socketService.emitEvent(props.tool.config?.event, {
                    voltage: props.tool?.config?.initialVoltage,
                    current: props.tool?.config?.initialCurrent
                });
            }
        }
        return () => {
            if(props.tool?.category?.config?.hasEvent && props.tool.config?.event){
                socketService.removeListenEvent(props.tool.config?.event, handleNewData)
            }
        }
    },[])

    useEffect(() => {
        if(triggerNewData){
            updateTriggerNewData(undefined)
        }
    },[triggerNewData])

    /**
     * @INFO Llega un nuevo dato del socket
     * @param _data 
     */
    const handleNewData = (_data: any) => {
        if(_data){
            updateTriggerNewData(_data)
        }
    }

    /**
     * @INFO Click en el boton de aplicar
     */
    const handleClickApply = () => {
        if(!props.tool.config?.event) return;
        const values = form.getValues()
        socketService.emitEvent(props.tool.config?.event, values);
        updateCurrent(values.current);
        updateVoltage(values.voltage);
    }

    return(
        <div
            className={`source_module_dashboard-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <div className="header-card">
                <TextComponent>
                    FUENTE DC
                </TextComponent>
                {props.tool?.category?.config?.hasVoltage?
                    <TextComponent>
                        Voltaje: {voltage}V
                    </TextComponent>
                :null}
                {props.tool?.category?.config?.hasCurrent?
                    <TextComponent>
                        Corriente: {current}A
                    </TextComponent>
                :null}
            </div>
            <div className="container-body-card">
                {props.tool?.category?.config?.hasVoltage ?
                        <InputComponent
                            label='Voltaje [V]'
                            hint={validatorsVoltage[0] ? `De ${validatorsVoltage[0].min} a ${validatorsVoltage[0].max}V` : ''}
                            type='number'
                            onChange={form.handleChange}
                            value={form.values['voltage']}
                            name='voltage'
                            invalid={form.errors['voltage'] ? true : false}
                            invalidText={form.errors['voltage']}
                            disabled={!(dashboard.dashboardType === 'normal' || dashboard.reservation?.publicSettings?.source?.enableVoltage)}
                        />
                :
                    null
                }
                {props.tool?.category?.config?.hasCurrent ?
                    <InputComponent
                        label='Corriente [A]'
                        hint={validatorsCurrent[0] ? `De ${validatorsCurrent[0].min} a ${validatorsCurrent[0].max}A` : ''}
                        type='number'
                        onChange={form.handleChange}
                        value={form.values['current']}
                        name='current'
                        invalid={form.errors['current'] ? true : false}
                        invalidText={form.errors['current']}
                        disabled={!(dashboard.dashboardType === 'normal' || dashboard.reservation?.publicSettings?.source?.enableCurrent)}
                    />
                :null}
                {
                    dashboard.dashboardType === 'normal' || 
                    dashboard.reservation?.publicSettings?.source?.enableVoltage || 
                    dashboard.reservation?.publicSettings?.source?.enableCurrent 
                    ?
                <ButtonComponent
                    variant='accent'
                    style={{
                        color: 'white',
                        padding: '0.5rem 4rem'
                    }}
                    disabled={form.isInvalid()}
                    onClick={handleClickApply}
                >
                    Aplicar
                </ButtonComponent>
                :null}
            </div>
        </div>
    );
}

export default SourceModuleDashboard;