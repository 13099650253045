import store from 'redux/store'
import graphQLUtil from "utils/graphQL/graphQL.util"
import { gql } from "@apollo/client"

import * as ToolCategoryTypes from 'types/toolCategory/toolCategory.types';

class ToolCategoryService{

    private storeObject = store

    constructor(){

    }

    /**
     * @INFO Traer la lista de toolCategories
     * @param _params 
     */
    public list = async (_params?: ToolCategoryTypes.IParamsToolCategoryList): Promise<ToolCategoryTypes.IToolCategory[]> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query toolCategories($token: String!){
                        toolCategories(token: $token){
                            ${ToolCategoryTypes.fullToolCategoryQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token
                }
            }
        })
        if(response?.data?.toolCategories?.length){
            return response.data.toolCategories
        }else{
            return []
        }
    }
    
    /**
     * @INFO Traer un toolCategory por id
     * @param _params 
     */
    public getById = async (_params: ToolCategoryTypes.IParamsToolCategoryGetById): Promise<ToolCategoryTypes.IToolCategory | undefined> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query toolCategory($token: String!, $id: ID!){
                        toolCategory(token: $token, id: $id){
                            ${ToolCategoryTypes.fullToolCategoryQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        })
        if(response?.data?.toolCategory){
            return response.data.toolCategory
        }else{
            return undefined
        }
    }

    /**
     * @INFO Crear una categoria de tool
     * @param _params 
     * @returns 
     */
    public create = async (_params: ToolCategoryTypes.IParamsToolCategoryCreate): Promise<ToolCategoryTypes.IToolCategory | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation newToolCategory($token: String!, $toolCategory: ToolCategoryInput!){
                        newToolCategory(token: $token, toolCategory: $toolCategory){
                            ${ToolCategoryTypes.fullToolCategoryQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    toolCategory: {..._params.category}
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.newToolCategory){
            return response.data.newToolCategory
        }else{
            return undefined
        }
    }

    /**
     * @INFO Actualizar un toolCategory
     * @param _params 
     * @returns 
     */
    public update = async (_params: ToolCategoryTypes.IParamsToolCategoryUpdate)
    : Promise<ToolCategoryTypes.IToolCategory | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation updateToolCategory($token: String!, $id: ID!, $toolCategory: UpdateToolCategoryInput!){
                        updateToolCategory(token: $token, id: $id, toolCategory: $toolCategory){
                            ${ToolCategoryTypes.fullToolCategoryQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    toolCategory: {..._params.category},
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.updateToolCategory){
            return response.data.updateToolCategory
        }else{
            return undefined
        }
    }

    /**
     * @INFO Eliminar un toolCategory
     * @param _params 
     * @returns 
     */
    public delete = async (_params: ToolCategoryTypes.IParamsToolCategoryDelete): Promise<ToolCategoryTypes.IToolCategory | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation removeToolCategory($token: String!, $id: ID!){
                        removeToolCategory(token: $token, id: $id){
                            ${ToolCategoryTypes.fullToolCategoryQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.removeToolCategory){
            return response.data.removeToolCategory
        }else{
            return undefined
        }
    }
}

export default ToolCategoryService