// @import dependencies
import React, { CSSProperties } from 'react'
// @end dependencies

// @import components
import SubtitleComponent from 'components/commons/Subtitle/SubtitleComponent';
import TextComponent from 'components/commons/Text/TextComponent';
import { MdScreenShare } from 'react-icons/md';
// @end components

// @import types
import { IStoreApp } from 'redux/reducers/index';
import * as ReservationTypes from 'types/reservations/reservations.types';
// @end types

// @import hooks
import { useTranslation } from 'react-i18next';
import useMoment from 'hooks/useMoment/useMoment';
import { useSelector } from 'react-redux';
// @end hooks

// @import services
// @end services

// @import styles
import './HistoryReserveHome.scss'
// @end styles

interface IHistoryReserveHomeProps {
    className?: string
    style?: CSSProperties
    id?: string
}

const HistoryReserveHome: React.FC<IHistoryReserveHomeProps> = (props) => {

    const {t} = useTranslation()
    const reservations = useSelector((store: IStoreApp) => store.laboratories.reservations)

    return(
        <div
            className={`history_reserve_home-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <div className="header-card">
                <SubtitleComponent
                    className='header-card-title'
                >
                    {t('home.record.name')}
                </SubtitleComponent>
                <TextComponent
                    className='header-card-subtitle'
                >
                    {t('home.record.description')}
                </TextComponent>
            </div>
            <div className="container-items">
                {reservations.filter(r => r.date ? true : false).reverse().map((r) => (
                    <Item
                        key={r.id}
                        title={r.lab?.name as string}
                        category={r.lab?.category?.name as string}
                        date={r.date}
                        state={r.state}
                    />
                ))}
            </div>
        </div>
    );
}

// =====================================================
// Item del historial
// =====================================================
interface IItemProps {
    title: string
    category: string
    date: Date
    state: ReservationTypes.IReservation['state']
    className?: string
    style?: CSSProperties
    color?: string
}

const Item: React.FC<IItemProps> = (props) => {

    const {moment} = useMoment()
    const {t} = useTranslation()

    return(
        <div
            className={`history_reserve_item-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <div className="icon-item-reserve">
                <MdScreenShare/>
            </div>
            <div className="container-text-reserve">
                <SubtitleComponent
                    className='title-item-reserve'
                >
                    {props.title}
                </SubtitleComponent>
                <TextComponent
                    className='subtitle-item-reserve'
                >
                    {props.category}
                </TextComponent>
            </div>
            <div className="container-date-item">
                <SubtitleComponent
                    className='title-date-reserve'
                >
                    {moment(props.date).format('MMM DD, YYYY')}
                </SubtitleComponent>
                <TextComponent
                    className='subtitle-date-reserve'
                >
                    {moment(props.date).format('HH:mm')}
                </TextComponent>
            </div>
            <TextComponent
                className='state-item-text'
            >
                {t(`reservations.state.${props.state}`)}
            </TextComponent>
        </div>
    );
}


export default HistoryReserveHome;