class CurrencyService {
    public getTaxFromValue = (value: number, taxPercentage: number, decimals: number = 2) => {
        const result = (value*taxPercentage)/100
        return result.toFixed(decimals)
    }

    public getSubtotal = (value: number, taxPercentage: number, decimals: number = 2) => {
        const tax = this.getTaxFromValue(value, taxPercentage, decimals)
        const result = value - parseFloat(tax)
        return result.toFixed(decimals)
    }
}

export default CurrencyService