// @import dependencies
import React, { CSSProperties, useRef, useState } from 'react';
// @end dependencies

// @import components
import TextComponent from 'components/commons/Text/TextComponent';
import CalendarComponent, { TypesCalendarView } from 'components/commons/CalendarComponent/CalendarComponent';
import SubtitleComponent from 'components/commons/Subtitle/SubtitleComponent';
import ModalEditReserve from 'components/modules/ModalEditReserve/ModalEditReserve';
// @end components

// @import types
import { DateClickArg } from '@fullcalendar/interaction';
import { IStoreApp } from 'redux/reducers/index';
import { EventSourceInput } from '@fullcalendar/react';
import * as ReservationTypes from 'types/reservations/reservations.types';
// @end types

// @import hooks
import useMoment from 'hooks/useMoment/useMoment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useReservation from 'hooks/useReservation/useReservation';
// @end hooks

// @import services
import ScreenNotification from 'services/screenNotification/screenNotification';
// @end services

// @import styles
import './CardCalendarHome.scss';
// @end styles

interface ICardCalendarHomeProps {
    className?: string
    style?: CSSProperties
    id?: string
}

const CardCalendarHome: React.FC<ICardCalendarHomeProps> = (props) => {

    const {t} = useTranslation()
    const reservations = useSelector((store: IStoreApp) => store.laboratories.reservations);
    const {moment} = useMoment();
    const calendarRef = useRef();
    const hookReservation = useReservation();
    const [view, updateView] = useState<TypesCalendarView>('dayGridMonth');
    const [openModal, updateOpenModal] = useState<boolean>(false);
    const [currentReservation, updateCurrentReservation] = useState<ReservationTypes.IReservation | undefined>(undefined);

    // @INFO Servicios
    const screenNotification = new ScreenNotification();

    const getEvents = () => {
        const events: EventSourceInput = [];
        if (view === 'dayGridMonth') {
            reservations.forEach((res) => {
                events.push({
                    title: getTitleEvent(res),
                    date: moment(res.date).format('YYYY-MM-DD')
                });
            });
        } else {
            reservations.forEach((res) => {
                events.push({
                    title: getTitleEvent(res),
                    start: moment(res.date).format('YYYY-MM-DD HH:mm').replace(' ', 'T'),
                    end: moment(res.date).add(res.lab.config?.duration, 's').format('YYYY-MM-DD HH:mm').replace(' ', 'T')
                });
            });
        }
        return events;
    }

    const getTitleEvent = (reservation: ReservationTypes.IReservation) => {
        let response = '';
        if (reservation?.lab?.name) {
            response = `${response}${reservation.lab.name} `;
        }
        if (reservation?.date) {
            response = `${response}${moment(reservation.date).format('HH:mm')} `;
        }
        if(reservation?.lab?.category?.config?.hasDuration) {
            response = `${response}- ${moment(reservation.date).add(reservation.lab.config?.duration, 's').format('HH:mm')} `;
        }
        return response;
    }

    const handleClickDate = (date: DateClickArg) => {
        if (view === 'timeGridDay') {
            const reservation = hookReservation.searchByDateBetween(date.date);
            if (reservation) {
                updateCurrentReservation(reservation);
                updateOpenModal(true);
            }
        } else {
            // @ts-ignore
            calendarRef.current?.getApi().changeView('timeGridDay', date.date);
        }
    }

    const handleCloseModalEdit = () => {
        updateOpenModal(false);
        updateCurrentReservation(undefined);
    }

    return(
        <div
            className={`card_calendar_home-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <div className="header-card">
                <SubtitleComponent
                    className='header-card-title'
                >
                    {t('home.calendar.name')}
                </SubtitleComponent>
                <TextComponent
                    className='header-card-subtitle'
                >
                    {t('home.calendar.description')}
                </TextComponent>
            </div>
            <div className="container-calendar-home">
                <CalendarComponent
                    style={{boxShadow: 'none'}}
                    events={getEvents()}
                    contentHeight={'400px'}
                    calendarRef={calendarRef}
                    dateClick={handleClickDate}
                    onChangeView={(_view) => updateView(_view)}
                />
            </div>
            <ModalEditReserve
                open={openModal}
                reservation={currentReservation}
                onClose={() => handleCloseModalEdit()}
            />
        </div>
    );
}

export default CardCalendarHome;