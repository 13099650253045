// @import dependencies
import React, { CSSProperties, useEffect, useState } from 'react'
// @end dependencies

// @import components
import PreviewLaboratoryCard from 'components/modules/PreviewLaboratoryCard/PreviewLaboratoryCard';
// @end components

// @import types
import * as FavoriteTypes from 'types/favorite/favorite.types'
// @end types

// @import services
import FavoriteService from 'services/favorite/favoriteService'
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './FavoritesModule.scss'
import TextComponent from 'components/commons/Text/TextComponent';
import { useHistory } from 'react-router';
// @end styles

interface IFavoritesModuleProps {
    className?: string
    style?: CSSProperties
    id?: string
    
}

const FavoritesModule: React.FC<IFavoritesModuleProps> = (props) => {

    const [favorites, updateFavorites] = useState<FavoriteTypes.IFavorite[]>([])
    const history = useHistory()

    // @INFO Servicios
    const favoriteService = new FavoriteService()

    useEffect(() => {
        getFavorites()
    },[])

    /**
     * @INFO Obtener la lista de favoritos
     */
    const getFavorites = async () => {
        const response = await favoriteService.list()
        updateFavorites(response)
    }

    /**
     * @INFO Eliminar un laboratorio de favoritos
     * @param _idLab 
     * @returns 
     */
    const handleClickDelete = async (_idLab?: string) => {
        if(!_idLab) return
        const response = await favoriteService.delete({
            lab: _idLab
        })
        await getFavorites()
    }

    const handleClickGetLab = (idLab?: string) => {
        if(history && idLab){
            history.push(`/laboratory-preview/${idLab}`)
        }
    }

    return(
        <div
            className={`favorites_module-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            {favorites.length ?
                favorites.map((_fav) => (
                    <PreviewLaboratoryCard
                        key={_fav.lab.id}
                        laboratory={_fav.lab}
                        deleteFavorite
                        hideShare
                        onClickDeleteFavorite={() => handleClickDelete(_fav?.lab?.id)}
                        onClickGet={() => handleClickGetLab(_fav?.lab?.id)}
                    />
                ))
            :
                <TextComponent>
                    No hay favoritos
                </TextComponent>
            }
        </div>
    );
}

export default FavoritesModule;