// @import dependencies
import React, { CSSProperties } from 'react'
import styled from 'styled-components';
// @end dependencies

// @import components
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import CategoryCatalogerCard from './components/CategoryCatalogerCard/CategoryCatalogerCard';
import SubtitleComponent from 'components/commons/Subtitle/SubtitleComponent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import { IoIosAddCircleOutline } from 'react-icons/io';
// @end components

// @import types
import * as AppTypes from 'types/app/app.types'
// @end types

// @import styles
import './HomeCatalogerPage.scss'
// @end styles

// @import assets
import bannerImg from 'assets/images/cataloger/banner.png';
import hardwareImg from 'assets/images/cataloger/hardware.png';
import laboratoryImg from 'assets/images/cataloger/laboratory.png';
import laboratory_categoryImg from 'assets/images/cataloger/laboratory_category.png';
import toolImg from 'assets/images/cataloger/tool.png';
import tool_categoryImg from 'assets/images/cataloger/tool_category.png';
// @end assets

interface IHomeCatalogerPageProps {
    className?: string
    style?: CSSProperties
    history?: any
}

const ContainerActions = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    &>button{
        background-color: var(--background);
        color: var(--text);
        margin-left: auto;
        svg{
            color: var(--text);
        }
    }
`;

interface IItemCatalogerHome{
    name: string
    shortDescription: string
    description: string
    buttonNewText: string
    buttonListText: string
    image: any
    newPath: string
    listType: AppTypes.ITypeCategoryListCataloger
}

interface ICategoryCatalogerHome{
    title: string;
    items: IItemCatalogerHome[];
}

const HomeCatalogerPage: React.FC<IHomeCatalogerPageProps> = (props) => {

    const categories: ICategoryCatalogerHome[] = [
        {
            title: "Laboratorios",
            items: [
                {
                    name: 'Categorías de herramientas',
                    shortDescription: 'Crea categorías para las herramientas',
                    description: 'Crea y edita las categorías de las herramientas que se utilizarán en el laboratorio.',
                    buttonNewText: 'Nueva categoría',
                    buttonListText: 'Ver categorias',
                    image: tool_categoryImg,
                    newPath: '/create-tool-category',
                    listType: 'tool-category'
                },
                {
                    name: 'Herramientas',
                    shortDescription: 'Crea herramientas para los laboratorios',
                    description: 'Crea y edita herramientas que se utilizarán en el laboratorio.',
                    buttonNewText: 'Nueva herramienta',
                    buttonListText: 'Ver herramientas',
                    image: toolImg,
                    newPath: '/create-tool',
                    listType: 'tool'
                },
                {
                    name: 'Hardware',
                    shortDescription: 'Crea hardware para los laboratorios',
                    description: 'Crea y edita hardware que se utilizarán en el laboratorio.',
                    buttonNewText: 'Nuevo hardware',
                    buttonListText: 'Ver hardwares',
                    image: hardwareImg,
                    newPath: '/create-hardware',
                    listType: 'hardware'
                },
                {
                    name: 'Categorías de laboratorios',
                    shortDescription: 'Crea categorías de laboratorios',
                    description: 'Utiliza diferentes herramientas para crear y editar categorías de laboratorios.',
                    buttonNewText: 'Nueva categoría',
                    buttonListText: 'Ver categorías',
                    image: laboratory_categoryImg,
                    newPath: '/create-laboratory-category',
                    listType: 'laboratory-category'
                },
                {
                    name: 'Laboratorios',
                    shortDescription: 'Crea laboratorios de multiples categorías',
                    description: 'Utiliza diferentes herramientas para crear y editar laboratorios de categorías como Realidad virtual, Acceso remoto o computación en la nube.',
                    buttonNewText: 'Nuevo laboratorio',
                    buttonListText: 'Ver laboratorios',
                    image: laboratoryImg,
                    newPath: '/create-laboratory',
                    listType: 'laboratory'
                }                
            ]
        },{
            title: "Banners",
            items: [
                {
                    name: 'Banners',
                    shortDescription: 'Crea banners para dar información',
                    description: 'Crea y edita banners para dar información sobre nuevos laboratorios y promociones.',
                    buttonNewText: 'Nuevo banner',
                    buttonListText: 'Ver banners',
                    image: bannerImg,
                    newPath: '/create-banner',
                    listType: 'banner'
                }
            ]
        }                        
    ]

    /**
     * @INFO Ir a la pantalla de lista de catalogación
     */
    const handleClickGoList = (type: AppTypes.ITypeCategoryListCataloger) => {
        if(props.history){
            props.history.push(`/cataloger-list/${type}`)
        }
    }

    /**
     * @INFO Click en crear un elemento
     * @param path 
     */
    const handleClickNew = (path: string) => {
        if(props.history){
            props.history.push(path)
        }
    }

    return(
        <AppLayout
            history={props.history}
            headerTitle='Catalogo'
        >
            <div
                className={`home_cataloger_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
            >
                {
                    categories?.map((c) => (
                        <div key={c.title} className="container-card-category">
                            <SubtitleComponent>
                                {c.title}
                            </SubtitleComponent>
                            <div>
                                {
                                    c.items.map((_category, idx) => (
                                        <div 
                                            className="container-card-item"
                                            key={_category.name}
                                        >
                                            <ContainerActions>
                                                <SubtitleComponent>
                                                    {idx+1}. {_category.name}
                                                </SubtitleComponent>
                                                <ButtonComponent
                                                    onClick={() => handleClickNew(_category.newPath)}
                                                >
                                                    <ButtonComponent.Icon>
                                                        <IoIosAddCircleOutline/>
                                                    </ButtonComponent.Icon>
                                                    {_category.buttonNewText}
                                                </ButtonComponent>
                                            </ContainerActions>
                                            <CategoryCatalogerCard
                                                image={_category.image}
                                                title={_category.name}
                                                description={_category.shortDescription}
                                                explication={_category.description}
                                                buttonText={_category.buttonListText}
                                                onClick={() => handleClickGoList(_category.listType)}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </AppLayout>
    );
}

export default HomeCatalogerPage;