import * as AuthTypes from 'types/auth/auth.types'
import * as AuthActionTypes from 'types/auth/auth.actions.types'

const initialState: AuthTypes.IAuthState = {
    user: undefined,
    hasData: false,
    isLogged: false,
    loading: false,
    token: undefined
}

const authReducer = (state: AuthTypes.IAuthState = initialState, action: any): AuthTypes.IAuthState => {
    switch(action.type){
        case AuthActionTypes.LOGIN:
        case AuthActionTypes.LOGIN_TOKEN:
        case AuthActionTypes.CREATE_ACCOUNT:
            return {
                ...state,
                loading: true
            }
        case AuthActionTypes.LOGIN_ERROR:
            return {
                ...state,
                loading: false,
                token: undefined,
                isLogged: false,
                hasData: false,
                user: undefined
            }
        case AuthActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
                token: action.payload.token,
                loading: false,
                isLogged: true,
                hasData: true
            }
        case AuthActionTypes.LOGOUT:
            return {
                ...state,
                isLogged: false,
                hasData: false,
                token: undefined,
                user: undefined
            }
        case AuthActionTypes.UPDATE_USER:
            return {
                ...state,
                user: action.payload
            }
        case AuthActionTypes.CHANGE_PASSWORD:
            return {
                ...state
            }
        default: 
            return {...state}
    }
}

export default authReducer;