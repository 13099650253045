import React, { useState, useEffect } from 'react'

import * as UseTriggerReset from './useTriggerReset.types'

const useTriggerReset = (_options?: UseTriggerReset.IUseTriggerResetInput): UseTriggerReset.IUseTriggerResetOutput => {

    const [reset, updateReset] = useState<boolean>(false)

    useEffect(() => {
        if(reset){
            updateReset(false)
        }
    },[reset])

    /**
     * @INFO Hacer un trigger de reset
     */
    const trigger = () => {
        updateReset(true)
    }

    return {
        reset,
        trigger
    }
}

export default useTriggerReset