import TextComponent from 'components/commons/Text/TextComponent';
import { FC } from 'react';
import LandingLayout from '../LandingPage/LandingLayout/LandingLayout';
import OpenpayIcon from 'assets/images/openpay/Logo.jpg'

import './TermsAndConditionsPage.scss'

const TermsAndConditionsPage: FC = () => {
  return (
    <LandingLayout>
      <div className="terms_and_conditions_page--layout">
        <div className="terms_and_conditions--container">
          <TextComponent type='h2'>Terminos y condiciones</TextComponent>
          <TextComponent>
            Pagos en línea (tarjeta de crédito o débito) mediante la pasarela de pagos Openpay. 
            <br />
            Desde nuestro Ecommerce no capturamos, almacenamos ni transmitimos datos transaccionales. Para esto contamos con los servicios de Openpay, que es una plataforma de pagos certificada; que garantiza la seguridad de todas las transacciones por medio de software de encriptación, procedimientos de validación y medidas robustas de protección de datos a nivel bancario (certificación PCI nivel 1). Por eso realizar los pagos en nuestro sitio web es seguro. 
            <br />
            <br />
            Si su pago es con PSE, la pasarela de pagos Openpay se encarga de comunicarlo directamente con su banco a través de ACH, al pagar a través de este canal, está utilizando los procesos de seguridad de su propio banco quien es el que valida su clave principal y segunda clave si es el caso.
          </TextComponent>
          <img src={OpenpayIcon} alt="" />
        </div>
      </div>
    </LandingLayout>
  )
}

export default TermsAndConditionsPage;
