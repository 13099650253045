import * as ReservationTypes from "types/reservations/reservations.types"
import graphQLUtil from "utils/graphQL/graphQL.util"
import { gql } from "@apollo/client"
import store from "redux/store"

class ReservationsService{

    private storeObject = store

    constructor(){

    }

    /**
     * @INFO Listar las reservas del usuario
     * @param _params 
     */
    public listReservations = async (_params?: ReservationTypes.ListReserveParams):Promise<ReservationTypes.IReservation[]> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query reservations($token: String!){
                        reservations(token: $token){
                            ${ReservationTypes.fullReservationQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token
                }
            }
        })
        if(response?.data?.reservations?.length){
            return response.data.reservations
        }else{
            return []
        }
    }

    /**
     * @INFO Obtener la lista de horas disponibles para un laboratorio
     * @param _params 
     * @returns 
     */
    public availableHours = async (_params: ReservationTypes.IAvailableHoursParams): Promise<ReservationTypes.IAvailableHour[]> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query availableHours($token: String!, $lab: ID!, $date: Date!){
                        availableHours(token: $token, lab: $lab, date: $date){
                            ${ReservationTypes.fullAvailableHourQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    lab: _params.lab,
                    date: _params.date
                }
            }
        })
        if(response?.data?.availableHours?.length){
            return response.data.availableHours
        }else{
            return []
        }
    }

    /**
     * @INFO Crear nueva reserva
     * @param _params 
     * @returns 
     */
    public newReserve = async (_params: ReservationTypes.INewReserveParams): Promise<ReservationTypes.IReservation | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation newReservation($token: String!, $reservation: ReservationInput!){
                        newReservation(token: $token, reservation: $reservation){
                            ${ReservationTypes.fullReservationQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    reservation: {..._params}
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.newReservation){
            return response.data.newReservation
        }else{
            return undefined
        }
    }
    
    /**
     * @INFO Actualizar reserva
     * @param _params 
     * @returns 
     */
    public updateReserve = async (_params: ReservationTypes.UpdateReserveParams): Promise<ReservationTypes.IReservation | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation updateReservation($token: String!, $id: ID!, $reservation: UpdateReservationInput!){
                        updateReservation(token: $token, id: $id, reservation: $reservation){
                            ${ReservationTypes.fullReservationQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    reservation: {..._params.reservation},
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.updateReservation){
            return response.data.updateReservation
        }else{
            return undefined
        }
    }
    
    /**
     * @INFO Eliminar reserva
     * @param _params 
     * @returns 
     */
    public deleteReserve = async (_params: ReservationTypes.RemoveReserveParams): Promise<ReservationTypes.IReservation | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation removeReservation($token: String!, $id: ID!){
                        removeReservation(token: $token, id: $id){
                            ${ReservationTypes.fullReservationQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.removeReservation){
            return response.data.removeReservation
        }else{
            return undefined
        }
    }
    
    /**
     * @INFO Publicar una reserva
     * @param _params 
     * @returns 
     */
    public publish = async (_params: ReservationTypes.PublishReserveParams): Promise<ReservationTypes.IReservation | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation publishReservation($token: String!, $id: ID!){
                        publishReservation(token: $token, id: $id){
                            ${ReservationTypes.fullReservationQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.publishReservation){
            return response.data.publishReservation
        }else{
            return undefined
        }
    }

    /**
     * @INFO Verificar si una reserva es publica
     * @param _params 
     * @returns 
     */
    public verifyPublished = async (_params: ReservationTypes.PublishReserveParams): Promise<ReservationTypes.IReservation | undefined> => {
        if(!_params) return
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query validateReservation($id: ID!){
                        validateReservation(id: $id){
                            ${ReservationTypes.fullReservationQuery}
                        }
                    }
                `,
                variables: {
                    id: _params.id
                }
            }
        },
        {
            alertOnFailed: true
        })
        if(response?.data?.validateReservation){
            return response.data.validateReservation
        }else{
            return undefined
        }
    }
}

export default ReservationsService