// @import dependencies
import React, { CSSProperties } from 'react'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import Rating from 'react-rating';
// @end dependencies

// @import components
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './RatingComponent.scss'
// @end styles

interface IRatingComponentProps {
    rating?: number;
    className?: string;
    style?: CSSProperties;
    id?: string;
    size?: string;
    readonly?: boolean;
    onChange?: (e: number) => void;
}

const RatingComponent: React.FC<IRatingComponentProps> = (props) => {
    return(
        <div
            className={`rating_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
            id={props.id}
        >
            <Rating
                // emptySymbol={<AiOutlineStar className='icon-star-rating' style={{ color: 'var(--text)', fontSize: props.size}} />}
                emptySymbol={<AiFillStar className='icon-star-rating' style={{ color: '#979797', fontSize: props.size}} />}
                fullSymbol={<AiFillStar className='icon-star-rating' style={{ color: 'var(--secondary)', fontSize: props.size}} />}
                readonly={props.readonly}
                initialRating={props.rating}
                onChange={props.onChange}
            />  
        </div>
    );
}

export default RatingComponent;