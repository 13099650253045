import graphQLUtil from 'utils/graphQL/graphQL.util';
import { gql } from '@apollo/client';

class LocationsService{
    constructor(){

    }

    /**
     * @INFO Obtener la lista de países
     */
    public getCountries = async () =>{
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query countries{
                        countries
                    }
                `
            }
        })

        if(response?.data?.countries?.length){
            return response.data.countries
        }else{
            return []
        }
    }

    /**
     * @INFO Obtener la lista de provincias
     * @param _country 
     * @returns 
     */
     public getProvinces = async (_country: string) =>{
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query provinces($country: String!){
                        provinces(country: $country)
                    }
                `,
                variables: {
                    country: _country
                }
            },
        })

        if(response?.data?.provinces?.length){
            return response.data.provinces
        }else{
            return []
        }
    }

    /**
     * @INFO Obtener la lista de ciudades
     * @param _country 
     * @param _province
     * @returns 
     */
     public getCities = async (_country: string, _province: string) =>{
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query cities($country: String!, $province: String!){
                        cities(country: $country, province: $province)
                    }
                `,
                variables: {
                    country: _country,
                    province: _province
                }
            },
        })

        if(response?.data?.cities?.length){
            return response.data.cities
        }else{
            return []
        }
    }
}

export default LocationsService