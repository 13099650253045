import React, { useState, useEffect } from 'react'
import { IFieldFormValidator, IUseFormValidator } from './useFormValidator.types'
import UseFormValidatorValidators from './useFormValidatorValidators'

const useValidator = (_fields: IFieldFormValidator[])
:IUseFormValidator => {

    const [fields, updateFields] = useState<IFieldFormValidator[]>([..._fields])
    const [values, updateValues] = useState<any>({})
    const [errors, updateErrors] = useState<any>({})
    const [triggerValidate, updateTriggerValidate] = useState<any>({})

    // @INFO Servicios
    const useFormValidatorValidators = new UseFormValidatorValidators()

    useEffect(() => {
        // @INFO Validar los campos
        handleValidateFields()
    },[])

    useEffect(() => {
        // @INFO Actualizar los values
        handleUpdateValues()
        // @INFO Actualizar los errores
        handleUpdateErrors()
    }, [fields])

    useEffect(() => {
        // @INFO Validar campos
        handleValidateFields()
    },[triggerValidate])

    /**
     * @INFO Validar todos los campos del formulario
     */
    const handleValidateFields = () => {
        const newFields = fields.reduce((accum: IFieldFormValidator[], item) => {
            // @INFO Verificar si es requerido
            let invalid: boolean = false
            if(item.required){
                if(
                    (typeof item.value === 'string' && item.value?.length) ||
                    (typeof item.value === 'number' && item.value !== undefined) ||
                    (typeof item.value === 'object' && item.value)
                ){
                    invalid = false
                }else{
                    invalid = true
                }
            }
            // @INFO Correr los validadores
            if(!invalid && item.validators?.length && item.value){
                let responseIsValid = true
                for(let i=0; i<item.validators.length ;i++){
                    if(!useFormValidatorValidators.isValid([...fields] ,item.value, item.validators[i])){
                        responseIsValid = false
                        break
                    }
                }
                if(!responseIsValid){
                    invalid = true
                }
            }
            // @INFO Agregar el item al array
            item.invalid = invalid
            accum.push(item)
            return accum
        },[])
        updateFields([...newFields])
    }

    const handleUpdateValues = () => {
        const newValues = getValuesInternal()
        updateValues(newValues)
    }

    const handleUpdateErrors = () => {
        const newErrors = fields.reduce((accum: any, item) => {
            if(item.required && item.invalid){
                accum[item.name] = item.messageError
            }
            return accum
        },{})
        updateErrors(newErrors)
    }

    const handleChange = (e: any) => {
        if(e?.target?.name){
            const fieldsName: string = e.target.name
            const idx = fields.findIndex((item) => item.name === fieldsName)
            if(idx>=0){
                const aux = [...fields]
                if(e.target.type === 'file' && e.target.files?.length){
                    aux[idx].value = e.target.files[0] ? e.target.files[0] : aux[idx].value
                }else if(e.target.type === 'checkbox'){
                    aux[idx].value = e.target.checked
                }else{
                    aux[idx].value = e.target.value
                }
                if(aux[idx].required){
                    let invalid = false
                    // @INFO Verificar si es requerido
                    if(
                        (typeof aux[idx].value === 'string' && aux[idx].value?.length) ||
                        (typeof aux[idx].value === 'number') ||
                        (typeof aux[idx].value === 'object' && aux[idx].value)
                    ){
                        invalid = false
                    }else{
                        invalid = true
                    }
                    // @INFO Correr los validadores
                    if(!invalid && aux[idx].validators?.length && aux[idx].value){
                        let responseIsValid = true
                        // @ts-ignore
                        for(let i=0; i<aux[idx].validators.length ;i++){
                            // @ts-ignore
                            if(!useFormValidatorValidators.isValid([...fields] ,aux[idx].value, aux[idx].validators[i])){
                                responseIsValid = false
                                break
                            }
                        }
                        if(!responseIsValid){
                            invalid = true
                        }
                    }
                    // @INFO Guardar el valor
                    aux[idx].invalid = invalid
                }
                updateFields([...aux])
            }
        }
    }

    const getValuesInternal = () => {
        const response = fields.reduce((accum: any, item) => {
            accum[item.name] = item.value
            return accum
        },{})
        return response
    }

    /**
     * @INFO Convertir todos los campos a un objeto con todas las propiedades de los nombres (algo.algo.algo)
     * @returns 
     */
    const getValues = () => {
        const splitLevel: string[][] = []
        // @INFO Calcular el split level
        fields.forEach((field, idxField) => {
            let acumSplit = ''
            field.name.split('.').forEach((itemSplit, idxSplit) => {
                acumSplit += '.' + itemSplit
                if(!splitLevel[idxSplit] || !splitLevel[idxSplit].includes(acumSplit)){
                    if(splitLevel[idxSplit]){
                        splitLevel[idxSplit].push(acumSplit)
                    }else{
                        splitLevel[idxSplit] = [acumSplit]
                    }
                }
            })
        })
        
        // @INFO Convertir el split level en un objeto
        let object: any = {}
        splitLevel.forEach((itemSplit) => {
            itemSplit.forEach((itemSplit2) => {
                object = updateObj(itemSplit2, object, {})
            })
        })
        
        // @INFO Rellenar la información del objeto con la de los campos
        fields.forEach((field) => {
            object = updateObj(field.name, object, field.value)
        })
        
        return {...object}
    }

    /**
     * @INFO Actualizar el valor de un objeto mediante una key (algo.algo.algo)
     * @param path 
     * @param object 
     * @param value 
     * @returns 
     */
    const updateObj = (path: string, object: any, value: any) => {
        var valuePath = path.split('.').filter((item) => item.length),
            last = valuePath.pop(),
            temp = object;
    
        for (let i = 0; i < valuePath.length; i++) {
            temp = temp[valuePath[i]];
        }
        // @ts-ignore
        temp[last] = value;
        return object
    }

    const isInvalid = () => {
        let invalid = false
        invalid = fields.reduce((accum: boolean, item) => {
            if(!accum && item.required && item.invalid){
                accum = true
            }
            return accum
        }, invalid)
        return invalid
    }

    const setValues = (data: any) => {
        if(data && Object.keys(data)?.length){
            const aux = [...fields]
            aux.reduce((accum: IFieldFormValidator[], itemField) => {
                const newData = getDataRoot(itemField.loadKey ? itemField.loadKey : itemField.name, data)
                if(newData){
                    handleChange({target: {name: itemField.name, value: newData}})
                }
                return accum
            },[])
            setTimeout(() => {
                updateTriggerValidate({})
            },10)
        }
    }

    /**
     * @INFO Cambiar el valor de un campo
     * @param field 
     * @param value 
     */
    const setValue = (field: string, value: any) => {
        handleChange({target: {name: field, value}})
        setTimeout(() => {
            updateTriggerValidate({})
        },10)
    }

    // @INFO Encontrar la información según la ruta
    const getDataRoot = (root: string, data: any) => {
        const roots = root.split('.')
        const value = roots.reduce((acum: any, item: string) => {
            if(acum === undefined || acum === null) return undefined
            acum = acum[item]
            return acum
        }, {...data})
        return value
    }

    const resetForm = () => {
        const aux = [...fields]
        const newFields = aux.reduce((accum: IFieldFormValidator[], item) => {
            accum.push({
                ...item,
                value: ''
            })
            return accum
        },[])
        updateFields([...newFields])
        setTimeout(() => {
            updateTriggerValidate({})
        },10)
    }

    /**
     * @INFO Agregar un nuevo campo al formulario
     * @param _field 
     */
    const addFields = (_fields: IFieldFormValidator[]) => {
        if(!_fields?.length) return
        const aux = [...fields]
        _fields.forEach((_field) => {
            const element = aux.find((item) => item.name === _field.name)
            if(
                !element || 
                (typeof _field.value === 'object' && JSON.stringify(element.value) !== JSON.stringify(_field.value)) ||
                Array.isArray(_field.value) && Array.isArray(element.value) && _field.value.length !== element.value.length
            ){
                aux.push({..._field})
            }
        })
        updateFields(_fields =>  [...aux])
        setTimeout(() => {
            updateTriggerValidate({})
        },10)
    }

    /**
     * @INFO Eliminar un campo del formulario
     * @param _name 
     */
    const removeFields = (_names: string[]) => {
        if(!_names?.length) return
        const aux = [...fields]
        _names.forEach((_name) => {
            const idxField = aux.findIndex((item) => item.name === _name)
            if(idxField >= 0) {
                aux.splice(idxField,1)
            }
        })
        updateFields(_fields => [...aux])
        setTimeout(() => {
            updateTriggerValidate({})
        },10)
    }

    /**
     * @INFO Revisar si el formulario tiene un campo
     * @param _field 
     * @returns 
     */
    const hasField = (_field: string) => {
        if(fields.find((_f) => _f.name === _field)){
            return true
        }
        return false
    }

    return {
        values,
        errors,
        handleChange,
        getValues,
        isInvalid,
        setValues,
        setValue,
        resetForm,
        addFields,
        removeFields,
        hasField
    }
}

export default useValidator;