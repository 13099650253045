// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react';
// @end dependencies

// @import components
import HeaderImageCard from 'components/commons/HeaderImageCard/HeaderImageCard';
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import Cards from 'react-credit-cards';
import { MdDone } from 'react-icons/md';
import { AiOutlineClose, AiOutlineDownload, AiOutlineHome } from 'react-icons/ai';
import TextComponent from 'components/commons/Text/TextComponent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
// @end components

// @import types
import { IStoreApp } from 'redux/reducers/index'
// @end types

// @import services
// @end services

// @import hooks
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// @end hooks

// @import actions
// @end actions

// @import utils
import { HOME_ROUTE } from 'config/globals';
// @end utils

// @import assets
import BackgroundHeaderPlans from 'assets/images/plans/background_header_plans.png'
// @end assets

// @import styles
import './PaymentStatusPage.scss'
import "react-credit-cards/es/styles-compiled.css"
import { PaymentGatewayResponse } from 'types/license/license.types';
import { useTranslation } from 'react-i18next';
// @end styles

interface PaymentStatusPageProps {
    className?: string
    style?: CSSProperties
    id?: string
}

const PaymentStatusPage: React.FC<PaymentStatusPageProps> = (props) => {

    const params = useParams<{status: 'failed' | 'success'}>()
    const user = useSelector((store: IStoreApp) => store.auth?.user)
    const location = useLocation<PaymentGatewayResponse>()
    const history = useHistory()
    const [paymentGatewayResponse, setPaymentGatewayResponse] = useState<PaymentGatewayResponse | null>(null)
    const { t } = useTranslation()

    useEffect(() => {
        if (location.state) {
            setPaymentGatewayResponse(location.state)
            window.history.replaceState({}, "")
        } else {
            setPaymentGatewayResponse(null)
            history.push(HOME_ROUTE)
        }
    }, [])

    const title = {
        failed: 'No hemos podido comprobar tu transacción',
        success: '¡Gracias por unirte a nuestra plataforma!'
    }

    const paymentStatus = {
        failed: '¡Tu pago ha fallado!',
        success: '¡Tu pago ha sido exitoso!'
    }

    const iconStatus = {
        failed: (<AiOutlineClose/>),
        success: (<MdDone/>),
    }

    return(
        <AppLayout
            headerTitle='Payment status'
        >
            <div
                className={`payment_status_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
                id={props.id}
            >
                <HeaderImageCard
                    image={BackgroundHeaderPlans}
                    classNameChildren='payment_status_page--container'
                    title={title[params.status]}
                >
                    <div className="payment_status_page--status-card">
                        <div className={`payment_status--status-icon ${params.status}`}>
                            {iconStatus[params.status]}
                        </div>
                        <TextComponent type='subtitle' >
                            {paymentStatus[params.status]}
                        </TextComponent>
                        <div className={`payment_status--status-line ${params.status}`}></div>
                    </div>
                    <div className="payment_status_page--content">
                        <div>
                            <Cards
                                name={`${user?.firstName} ${user?.lastName}`}
                                number="**** **** **** ****"
                                expiry="10/20"
                                cvc="000"
                            />
                            {params.status === 'success' ? (
                                <ButtonComponent variant='primary' >
                                    <ButtonComponent.Icon>
                                        <AiOutlineDownload/>
                                    </ButtonComponent.Icon>
                                    Descargar factura
                                </ButtonComponent>
                            ) : null}
                        </div>
                        <div>
                            <TextComponent>
                                <strong>Estado: </strong>{t(`payment.status.${paymentGatewayResponse?.status}`)}
                            </TextComponent>
                            {params.status === 'success' && (
                                <>
                                    <TextComponent>
                                        <strong>Finaliza el: </strong>{paymentGatewayResponse?.period_end_date}
                                    </TextComponent>
                                    <TextComponent>
                                        <strong>Próximo pago: </strong>{paymentGatewayResponse?.charge_date}
                                    </TextComponent>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="payment_status_page--actions">
                        <Link to={HOME_ROUTE} >
                            <ButtonComponent
                                variant='accent'
                                className='payment_status_page--actions-home_button'
                            >
                                <ButtonComponent.Icon>
                                    <AiOutlineHome/>
                                </ButtonComponent.Icon>
                                Volver a inicio
                            </ButtonComponent>
                        </Link>
                    </div>
                </HeaderImageCard>
            </div>
        </AppLayout>
    );
}

export default PaymentStatusPage;