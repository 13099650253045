// @import dependencies
import React, { CSSProperties, useState, useEffect, ChangeEvent } from 'react';
// @end dependencies

// @import components
import HeaderImageCard from 'components/commons/HeaderImageCard/HeaderImageCard';
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import Cards from 'react-credit-cards';
import TextComponent from 'components/commons/Text/TextComponent';
import InputComponent from 'components/commons/Input/InputComponent';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import { BiShoppingBag } from 'react-icons/bi';
// @end components

// @import types
import * as LicenseTypes from 'types/license/license.types'
// @end types

// @import services
// @end services

// @import hooks
import { useHistory, useLocation } from 'react-router-dom';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
import BackgroundHeaderPlans from 'assets/images/plans/background_header_plans.png'
import OpenpayIcon from 'assets/images/openpay/Logo.jpg'
// @end assets

// @import styles
import './PaymentPage.scss'
import "react-credit-cards/es/styles-compiled.css"
import { HOME_ROUTE } from 'config/globals';
import CurrencyService from 'types/currency/currencyService';
import useValidator from 'hooks/useFormValidator/useFormValidator';
import { useTranslation } from 'react-i18next';
import { ParamsLicenseCreate, SUBSCRIPTION_STATUS } from 'types/license/license.types';
import AppService from 'services/app/appService';
import { useSelector } from 'react-redux';
import { IStoreApp } from 'redux/reducers/index';
import LicenseService from 'services/license/licenseService';
import ScreenNotification from 'services/screenNotification/screenNotification';
import PaymentMethod from 'components/commons/PaymentMethod/PaymentMethod';
import { Payment } from 'types/license/license.types';
// @end styles

interface PaymentPageProps {
    className?: string
    style?: CSSProperties
    id?: string
}

const TAX_IVA = 19

const PaymentPage: React.FC<PaymentPageProps> = (props) => {

    const { t } = useTranslation()
    const [loading, setLoading] = useState<boolean>(false)
    const [information, setInformation] = useState<LicenseTypes.LicenseStateParams | undefined>(undefined)
    const [payments, setPayments] = useState<Payment[]>([])
    const [selectedMethod, setSelectedMethod] = useState<Payment | undefined>(undefined)
    const userPublicKey = useSelector((store: IStoreApp) => store.auth?.user?.publicKeyEncrypt)
    const user = useSelector((store: IStoreApp) => store.auth?.user)
    const history = useHistory()
    const location = useLocation<LicenseTypes.LicenseStateParams>()
    const form = useValidator([
        { name: 'cardNumber', required: true, messageError: t('obligatory_field'), validators: [
            { type: 'length', min: 15, max: 16 }
        ] },
        { name: 'expYear', required: true, messageError: t('obligatory_field'), validators: [
            { type: 'length', min: 2, max: 2 },
        ] },
        { name: 'expMonth', required: true, messageError: t('obligatory_field') , validators: [
            { type: 'length', min:2, max: 2 },
            { type: 'range', min: 1, max: 12 },
        ]},
        { name: 'cvc', required: true, messageError: t('obligatory_field'), validators: [
            { type: 'length', min:3, max: 4 }
        ] },
        { name: 'name', required: true, messageError: t('obligatory_field') },
        { name: 'city', required: true, value: user?.city, messageError: t('obligatory_field') },
        { name: 'address', required: true, messageError: t('obligatory_field') },
        { name: 'phoneNumber', required: true, value: user?.phoneNumber, messageError: t('obligatory_field') },
        { name: 'email', required: true, value: user?.email , messageError: t('obligatory_field'), validators: [
            { type: 'email' }
        ] },
    ])

    const currencyService = new CurrencyService()
    const appService = new AppService()
    const licenseService = new LicenseService()
    const screenNotification = new ScreenNotification()

    useEffect(() => {
        if (location.state) {
            setInformation(location.state)
        } else {
            history.push(HOME_ROUTE)
        }
        getPayments()
    }, [])

    const getPayments = async () => {
        const response = await licenseService.getPayments()
        if (response?.length) {
            setPayments(response)
        } else {
            setPayments([])
        }
    }

    const handleChangeNumberCard = (e: ChangeEvent<HTMLInputElement>) => {
        const value: string = e.target.value
        const newValue = value.replace(/ /gi, '')
        form.setValue('cardNumber', newValue)
    }

    const handleClickContinue = async () => {
        if (!information || !userPublicKey || !selectedMethod) return

        let response: LicenseTypes.ResponseLicenseCreate | undefined

        setLoading(true)

        if (selectedMethod?.id === 'new') {
            const formValue = form.getValues()
            const paramsLicense: ParamsLicenseCreate = {
               license: {...formValue},
            }
            paramsLicense.license.plan = information.plan
            paramsLicense.license.labs = information.labs
            paramsLicense.license.amount = Number(information.amount)
            paramsLicense.license.cardNumber =
                appService.encryptData(paramsLicense.license.cardNumber, userPublicKey)
            paramsLicense.license.cvc =
                appService.encryptData(paramsLicense.license.cvc, userPublicKey)
            paramsLicense.license.expMonth =
                appService.encryptData(paramsLicense.license.expMonth, userPublicKey)
            paramsLicense.license.expYear =
                appService.encryptData(paramsLicense.license.expYear, userPublicKey)
            // @ts-ignore
            delete paramsLicense.license.email
            response = await licenseService.create(paramsLicense)
        } else {
            response = await licenseService.newLicenseExistingCard({
                license: {
                    plan: information.plan,
                    amount: information.amount,
                    labs: information.labs,
                    cardNumber: selectedMethod.card_number ?? '',
                    tokenCard: selectedMethod.id ?? '',
                }
            })
        }

        if (response?.paymentGatewayResponse) {
            if (response?.paymentGatewayResponse?.status === SUBSCRIPTION_STATUS.ACTIVE) {
                history.push('/payment/success', response?.paymentGatewayResponse)
            } else {
                history.push('/payment/failed', response?.paymentGatewayResponse)
            }
        } else {
            screenNotification.showFailedTimer({ text: 'Error creando la licencia' })
        }
        setLoading(false)
    }

    const handleChangeMethod = (payment: Payment) => {
        setSelectedMethod(payment)
    }

    return(
        <AppLayout
            headerTitle='Payment'
        >
            <div
                className={`payment_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
                id={props.id}
            >
                <HeaderImageCard
                    image={BackgroundHeaderPlans}
                    classNameChildren='payment_page--container'
                    title='Adquiere tu plan y danos a conocer tus datos'
                >
                    <div className='payment_page--card'>
                        <Cards
                            name={form.values['name'] ? form.values['name'] : ''}
                            number={form.values['cardNumber'] ? form.values['cardNumber'] : ''}
                            expiry={`${form.values['expMonth'] ? form.values['expMonth'] : ''}/${form.values['expYear'] ? form.values['expYear'] : ''}`}
                            cvc={form.values['cvc'] ? form.values['cvc'] : ''}
                        />
                        <div>
                            <TextComponent
                                type='h3'
                            >
                                Completa tu pago
                            </TextComponent>
                            <div className="payment_page--title-underline"></div>
                            <PaymentMethod
                                payments={payments}
                                selected={selectedMethod}
                                onChange={handleChangeMethod}
                            />
                        </div>
                    </div>
                    {selectedMethod?.id === 'new' ?
                        <div className="payment_page--form">
                            <InputComponent
                                background='background-input'
                                placeholder='Numero de tarjeta'
                                style={{ gridColumn: '1/3' }}
                                type='number'
                                onChange={handleChangeNumberCard}
                                name='cardNumber'
                                value={form.values['cardNumber']}
                                invalid={form.errors['cardNumber']}
                                invalidText={form.errors['cardNumber']}
                            />
                            <InputComponent
                                background='background-input'
                                placeholder='Año de vencimiento (Ej: 21)'
                                type='number'
                                onChange={form.handleChange}
                                name='expYear'
                                value={form.values['expYear']}
                                invalid={form.errors['expYear']}
                                invalidText={form.errors['expYear']}
                            />
                            <InputComponent
                                background='background-input'
                                placeholder='Mes de vencimiento (Ej: 10)'
                                type='number'
                                onChange={form.handleChange}
                                name='expMonth'
                                value={form.values['expMonth']}
                                invalid={form.errors['expMonth']}
                                invalidText={form.errors['expMonth']}
                            />
                            <InputComponent
                                background='background-input'
                                placeholder='CVC'
                                type='number'
                                onChange={form.handleChange}
                                name='cvc'
                                value={form.values['cvc']}
                                invalid={form.errors['cvc']}
                                invalidText={form.errors['cvc']}
                            />
                            <InputComponent
                                background='background-input'
                                placeholder='Nombre en la tarjeta'
                                style={{ gridColumn: '2/4' }}
                                onChange={form.handleChange}
                                name='name'
                                value={form.values['name']}
                                invalid={form.errors['name']}
                                invalidText={form.errors['name']}
                            />
                            <InputComponent
                                background='background-input'
                                placeholder='Teléfono'
                                onChange={form.handleChange}
                                name='phoneNumber'
                                value={form.values['phoneNumber']}
                                invalid={form.errors['phoneNumber']}
                                invalidText={form.errors['phoneNumber']}
                            />
                            <InputComponent
                                background='background-input'
                                placeholder='Correo electrónico'
                                style={{ gridColumn: '1/3' }}
                                onChange={form.handleChange}
                                name='email'
                                value={form.values['email']}
                                invalid={form.errors['email']}
                                invalidText={form.errors['email']}
                                disabled
                            />
                            <InputComponent
                                background='background-input'
                                placeholder='Ciudad'
                                style={{ gridColumn: '3/5' }}
                                onChange={form.handleChange}
                                name='city'
                                value={form.values['city']}
                                invalid={form.errors['city']}
                                invalidText={form.errors['city']}
                            />
                            <InputComponent
                                background='background-input'
                                placeholder='Dirección'
                                style={{ gridColumn: '1/3' }}
                                onChange={form.handleChange}
                                name='address'
                                value={form.values['address']}
                                invalid={form.errors['address']}
                                invalidText={form.errors['address']}
                            />
                        </div>
                    : null}
                    <div className="payment_page--actions">
                        <ButtonComponent
                            variant='accent'
                            className='payment_page--actions-buy_button'
                            disabled={!selectedMethod || (selectedMethod?.id === 'new' && form.isInvalid())}
                            loading={loading}
                            onClick={handleClickContinue}
                        >
                            <ButtonComponent.Icon>
                                <BiShoppingBag/>
                            </ButtonComponent.Icon>
                            Continuar con la compra
                        </ButtonComponent>
                    </div>
                </HeaderImageCard>
                <div className='payment_page--information-cards'>
                    <div className="payment_page--valor-card">
                        <div>
                            <div>
                                <TextComponent style={{ fontWeight: 'bold' }}>
                                    Subtotal
                                </TextComponent>
                                <TextComponent style={{ color: 'var(--text2)' }}>
                                    Valor de la suscripción sin impuesto
                                </TextComponent>
                            </div>
                            <TextComponent
                                type='subtitle'
                                className='payment_page--valor-number'
                            >
                                ${currencyService.getSubtotal(information?.amount ?? 0, TAX_IVA)}
                            </TextComponent>
                        </div>
                        <div>
                            <div>
                                <TextComponent style={{ fontWeight: 'bold' }}>
                                    IVA
                                </TextComponent>
                                <TextComponent style={{ color: 'var(--text2)' }} >
                                    Valor impuesto (19%)
                                </TextComponent>
                            </div>
                            <TextComponent
                                type='subtitle'
                                className='payment_page--valor-number'
                            >
                                ${currencyService.getTaxFromValue(information?.amount ?? 0, TAX_IVA)}
                            </TextComponent>
                        </div>
                        <div>
                            <TextComponent style={{ fontWeight: 'bold', color: 'var(--secondary)' }}>
                                Valor a pagar
                            </TextComponent>
                            <TextComponent
                                type='subtitle'
                                className='payment_page--valor-number'
                                style={{ color: 'var(--secondary)' }}
                            >
                                ${information?.amount}
                            </TextComponent>
                        </div>
                    </div>
                    <div className="payment_page--valor-card">
                        <img src={OpenpayIcon} alt="" />
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

export default PaymentPage;