import React, { CSSProperties, useState, useEffect } from 'react'
import InputComponent, { ITypesInputComponent } from 'components/commons/Input/InputComponent'
import LabelComponent from 'components/commons/LabelComponent/LabelComponent'

import './FieldEditorComponent.scss'
import TextComponent from 'components/commons/Text/TextComponent'
import moment from 'moment'
import { useSelector } from 'react-redux';
import { IStoreApp } from 'redux/reducers/index';
import { useTranslation } from 'react-i18next';

interface IFieldEditorComponentProps {
    label: string
    keyApi?: string
    fontSize?: string
    defaultValue?: string
    className?: string
    style?: CSSProperties
    type?: ITypesInputComponent
    isDate?: boolean
    onSave?: (value: any) => any
}

const FieldEditorComponent: React.FC<IFieldEditorComponentProps> = (props) => {

    const [value, updateValue] = useState<string>('')
    const [editState, updateEditState] = useState<boolean>(false)
    const theme = useSelector((store: IStoreApp) => store.app.theme)
    const {t} = useTranslation()

    useEffect(() => {
        loadDefaultValue()
    },[props.defaultValue])

    /**
     * @INFO Cargar el valor por defecto
     */
    const loadDefaultValue = () => {
        if(props.defaultValue){
            updateValue(props.defaultValue)
        }else{
            updateValue('')
        }
    }

    const handleChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateValue(e.target.value)
    }

    const handleClickEdit = () => {
        updateEditState(true)
    }

    const handleClickCancelEdit = () => {
        updateEditState(false)
        loadDefaultValue()
    }

    const handleClickSave = () => {
        if(props.onSave){
            if(props.keyApi){
                props.onSave({[props.keyApi]: value})
            }else{
                props.onSave(value)
            }
            updateEditState(false)
        }
    }

    return(
        <div
            className={`field_editor_component-layout ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <LabelComponent
                style={{fontSize: props.fontSize}}
                className='title-field-editor'
            >
                {props.label}
            </LabelComponent>
            {editState ?
                <InputComponent
                    value={value}
                    onChange={handleChangeField}
                    type={props.type}
                    background={theme === 'light' ? undefined : 'background-login'}
                    className='input-field-editor'
                />
            :
                <TextComponent
                    className='text-field-editor'
                >
                    {props.isDate ? moment(value).format('DD MMM YYYY') : value}
                </TextComponent>
            }
            {editState ?
                <div
                    className='container-cancel-save-text'
                >
                    <TextComponent
                        onClick={handleClickSave}
                        className='edit-button-text'
                    >
                        {t('profile.save')}
                    </TextComponent>
                    <TextComponent
                        onClick={handleClickCancelEdit}
                        className='edit-button-text'
                    >
                        {t('profile.cancel')}
                    </TextComponent>
                </div>
            :
                <TextComponent
                    onClick={handleClickEdit}
                    className='edit-button-text'
                >
                    {t('profile.edit')}
                </TextComponent>
            }
        </div>
    );
}

export default FieldEditorComponent;