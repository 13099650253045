import Sidebar from 'components/modules/Sidebar/Sidebar';
import React, { CSSProperties, useState, useEffect } from 'react';
import Header from 'components/modules/Header/Header';
import { useLaboratory } from 'hooks/useLaboratory/useLaboratory';
import './AppLayout.scss'
import AppService from 'services/app/appService';
import { useSelector } from 'react-redux';
import { IStoreApp } from 'redux/reducers/index';
import CustomHeaderModule from 'components/modules/CustomHeaderModule/CustomHeaderModule';
import { useDashboard } from 'hooks/useDashboard/useDashboard';
import { useHistory } from 'react-router-dom';
import RouterService from 'services/router/routerService';
import { GAME_CONFIG } from 'config/globals';

export interface AppLayoutProps {
    children?: any
    headerTitle?: string
    history?: any
    className?: string
    style?: CSSProperties
    fetchMyLabs?: boolean
    fetchFavorites?: boolean
    fetchLabCategories?: boolean
}
 
const AppLayout: React.SFC<AppLayoutProps> = (props) => {

    const routerService = new RouterService()
    const [openSidebar, updateOpenSidebar] = useState<boolean>(false);
    const app = useSelector((store: IStoreApp) => store?.app);
    const hasData = useSelector((store: IStoreApp) => store.auth.hasData)
    const dashboard = useDashboard();
    const history = useHistory()
    const hookLabs = useLaboratory({ 
        fetchLabs: props.fetchMyLabs, 
        fetchLabsCategories: props.fetchLabCategories,
        fetchFavorites: props.fetchFavorites !== undefined ? props.fetchFavorites : true   // Por defecto esta en true por que el header lo necesita
    });

    const appService = new AppService();

    const isHeaderEnabled = app?.systemConfig?.header?.defaultEnabled !== undefined
                            ? app?.systemConfig?.header?.defaultEnabled
                            : dashboard?.reservation?.publicSettings?.header?.enabled

    useEffect(() => {
        if (hasData && routerService.getGameToken()) {
            const queryParams = new URLSearchParams(window.location.search)
            if (GAME_CONFIG['token_name'] && queryParams.has(GAME_CONFIG['token_name'])) {
                queryParams.delete(GAME_CONFIG['token_name'])
                history.replace({
                    search: queryParams.toString(),
                })
            }
        }
    }, [hasData])

    return (  
        <div 
            className={`app_layout-layout ${openSidebar ? 'open-sidebar' : ''} ${props.className ? props.className : ''}`}
            style={props.style}
        >
            <div style={{
                width: appService.validateCustomConfigModule('SIDEBAR') ? undefined : '0px',
                padding: appService.validateCustomConfigModule('SIDEBAR') ? undefined : '0px'
            }} >
                {appService.validateCustomConfigModule('SIDEBAR') ?
                    <Sidebar
                        onChangeOpen={(state) => updateOpenSidebar(state)}
                        history={props.history}
                    />
                :null}
            </div>
            <div>
                {appService.validateCustomConfigModule('HEADER') ?
                    app?.systemConfig?.header?.type === 'custom' ?
                    isHeaderEnabled ? 
                            <CustomHeaderModule
                            />
                        :null
                    :
                    <Header
                        title={props.headerTitle}
                        history={props.history}
                    />
                :null}
                <main
                    className='main-app-layout'
                >
                    {props.children}
                </main>
            </div>
        </div>
    );
}
 
export default AppLayout;