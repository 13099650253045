// @import dependencies
import React, { CSSProperties, useState } from 'react'
// @end dependencies

// @import components
import { Modal } from 'react-bootstrap'
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import InputComponent from 'components/commons/Input/InputComponent';
// @end components

// @import types
// @end types

// @import services
import NotebookService from 'services/notebook/notebookService';
// @end services

// @import hooks
import useValidator from 'hooks/useFormValidator/useFormValidator';
import { useTranslation } from 'react-i18next';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './ModalNewNotebook.scss'
// @end styles

interface IModalNewNotebookProps {
    open: boolean
    labId: string
    className?: string
    style?: CSSProperties
    id?: string
    onClose?: (status?: boolean) => any
}

const ModalNewNotebook: React.FC<IModalNewNotebookProps> = (props) => {

    const [loading, updateLoading] = useState<boolean>(false)

    const {t} = useTranslation()

    const form = useValidator([
        { name: 'name', required: true, messageError: t('obligatory_field') }
    ])

    // @INFO Servicios
    const notebookService = new NotebookService()

    const handleCloseModal = (status?: boolean) => {
        if(props.onClose){
            props.onClose(status)
        }
    }

    const handleClickCreateNotebook = async () => {
        updateLoading(true)
        const response = await notebookService.create({
            notebook: {
                lab: props.labId,
                name: form.values.name
            }
        })
        if(response){
            handleCloseModal(true)
        }
        updateLoading(false)
    }

    return(
        <Modal
            onHide={() => handleCloseModal()}
            show={props.open}
        >
            <Modal.Header
                closeButton
            >
                <Modal.Title>
                    Nuevo notebook
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    className={`modal_new_notebook-layout ${props.className ? props.className : ''}`}
                    style={props.style}
                    id={props.id}
                >
                    <InputComponent
                        name='name'
                        label='Nombre'
                        placeholder='Nombre'
                        onChange={form.handleChange}
                        value={form.values?.name}
                        invalid={form.errors?.name ? true : false}
                        invalidText={form.errors?.name}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonComponent
                    onClick={() => handleCloseModal()}
                >
                    Cancelar
                </ButtonComponent>
                <ButtonComponent
                    variant='primary'
                    disabled={form.isInvalid()}
                    onClick={handleClickCreateNotebook}
                    loading={loading}
                >
                    Crear
                </ButtonComponent>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalNewNotebook;