import React, { CSSProperties } from 'react'
import ProgressBarComponent from 'components/commons/ProgressBar/ProgressBarComponent';

import './CardCategoryHome.scss'
import TextComponent from 'components/commons/Text/TextComponent';
import { useTranslation } from 'react-i18next';


interface ICardCategoryHomeProps {
    title: string
    description: string
    myProgress: number
    totalProgress: number
    image: any
    className?: string
    style?: CSSProperties
    sliderItem?: boolean
    onClick?: () => any
}

const CardCategoryHome: React.FC<ICardCategoryHomeProps> = (props) => {

    const {t} = useTranslation()

    return(
        <div className={props.sliderItem ? `slider-item-card-laboratory-category` : ''}>
            <div
                className={`card_category_home-layout ${props.className ? props.className : ''}`}
                style={props.style}
                onClick={props.onClick}
            >
                <div className="container-image-card">
                    <img src={props.image} alt="" />
                </div>
                <p>{props.title}</p>
                <p>{props.description}</p>
                <ProgressBarComponent
                    progress={props.myProgress}
                    height={'8px'}
                    color={'linear-gradient(90deg, #0C9CF2 50%, #00FAEC 100%)'}
                    showProgress
                    asInline
                />
                <div className="total-progress-container">
                    <TextComponent
                        className='total-content-text'
                    >
                        {t('home.categories.total_content')}
                    </TextComponent>
                    <ProgressBarComponent
                        progress={props.totalProgress}
                        height={'8px'}
                        color={'linear-gradient(90deg, #0C9CF2 50%, #00FAEC 100%)'}
                        showProgress
                        asInline
                    />
                </div>
            </div>
        </div>
    );
}

export default CardCategoryHome;