// @import dependencies
import React, { CSSProperties, useState, useEffect } from 'react';
// @end dependencies

// @import components
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import TableComponent, { IKeyTable } from 'components/commons/TableComponent/TableComponent';
import TextComponent from 'components/commons/Text/TextComponent';
import HeaderImageCard from 'components/commons/HeaderImageCard/HeaderImageCard';
import ButtonComponent from 'components/commons/Button/ButtonComponent';
import { BiShoppingBag } from 'react-icons/bi';
// @end components

// @import types
import * as LaboratoryTypes from 'types/laboratories/laboratories.types'
import * as PlanTypes from 'types/plan/plan.types'
// @end types

// @import services
import LaboratoryService from 'services/laboratory/laboratoryService';
import PlanService from 'services/plan/planService';
// @end services

// @import hooks
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
// @end hooks

// @import actions
// @end actions

// @import utils
import { HOME_ROUTE } from 'config/globals';
// @end utils

// @import assets
import BackgroundHeaderPlans from 'assets/images/plans/background_header_plans.png'
// @end assets

// @import styles
import './CustomPlanPage.scss'
import { LicenseStateParams } from 'types/license/license.types';
// @end styles

interface CustomPlanPageProps {
    className?: string
    style?: CSSProperties
    id?: string
}

interface LaboratoryPlan extends LaboratoryTypes.ILaboratory {
    checked: boolean
}

const CustomPlanPage: React.FC<CustomPlanPageProps> = (props) => {

    const [laboratories, setLaboratories] = useState<LaboratoryPlan[]>([])
    const [selectedLabs, setSelectedLabs] = useState<LaboratoryPlan[]>([])
    const [plan, setPlan] = useState<PlanTypes.Plan | undefined>(undefined)
    const { planId } = useParams<{planId: string}>()
    const history = useHistory()

    const laboratoryService = new LaboratoryService()
    const planService = new PlanService()

    const tableKeys: IKeyTable[] = [
        { key: 'checked', name: ' ', type: 'checkbox'},
        { key: 'thumbnail', name: 'Miniatura', type: 'image'},
        { key: 'name', name: 'Nombre' }, 
        { key: 'description', name: 'Descripción' },
        { key: 'price', name: 'Precio (USD)' },
    ]

    useEffect(() => {
        getPlan()
        getLaboratories()
    }, [])

    useEffect(() => {
        if (laboratories?.length) {
            setSelectedLabs(laboratories.filter((lab) => lab.checked))
        } else {
            setSelectedLabs([])
        }
    }, [laboratories])

    const getLaboratories = async () => {
        const labs: LaboratoryTypes.ILaboratory[] = await laboratoryService.list({ limit: 30, page: 1 })
        const newLabs: LaboratoryPlan[] = labs?.map((lab) => ({ ...lab, checked: false }))
        setLaboratories(newLabs)
    }

    const getPlan = async () => {
        const planResponse = await planService.getById({ id: planId })
        if (planResponse) {
            setPlan(planResponse)
        } else {
            history.push(HOME_ROUTE)
        }
    }

    const handleClickCheckbox = (laboratory: LaboratoryPlan) => {
        const idx = laboratories?.findIndex((lab) => lab.id === laboratory.id)
        const newLabs = [...laboratories]
        newLabs[idx].checked = !newLabs[idx].checked
        setLaboratories(newLabs)
    }

    const getSubtotal = () => {
        let total = 0
        if (selectedLabs?.length) {
            total = selectedLabs.reduce((sum, item) => sum + Number(item.price), 0)
        }
        return total
    }

    const handleClickPay = () => {
        const params: LicenseStateParams = {
            plan: planId,
            amount: getSubtotal(),
            labs: selectedLabs.map((lab) => lab.id as string)
        }
        history.push('/payment', params)
    }

    return(
        <AppLayout
            headerTitle='Custom plan'
        >
            <div
                className={`custom_plan_page-layout ${props.className ? props.className : ''}`}
                style={props.style}
                id={props.id}
            >
                <HeaderImageCard
                    title="Arma tu propio plan de laboratorios"
                    image={BackgroundHeaderPlans}
                >
                    <div className="custom_plan--information">
                        <div>
                            <div className="custom_plan--plan">
                                <TextComponent className='custom_plan--plan-title'>
                                    Plan:
                                </TextComponent>
                                <TextComponent>
                                    {plan?.name}
                                </TextComponent>
                            </div>
                            <TextComponent
                                type='subtitle'
                            >
                                Selecciona los laboratorios que deseas acceder
                            </TextComponent>
                            <TextComponent
                                type='caption'
                            >
                                Laboratorios seleccionados: {selectedLabs?.length}
                            </TextComponent>
                        </div>
                        <div>
                            <TextComponent
                                type='subtitle'
                            >
                                ${getSubtotal()}/mes
                            </TextComponent>
                            <ButtonComponent
                                variant='accent'
                                disabled={!getSubtotal()}
                                onClick={handleClickPay}
                            >
                                <ButtonComponent.Icon>
                                    <BiShoppingBag/>
                                </ButtonComponent.Icon>
                                Comprar
                            </ButtonComponent>
                        </div>
                    </div>
                    <div className="custom_plan--table">
                        <TableComponent
                            keys={tableKeys}
                            rows={laboratories}
                            onClickCheckbox={handleClickCheckbox}
                        />
                    </div>
                </HeaderImageCard>
            </div>
        </AppLayout>
    );
}

export default CustomPlanPage;
