import { FC } from 'react'
import HomeCarousel from 'components/commons/HomeCarousel/HomeCarousel';
import SmartFactoryNavBar from './components/SmartFactoryNavBar/SmartFactoryNavBar';
import SmartFactoryNewExperience from './components/SmartFactoryNewExperience/SmartFactoryNewExperience';
import SmartFactoryInfoCard from './components/SmartFactoryInfoCard/SmartFactoryInfoCard';
import SmartFactoryOurClients from './components/SmartFactoryOurClients/SmartFactoryOurClients';
import SmartFactoryFooter from './components/SmartFactoryFooter/SmartFactoryFooter';
import SmartFactoryPlatforms from './components/SmartFactoryPlatforms/SmartFactoryPlatforms';
import SmartFactoryPlan from './components/SmartFactoryPlan/SmartFactoryPlan';

import Card1Img from 'assets/images/landing/customLandings/SmartFactory/card1.png'
import Card2Img from 'assets/images/landing/customLandings/SmartFactory/card2.png'
import Card3Img from 'assets/images/landing/customLandings/SmartFactory/card3.png'
import Card4Img from 'assets/images/landing/customLandings/SmartFactory/card4.png'
import BackgroundImage from 'assets/images/landing/customLandings/SmartFactory/background.png'

import './SmartFactoryPage.scss'
import { BannerLocation } from 'types/banners/banners.types';

interface SmartFactoryPageProps {

}

interface AdvantageCard{
    name: string
    img: string
    description: string
}

const ADVANTAGE_CARDS: AdvantageCard[] = [
    {
        name: 'INMERSIVO',
        description: 'En Smart Factory, realizarás una inmersión en primera persona dentro del mundo de las fábricas inteligentes.',
        img: Card1Img
    },
    {
        name: 'COLABORATIVO',
        description: 'Construye y optimiza líneas de producción, consulta el estado de tus equipos, automatiza procesos, interactúa con otras personas.',
        img: Card2Img
    },
    {
        name: 'PRODUCTIVIDAD',
        description: 'Obtén experiencia para adquirir mejores tecnologías, optimiza tus líneas de producción para hacer tu empresa más redituable.',
        img: Card3Img
    },
    {
        name: 'ENSEÑANZA',
        description: 'Un método novedoso para la capacitación en procesos industriales y la apropiación de tecnologías.',
        img: Card4Img
    },
]

const SmartFactoryPage: FC<SmartFactoryPageProps> = () => {
    return (
        <div
            className="smart_factory_page--layout"
            style={{ backgroundImage: `url(${BackgroundImage})`
        }}>
            <div id='Inicio' className="smart_factory_page--header">
                <SmartFactoryNavBar
                    className='smart_factory_page--nav-bar'
                />
                <HomeCarousel
                    classNameItem='item-banner-smart-factory'
                    className='banners-smart-factory'
                    filter={{ locations: [BannerLocation.LANDING_SMART_FACTORY] }}
                />
            </div>
            <SmartFactoryNewExperience/>
            <div id='Funcionalidades' className='smart_factory_page--advantages-text' >
                <h1>
                    Descubre nuestras ventajas
                </h1>
                <p>
                    I4.0 Smart Factory está dirigido a los sectores productivos y educativos, que buscan métodos novedosos para la capacitación en procesos industriales y la apropiación de tecnologías 4.0.
                </p>
            </div>
            <div className="smart_factory_page--cards">
                {ADVANTAGE_CARDS.map((card, idx) => (
                    <SmartFactoryInfoCard
                        title={card.name}
                        description={card.description}
                        img={card.img}
                        key={idx}
                    />
                ))}
            </div>
            <SmartFactoryOurClients className='smart_factory_page--our-clients' />
            <SmartFactoryPlatforms/>
            <SmartFactoryPlan className='smart_factory_page--plan' />
            <SmartFactoryFooter/>
        </div>
    )
}

export default SmartFactoryPage
