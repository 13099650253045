// @import dependencies
import React, { CSSProperties, useEffect, useState } from 'react'
// @end dependencies

// @import components
import { AiOutlineSearch } from 'react-icons/ai'
import TitlePageComponent from 'components/commons/TitlePage/TitlePageComponent'
import InputComponent from 'components/commons/Input/InputComponent'
import { MdNotificationsNone } from 'react-icons/md'
import { RiDownloadFill, RiMoonClearLine } from 'react-icons/ri'
import ButtonComponent from 'components/commons/Button/ButtonComponent'
import { GrFavorite } from 'react-icons/gr'
import { Link } from 'react-router-dom'
import TooltipComponent from 'components/commons/TooltipComponent/TooltipComponent'
import TimerComponent from 'components/commons/TimerComponent/TimerComponent'
import LabInProgress from 'components/commons/LabInProgress/LabInProgress'
import TextComponent from 'components/commons/Text/TextComponent'
import PopUpComponent from 'components/commons/PopUp/PopUpComponent'
import NotificationsList from 'components/modules/NotificationsList/NotificationsList';
// @end components

// @import types
import { IStoreApp } from 'redux/reducers/index';
// @end types

// @import services
// @end services

// @import hooks
import { useResizeDetector } from 'react-resize-detector';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLaboratory } from 'hooks/useLaboratory/useLaboratory';
import useMoment from 'hooks/useMoment/useMoment';
// @end hooks

// @import actions
import * as NotificationActions from 'redux/reducers/notifications/notifications.actions';
import { updateThemeAppAction } from 'redux/reducers/app/app.actions'
import * as AuthActions from 'redux/reducers/auth/auth.actions'
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './Header.scss'
import SearchLaboratories from '../SearchLaboratories/SearchLaboratories'
// @end styles

export interface HeaderProps {
    title?: string
    history?: any
    className?: string
    style?: CSSProperties
}
 
const Header: React.SFC<HeaderProps> = (props) => {

    const dispatch = useDispatch()
    const user = useSelector((store: IStoreApp) => store.auth?.user)
    const currentReservation = useSelector((store: IStoreApp) => store.laboratories.currentReservation);
    const app = useSelector((store: IStoreApp) => store.app)
    const unreadNotifications = useSelector((store: IStoreApp) => store.notifications.unread);
    const notifications = useSelector((store: IStoreApp) => store.notifications.notifications);
    const size = useResizeDetector()
    const hookLabs = useLaboratory();
    const [inProgress, updateInProgress] = useState<boolean>(false);
    const [showNotifications, updateShowNotifications] = useState<boolean>(false);
    const {moment} = useMoment();

    const {t} = useTranslation()

    useEffect(() => {
        validateReservation()
    }, [currentReservation])

    useEffect(() => {
        dispatch(NotificationActions.getNotificationsAction());
    }, [])

    useEffect(() => {
        dispatch(NotificationActions.setUnreadNotificationsAction());
    }, [notifications])

    const validateReservation = () => {
        if (currentReservation) {
            if ( moment(currentReservation.date).isBefore(new Date())) {
                updateInProgress(true);
            } else {
                updateInProgress(false);
            }
        } else {
            updateInProgress(false);
        }
    }

    const handleClickLogout = () => {
        dispatch(AuthActions.logoutAction())
    }

    const handleChangeTheme = () => {
        if(app.theme === 'light'){
            dispatch(updateThemeAppAction('dark'))
        }else if(app.theme === 'dark'){
            dispatch(updateThemeAppAction('light'))
        }
    }

    const handleClickDownloadApp = () => {
        window.open('https://vlesim.com/api/downloads/apps/android/vlesim.apk', '_blank')
    }

    return (  
        <header 
            className={`header-layout ${props.className ? props.className : ''}`}
            style={props.style}
            ref={size.ref}
        >
            {/* <img onClick={handleGoHome} src={vlesimIcon} alt=""/> */}
            <TitlePageComponent
                className='title-header'
            >
                VLESIM
            </TitlePageComponent>
            <SearchLaboratories className='input-search-header' />
            <div className="container-icons-header">
                {currentReservation ?
                    inProgress ?
                        <LabInProgress
                            reservation={currentReservation}
                        />
                    :
                    <div>
                        <TextComponent
                            type='label'
                            style={{fontWeight: 'bold'}}
                        >
                            Tu reserva inicia en
                        </TextComponent>
                        <TimerComponent
                            size='small'
                            initialDate={currentReservation.date}
                            limitTime={currentReservation.lab.config?.duration}
                            offsetSeconds={currentReservation.lab.config?.duration ? -currentReservation.lab.config?.duration : undefined}
                            withoutProgress
                            onEndTimer={() => validateReservation()}
                        />
                    </div>
                : null}
                {size.width && size.width > 960 ?
                    <ButtonComponent
                        variant='primary'
                        onClick={handleClickDownloadApp}
                    >
                        <RiDownloadFill/>
                        {t('header.download_app')}
                    </ButtonComponent>
                :null}
                <PopUpComponent
                    show={showNotifications}
                    placement='auto'
                    onClickClose={() => updateShowNotifications(false)}
                    content={<NotificationsList/>}
                    hideClose
                >
                    <IconHeader
                        number={unreadNotifications}
                        onClick={() => updateShowNotifications(!showNotifications)}
                    >
                        <MdNotificationsNone/>
                    </IconHeader>
                </PopUpComponent>
                <Link
                    to='/favorites'
                >
                    <TooltipComponent
                        text='Favoritos'
                        placement='bottom'
                    >
                        <IconHeader
                            number={hookLabs.favorites.length}
                        >
                            <GrFavorite/>
                        </IconHeader>
                    </TooltipComponent>
                </Link>
                <div 
                    className="change-theme-icon-header"
                    onClick={handleChangeTheme}
                >
                    <RiMoonClearLine/>
                </div>
            </div>
        </header>
    );
}

// ============================================
// @INFO Icono para el header
// ============================================
interface IIconHeaderProps {
    className?: string
    style?: CSSProperties
    children?: any
    number?: number
    onClick?: () => void
}

const IconHeader: React.FC<IIconHeaderProps> = (props) => {
    return(
        <div
            className={`icon_header-layout ${props.className ? props.className : ''}`}
            style={props.style}
            onClick={props.onClick}
        >
            {props.number ?
                <div className="number-icon-header">
                    {props.number}
                </div>
            :null}
            {props.children}
        </div>
    );
}

 
export default Header;