// @import dependencies
import { gql } from "@apollo/client"
import store from "redux/store"
// @end dependencies

// @import utils
import graphQLUtil from "utils/graphQL/graphQL.util"
// @end utils

// @import types
import * as AuthTypes from 'types/auth/auth.types';
import * as UserTypes from 'types/user/user.types';
// @end types

class UserService{

    private storeObject = store

    constructor(){

    }

    /**
     * @INFO Actualizar un usuario
     * @param _params 
     * @returns 
     */
    public updateUser = async (_params: any) => {
        if(!_params) return {}
        const response = await graphQLUtil.send({
            method: 'mutate',
            data: {
                mutation: gql`
                    mutation updateUser($token:String!, $user: UpdateUserInput!){
                        updateUser(token: $token, user: $user){
                            ${AuthTypes.FullUserGQL}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth.token,
                    user: {..._params}
                }
            }
        },
        {
            alertOnFailed: true
        })
        return response
    }

    /**
     * @INFO Buscar usuarios
     * @param _params 
     */
    public searchUsers = async (_params?: UserTypes.ParamsUsersSearch): Promise<AuthTypes.IUser[]> => {
        const response = await graphQLUtil.send({
            method: 'watch',
            watch: {
                query: gql`
                    query users($token: String!, $search: String){
                        users(token: $token, search: $search){
                            ${UserTypes.searchUsersQuery}
                        }
                    }
                `,
                variables: {
                    token: this.storeObject.getState().auth?.token,
                    search: _params?.search
                }
            }
        })
        if(response?.data?.users?.length){
            return response.data.users
        }else{
            return []
        }
    }
}

export default UserService