import MultiSelectComponent, { IOptionSelect } from 'components/commons/MultiSelectComponent/MultiSelectComponent'
import { FC, useState, useEffect, CSSProperties } from 'react'
import { BannerLocation } from 'types/banners/banners.types'

import './BannerLocationSelect.scss'

interface BannerLocationSelectProps {
    className?: string
    style?: CSSProperties
    id?: string
    label?: string
    defaultValue?: BannerLocation[]
    isMultiple?: boolean
    onChange?: (id?: string[], locations?: IOptionSelect[]) => any
    onChangeOne?: (id?: string, location?: IOptionSelect) => any
}

const BannerLocationSelect: FC<BannerLocationSelectProps> = ({ onChange, onChangeOne, isMultiple, label, defaultValue }) => {

    const [locations, updateLocations] = useState<IOptionSelect[]>([
        {
            label: 'Landing Vlesim',
            value: BannerLocation.LANDING_VLESIM
        },
        {
            label: 'Landing Smart Factory',
            value: BannerLocation.LANDING_SMART_FACTORY
        },
        {
            label: 'Pantalla de laboratorios',
            value: BannerLocation.LABORATORIES_PAGE
        },
    ])
    const [resetSelect, updateResetSelect] = useState<boolean>(false)

    useEffect(() => {
        updateResetSelect(true)
        setTimeout(() => {
          updateResetSelect(false)
        },1)
    }, [defaultValue?.length])

    /**
     * @INFO Cambia el selector 
     * @param _options 
     */
    const handleChangeSelect = (_options: IOptionSelect[]) => {
        const _locations = locations?.filter((_t) => _options.find((_o) => _o.value === _t.value))
        const _location = _locations?.length ? _locations[0] : undefined
        if(onChangeOne){
            onChangeOne(_location?.value, _location)
        }
        if(onChange){
            onChange(_locations?.map((_t) => _t.value) as string[], _locations)
        }
    }

    /**
     * @INFO Obtener las opciones por defecto
     * @returns 
     */
    const getDefaultOptions = () => {
        const _options = locations?.reduce((accum: IOptionSelect[], item) => {
            if(defaultValue?.find((_d) => _d === item.value)){
                accum.push(item);
            }
            return accum
        },[])
        return _options
    }

    return (
        <div className="banner_location_select--layout">
            {!resetSelect ?
                <MultiSelectComponent
                    options={locations}
                    disableMulti={!isMultiple}
                    label={label}
                    defaultValue={getDefaultOptions()}
                    onChange={(data) => handleChangeSelect(data)}
                />
            :null}
        </div>
    )
}

export default BannerLocationSelect