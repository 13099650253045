import { fullPlanQuery, Plan } from "types/plan/plan.types"

export enum DOC_TYPE {
    CC = 'CC',
    CE = 'CE',
    PPN = 'PPN',
    SSN = 'SSN',
    LIC = 'LIC',
    NIT = 'NIT',
    DNI = 'DNI',
}

export enum SUBSCRIPTION_STATUS {
    ACTIVE = 'active',
    TRIAL = 'trial',
    PAST_DUE = 'past_due',
    UNPAID = 'unpaid',
    CANCELLED = 'cancelled',
}

export interface License {
    id: string
    plan: Plan
    subscription: string
    status: string
    user: string
    startDate: Date
    finishDate: Date
    labs: string[]
    affiliates: string[]
    affiliateLimit: number
}

export interface ExtraParamsLicenseCreate {
    cardNumber: string
    expYear: string
    expMonth: string
    cvc: string
    name: string
    city: string
    address: string
    phoneNumber: string
}

export interface ParamsLicensesList {
}

export interface ParamsLicenseCreate{
    license: Omit<License, 'id' | 'subscription' | 'status' | 'user' | 'startDate' | 'finishDate' | 'plan'> & ExtraParamsLicenseCreate & {
        amount: number
        plan: string
    }
}

export interface Subscription {
    id: string;
    card: Card;
    cancel_at_period_end: boolean;
    charge_date: string;
    creation_date: Date;
    current_period_number: number;
    period_end_date: string;
    status: SUBSCRIPTION_STATUS;
    trial_end_date: string;
    plan_id: string;
    customer_id: string;
}

export interface Card {
    id: string;
    type: string;
    brand: string;
    card_number: string;
    holder_name: string;
    expiration_year: string;
    expiration_month: string;
    allows_charges: boolean;
    allows_payouts: boolean;
    creation_date: Date;
    cvv2?: string;
    bank_name: string;
    bank_code: string;
    customer_id: string;
}

export type PaymentGatewayResponse = Subscription

export interface ResponseLicenseCreate {
    license: License
    paymentGatewayResponse: PaymentGatewayResponse
}

export interface ParamsLicenseUpdate{
    license: Partial<Omit<License, 'id'>>
    id: string
}

export interface ParamsLicenseDelete{
    id: string
}

export interface ParamsLicenseGetById{
    id: string
}

export interface LicenseStateParams {
    plan: string
    amount: number
    labs: string[]
}

export interface ParamsNewLicenseExistingCard {
    license: {
        plan: string
        labs: string[]
        amount: number
        affiliates?: string[]
        affiliateLimit?: number
        tokenCard: string
        cardNumber: string
        default?: boolean
    }
}

export type Payment = Partial<Card>

export const fullLicenseQuery = `
    id
    plan {
        ${fullPlanQuery}
    }
    subscription
    status
    user
    startDate
    finishDate
    labs
    affiliates
    affiliateLimit
`

export const fullLicenseCreateQuery = `
    license {
        ${fullLicenseQuery}
    }
    paymentGatewayResponse {
        id
        card {
            id
            type
        }
        cancel_at_period_end
        charge_date
        creation_date
        current_period_number
        period_end_date
        status
        trial_end_date
        plan_id
        customer_id
    }
`

export const fullPaymentQuery = `
    id
    type
    brand
    card_number
    holder_name
    expiration_year
    expiration_month
    allows_charges
    allows_payouts
    creation_date
    bank_name
    bank_code
    customer_id
`
