// @import types
import * as ReservationTypes from 'types/reservations/reservations.types';
// @end types

export const SET_RESERVATION_DASHBOARD = '[dashboard] SET_RESERVATION_DASHBOARD';
export const SET_RESERVATIONS_DASHBOARD = '[dashboard] SET_RESERVATIONS_DASHBOARD';
export const UPDATE_RESERVATION_DASHBOARD = '[dashboard] UPDATE_RESERVATION_DASHBOARD';
export const UPDATE_RESERVATION_DASHBOARD_SUCCESS = '[dashboard] UPDATE_RESERVATION_DASHBOARD_SUCCESS';
export const UPDATE_RESERVATION_DASHBOARD_ERROR = '[dashboard] UPDATE_RESERVATION_DASHBOARD_ERROR';

export interface DashboardState{
    reservation?: ReservationTypes.IReservation;
    reservations: ReservationTypes.IReservation[];
    loading: boolean
    error: boolean
}

interface SetReservationAction {
    type: typeof SET_RESERVATION_DASHBOARD;
    payload: ReservationTypes.IReservation | undefined;
}

interface SetReservationsAction {
    type: typeof SET_RESERVATIONS_DASHBOARD;
    payload: ReservationTypes.IReservation[];
}

interface UpdateReservationDashboardAction {
    type: typeof UPDATE_RESERVATION_DASHBOARD;
    payload: undefined;
}

interface UpdateReservationDashboardActionSuccess {
    type: typeof UPDATE_RESERVATION_DASHBOARD_SUCCESS;
    payload: ReservationTypes.IReservation;
}

interface UpdateReservationDashboardActionError {
    type: typeof UPDATE_RESERVATION_DASHBOARD_ERROR;
    payload: undefined;
}

export type DashboardActionsTypes = SetReservationAction | SetReservationsAction | UpdateReservationDashboardAction |
                                    UpdateReservationDashboardActionSuccess | UpdateReservationDashboardActionError;