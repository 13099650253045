// @import dependencies
import React, { CSSProperties } from 'react'
// @end dependencies

// @import components
import FooterLanding from '../FooterLanding/FooterLanding';
import HeaderLandingPage from '../HeaderLandingPage/HeaderLandingPage';
// @end components

// @import types
// @end types

// @import services
// @end services

// @import hooks
import { useChangeTheme } from 'hooks/useChangeTheme/useChangeTheme';
// @end hooks

// @import actions
// @end actions

// @import utils
// @end utils

// @import assets
// @end assets

// @import styles
import './LandingLayout.scss'
// @end styles

interface ILandingLayoutProps {
    children?: any
    className?: string
    style?: CSSProperties
    id?: string
    hideFooter?: boolean
}

const LandingLayout: React.FC<ILandingLayoutProps> = (props) => {

    const themeControl = useChangeTheme()

    return(
        <div
            className={`landing_layout-layout ${props.className ? props.className : ''}`}
            style={{
                ...props.style,
                backgroundColor: themeControl.theme === 'light' ? 'white' : undefined
            }}
            id={props.id}
        >
            <HeaderLandingPage/>
            {props.children}
            {!props.hideFooter ?
                <FooterLanding
                    style={{
                        marginTop: 'auto'
                    }}
                />
            :null}
        </div>
    );
}

export default LandingLayout;