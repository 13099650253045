import * as OccupationTypes from 'types/occupation/occupation.types';
export interface IAuthState{
    loading: boolean
    user?: IUser
    hasData: boolean
    isLogged: boolean
    token?: string
}

export interface IUser{
    id: string
    firstName: string
    lastName: string
    birthday: Date
    phoneNumber: string
    occupation: OccupationTypes.Occupation
    avatar: string
    docNumber: number
    user: string
    email: string
    password: string
    institution: string
    state: boolean
    google: boolean
    publicKeyEncrypt: string
    role: {
        id: string
        name: string
        permissions: string[]
    }
    country: string
    province: string
    city: string
    lang: 'es' | 'en'
}

export interface ILoginCredentials{
    email: string
    password: string
}

export interface ILoginByToken{
    token: string
}

export interface IParamsChangePassword{
    currentPassword: string
    newPassword: string
}

export interface VerifyAuthenticationCodeParams {
    token: string
    code: string
}

// ================================================
// Para Graph QL
// ================================================
// ! falta avatar e institution
export const FullUserGQL = `
    id
    firstName
    lastName
    birthday
    phoneNumber
    occupation{
        id
        name
    }
    avatar
    docNumber
    user
    email
    password
    institution
    state
    google
    publicKeyEncrypt
    role{
        id
        name
        permissions
    }
    country
    province
    city
    lang
`